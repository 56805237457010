import { CreateCategoryInput, Product } from 'src/ts-types/custom.types';
import { ProductType } from 'src/ts-types/generated';

const exampleCategory: CreateCategoryInput = {
  categoryTagIcons: 'Accessories',
  categoryImage:
    'https://ik.imagekit.io/bcupc7szar/v0/b/theshop-334912.appspot.com/o/654817627d2907736c84fa8b%2Fcategory%2F5rbB?alt=media&token=e6290961-95da-4745-9000-ca890c2c59e5',
  categoryNameWithTeanslations: [
    {
      Language: 'he',
      value: 'שם קטגוריה',
    },
    {
      Language: 'ar',
      value: 'اسم الفئة',
    },
  ],
};

const productData: Product = {
  price: 1200,
  mainImage: '',
  moreImages: [],
  brandId: '',
  categoryId: '654827f17d2907736c84fa8c',
  variantAllOptions: [
    {
      optionName: {
        meta: 'color',
        optionNameId: 'k1',
        valueWithTeanslations: [
          {
            value: 'צבע',
            Language: 'he',
          },
        ],
      },
      optionsValues: [
        {
          optionValueId: 'v1',
          meta: '#FF0000',
          valueWithTeanslations: [
            {
              value: 'Red',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v2',
          meta: '#FFFFFF',
          valueWithTeanslations: [
            {
              value: 'white',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v3',
          meta: '#000000',
          valueWithTeanslations: [
            {
              value: 'black',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v4',
          meta: '#00FF00',
          valueWithTeanslations: [
            {
              value: 'Lime',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v5',
          meta: '#0000FF',
          valueWithTeanslations: [
            {
              value: 'Blue',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v6',
          meta: '#FFFF00',
          valueWithTeanslations: [
            {
              value: 'Yellow',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v7',
          meta: '#00FFFF',
          valueWithTeanslations: [
            {
              value: 'Cyan',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v8',
          meta: '#FF00FF',
          valueWithTeanslations: [
            {
              value: 'Magenta',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v9',
          meta: '#C0C0C0',
          valueWithTeanslations: [
            {
              value: 'Silver',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v10',
          meta: '#808080',
          valueWithTeanslations: [
            {
              value: 'Gray',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v11',
          meta: '#800000',
          valueWithTeanslations: [
            {
              value: 'Maroon',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v12',
          meta: '#808000',
          valueWithTeanslations: [
            {
              value: 'Olive',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v13',
          meta: '#008000',
          valueWithTeanslations: [
            {
              value: 'Green',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v14',
          meta: '#800080',
          valueWithTeanslations: [
            {
              value: 'Purple',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v15',
          meta: '#008080',
          valueWithTeanslations: [
            {
              value: 'Teal',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v16',
          meta: '#000080',
          valueWithTeanslations: [
            {
              value: 'Navy',
              Language: 'he',
            },
          ],
        },
      ],
    },
    {
      optionName: {
        optionNameId: 'k2',
        valueWithTeanslations: [
          {
            value: ' מידה',
            Language: 'he',
          },
        ],
      },
      optionsValues: [
        {
          optionValueId: 'v0',
          meta: 'OneSize',
          valueWithTeanslations: [
            {
              value: 'One Size',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v1',
          meta: 'Small',
          valueWithTeanslations: [
            {
              value: 'Small',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v2',
          meta: 'Medium',
          valueWithTeanslations: [
            {
              value: 'Medium',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v3',
          meta: 'Large',
          valueWithTeanslations: [
            {
              value: 'Large',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v4',
          meta: 'X-Large',
          valueWithTeanslations: [
            {
              value: 'X-Large',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v5',
          meta: 'XX-Large',
          valueWithTeanslations: [
            {
              value: 'XX-Large',
              Language: 'he',
            },
          ],
        },
      ],
    },
    {
      optionName: {
        optionNameId: 'k3',
        valueWithTeanslations: [
          {
            value: ' מידה נעלים',
            Language: 'he',
          },
        ],
      },
      optionsValues: [
        {
          optionValueId: 'v0',
          meta: '35',
          valueWithTeanslations: [
            {
              value: '35',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v1',
          meta: '36',
          valueWithTeanslations: [
            {
              value: '36',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v2',
          meta: '37',
          valueWithTeanslations: [
            {
              value: '37',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v3',
          meta: '38',
          valueWithTeanslations: [
            {
              value: '38',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v4',
          meta: '39',
          valueWithTeanslations: [
            {
              value: '39',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v5',
          meta: '40',
          valueWithTeanslations: [
            {
              value: '40',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v6',
          meta: '41',
          valueWithTeanslations: [
            {
              value: '41',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v7',
          meta: '42',
          valueWithTeanslations: [
            {
              value: '42',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v8',
          meta: '43',
          valueWithTeanslations: [
            {
              value: '43',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v9',
          meta: '44',
          valueWithTeanslations: [
            {
              value: '44',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v10',
          meta: '45',
          valueWithTeanslations: [
            {
              value: '45',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v11',
          meta: '46',
          valueWithTeanslations: [
            {
              value: '46',
              Language: 'he',
            },
          ],
        },
        {
          optionValueId: 'v12',
          meta: '47',
          valueWithTeanslations: [
            {
              value: '47',
              Language: 'he',
            },
          ],
        },
      ],
    },
  ],
  variantDetailsList: [
    {
      price: 1200,
      countInStock: 1000,
      active: true,
      variantsOptionsCombineList: [
        {
          optionNameId: 'k1',
          optionValueId: 'v1',
        },
        {
          optionNameId: 'k2',
          optionValueId: 'v0',
        },
      ],
      discountPrice: 1000,
      mainImage:
        'https://ik.imagekit.io/bcupc7szar/v0/b/theshop-334912.appspot.com/o/654817627d2907736c84fa8b%2Fproducts%2FZZJY?alt=media&token=5e54bafc-6e9c-4d2d-b37f-40e6a7f84b76',
      mainVariant: true,
    },
    {
      price: 1200,
      countInStock: 1000,
      active: true,
      variantsOptionsCombineList: [
        {
          optionNameId: 'k1',
          optionValueId: 'v1',
        },
        {
          optionNameId: 'k2',
          optionValueId: 'v2',
        },
      ],
      discountPrice: 1000,
      mainImage:
        'https://ik.imagekit.io/bcupc7szar/v0/b/theshop-334912.appspot.com/o/654817627d2907736c84fa8b%2Fproducts%2Fx8od?alt=media&token=1c2025c4-29e2-40d0-b3c9-91afd2ac5a12',
      mainVariant: false,
    },
    {
      price: 1200,
      countInStock: 1000,
      active: true,
      variantsOptionsCombineList: [
        {
          optionNameId: 'k1',
          optionValueId: 'v3',
        },
        {
          optionNameId: 'k2',
          optionValueId: 'v3',
        },
      ],
      discountPrice: 1000,
      mainImage: '/product.svg',
      mainVariant: false,
    },
  ],
  discountPrice: 200,
  active: true,
  topProduct: false,
  name: [],
  productType: ProductType.Variable,
  productInfoWithTranslations: [
    {
      language: 'he',
      name: 'מוצר לדוגמה',
      shortDescription: 'כותרת מוצר לדוגמה',
      description:
        'וצר הוא פריט פיזי או נגמר, שירות או פריט דיגיטלי שניותר בשוק למכירה כלכלית בכדי לענות על צרכים או רצונות של לקוחות. מוצר יכול להיות חפשי, כגון צעצוע, סחורה ניירתית, או פריט אלקטרוני, וגם להיות מוצר שמשלב בין מרכיבים שונים, כמו מכונת כביסה, רכב אוטומטי, או מערכת תוכנה.',
    },
    {
      language: 'ar',
      name: 'المنتج',
      shortDescription: 'عنوان المنتج',
      description:
        'السلعة هي عنصر مادي أو نهائي أو خدمة أو عنصر رقمي يتم طرحه في السوق للبيع الاقتصادي لتلبية احتياجات العملاء أو رغباتهم. يمكن أن يكون المنتج مجانيًا، مثل لعبة أو سلعة ورقية أو عنصر إلكتروني، كما يمكن أن يكون منتجًا يجمع بين مكونات مختلفة، مثل غسالة أو مركبة أوتوماتيكية أو نظام برمجي.',
    },
  ],
  countInStock: 1000,
  variantType: 'Cloths',
};

export { exampleCategory, productData };
