import Card from '../../components/common/card';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { useEffect, useState } from 'react';
import { ContactUsPayload, ContactUsResponse } from '../../ts-types/custom.types';
import { ROUTES } from '../../utils/routes';
import Button from '../../components/ui/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Loader from 'src/components/ui/loader/loader';
// @ts-ignore
import { useForm } from 'react-hook-form';
import Input from 'src/components/ui/input';
import { UpdateContactUs, getContactUs } from 'src/store/shop/shopsAPIActions';
// @ts-ignore
import { toast } from 'react-toastify';
import { getShopDetails } from 'src/utils/shopUtils';
import { RootState } from 'src/store';
import { TOAST_MESSAGE } from 'src/utils/constants';

const ContactUsPage = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const contactUsDetailsResponse: ContactUsResponse =
    useAppSelector((state: RootState) => state.shop.shopDetails?.updateContactUsResponse) || {};

  const { data: contactUsDetails, isFetching } = contactUsDetailsResponse;

  const methods = useForm<ContactUsPayload>({
    defaultValues: { ...contactUsDetails },
    shouldUnregister: true,
    mode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    dispatch(getContactUs());
  }, []);

  const onSubmit = (values: ContactUsPayload) => {
    const areAllValuesNull = Object.values(contactUsDetails ?? {}).every((value) => value === null);

    dispatch(UpdateContactUs({ ...values }, areAllValuesNull))
      .then((response: any) => {
        toast.success(t(TOAST_MESSAGE.CONTACT_INFO_UPDATED));
        const { shopId } = getShopDetails();
        history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`);
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        setLoading(false);
      });
    setTimeout(() => {
      dispatch(getContactUs());
    }, 700);
  };

  if (loading) return <Loader text={t('text-loading')} />;
  const { shopId } = getShopDetails();

  if (isFetching) return <Loader text={t('text-loading')} />;

  return (
    <Card
      className=''
      title={t('contact-us-details-title')}
      titleClassName='text-lg font-semibold text-heading'
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className='grid sm:grid-cols-1 md:grid-cols-1 gap-2 content-evenly animate-in slide-in-from-top'>
          <Card className='' withBg={false} withShadow={false}>
            <div className='flex flex-col md:flex-row'>
              <Input
                label={t('input-label-email-address')}
                {...register('emailAddress')}
                type='email'
                variant='outline'
                className='mb-4'
                error={t(errors?.emailAddress?.message)}
              />
              <Input
                label={t('input-label-phone-number')}
                {...register('phoneNumber')}
                type='email'
                variant='outline'
                className='mb-4'
                containerClassName='md:mr-4'
                error={t(errors?.phoneNumber?.message)}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <Input
                label={t('input-label-whatsUp')}
                {...register('whatsUp')}
                type='email'
                variant='outline'
                className='mb-4'
                error={t(errors?.emailAddress?.message)}
              />
            </div>
          </Card>
          <Card className='' title={t('address-details-title')} withBg={false} withShadow={false}>
            <div className='flex flex-col md:flex-row'>
              <Input
                label={t('input-label-city')}
                {...register('address.city')}
                type='email'
                variant='outline'
                className='mb-4'
                error={t(errors?.address?.city?.message)}
              />
              <Input
                label={t('input-label-street-address')}
                {...register('address.streetAddress')}
                type='email'
                variant='outline'
                className='mb-4'
                containerClassName='md:mr-4'
                error={t(errors?.address?.streetAddress?.message)}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <Input
                label={t('input-label-longitude')}
                {...register('address.longitude')}
                type='number'
                variant='outline'
                className='mb-4'
                error={t(errors?.address?.longitude)}
              />
              <Input
                label={t('input-label-latitude')}
                {...register('address.latitude')}
                type='number'
                variant='outline'
                className='mb-4'
                error={t(errors?.address?.latitude)}
              />
            </div>
          </Card>
          <Card className='' title={t('contact-us-social-media')} withBg={false} withShadow={false}>
            <div className='flex flex-col md:flex-row'>
              <Input
                label={t('input-label-facebook')}
                {...register('followUs.facebook')}
                type='email'
                variant='outline'
                className='mb-4'
                error={t(errors?.followUs?.facebook?.message)}
              />
              <Input
                label={t('input-label-instagram')}
                {...register('followUs.instagram')}
                type='email'
                variant='outline'
                className='mb-4'
                containerClassName='md:mr-4'
                error={t(errors?.followUs?.instagram?.message)}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <Input
                label={t('input-label-twitter')}
                {...register('followUs.twitter')}
                type='email'
                variant='outline'
                className='mb-4'
                error={t(errors?.followUs?.twitter?.message)}
              />
              <Input
                label={t('input-label-youtube')}
                {...register('followUs.youtube')}
                type='email'
                variant='outline'
                className='mb-4'
                containerClassName='md:mr-4'
                error={t(errors?.followUs?.youtube?.message)}
              />
            </div>
          </Card>
          {/* <Card
            className=''
            title={t('contact-us-working-hours')}
            withBg={false}
            withShadow={false}
          >
            <div className='w-full items-center'>
              <Input
                label={t('input-label-companyID')}
                {...register('emailAddress')}
                type='email'
                variant='outline'
                className='mb-4'
                error={t(errors?.emailAddress?.message)}
              />
              <Input
                label={t('input-label-apiKey')}
                {...register('phoneNumber')}
                type='email'
                variant='outline'
                className='mb-4'
                error={t(errors?.phoneNumber?.message)}
              />
            </div>
          </Card> */}
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {t('form-save')}
        </Button>
        <Button variant='outline' onClick={() => history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`)}>
          {t('form-return')}
        </Button>
      </form>
    </Card>
  );
};

export default ContactUsPage;
