import ConfirmationCard from 'src/components/common/confirmation-card';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { deleteCoupon, getAllCoupons } from 'src/store/shop/actions';
import { useAppDispatch } from 'src/utils/reduxHooks';

const CouponDeleteView = () => {
  const { data } = useModalState();
  const { closeModal } = useModalAction();
  const dispatch = useAppDispatch();

  async function handleDelete() {
    dispatch(deleteCoupon(data)).then((res: any) => {
      dispatch(getAllCoupons());
    });
    closeModal();
  }
  return (
    <ConfirmationCard onCancel={closeModal} onDelete={handleDelete} deleteBtnLoading={false} />
  );
};

export default CouponDeleteView;
