import SelectInput from 'src/components/ui/unControlled-select-input';
import Label from 'src/components/ui/label';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Categories, Category } from 'src/ts-types/custom.types';
import { getTranslatedItem } from 'src/common';

interface Props {
  className?: string;
  control: Control<any>;
  setValue: any;
  getValue?: any;
  title?: string;
  required?: boolean;
  categories?: Categories;
  defaultValue?: string;
  error?: string;
  nameToWatch?: string;
  handleChange?: any;
  watch?: (val: string) => any;
}

const ProductCategoryInput = ({
  control,
  setValue,
  error,
  nameToWatch = 'categoryId',
  title = 'input-label-categories',
  categories,
  defaultValue = '',
  className = '',
  required,
  handleChange,
  watch,
}: Props) => {
  const { t }: any = useTranslation<any>();
  const category = watch?.(nameToWatch);

  // eslint-disable-next-line array-callback-return
  const cate: Category[] = [];
  categories?.data?.forEach((item: Category) => {
    item.name = getTranslatedItem(item?.categoryNameWithTeanslations ?? undefined);
    item.id = item.categoryId;
    cate.push(item);
    item.children?.forEach((subItemCategories: Category) => {
      subItemCategories.name = `\u23CE  ${getTranslatedItem(
        subItemCategories?.categoryNameWithTeanslations,
      )}`;
      subItemCategories.id = subItemCategories.categoryId;
      cate.push(subItemCategories);
    });
  });

  return (
    <div className={`mb-5 ${className}`}>
      <Label className={`${required ? 'required-input' : ''}`}>{t(title)}</Label>
      <SelectInput
        name='categoryId'
        value={category}
        setValue={setValue}
        handleOnChange={handleChange}
        defaultValue={defaultValue}
        control={control}
        getOptionLabel={(option: any) => option.name}
        getOptionValue={(option: any) => option.id}
        options={cate}
        errorStyle={error ? 'border-red-500 focus:border-red-500' : ''} // Pass errorStyle if error exists
      />
      {error && <span className='my-2 text-xs text-end text-red-500'>{error}</span>}
    </div>
  );
};

export default ProductCategoryInput;
