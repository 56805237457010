import cn from 'classnames';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Avatar from 'src/components/common/avatar';
import { siteSettings } from 'src/settings/site.settings';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { RootState } from 'src/store';
import { fetchUserDetails } from 'src/store/session/actions';

export default function AuthorizedMenu() {
  const { t }: any = useTranslation<any>();
  const dispatch = useAppDispatch();

  const userDetails = useAppSelector(
    (state: RootState) => state.session?.sessionDetails?.userDetails,
  );
  // const data = useAppSelector((state: RootState) => state.session.sessionDetails);

  // useEffect(() => {
  //   dispatch(fetchUserDetails(data?.logIn?.id ?? ''));
  // }, []);

  // Again, we're using framer-motion for the transition effect
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <Menu.Button className='flex items-center focus:outline-none'>
        <Avatar
          src={userDetails?.data?.profilePictureUrl ?? siteSettings?.avatar?.placeholder}
          alt='avatar'
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          as='ul'
          className={cn(
            'absolute end-0 w-48 mt-1 origin-top-end bg-white rounded shadow-700 focus:outline-none',
          )}
        >
          {siteSettings.authorizedLinks.map(({ href, labelTransKey }) => (
            <Menu.Item key={`${href}${labelTransKey}`}>
              {({ active }) => (
                <li className='border-b border-gray-100 cursor-pointer last:border-0'>
                  <Link
                    to={href}
                    className={cn(
                      'block px-4 py-3 text-sm capitalize font-semibold transition duration-200 hover:text-accent',
                      active ? 'text-accent' : 'text-heading',
                    )}
                  >
                    {t(labelTransKey)}
                  </Link>
                </li>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
