/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { siteSettings } from 'src/settings/site.settings';
import { TranslationObject } from 'src/ts-types/custom.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import RTLTextEditor from './RTLTextEditor';
import { getTranslatedLang, getTranslatedValue } from 'src/common';

const modules = {
  toolbar: false, // Provide default toolbar or hide it
};
export interface Props {
  className?: string;
  inputClassName?: string;
  label?: string;
  required?: boolean;
  name: string;
  values?: TranslationObject[];
  setValue: (name: string, val: unknown) => void;
  error?: string;
  shadow?: boolean;
  onBlur?: any;
  variant?: 'normal' | 'solid' | 'outline';
}

const classes = {
  root: 'py-3 px-4 w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0',
  normal: 'bg-gray-100 border border-border-base focus:shadow focus:bg-light focus:border-accent',
  solid: 'bg-gray-100 border border-border-100 focus:bg-light focus:border-accent',
  outline: 'border border-border-base focus:border-accent',
  shadow: 'focus:shadow',
};

const TextAreaWithTranslation = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    label,
    name,
    error,
    required,
    values,
    setValue,
    variant = 'normal',
    shadow = false,
    inputClassName,
    onBlur,
    ...rest
  } = props;
  const [activeLanguage, setActiveLanguage] = useState<string>(siteSettings.languages[0].value);
  const [saveText, setSaveText] = useState<string>(false);
  const [translatedObject, setTranslatedObject] = useState<TranslationObject[]>(values! ?? []);
  const [inputValue, setInputValue] = useState<string>(
    getTranslatedValue(values || [], activeLanguage) || '',
  );
  console.log('inputValue --> ', inputValue);

  const { t }: any = useTranslation<any>();

  const rootClassName = cn(
    classes.root,
    {
      [classes.normal]: variant === 'normal',
      [classes.solid]: variant === 'solid',
      [classes.outline]: variant === 'outline',
    },
    {
      [classes.shadow]: shadow,
    },
    inputClassName,
  );

  const handleInputChange = (content, delta, source, editor) => {
    if (saveText) {
      const textValue = editor.getHTML();
      const isLanExist = getTranslatedLang(translatedObject, activeLanguage);
      if (isLanExist) {
        translatedObject?.map((item: TranslationObject) => {
          if (item.Language === activeLanguage) {
            item.value = textValue;
          }
        });
      } else {
        translatedObject?.push({
          Language: activeLanguage,
          value: textValue,
        });
      }
      setTranslatedObject(translatedObject);
      setValue(translatedObject);
      setInputValue(getTranslatedValue(translatedObject, activeLanguage));
    }
    setSaveText(true);
  };

  const handleChangeActiveLanguage = (language: string) => {
    setTimeout(() => {
      setActiveLanguage(language);
      setInputValue(getTranslatedValue(translatedObject, language) || '');
      setSaveText(false);
    }, 300);
  };

  return (
    <div className={` w-full mx-2 svelte-1l8159u ${className}`}>
      <div className='flex items-center justify-between mb-1 mt-3'>
        {label && (
          <>
            <label
              className={`${
                required ? 'required-input' : ''
              } block text-body-dark font-semibold text-sm leading-none`}
            >
              {label}
            </label>
            <div className='flex justify-end'>
              <span className='opacity-80 text-sm'>{t('translated_field')}</span>
              <FontAwesomeIcon icon={faLanguage as IconProp} size={'1x'} className='pr-2 pt-1' />
            </div>
          </>
        )}
      </div>
      <RTLTextEditor
        value={inputValue}
        onChange={handleInputChange}
        onBlur={onBlur}
        ref={ref}
        {...rest}
      />
      <div className={'flex text-xs text-start w-full flex flex-col md:flex-row'}>
        <div className='text-start text-xs text-start w-full flex-col md:flex-row items-center'>
          {siteSettings.languages?.map((item) => (
            <span
              onClick={() => handleChangeActiveLanguage(item.value)}
              className={` cursor-pointer ${
                item.value === activeLanguage ? 'font-bold text-lg text-accent' : 'text-sm'
              }`}
            >
              {item.displayValue}
            </span>
          ))}
        </div>
        {error && (
          <span className='my-2 text-xs text-end text-red-500 justify-end w-full md:w-3/4 flex flex-col md:flex-row '>
            {error}
          </span>
        )}
      </div>
    </div>
  );
});

export default TextAreaWithTranslation;
