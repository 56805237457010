export const categoryIcons = [
  {
    value: 'Accessories',
    label: 'Accessories',
  },
  {
    value: 'FruitsVegetable',
    label: 'Fruits and Vegetable',
  },
  {
    value: 'MeatFish',
    label: 'Meat and Fish',
  },
  {
    value: 'Purse',
    label: 'Purse',
  },
  {
    value: 'HandBags',
    label: 'Hand Bags',
  },
  {
    value: 'ShoulderBags',
    label: 'Shoulder Bags',
  },
  {
    value: 'Wallet',
    label: 'Wallet',
  },
  {
    value: 'LaptopBags',
    label: 'Laptop Bags',
  },
  {
    value: 'WomenDress',
    label: 'Women Dress',
  },
  {
    value: 'OuterWear',
    label: 'Outer Wear',
  },
  {
    value: 'Pants',
    label: 'Pants',
  },
  {
    value: 'Tops',
    label: 'Tops',
  },
  {
    value: 'Shirts',
    label: 'Shirts',
  },
  {
    value: 'Skirts',
    label: 'Skirts',
  },
  {
    value: 'Face',
    label: 'Face',
  },
  {
    value: 'Eyes',
    label: 'Eyes',
  },
  {
    value: 'Lips',
    label: 'Lips',
  },
  {
    value: 'Snacks',
    label: 'Snacks',
  },
  {
    value: 'PetCare',
    label: 'PetCare',
  },
  {
    value: 'HomeCleaning',
    label: 'Home Cleaning',
  },
  {
    value: 'Dairy',
    label: 'Dairy',
  },
  {
    value: 'Cooking',
    label: 'Cooking',
  },
  {
    value: 'Breakfast',
    label: 'Breakfast',
  },
  {
    value: 'Beverage',
    label: 'Beverage',
  },
  {
    value: 'BeautyHealth',
    label: 'Beauty Health',
  },
  {
    value: 'ShavingNeeds',
    label: 'Shaving Needs',
  },
  {
    value: 'OralCare',
    label: 'Oral Care',
  },
  {
    value: 'FacialCare',
    label: 'Facial Care',
  },
  {
    value: 'Deodorant',
    label: 'Deodorant',
  },
  {
    value: 'BathOil',
    label: 'Bath Oil',
  },
  {
    value: 'Chair',
    label: 'Chair',
  },
  {
    value: 'Bed',
    label: 'Bed',
  },
  {
    value: 'Bookshelf',
    label: 'Bookshelf',
  },
  {
    value: 'CenterTable',
    label: 'Center Table',
  },
  {
    value: 'DressingTable',
    label: 'Dressing Table',
  },
  {
    value: 'ReadingTable',
    label: 'Reading Table',
  },
  {
    value: 'Sofa',
    label: 'Sofa',
  },
  {
    value: 'RelaxChair',
    label: 'Relax Chair',
  },
  {
    value: 'Storage',
    label: 'Storage',
  },
  {
    value: 'Tools',
    label: 'Tools',
  },
  {
    value: 'Table',
    label: 'Table',
  },
  {
    value: 'Perfume',
    label: 'Perfume',
  },
  {
    value: 'HomeProducts',
    label: 'Home Products',
  },
  {
    value: 'NichePerfumes',
    label: 'Niche perfumes',
  },
  {
    value: 'FacialCareNew',
    label: 'Facial care',
  },
  {
    value: 'MakeUp',
    label: 'MakeUp',
  },
  {
    value: 'BodyCare',
    label: 'Body Care',
  },
  {
    value: 'Gift',
    label: 'Gift',
  },
  {
    value: 'Vitamins',
    label: 'Vitamins',
  },
  {
    value: 'Baby',
    label: 'Baby',
  },
  {
    value: 'Watch',
    label: 'Watch',
  },
  {
    value: 'Others',
    label: 'Others',
  },
  {
    value: 'Appliances',
    label: 'Appliances',
  },
  {
    value: 'Dental',
    label: 'Dental products',
  },
];
