/* eslint-disable @typescript-eslint/no-explicit-any */
export const WithdrawIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
    <path
      data-name='Path 22632'
      d='M8 6.594a2.344 2.344 0 102.344 2.344A2.346 2.346 0 008 6.594zm0 3.75a1.406 1.406 0 111.406-1.406A1.408 1.408 0 018 10.344z'
      fill='currentColor'
    />
    <path
      data-name='Path 22633'
      d='M14.594 0H1.406A1.408 1.408 0 000 1.406v1.875a1.408 1.408 0 001.406 1.407h2.375v10.843A.469.469 0 004.25 16h7.5a.469.469 0 00.469-.469V4.688h2.375A1.408 1.408 0 0016 3.281V1.406A1.408 1.408 0 0014.594 0zm-3.313 15.063H4.719V2.813h6.563zm3.782-11.782a.469.469 0 01-.469.469h-2.375v-.937h1.438a.469.469 0 000-.937H2.344a.469.469 0 000 .938h1.437v.936H1.406a.469.469 0 01-.469-.469V1.406a.469.469 0 01.469-.468h13.188a.469.469 0 01.469.469z'
      fill='currentColor'
    />
    <path
      data-name='Path 22634'
      d='M8 12.219a.469.469 0 00-.469.469v.969a.469.469 0 00.938 0v-.969A.469.469 0 008 12.219z'
      fill='currentColor'
    />
    <path
      data-name='Path 22635'
      d='M8 5.656a.469.469 0 00.469-.469v-.969a.469.469 0 00-.937 0v.969A.469.469 0 008 5.656z'
      fill='currentColor'
    />
  </svg>
);
