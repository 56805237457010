import Card from 'src/components/common/card';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  getShippingAdWithRulesForAdmin,
  setShippingPriceWithRules,
  updateShippingPriceWithRules,
} from '../../store/shop/actions';
import Button from 'src/components/ui/button';
import Input from '../../components/ui/input';
import { ShippingAdWithRules } from '../../ts-types/custom.types';
import { toast } from 'react-toastify';
import { withPermission } from 'src/HOC/withPermission';
import { yupResolver } from '@hookform/resolvers/yup';
import { shippingFormValidation } from './shippingFormValidation';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import SwitchInput from 'src/components/ui/switch-input';
import { RootState } from 'src/store';
import { TOAST_MESSAGE } from 'src/utils/constants';
import { setShippingAdWithRules } from 'src/store/shop/actionTypes';
import { getShopDetails } from 'src/utils/shopUtils';
import TextAreaWithTranslation from 'src/components/ui/text-areaWithTranslation';
import Trash from 'src/components/icons/trash';

const ShippingPage = () => {
  const [, setErrorMessage] = useState<string | null>(null);
  const shippingResponse =
    useAppSelector((state: RootState) => state.shop.shopDetails?.shippingAdWithRules) || {};
  const {
    active,
    pricePerDistance = false,
    adMessage,
    freeShippingWhenTotalIs,
    shippingPrice,
    distancePriceList,
  } = shippingResponse.data ?? {};
  const [isActive, setIsActive] = useState<boolean>(active ?? false);
  const [priceDistance, setPricePerDistance] = useState<boolean>(pricePerDistance ?? false);

  const dispatch = useAppDispatch();
  const { t }: any = useTranslation();
  const history = useHistory();

  useEffect(() => {
    dispatch(setShippingAdWithRules({ data: {} }));
    dispatch(getShippingAdWithRulesForAdmin());
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<ShippingAdWithRules>({
    mode: 'onBlur',
    defaultValues: {
      adMessage: adMessage,
      active,
      shippingPrice,
      freeShippingWhenTotalIs,
      pricePerDistance,
      distancePriceList: distancePriceList || [],
    },
    resolver: yupResolver(shippingFormValidation),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'distancePriceList',
  });

  useEffect(() => {
    if (shippingResponse.data) {
      setValue('shippingId', shippingResponse.data.shippingId);
      setValue('active', shippingResponse.data.active);
      setValue('shippingPrice', shippingResponse.data.shippingPrice);
      setValue('freeShippingWhenTotalIs', shippingResponse.data.freeShippingWhenTotalIs);
      setValue('adMessage', shippingResponse.data.adMessage);
      setValue('pricePerDistance', shippingResponse.data.pricePerDistance);
      setPricePerDistance(shippingResponse.data.pricePerDistance ?? false);
      setIsActive(shippingResponse?.data?.active ?? false);
    }
  }, [shippingResponse, setValue]);

  const updateValue = (values: ShippingAdWithRules) => {
    const { adMessage } = shippingResponse.data ?? {};
    if (adMessage) {
      dispatch(updateShippingPriceWithRules(values))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
          dispatch(getShippingAdWithRulesForAdmin());
        })
        .catch((error: { response: { data: { message?: any } } }) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    } else {
      dispatch(setShippingPriceWithRules(values))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        })
        .catch((error: { response: { data: { message?: any } } }) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            dispatch(getShippingAdWithRulesForAdmin());
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    }
  };

  const { shopId } = getShopDetails();

  return (
    <form onSubmit={handleSubmit(updateValue)} className=''>
      <Card
        title={<h1 className='text-lg font-semibold text-heading'>{t('input-label-Shipping')}</h1>}
      >
        <div className='w-full md:w-2/3'>
          <div className='mt-5 pr-3'>
            <SwitchInput
              label={t('input-label-disable-variant')}
              setValue={setValue}
              defaultValue={isActive}
              name='active'
              onChange={(val) => setIsActive(val)}
              value={watch('active')}
            />
          </div>
          <TextAreaWithTranslation
            label={t('input-label-Shipping-title-text')}
            values={watch('adMessage')}
            setValue={(val: any) => {
              setValue('adMessage', val);
            }}
            error={t(errors.adMessage?.message)}
            variant='outline'
            className='mb-5 '
            name='shortDescription'
          />
          <div className='mt-5 pr-3'>
            <SwitchInput
              label={t('input-label-shipping-distance')}
              setValue={setValue}
              defaultValue={priceDistance}
              name='pricePerDistance'
              onChange={(val) => setPricePerDistance(val)}
              value={watch('pricePerDistance')}
            />
          </div>
          {priceDistance ? (
            <>
              {fields.map((field, index) => (
                <div className='flex flex-row' key={field.id}>
                  <Input
                    label={t('shipping-up-to-distance')}
                    {...register(`distancePriceList.${index}.upToDistance`, {
                      valueAsNumber: true,
                    })}
                    type='number'
                    containerClassName='ml-4'
                    variant='outline'
                  />
                  <Input
                    label={`${t('shipping-up-to-distance-price')} ${watch(`distancePriceList.${index}.upToDistance`)} ${t('km')}`}
                    {...register(`distancePriceList.${index}.price`, { valueAsNumber: true })}
                    type='number'
                    containerClassName='ml-4'
                    variant='outline'
                  />
                  <button
                    onClick={(e) => remove(index)}
                    className='mt-8 text-red-500 transition duration-200 hover:text-red-600 focus:outline-none'
                    title={t('text-delete')}
                  >
                    <Trash width={16} />
                  </button>
                </div>
              ))}
              <Button
                className='mt-4'
                onClick={() => append({ upToDistance: 0, price: 0, moreInfo: '' })}
              >
                {t('button-label-add')}
              </Button>
            </>
          ) : (
            <>
              <Input
                label={t('shipping-price-input-label')}
                type='number'
                required
                variant='outline'
                {...register('shippingPrice', { valueAsNumber: true })}
              />
              <Input
                label={t('shipping-free-when-total-input-label')}
                {...register('freeShippingWhenTotalIs', { valueAsNumber: true })}
                type='number'
                variant='outline'
              />
            </>
          )}
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {t('form-save')}
        </Button>
        <Button onClick={() => history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`)} variant='outline'>
          {t('form-return')}
        </Button>
      </Card>
    </form>
  );
};

export default withPermission(ShippingPage);
