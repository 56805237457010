/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const HandBags: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18.08'
      height='17.265'
      fill='currentColor'
      {...props}
    >
      <g data-name='Hand bags'>
        <path
          data-name='Path 21806'
          d='M17.572 8.341H.507v-3.83a1.457 1.457 0 011.456-1.455h14.154a1.457 1.457 0 011.455 1.455v3.83zm-7.373.468v.226a.543.543 0 01-.542.543H8.424a.543.543 0 01-.542-.543v-.226zm6.906 7.949H.975l.3-7.949h6.141v.226a1.011 1.011 0 001.01 1.01h1.233a1.011 1.011 0 001.01-1.01v-.226h6.141l.3 7.949zM6.164 1.52A1.013 1.013 0 017.176.508h3.729a1.013 1.013 0 011.012 1.012v1.068H6.165V1.52zm9.953 1.068h-3.734V1.52A1.481 1.481 0 0010.904.041H7.175A1.481 1.481 0 005.696 1.52v1.068H1.963A1.926 1.926 0 00.039 4.511v4.063a.234.234 0 00.234.234h.532L.496 16.98a.234.234 0 00.234.245h16.619a.234.234 0 00.234-.245l-.308-8.172h.532a.233.233 0 00.233-.234V4.511a1.925 1.925 0 00-1.923-1.923z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='.08'
        />
      </g>
    </svg>
  );
};
