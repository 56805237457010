import { useForm } from 'react-hook-form';
import Button from 'src/components/ui/button';
import { useAppSelector, useAppDispatch } from '../../utils/reduxHooks';
import Card from 'src/components/common/card';
import { yupResolver } from '@hookform/resolvers/yup';
import { brandValidationSchema } from './brand-validation-schema';
import { useTranslation } from 'react-i18next';
import { createBrand } from 'src/store/shop/actions';
import { Brand, BrandInput, TranslationObject } from 'src/ts-types/custom.types';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import { toast } from 'react-toastify';
import { SAVE_MODE, UPLOAD_SECTION } from 'src/ts-types';
import UnControlledFileInput from '../ui/unControlled-file-input';
import { RootState } from 'src/store';
import SwitchInput from '../ui/switch-input';
import ProductCategoryInput from '../product/product-category-input';
import { useEffect } from 'react';
import { getAllCategories } from 'src/store/shop/shopsAPIActions';
import InputWithTranslation from '../ui/inputWithTranslation';
import { TOAST_MESSAGE } from 'src/utils/constants';

type FormValues = {
  brandNameWithTeanslations: TranslationObject[];
  brandImage: string;
  topBrand?: boolean;
  mainCategory: any;
  brandId?: string;
};

type IProps = {
  initialValues?: Brand | null;
};

export default function CreateOrUpdateBrandsForm({ initialValues }: IProps) {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state: RootState) => state.shop.shopDetails?.categories);

  useEffect(() => {
    dispatch(getAllCategories({}));
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      brandNameWithTeanslations: initialValues?.brandNameWithTeanslations,
      brandImage: initialValues?.brandImage,
      topBrand: initialValues?.topBrand,
      mainCategory: initialValues?.mainCategory,
      brandId: initialValues?.brandId,
    },
    resolver: yupResolver(brandValidationSchema),
  });

  function onSubmit(values: FormValues) {
    const input: BrandInput = {
      brandImage: values?.brandImage,
      mainCategory: values.mainCategory.categoryId,
      topBrand: values.topBrand,
      brandNameWithTeanslations: values.brandNameWithTeanslations,
      brandId: values.brandId ?? '',
    };
    dispatch(createBrand(input, input.brandId !== '' ? SAVE_MODE.EDIT : SAVE_MODE.SAVE)).then(
      (res: any) => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        history.push(ROUTES.BRANDS);
      },
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        className='w-full'
        title={
          <h1 className='text-lg font-semibold text-heading'>{t('form-title-create-brand')}</h1>
        }
      >
        <div className='w-full md:w-2/3'>
          <InputWithTranslation
            label={t('input-label-name')}
            error={t(errors.brandNameWithTeanslations?.message)}
            variant='outline'
            required
            values={getValues('brandNameWithTeanslations')}
            setValue={(val: any) => setValue('brandNameWithTeanslations', val)}
            name='brandNameWithTeanslations'
          />
          <UnControlledFileInput
            withCrop
            croppedMaxWidth={80}
            croppedMaxHeight={80}
            multiple={false}
            classContainer='mt-2'
            onImageUploaded={(val: any) => setValue('brandImage', val)}
            defaultValue={getValues('brandImage')}
            uploadSection={UPLOAD_SECTION.CATEGORY}
          />
          <SwitchInput
            label={t('input-label-send-topBrand')}
            name='supportsCash'
            onChange={(val) => setValue('topBrand', val)}
            setValue={setValue}
            value={getValues('topBrand')}
          />
          <div className='mb-5 mt-5'>
            <ProductCategoryInput
              className='w-full step1prod'
              control={control}
              setValue={setValue}
              required
              title='select-label-main-category'
              categories={categories}
              handleChange={(val: string) => setValue('mainCategory', val)}
              // @ts-ignore
              defaultValue={getValues('mainCategory')}
              nameToWatch='mainCategory'
              watch={watch}
            />
          </div>
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {initialValues ? t('button-label-update-brand') : t('button-label-add-brand')}
        </Button>
        <Button variant='outline' onClick={() => history.push(ROUTES.BRANDS)}>
          {t('form-return')}
        </Button>
      </Card>
    </form>
  );
}
