import Card from '../../components/common/card';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { useForm } from 'react-hook-form';
import { SaleSubDetails } from '../../ts-types/custom.types';
import Input from '../../components/ui/input';
import { useState } from 'react';
import Button from '../../components/ui/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import Label from '../../components/ui/label';
import { DatePicker } from 'src/components/ui/date-picker';
import { createSale, updateSale } from '../../store/shop/actions';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import InputWithTranslation from 'src/components/ui/inputWithTranslation';
import { siteSettings } from 'src/settings/site.settings';
import UnControlledSelectInput from 'src/components/ui/unControlled-select-input';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootState } from 'src/store';
import ProductCategoryInput from 'src/components/product/product-category-input';
import { salesFormValidation } from './salesFormValidation';
import { formatDate, getEndOfDayDate } from 'src/common';
import { TOAST_MESSAGE } from 'src/utils/constants';

type IProps = {
  initialValues?: SaleSubDetails | null;
};

export default function EditOrCreateSale({ initialValues }: IProps) {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [date, setDate] = useState<number>(
    initialValues?.expirationDate
      ? Date.parse(formatDate(initialValues?.expirationDate))
      : Date.now(),
  );

  const [, setErrorMessage] = useState<string | null>(null);
  const categories = useAppSelector((state: RootState) => state.shop.shopDetails?.categories);
  const [upd, setUpd] = useState(false);

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<SaleSubDetails>({
    defaultValues: {
      saleDescription: initialValues?.saleDescription,
      saleId: initialValues?.saleId,
      saleType: initialValues?.saleType,
      totalPriceSaleObject: initialValues?.totalPriceSaleObject,
      moneyToDiscount: initialValues?.moneyToDiscount,
      percentageToDiscount: initialValues?.percentageToDiscount,
      expirationDate: initialValues?.expirationDate,
      categoryId: initialValues?.categoryId ?? '',
    },
    resolver: yupResolver(salesFormValidation),
  });
  console.info('initialValues', initialValues);

  const onSubmit = (values: SaleSubDetails) => {
    const saleExpDate = getEndOfDayDate(values.expirationDate);
    values.expirationDate = saleExpDate;
    if (!_.isEmpty(values?.saleId)) {
      dispatch(updateSale({ saleDetails: values }))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
          history.push(ROUTES.SALES);
        })
        .catch((error: any) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            animateScroll.scrollToTop();
          } else {
            Object.keys(error?.response?.data).forEach((field: any) => {
              setError(field, {
                type: 'manual',
                message: error?.response?.data[field][0],
              });
            });
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    } else {
      dispatch(createSale(values))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
          history.push(ROUTES.SALES);
        })
        .catch((error: any) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            animateScroll.scrollToTop();
          } else {
            Object.keys(error?.response?.data).forEach((field: any) => {
              setError(field, {
                type: 'manual',
                message: error?.response?.data[field][0],
              });
            });
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    }
  };

  const onChangeDate = (value: Date) => {
    // Convert the selected date to UTC
    const utcDate = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
    setDate(utcDate.getTime());
    setValue('expirationDate', formatDate(utcDate.toISOString()));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title={<h1 className='text-lg font-semibold text-heading'>{t('label-sales')}</h1>}>
        <div className='w-full md:w-2/3'>
          <InputWithTranslation
            label={t('input-label-name')}
            error={t(errors.saleDescription?.message)}
            variant='outline'
            required
            values={getValues('saleDescription')}
            setValue={(val: any) => setValue('saleDescription', val)}
            name='saleDescription'
          />
          <div className='mb-5 mt-5'>
            <UnControlledSelectInput
              name='saleType'
              control={control}
              idKey='value'
              label={t('input-label-sales-type-text')}
              required
              handleOnChange={(val: any) => {
                setValue('saleType', val.value), setUpd(!upd);
              }}
              getOptionLabel={(option: any) => t(option.label)}
              getOptionValue={(option: any) => option.value}
              options={siteSettings.salesOptions}
              defaultValue={getValues('saleType')}
            />
          </div>
          <Input
            label={t('input-label-sales-money-to-discount-percentage')}
            {...register('percentageToDiscount')}
            type='number'
            required
            variant='outline'
          />
          <>
            {getValues('saleType') === siteSettings.salesOptions[1].value && (
              <ProductCategoryInput
                className='w-full step1prod pt-4'
                control={control}
                setValue={setValue}
                required
                categories={categories}
                // @ts-ignore
                defaultValue={getValues('categoryId')}
                watch={watch}
              />
            )}
          </>

          <Input
            label={t('input-label-sales-min-price-to-get-sale-text')}
            type='number'
            variant='outline'
            required
            {...register('totalPriceSaleObject.minPriceToGetSale', { required: true })}
            className='mb-5 '
          />
          <Label className='mt-3'>{t('input-label-sales-expiration-date-text')}</Label>
          {/* @ts-ignore */}
          <DatePicker
            required
            dateFormat='dd/MM/yyyy'
            onChange={(date: Date) => onChangeDate(date)}
            selected={new Date(date)}
            minDate={new Date()}
            className='border border-border-base'
          />
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {t('form-save')}
        </Button>
        <Button variant='outline' onClick={() => history.push(ROUTES.COUPONS)}>
          {t('form-return')}
        </Button>
      </Card>
    </form>
  );
}
