import Input from 'src/components/ui/input';
import { faCreditCard, faIdCard, faCalendarCheck, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Button from '../ui/button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import InputMask, { MaskInputType } from '../ui/inpu-mask';

export type Props = {
  register: any;
  errors: any;
  setValue?: any;
  formName?: string;
  getValues?: any;
  onBackClick?: any;
};

const CreditCard = ({ register, errors, formName, setValue, getValues, onBackClick }: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <>
      <div className='flex flex-col md:flex-row'>
        <Input
          label={t('input-card-customer-id')}
          {...register('cardDetails.creditCardCitizenID')}
          variant='outline'
          dir='ltr'
          containerClassName='ml-2'
          className='text-start'
          maxLength='9'
          icon={<FontAwesomeIcon icon={faIdCard as IconProp} />}
          error={t(errors.cardDetails?.creditCardCitizenID?.message)}
        />
        <InputMask
          label={t('input-card-number')}
          setValue={setValue}
          initValue={getValues('cardDetails.creditCardNumber')}
          name='cardDetails.creditCardNumber'
          variant='outline'
          dir='ltr'
          maskInputType={MaskInputType.CREDIT_CARD}
          className='card-number text-start'
          placeholder={t('input-card-number')}
          icon={<FontAwesomeIcon icon={faCreditCard as IconProp} />}
          error={t(errors.cardDetails?.creditCardNumber?.message)}
        />
      </div>
      <div className='grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-cols-3 pr-2'>
        <InputMask
          label={t('input-card-expiration-MontYear')}
          name='cardDetails.creditCardExpirationMonthYear'
          initValue={getValues('cardDetails.creditCardExpirationMonthYear')}
          maskInputType={MaskInputType.MM_YY}
          variant='outline'
          className='text-start'
          dir='ltr'
          icon={<FontAwesomeIcon icon={faCalendarCheck as IconProp} />}
          error={t(errors.cardDetails?.creditCardExpirationMonthYear?.message)}
          setValue={setValue}
        />
        <Input
          label={t('input-card-cvc')}
          {...register('cardDetails.creditCardCVV')}
          variant='outline'
          dir='ltr'
          maxLength='3'
          className='text-start'
          error={t(errors.cardDetails?.creditCardCVV?.message)}
        />
      </div>
      <Button
        className='w-full mt-8'
        type='submit'
        form={formName}
        loading={false}
        icon={<FontAwesomeIcon className='text-white' icon={faLock as IconProp} size={'1x'} />}
        disabled={false}
      >
        {t('button-label-pay')}
      </Button>
      <Button
        className='w-full mt-8'
        type='submit'
        onClick={() => onBackClick?.()}
        variant='outline'
        form={formName}
        loading={false}
        disabled={false}
      >
        {t('button-label-back')}
      </Button>
    </>
  );
};

export default CreditCard;
