import ConfirmationCard from 'src/components/common/confirmation-card';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { deletePlugin, getAllPlugins } from 'src/store/shop/actions';
import { useAppDispatch } from 'src/utils/reduxHooks';

const PluginDeleteView = () => {
  const { data } = useModalState();
  const { closeModal } = useModalAction();
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(deletePlugin(data)).then((res: any) => {
      dispatch(getAllPlugins());
    });
    closeModal();
  };

  return (
    <ConfirmationCard onCancel={closeModal} onDelete={handleDelete} deleteBtnLoading={false} />
  );
};

export default PluginDeleteView;
