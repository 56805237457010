import { useTranslation } from 'react-i18next';
import usePriceNew from 'src/utils/use-priceNew';
import KryXenon from './kryXenon';
import { getDefaultVariant, getTranslatedProductItem, variantIsInSock } from 'src/common';
import { Product } from 'src/ts-types/custom.types';
type IProps = {
  initialValues?: Product | null;
  productLayout: string;
  product: Product;
};

const MAP_PRODUCT_TO_CARD: Record<string, any> = {
  neon: KryXenon,
  helium: KryXenon,
  argon: KryXenon,
  krypton: KryXenon,
  // xenon: Xenon,
  kryXenon: KryXenon,
  oganesson: KryXenon,
  single: undefined,
  big: undefined,
  pillVertical: undefined,
};

export default function ProductPreview({ product, productLayout }: IProps) {
  const { t }: any = useTranslation();
  const Component = MAP_PRODUCT_TO_CARD[productLayout] ?? KryXenon;
  const mainVariant = getDefaultVariant(product, '');
  const {
    price,
    basePrice = null,
    discount,
    hasDiscount,
  } = usePriceNew({
    price: product?.price ?? 0,
    discount: product?.discountPrice,
  });
  console.log('product ', product);
  return (
    <Component
      productImageText={t('text-product-image')}
      outOfStockText={t('text-out-stock')}
      product={product}
      productPlaceholder={'productPlaceholder'}
      mainVariant={mainVariant}
      variantIsInSock={variantIsInSock}
      handleProductQuickView={() => {
        console.log('test');
      }}
      prices={{ price, basePrice, discount, hasDiscount }}
      addToCart={() => {
        console.log('test');
      }}
      productName={getTranslatedProductItem(product?.productInfoWithTranslations ?? []).name}
      shortDescription={
        getTranslatedProductItem(product?.productInfoWithTranslations ?? []).shortDescription
      }
      baseRoute={''}
      productIsAvailable={true}
    />
  );
}
