/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const OfficeGuyIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg
    fill='#000000'
    width='20px'
    height='20px'
    className='w-5 h-5 me-4'
    viewBox='0 0 32 32'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='SVGRepo_bgCarrier' strokeWidth='0' />

    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />

    <g id='SVGRepo_iconCarrier'>
      {' '}
      <title>credit-card</title>{' '}
      <path d='M0 26.016q0 0.832 0.576 1.408t1.44 0.576h28q0.8 0 1.408-0.576t0.576-1.408v-20q0-0.832-0.576-1.408t-1.408-0.608h-28q-0.832 0-1.44 0.608t-0.576 1.408v20zM2.016 26.016v-14.016h28v14.016h-28zM2.016 8v-1.984h28v1.984h-28zM4 24h4v-1.984h-4v1.984zM4 20h6.016v-5.984h-6.016v5.984zM10.016 24h4v-1.984h-4v1.984zM16 24h4v-1.984h-4v1.984zM22.016 24h1.984v-1.984h-1.984v1.984zM26.016 24h1.984v-1.984h-1.984v1.984z' />{' '}
    </g>
  </svg>
);
