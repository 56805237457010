/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/common/card';
import Button from 'src/components/ui/button';
import cn from 'classnames';
import Loader from 'src/components/ui/loader/loader';
import { setActiveEmployee, setActivePlugin } from 'src/store/shop/actions';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { SAVE_MODE } from 'src/ts-types';
import { ROUTES } from 'src/utils/routes';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/store';
import EmployeeList from 'src/components/shopEmployees/employeeList';
import { getAllEmployee } from 'src/store/shop/shopsAPIActions';

export default function AddNewUser() {
  const employees = useAppSelector((state: RootState) => state.shop.shopDetails?.shopEmployees);
  const { t }: any = useTranslation();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isFetching = useAppSelector((state: RootState) => employees?.isFetching);

  useEffect(() => {
    dispatch(getAllEmployee());
  }, [dispatch]);

  if (isFetching) return <Loader text={t('text-loading')} />;

  const handleCreateNewEmployee = () => {
    dispatch(
      setActiveEmployee(
        {
          employeeEmail: '',
          employeeRole: '',
          password: '',
          employeeMobile: '',
          userId: '',
        },
        SAVE_MODE.SAVE,
      ),
    );
    history.push(ROUTES.EDIT_SHOP_EMPLOYEE);
  };
  return (
    <>
      <Card className='flex flex-col mb-8'>
        <div className='w-full flex md:flex-row smx:flex-row items-center'>
          <div className='md:w-1/4 mb-4 md:mb-0'>
            <h1 className='text-lg font-semibold text-heading'>{t('SHOP_USER_TITLE')}</h1>
          </div>

          <div className='justify-end w-full md:w-3/4 flex md:flex-row items-center'>
            <Button onClick={() => handleCreateNewEmployee()} className='h-12 ms-4 md:ms-6'>
              <span className='hidden md:block'>+ {t('button-label-add-user')}</span>
              <span className='md:hidden'>+ {t('button-label-add')}</span>
            </Button>
          </div>
        </div>
        <div
          className={cn('w-full flex transition', {
            'h-auto visible': visible,
            'h-0 invisible': !visible,
          })}
        ></div>
      </Card>
      <EmployeeList employees={employees} />
      <ToastContainer autoClose={2000} />
    </>
  );
}
