import Logo from 'src/components/ui/logo';
import RegistrationForm from 'src/components/auth/registrationForm';
import { isAuthenticated } from 'src/utils/authUtils';
import { ROUTES } from 'src/utils/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function RegisterPage() {
  const history = useHistory();
  if (isAuthenticated()) {
    history.replace(ROUTES.SHOPS);
  }
  const { t }: any = useTranslation<any>();
  return (
    <div className='flex items-center justify-center h-screen bg-white sm:bg-gray-100'>
      <div className='m-auto max-w-md w-full bg-white sm:shadow p-5 sm:p-8 rounded'>
        <div className='flex justify-center mb-2'>
          <Logo />
        </div>
        <h3 className='text-center text-base italic text-gray-500 mb-6 mt-4'>
          {t('admin-register-title')}
        </h3>
        <RegistrationForm />
      </div>
    </div>
  );
}
