/* eslint-disable no-debugger */
/* eslint-disable camelcase */
import SSRCookie from 'cookie';
import jwt_decode from 'jwt-decode';
import { AUTH_CRED, EMPLOYEE, STORE_OWNER, SUPER_ADMIN } from './constants';

export const allowedRoles = [SUPER_ADMIN, STORE_OWNER];
export const superAdminOnly = [SUPER_ADMIN];
export const superAdminAndShopOwner = [SUPER_ADMIN, STORE_OWNER];
export const employeeRole = [SUPER_ADMIN, EMPLOYEE, STORE_OWNER];

export function setAuthCredentials(token: string | undefined, refreshToken: string | undefined) {
  if (token || refreshToken) {
    const decoded: any = jwt_decode(token!);
    const isSuperAdmin = decoded?.rule === SUPER_ADMIN;
    localStorage?.setItem(
      AUTH_CRED,
      JSON.stringify({
        token,
        refreshToken,
        permissions: isSuperAdmin ? [SUPER_ADMIN, STORE_OWNER] : [STORE_OWNER],
        email: decoded.email,
      }),
    );
  } else {
    localStorage?.removeItem(AUTH_CRED);
  }
}

export function getAuthCredentials(context?: any): {
  token: string | null;
  permissions: string[] | null;
  refreshToken: string | null;
  email: string | null;
} {
  let authCred;
  if (context) {
    authCred = parseSSRCookie(context)[AUTH_CRED];
  } else {
    authCred = localStorage?.getItem(AUTH_CRED);
  }
  if (authCred) {
    return JSON.parse(authCred);
  }
  return { token: null, permissions: null, refreshToken: null, email: '' };
}

export function parseSSRCookie(context: any) {
  return SSRCookie.parse(context.req.headers.cookie ?? '');
}

export function hasAccess(_itemPermissions: string[] | undefined | null) {
  const { permissions } = getAuthCredentials();
  if (_itemPermissions) {
    return Boolean(_itemPermissions?.find((aRole) => permissions?.includes(aRole)));
  }
  return false;
}
export function isAuthenticated() {
  const { token } = getAuthCredentials();
  return !!token;
}

export function isSuperAdmin() {
  const { permissions } = getAuthCredentials();
  return !!permissions?.find((aRole) => aRole === SUPER_ADMIN);
}
