import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getActiveShopDetails,
  getAllMyShops,
  updateShopPrivateDetails,
} from 'src/store/shop/shopsAPIActions';
import { SHOP_STATUS } from 'src/ts-types';
import { ShopStatus } from 'src/ts-types/custom.types';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { ROUTES } from 'src/utils/routes';
import { getShopActionButtonTest, setShopId } from 'src/utils/shopUtils';
import Button from '../ui/button';

type Props = {
  className?: string;
  shopId: string;
  currentStatus: ShopStatus;
};

const ShopActionButton = ({ className, shopId, currentStatus }: Props) => {
  const { t }: any = useTranslation<any>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const buttonText = getShopActionButtonTest(currentStatus, t);

  const handleShopChangeStatus = (e: any) => {
    switch (currentStatus) {
      case SHOP_STATUS.RUNNING:
        dispatch(
          updateShopPrivateDetails({
            shopId: shopId,
            shopCurrentStatus: SHOP_STATUS.CANCELED,
          }),
        )
          .then(() => {
            toast.success(t('SHOP_STATUS_UPDATED_SUCCESSFULLY'));
            dispatch(getAllMyShops());
          })
          .catch(() => {
            toast.error(t('SHOP_STATUS_UPDATED_FAILED'));
          });
        break;
      case SHOP_STATUS.CANCELED:
      case SHOP_STATUS.IN_PROGRESS:
        dispatch(
          updateShopPrivateDetails({
            shopId: shopId,
            shopCurrentStatus: SHOP_STATUS.RUNNING,
          }),
        )
          .then(() => {
            toast.success(t('SHOP_STATUS_UPDATED_SUCCESSFULLY'));
            dispatch(getAllMyShops());
          })
          .catch(() => {
            toast.error(t('SHOP_STATUS_UPDATED_FAILED'));
          });
        break;
      case SHOP_STATUS.NOT_STARTED:
      case SHOP_STATUS.PAYMENT_FAILED:
        setShopId(shopId);
        dispatch(getActiveShopDetails());
        history.push(ROUTES.ANONY_CREATE_SHOP);
        break;
      default:
        return '';
    }
  };

  return (
    <Button
      className={`${className}`}
      loading={false}
      variant='custom'
      size='small'
      onClick={(e) => handleShopChangeStatus(e)}
    >
      {buttonText}
    </Button>
  );
};

export default ShopActionButton;
