/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const LaptopBags: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18.1'
      height='13.626'
      fill='currentColor'
      {...props}
    >
      <g data-name='Laptop bags'>
        <path
          data-name='Path 21808'
          d='M17.659 8.533v.039h-7.111v-.71a.553.553 0 00-.552-.552H8.104a.553.553 0 00-.552.552v.71H.443v-4.96A1.369 1.369 0 011.81 2.245h14.483a1.369 1.369 0 011.367 1.367v4.921zM7.942 9.647V7.861a.162.162 0 01.162-.162h1.892a.162.162 0 01.162.162v1.786a.162.162 0 01-.162.162H8.104a.162.162 0 01-.162-.162zm9.717 2.166a1.369 1.369 0 01-1.367 1.367H1.807A1.369 1.369 0 01.44 11.813V8.962h7.111v.685a.553.553 0 00.552.552h1.892a.553.553 0 00.552-.552v-.685h7.111v2.851zM6.454 1.633A1.19 1.19 0 017.643.444h2.815a1.19 1.19 0 011.189 1.189v.23H6.455v-.23zm9.839.23h-4.256v-.23A1.582 1.582 0 0010.458.054H7.643a1.582 1.582 0 00-1.579 1.579v.23H1.808A1.759 1.759 0 00.051 3.62v8.2a1.759 1.759 0 001.757 1.758h14.485a1.759 1.759 0 001.757-1.758v-8.2a1.759 1.759 0 00-1.757-1.757z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='.1'
        />
      </g>
    </svg>
  );
};
