import { ShopUserRoles } from 'src/ts-types';
import * as yup from 'yup';

export const userDetailsValidator = yup.object().shape({
  employeeEmail: yup.string().email('error-submit-email').required('required'),
  employeeMobile: yup
    .string()
    .nullable(true)
    .required('required')
    .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'error-phone-required'),
  employeeRole: yup.mixed().oneOf(Object.values(ShopUserRoles)).required('required'),
  password: yup.string().required('error-password-required'),
});
