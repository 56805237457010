import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import Card from 'src/components/common/card';
import { useEffect, useState } from 'react';
import { ROUTES } from 'src/utils/routes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/ui/button';
import Loader from 'src/components/ui/loader/loader';
import { getAllAddOnsCategories } from 'src/store/shop/shopsAPIActions';
import { PAGE_SIZE } from 'src/ts-types';
import { RootState } from 'src/store';
import AddOnCategoryList from 'src/components/categoriesAddons/addon-category-list';

const AddOnPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const { t }: any = useTranslation();
  const addOnsCategories = useAppSelector(
    (state: RootState) => state.shop?.shopDetails?.addonsCategories,
  );

  useEffect(() => {
    dispatch(getAllAddOnsCategories({}));
  }, [dispatch, page]);

  if (addOnsCategories?.isFetching) return <Loader text={t('text-loading')} />;

  function handlePagination(current: any) {
    setPage(current);
  }
  return (
    <>
      <Card className='flex flex-col mb-8'>
        <div className='w-full flex md:flex-row smx:flex-row items-center'>
          <div className='md:w-1/4 mb-4 md:mb-0'>
            <h1 className='text-xl font-semibold text-heading'>
              {t('input-label-addons-caregories')}
            </h1>
          </div>

          <div className='justify-end w-full xl:w-3/4 flex md:flex-row space-y-4 md:space-y-0 items-center ms-auto'>
            <Button
              className='ms-4 md:ms-6'
              onClick={() => history.push(ROUTES.CREATE_CATEGORY_ADDONS)}
            >
              <span className='block md:hidden xl:block'>{t('button-label-add')} +</span>
              <span className='hidden md:block xl:hidden'>{t('button-label-add')} +</span>
            </Button>
          </div>
        </div>
      </Card>
      {addOnsCategories && (
        <AddOnCategoryList addonsCategories={addOnsCategories} onPagination={handlePagination} />
      )}
    </>
  );
};

export default AddOnPage;
