import React from 'react';

const ProductCardSkeleton: React.FC = () => {
  return (
    <div className='border border-gray-300 shadow rounded-md p-4 max-w-sm w-full mx-auto'>
      <div className='animate-pulse'>
        <div className='flex justify-between'>
          <div className='flex '>
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className='h-6 w-24 bg-gray-400 rounded ml-5'></div>
            ))}
          </div>
        </div>
        <div className='flex justify-between pt-2'>
          <div className='flex '>
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className='h-6 w-24 bg-gray-400 rounded ml-5'></div>
            ))}
          </div>
        </div>
        <div className='mt-4 space-y-4 pt-12'>
          <div className='h-48 bg-gray-400 rounded'></div>
          <div className='flex justify-between pt-12'>
            <div className='h-6 w-24 bg-gray-400 rounded'></div>
            <div className='h-6 w-16 bg-yellow-500 rounded'></div>
          </div>
          <div>
            <div className='h-4 bg-gray-400 rounded pt-12'></div>
            <div className='h-4 bg-gray-400 rounded mt-2'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardSkeleton;
