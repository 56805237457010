import ConfirmationCard from 'src/components/common/confirmation-card';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { deleteShop } from 'src/store/shop/actions';
import { getAllMyShops } from 'src/store/shop/shopsAPIActions';
import { useAppDispatch } from 'src/utils/reduxHooks';

const ShopDeleteView = () => {
  const { data } = useModalState();
  const { closeModal } = useModalAction();
  const dispatch = useAppDispatch();

  async function handleDelete() {
    dispatch(deleteShop(data)).then((res: any) => {
      dispatch(getAllMyShops());
    });
    closeModal();
  }
  return (
    <ConfirmationCard onCancel={closeModal} onDelete={handleDelete} deleteBtnLoading={false} />
  );
};

export default ShopDeleteView;
