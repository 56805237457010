import * as yup from 'yup';
import valid from 'card-validator';

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};

export const anonymousCreateShopValidation = yup.object().shape(
  {
    businessType: yup.string().required('required'),
    layoutType: yup.string().required('required'),
    website: yup.string().required('required').trim(),
    customerDetails: yup.object().shape({
      emailAddress: yup.string().email('error-submit-email').required('required'),
    }),
    companyDetails: yup.object().shape({
      companyName: yup.string().required('error-companyName-required'),
      companyType: yup.string().required('error-companyType-required'),
    }),
    cardDetails: yup.mixed().when('toValidateCardDetails', {
      is: (toValidateCardDetails: boolean) => toValidateCardDetails === true,
      then: yup.object().shape({
        creditCardNumber: yup
          .string()
          .test('test-number', 'error-card-validation', (value) => valid.number(value).isValid)
          .required('error-card-required'),
        creditCardExpirationMonthYear: yup
          .string()
          .min(4, 'text-expiration-error')
          .max(4, 'text-expiration-error')
          .required('required'),
        creditCardCitizenID: yup
          .number()
          .nullable()
          .transform((parsedValue, originalValue) => (originalValue === '' ? null : parsedValue))
          .test('len', 'id-error', (val: any) => val?.toString?.().length == 9)
          .required('required'),
        creditCardCVV: yup.string().required('required'),
      }),
    }),
  },
  [
    // Add Cyclic deps here because when require itself
    ['customerDetails.Phone', 'customerDetails.Phone'],
  ],
);

export const upgradeShopValidation = yup.object().shape({
  cardDetails: yup.mixed().when('toValidateCardDetails', {
    is: (toValidateCardDetails: boolean) => toValidateCardDetails === true,
    then: yup.object().shape({
      creditCardNumber: yup
        .string()
        .test('test-number', 'error-card-validation', (value) => valid.number(value).isValid)
        .required('error-card-required'),
      creditCardExpirationMonthYear: yup
        .string()
        .min(4, 'text-expiration-error')
        .max(4, 'text-expiration-error')
        .required('required'),
      creditCardCitizenID: yup
        .number()
        .nullable()
        .transform((parsedValue, originalValue) => (originalValue === '' ? null : parsedValue))
        .test('len', 'id-error', (val: any) => val?.toString?.().length == 9)
        .required('required'),
      creditCardCVV: yup.string().required('required'),
    }),
  }),
});
