/* eslint-disable @typescript-eslint/no-explicit-any */
export const Table: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='48.75'
      viewBox='0 0 60 48.75'
      fill='currentColor'
      {...props}
    >
      <path
        id='Center_table'
        data-name='Center table'
        d='M226.757,591.945A98.842,98.842,0,0,0,206,590a98.842,98.842,0,0,0-20.757,1.945C179.11,593.33,176,595.2,176,597.5s3.11,4.17,9.243,5.555a93.868,93.868,0,0,0,17.945,1.915v4.724a4.674,4.674,0,0,0,0,7.487v8.444h-1.875a4.7,4.7,0,0,0-4.594,3.75h-1.031A4.693,4.693,0,0,0,191,634.063v3.75a.938.938,0,0,0,.938.938h3.75a.938.938,0,0,0,.938-.937V635h.938a4.7,4.7,0,0,0,4.594-3.75h1.031v.938a2.813,2.813,0,0,0,5.625,0v-.937h1.031a4.7,4.7,0,0,0,4.594,3.75h.938v2.813a.938.938,0,0,0,.938.938h3.75a.938.938,0,0,0,.938-.937v-3.75a4.693,4.693,0,0,0-4.687-4.687h-1.031a4.7,4.7,0,0,0-4.594-3.75h-1.875v-8.444a4.674,4.674,0,0,0,0-7.487V604.97a93.877,93.877,0,0,0,17.945-1.915C232.89,601.67,236,599.8,236,597.5S232.89,593.33,226.757,591.945Zm-23.57,37.43h-1.875a.938.938,0,0,0-.937.938,2.816,2.816,0,0,1-2.812,2.813h-1.875a.938.938,0,0,0-.937.938v2.813h-1.875v-2.812a2.816,2.816,0,0,1,2.813-2.812h1.875a.938.938,0,0,0,.938-.937,2.816,2.816,0,0,1,2.813-2.812h1.875Zm7.5-1.875a2.816,2.816,0,0,1,2.813,2.813.938.938,0,0,0,.938.938h1.875a2.816,2.816,0,0,1,2.813,2.813v2.813H217.25v-2.812a.938.938,0,0,0-.937-.937h-1.875a2.816,2.816,0,0,1-2.812-2.812.938.938,0,0,0-.937-.937h-1.875V627.5Zm-3-11.817-.747.562v15.942a.938.938,0,0,1-1.875,0V616.245l-.747-.562a2.8,2.8,0,0,1,0-4.491l.747-.562V605h1.875v5.63l.747.562a2.8,2.8,0,0,1,0,4.491Zm18.659-14.457a96.9,96.9,0,0,1-20.344,1.9,96.9,96.9,0,0,1-20.344-1.9c-6.143-1.387-7.781-2.994-7.781-3.726s1.639-2.339,7.781-3.726a96.9,96.9,0,0,1,20.344-1.9,96.9,96.9,0,0,1,20.344,1.9c6.143,1.387,7.781,2.994,7.781,3.726S232.486,599.839,226.344,601.226Z'
        transform='translate(-176 -590)'
        fill='currentColor'
      />
    </svg>
  );
};
