import Card from '../common/card';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { useForm } from 'react-hook-form';
import Input from '../ui/input';
import Button from '../ui/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { siteSettings } from 'src/settings/site.settings';
import UnControlledSelectInput from 'src/components/ui/unControlled-select-input';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { userDetailsValidator } from './employeeDetailsValidator';
import { RootState } from 'src/store';
import PasswordInput from '../ui/password-input';
import { toast } from 'react-toastify';
import { addNewEmployee } from 'src/store/shop/shopsAPIActions';
import { EmployeeDetails } from 'src/ts-types/custom.types';
import { TOAST_MESSAGE } from 'src/utils/constants';

export default function AddNewShopEmployee() {
  const { t }: any = useTranslation();
  const history = useHistory();
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: RootState) => state.shop.shopDetails?.activeEmployee);

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<EmployeeDetails>({
    defaultValues: data ?? {
      employeeEmail: '',
      employeeRole: '',
      password: '',
      employeeMobile: '',
      userId: '',
    },
    resolver: yupResolver(userDetailsValidator),
  });

  const onSubmit = (values: EmployeeDetails) => {
    if (_.isEmpty(values.userId)) {
      delete values.userId;
    }
    dispatch(addNewEmployee(values))
      .then(() => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
      })
      .catch(() => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title={<h1 className='text-lg font-semibold text-heading'>{t('label-sales')}</h1>}>
        <div className='w-full md:w-2/3'>
          <Input
            label={t('input-label-email')}
            {...register('employeeEmail')}
            type='email'
            variant='outline'
            error={t(errors?.employeeEmail?.message)}
          />
          <div className='mb-5 mt-5'>
            <UnControlledSelectInput
              name='employeeRole'
              control={control}
              idKey='value'
              label={t('input-label-permission-type-text')}
              required
              defaultValue={data?.employeeRole}
              handleOnChange={(val: any) => setValue('employeeRole', val.value)}
              getOptionLabel={(option: any) => t(option.label)}
              getOptionValue={(option: any) => option.value}
              options={siteSettings.shopUsersRole}
            />
          </div>
          <PasswordInput
            label={t('input-label-password')}
            {...register('password')}
            variant='outline'
            forgotPageLink='/forgot-password'
            error={t(errors?.password?.message)}
          />
          <Input
            label={t('input-label-phone')}
            {...register('employeeMobile')}
            type='number'
            required
            error={t(errors?.employeeMobile?.message)}
          />
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {t('form-save')}
        </Button>
        <Button variant='outline' onClick={() => history.push(ROUTES.SHOP_USERS)}>
          {t('form-return')}
        </Button>
      </Card>
    </form>
  );
}
