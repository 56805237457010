/* eslint-disable react/display-name */
import cn from 'classnames';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';

export enum MaskInputType {
  CREDIT_CARD = 'CREDIT_CARD',
  CVV = 'CVV',
  MM_YY = 'MM_YY',
}

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  maskInputType: MaskInputType;
  inputClassName?: string;
  containerClassName?: string;
  label?: string;
  note?: string;
  value?: string;
  initValue?: string;
  icon?: any;
  required?: boolean;
  setValue: any;
  getValue?: any;
  ref?: any;
  name: string;
  labelClass?: string;
  error?: string;
  type?: string;
  shadow?: boolean;
  variant?: 'normal' | 'solid' | 'outline';
  size?: any | 'small' | 'medium' | 'large';
}
const classes = {
  root: 'px-4 h-12 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0',
  normal: 'bg-gray-100 border border-border-base focus:shadow focus:bg-light focus:border-accent',
  solid: 'bg-gray-100 border border-border-100 focus:bg-light focus:border-accent',
  outline: 'border border-border-base focus:border-accent',
  shadow: 'focus:shadow',
  small: 'md:w-1/3',
  medium: 'md:w-2/3',
  large: 'md:w-3/3',
};
const InputMask = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      size = 'large',
      label,
      value,
      icon,
      initValue,
      note,
      getValue,
      name,
      maskInputType,
      error,
      required,
      children,
      variant = 'normal',
      shadow = false,
      type = 'text',
      labelClass = '',
      setValue,
      inputClassName,
      ...rest
    },
    ref,
  ) => {
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === 'normal',
        [classes.solid]: variant === 'solid',
        [classes.outline]: variant === 'outline',
      },
      {
        [classes.shadow]: shadow,
      },
      {
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
      },
      inputClassName,
      className,
    );
    const [maskValue, setMaskValue] = useState<any | null>();
    const fallbackRef = React.useRef<HTMLInputElement | null>();
    const domRef: any = ref || fallbackRef;

    const mask = (value: any) => {
      if (maskInputType === MaskInputType.CREDIT_CARD) {
        return value?.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
      } else if (maskInputType === MaskInputType.MM_YY) {
        return value?.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
      }
      return '';
    };
    const handleChange = React.useCallback(
      (initValue?: any) => {
        if (domRef?.current) {
          if (maskInputType === MaskInputType.CREDIT_CARD) {
            const cardValue = mask(initValue ?? domRef.current.value);
            if (cardValue) {
              domRef.current.value = !cardValue[2]
                ? cardValue[1]
                : `${cardValue[1]} ${cardValue[2]}${`${
                    cardValue[3] ? ` ${cardValue[3]}` : ''
                  }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ''}`}`;
              cardValue.reverse();
              const numbers = domRef.current.value.replace(/(\D)/g, '');
              setValue(name, numbers);
            } else {
              setValue(name, value);
            }
          } else if (maskInputType === MaskInputType.MM_YY) {
            const dateMonthValue = mask(initValue ?? domRef.current.value);
            if (dateMonthValue) {
              domRef.current.value = !dateMonthValue[2]
                ? dateMonthValue[1]
                : `${dateMonthValue[1]}/${dateMonthValue[2]}${`${
                    dateMonthValue[3] ? ` ${dateMonthValue[3]}` : ''
                  }`}${`${dateMonthValue[4] ? ` ${dateMonthValue[4]}` : ''}`}`;
              const date = domRef.current.value.replace(/(\D)/g, '');
              setValue(name, date);
            } else {
              setValue(name, value);
            }
          }
        }
      },
      [ref],
    );

    useEffect(() => {
      if (initValue && initValue !== mask(initValue)) {
        setTimeout(() => {
          handleChange(initValue);
        }, 0);
      }
    }, [initValue]);

    return (
      <div className='flex-1 svelte-1l8159u'>
        <div className='flex items-center justify-between mb-3 mt-3'>
          <label htmlFor={name} className={'text-body-dark font-semibold text-sm leading-none'}>
            {label}
          </label>
        </div>
        <div className='relative h-12 inline-block items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0'>
          <div className='absolute top-4 left-3'>{icon}</div>
          <input
            id={name}
            key={name}
            name={name}
            type={type}
            onChange={() => handleChange()}
            ref={domRef}
            className={`${
              required ? 'required-input' : ''
            }  p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:outline-none focus:ring-0 ${rootClassName}`}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck='false'
            aria-invalid={error ? 'true' : 'false'}
            {...rest}
          />
          {/* <div className="absolute top-2 right-2">
             <button className="h-10 w-20 text-white rounded-lg bg-red-500 hover:bg-red-600">Search</button> </div> */}
          {error && <p className='text-sm text-start text-red-500'>{error}</p>}
        </div>
      </div>
    );
  },
);

export default InputMask;
