import { useEffect } from 'react';
import Loader from 'src/components/ui/loader/loader';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'src/components/ui/error-message';
import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import { setShopId } from 'src/utils/shopUtils';
import { useParams } from 'react-router-dom';
import SiteDashboard from 'src/components/shop/user/siteDashboard';
import {
  getActiveShopDetails,
  getAllBrands,
  getAllCategories,
} from 'src/store/shop/shopsAPIActions';
import { RootState } from 'src/store';

export default function ShopPage() {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const { id: shop } = useParams<{ id: string }>();
  const error = useAppSelector((state: RootState) => state.shop.shopDetails?.error);
  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getAllCategories({}));
    dispatch(getAllBrands({}));
    setShopId(shop);
    dispatch(getActiveShopDetails());
  }, [dispatch]);

  if (activeShop?.isFetching) return <Loader text={t('text-loading')} />;
  if (error) return <ErrorMessage message={error} />;

  return <SiteDashboard />;
}
