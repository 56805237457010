/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
export const TriangleArrowDown: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 123.959 123.958' {...props}>
    <path
      d='M117.979 28.017h-112c-5.3 0-8 6.4-4.2 10.2l56 56c2.3 2.3 6.1 2.3 8.401 0l56-56c3.799-3.8 1.099-10.2-4.201-10.2z'
      fill='currentColor'
    />
  </svg>
);
