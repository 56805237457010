/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-async-promise-executor */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  setProducts,
  setShopActiveCategory,
  setShopActiveProduct,
  setSProductVariant,
  setOrders,
  setUserOrders,
  setShippingAdWithRules,
  setShopActiveSale,
  setError,
  setCoupons,
  setShopActiveCoupon,
  setShopActivePlugin,
  setPlugins,
  clear,
  setActionTypeMethod,
  fetchOrderId,
  setShopActiveEmployee,
  setRefundResponse,
  setShopActiveAddonCategory,
  setSProductCurrentVariant,
  ShopStateClean,
} from './actionTypes';
import BaseService from '../../api/base';
import { API_ENDPOINTS_NEW } from '../../api/endpoints';
import {
  Category,
  CreateCategoryInput,
  Product,
  VariantDetailsList,
  SaveMode,
  SaleSubDetails,
  ProductsPagination,
  ShippingAdWithRules,
  CouponDetails,
  PlugInType,
  ActiveOrderResponse,
  EmployeeDetails,
  RefundPayload,
  BrandInput,
  AddOnCategory,
} from '../../ts-types/custom.types';
import { setShopId, getShopDetails } from 'src/utils/shopUtils';
import { SAVE_MODE } from '../../ts-types';
import { getAllAlerts } from './shopsAPIActions';

const service = new BaseService();

// ******************** -- API Calls ***************************************

export const clearState = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        // TODO Fetching
        await localStorage?.clear();
        dispatch(clear());
        // TODO Fetching;
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};
export const clearShopState = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(ShopStateClean());
        // TODO Fetching;
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};
export const getProduct = (data: Product[], productId = '') => {
  return data.find((product: Product) => product.productId === productId);
};
export const markAlertsAsSeen = (
  alerts: Array<string | undefined>,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      await service.all(`${API_ENDPOINTS_NEW.GET_PRODUCTS}`);
      service.create(API_ENDPOINTS_NEW.MARK_ALERT_AS_SEEN, alerts).then((res) => {
        dispatch(setError(''));
        dispatch(getAllAlerts(1));
      });
    } catch (e) {
      dispatch(setError('error'));
    }
  };
};
export const getProductsByProductId = ({
  pageNumber = 1,
  pageSize = 1,
  searchText = '',
  categoryId = '',
  productId = '',
}: ProductsPagination): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      let query = `/?page=${pageNumber}&size=${pageSize}`;
      if (searchText !== '') query += `&name=${searchText}`;
      if (categoryId !== '') query += `&categoryId=${categoryId}`;
      if (productId !== '') query += `&productId=${productId}`;
      const response = await service.all(`${API_ENDPOINTS_NEW.GET_PRODUCTS}${query}`);
      dispatch(setProducts(response.data));
      dispatch(setActiveProduct(getProduct(response.data.data, productId) ?? {}, SAVE_MODE.EDIT)) ??
        {};
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
    }
  };
};

export const createCategory = (
  category: CreateCategoryInput,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        service.create(API_ENDPOINTS_NEW.ADD_CATEGORY, category).then((res) => {
          // TODO Fetching
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const createBrand = (
  brand: BrandInput,
  saveMode?: SaveMode,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching{
        if (SAVE_MODE.SAVE === saveMode) {
          service.create(API_ENDPOINTS_NEW.ADD_BRAND, brand).then((res) => {
            // TODO Fetching
            dispatch(setError(''));
            resolve(res);
          });
        } else {
          const url =
            API_ENDPOINTS_NEW.UPDATE_BRAND_BY_ID.prefix +
            brand?.brandId +
            API_ENDPOINTS_NEW.UPDATE_BRAND_BY_ID.suffix;
          service.update(url, brand).then((res) => {
            // TODO Fetching
            dispatch(setError(''));
            resolve(res);
          });
        }
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const setActiveCategory = (
  category?: Category,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setShopActiveCategory(category!));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const setActiveAddonCategory = (
  addOnCategory?: AddOnCategory,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setShopActiveAddonCategory(addOnCategory!));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const setActiveProduct = (
  product: Product,
  saveMode?: SaveMode,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setShopActiveProduct(product, saveMode!));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const updateCategoryAction = (
  category?: Category,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url =
          API_ENDPOINTS_NEW.UPDATE_CATEGORY.prefix +
          category?.categoryId +
          API_ENDPOINTS_NEW.UPDATE_CATEGORY.suffix;
        service.update(url, category).then((res) => {
          // TODO Fetching
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const updateAddOnCategoryAction = (
  addOnCategory?: AddOnCategory,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url = API_ENDPOINTS_NEW.UPDATE_ADDONS_CATEGORY;
        service.update(url, addOnCategory).then((res) => {
          // TODO Fetching
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const addNewAddOnCategoryAction = (
  addOnCategory?: AddOnCategory,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url = API_ENDPOINTS_NEW.CREATE_ADDONS_CATEGORY;
        service.create(url, [{ ...addOnCategory }]).then((res) => {
          // TODO Fetching
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};
export const setProductVariant = (
  variantDetailsList?: VariantDetailsList[],
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setSProductVariant(variantDetailsList!));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const setProductCurrentVariant = (
  currentVariant?: VariantDetailsList,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setSProductCurrentVariant(currentVariant!));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const deleteProduct = (productId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url =
          API_ENDPOINTS_NEW.DELETE_PRODUCT.prefix +
          productId +
          API_ENDPOINTS_NEW.DELETE_PRODUCT.suffix;
        service.delete(url).then((res) => {
          // TODO Fetching
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const deleteAddonCategory = (
  addonCategoryId: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url = `${API_ENDPOINTS_NEW.DELETE_ADDONS_CATEGORY}?addOnCategoryId=${addonCategoryId}`;
        service.delete(url).then((res) => {
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const deleteAddonFromCategory = (
  addOnCategoryId: string,
  addOnId: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url = `${API_ENDPOINTS_NEW.DELETE_ADDONS}?addOnCategoryId=${addOnCategoryId}&addOnId=${addOnId}`;
        service.delete(url).then((res) => {
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const deleteShop = (shopId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        setShopId(shopId);
        service.create(API_ENDPOINTS_NEW.DELETE_SHOP, null).then((res) => {
          // TODO Fetching
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const setFetchOrderById = (
  orderId?: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(fetchOrderId({ data: {}, isFetching: true }));
        const getShopIdUrl = `${API_ENDPOINTS_NEW.GET_SHOP_ID_BY_ORDER_ID}/?orderId=${orderId}`;
        const url =
          API_ENDPOINTS_NEW.FETCH_ORDER_BY_ID.prefix +
          orderId +
          API_ENDPOINTS_NEW.FETCH_ORDER_BY_ID.suffix;
        let response: ActiveOrderResponse = {};
        const { shopId } = getShopDetails();
        if (!shopId) {
          service.find(getShopIdUrl).then(async (res) => {
            setShopId(res.data.shopId);
            setTimeout(async () => {
              response = await service.all(url);
            }, 0);
          });
        } else {
          response = await service.all(url);
        }
        dispatch(fetchOrderId({ data: response.data, isFetching: false }));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(fetchOrderId({ data: {}, isFetching: false }));
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const getShippingAdWithRulesForAdmin = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setShippingAdWithRules({ data: {}, isFetching: true }));
      const response = await service.all(`${API_ENDPOINTS_NEW.GET_SHIPPING_AD_WITH_RULES}`);
      dispatch(setShippingAdWithRules({ data: response.data, isFetching: false }));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
      dispatch(setShippingAdWithRules({ data: {}, isFetching: true }));
    }
  };
};

export const setShippingPriceWithRules = (
  shippingAdWithRules?: ShippingAdWithRules,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        service
          .create(API_ENDPOINTS_NEW.SET_SHIPPING_AD_WITH_RULES, shippingAdWithRules)
          .then((res) => {
            // TODO Fetching
            dispatch(setError(''));
            resolve(res);
          });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const updateShippingPriceWithRules = (
  shippingAdWithRules: ShippingAdWithRules,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        service
          .update(API_ENDPOINTS_NEW.UPDATE_SHIPPING_AD_WITH_RULES, shippingAdWithRules)
          .then((res) => {
            // TODO Fetching
            dispatch(setError(''));
            resolve(res);
          });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const setActiveSale = (
  saleDetails: SaleSubDetails,
  saleId: string,
  saveMode?: SaveMode,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        saleDetails.saleId = saleId;
        dispatch(setShopActiveSale(saleDetails, saveMode!));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const setActiveCoupon = (
  couponDetails: CouponDetails,
  saveMode?: SaveMode,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setShopActiveCoupon(couponDetails, saveMode!));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const updateSale = (saleDetails: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url =
          API_ENDPOINTS_NEW.UPDATE_SALE.prefix +
          saleDetails?.saleDetails.saleId +
          API_ENDPOINTS_NEW.UPDATE_SALE.suffix;
        service.update(url, saleDetails).then((res) => {
          // TODO Fetching
          // dispatch(set(response.data));
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};
export const createSale = (
  saleDetails?: SaleSubDetails,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url = API_ENDPOINTS_NEW.CREATE_SALE;
        service.create(url, { saleDetails: saleDetails }).then((res) => {
          // TODO Fetching
          // dispatch(set(response.data));
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};
export const deleteSale = (saleId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        // TODO Fetching

        const url =
          API_ENDPOINTS_NEW.DELETE_SALE.prefix + saleId + API_ENDPOINTS_NEW.DELETE_SALE.suffix;
        service.delete(url).then((res) => {
          // TODO Fetching
          // dispatch(set(response.data));
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const getAllPlugins = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await service.all(API_ENDPOINTS_NEW.GET_PLUGINS);
      dispatch(setPlugins(response));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
    }
  };
};

export const updateShopPlugin = (
  plugin: PlugInType,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        const url =
          API_ENDPOINTS_NEW.UPDATE_PLUGINS_BY_ID.prefix +
          plugin.plugInId +
          API_ENDPOINTS_NEW.UPDATE_PLUGINS_BY_ID.suffix;
        await service.update(url, plugin);
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const setActivePlugin = (
  plugin: PlugInType,
  saveMode: SaveMode,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setShopActivePlugin(plugin, saveMode));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const setActiveEmployee = (
  employee: EmployeeDetails,
  saveMode: SaveMode,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setShopActiveEmployee(employee, saveMode));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const createNewPlugin = (
  plugin: PlugInType,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        await service.create(API_ENDPOINTS_NEW.ADD_NEW_PLUGIN, plugin);
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const deletePlugin = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const url =
        API_ENDPOINTS_NEW.DELETE_PLUGINS_BY_ID.prefix +
        id +
        API_ENDPOINTS_NEW.DELETE_PLUGINS_BY_ID.suffix;
      await service.delete(url);
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
    }
  };
};

export const getAllCoupons = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response = await service.all(API_ENDPOINTS_NEW.GET_ALL_COUPON);
      dispatch(setCoupons(response.data));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
    }
  };
};

export const updateCoupon = (
  coupon: CouponDetails,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const url =
          API_ENDPOINTS_NEW.UPDATE_COUPON.prefix +
          coupon?.couponName +
          API_ENDPOINTS_NEW.UPDATE_COUPON.suffix;

        service.update(url, coupon).then((res) => {
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const createCoupon = (
  couponDetails: CouponDetails,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        service.create(API_ENDPOINTS_NEW.ADD_COUPON, couponDetails).then((res) => {
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const deleteCoupon = (couponName: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const url =
          API_ENDPOINTS_NEW.DELETE_COUPON.prefix +
          couponName +
          API_ENDPOINTS_NEW.DELETE_COUPON.suffix;

        service.delete(url).then((res) => {
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const setActionType = (
  actionType: SaveMode,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setActionTypeMethod(actionType));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
    }
  };
};

export const refundAction = (
  refundPayload: RefundPayload,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setRefundResponse({ isFetching: true }));
      await service.create(API_ENDPOINTS_NEW.REFUND_ORDER, refundPayload);
      dispatch(setRefundResponse({ isFetching: false }));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setRefundResponse({ isFetching: false }));
      dispatch(setError('error'));
    }
  };
};
