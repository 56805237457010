// @ts-nocheck
import { useEffect, useState } from 'react';
import Modal from 'src/components/ui/modal/modal';
import { Attachment } from 'src/ts-types/generated';
import { ImageType } from 'src/ts-types/custom.types';
import { CloseIcon } from 'src/components/icons/close-icon';
import Loader from 'src/components/ui/loader/loader';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storage } from 'src/firebase';
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';
import Label from '../ui/label';
import { getShopDetails } from 'src/utils/shopUtils';
import { getImages } from 'src/utils/categoryUtils';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUpload, faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { ImageEditor } from './imageEditor';
import { Blob } from 'buffer';
import { generateRandomString } from 'src/common';

const classes = {
  small: 'w-24 h-30',
  large: 'h-36',
};

const getPreviewImage = (value: any) => {
  let images: any[] = [];
  if (value) {
    images = Array.isArray(value) ? value : [{ ...value }];
  }
  const indexOfObject = images.findIndex((object) => {
    return object.imageUrl === '';
  });
  if (indexOfObject !== -1) {
    images.splice(indexOfObject, 1);
  }
  return images;
};

export default function CropUploader({
  onChange,
  value,
  multiple,
  onImageUploaded,
  defaultValue,
  uploadSection,
  classContainer = '',
  showDefaultValue = false,
  title = '',
  size = 'large',
  isCircle = false,
  croppedMaxWidth = 120,
  croppedMaxHeight = 60,
}: any) {
  const { t }: any = useTranslation<any>();
  const [files, setFiles] = useState<Attachment[] | string[]>(getPreviewImage(value));
  // eslint-disable-next-line prefer-const
  let [imgUrls, setImgUrls] = useState<ImageType[]>(getPreviewImage(value));
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
    // Reference to the DOM input element
    const { files } = event.target;

    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      setImageSrc(URL.createObjectURL(files[0]));
      setShowModal(true);
    }
    // Clear the event target value to give the possibility to upload the same image:
    event.target.value = '';
  };

  const saveCroppedImage = (file: Blob) => {
    const { shopId } = getShopDetails();
    const fileName = file && file.name ? file.name : generateRandomString(4);
    const storageRef = ref(storage, `${shopId}/${uploadSection}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setModalLoading(true);

    uploadTask.on(
      'state_changed',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      (snapshot) => {},
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
          // `url` is the download URL for 'images/stars.jpg'

          // Replace the firebase URL with ImageKit.io URL-endpoint
          downloadURL = downloadURL.replace(
            'https://firebasestorage.googleapis.com',
            'https://ik.imagekit.io/bcupc7szar',
          );

          setLoading(false);
          setShowModal(false);
          if (multiple) {
            imgUrls.push({
              imageUrl: downloadURL,
              name: uploadTask.snapshot.metadata.name,
            });
            setImgUrls(imgUrls);
            // @ts-ignore
            setFiles(files?.concat?.(file));
          } else {
            const imgArray: ImageType[] = [];
            imgUrls = imgArray;
            imgUrls.push({
              imageUrl: downloadURL,
              name: uploadTask.snapshot.metadata.name,
            });
            setImgUrls(imgUrls);
            // @ts-ignore
            setFiles(files?.concat?.(file));
          }
          setTimeout(() => {
            if (onChange) {
              onChange(imgUrls);
            }
          }, 0);
          if (multiple) {
            onImageUploaded(imgUrls);
          } else {
            onImageUploaded(imgUrls?.[0].imageUrl);
          }
          setModalLoading(false);
        });
      },
    );
  };

  useEffect(() => {
    if (defaultValue) {
      const img = getImages(defaultValue);
      setImgUrls(getPreviewImage(img));
    }
  }, [defaultValue]);

  const rootClassName = cn(
    'border-dashed border-2 border-border-bas rounded flex flex-row justify-center items-center cursor-pointer focus:border-accent-400 focus:outline-none',
    {
      [classes.small]: size === 'small',
      [classes.large]: size === 'large',
    },
  );

  const handleDelete = (e: any, imageName: string) => {
    // Find the image URL based on the image name
    e.preventDefault();
    e.stopPropagation();
    const imageToDelete = imgUrls.find((file) => file.name === imageName);
    if (!imageToDelete) return;

    // Create a reference to the image in Firebase Storage
    const img = imageName.replace(
      'https://ik.imagekit.io/bcupc7szar/',
      'https://firebasestorage.googleapis.com/',
    );
    const imageRef = ref(storage, `${img}`);

    // Delete the image from Firebase Storage
    deleteObject(imageRef)
      .then(() => {
        // Image deleted successfully, now update the state to remove the image from the UI
        const updatedFiles = files.filter((file) => file.name !== imageName);
        const updatedImgUrls = imgUrls.filter((file) => file.name !== imageName);
        setFiles(updatedFiles);
        setImgUrls(updatedImgUrls);
        if (onChange) {
          onChange(updatedImgUrls);
        }
        onImageUploaded(updatedImgUrls);
      })
      .catch((error) => {
        // Handle any errors during deletion
        console.error('Error deleting image from Firebase Storage:', error);
      });
  };

  const thumbs = imgUrls?.map((file: ImageType, idx) => {
    if (file.imageUrl) {
      return (
        <div
          className='inline-flex flex-col overflow-hidden border border-border-200 rounded mt-2 me-2 relative max-h-[120px]'
          key={idx}
        >
          <div className='flex items-center justify-center min-w-0 w-25 h-25 overflow-hidden'>
            <img src={file.imageUrl} width={croppedMaxWidth} height={croppedMaxHeight} />
          </div>
          <button
            className='w-4 h-4 flex items-center justify-center rounded-full bg-red-600 text-xs text-light absolute top-1 end-1 shadow-xl outline-none'
            onClick={(e) => handleDelete(e, file.name)}
          >
            <CloseIcon width={10} height={10} />
          </button>
        </div>
      );
    }
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.thumbnail));
    },
    [files],
  );

  return (
    <>
      {isMobile ? (
        <>
          <div className='flex items-center'>
            <Label className='float-right mb-[0px] pl-2'> {title} </Label>
            <input
              className='opacity-0 cursor-pointer absolute w-48'
              // class={{ height: 45, display: !selectedModal ? 'none' : 'block' }}
              type='file'
              accept='image/*;capture=camera'
              onChange={onLoadImage}
            ></input>
            <Link
              className='underline text-accent font-semibold transition-colors 
          duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-700 
          hover:no-underline focus:no-underline mr-2 smx:pb-[6px]'
            >
              {t('image-uploader-title')}
              <FontAwesomeIcon
                className='pr-2 pt-2'
                icon={faCameraRetro as IconProp}
                style={{ color: 'bg-accent' }}
                size={'1x'}
              />
            </Link>
          </div>
          <>
            {!showDefaultValue && (
              <div className={`${rootClassName}`}>
                {!!thumbs.length && thumbs}
                {loading && (
                  <div className='h-16 flex items-center mt-2 ms-2'>
                    <Loader simple={true} className='w-6 h-6' />
                  </div>
                )}
              </div>
            )}
          </>
        </>
      ) : (
        <>
          <section className={`${classContainer} flex flex-col upload`}>
            <div className='flex items-center'>
              <Label className='float-right'> {title} </Label>
              <input
                type='file'
                accept='image/*'
                onChange={onLoadImage}
                className='opacity-0 cursor-pointer absolute w-48'
                // class={{ height: 45, display: !selectedModal ? 'none' : 'block' }}
              ></input>
              <Link
                className='underline text-accent font-semibold transition-colors 
          duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-700 
          hover:no-underline focus:no-underline mb-4'
              >
                {t('image-uploader-title')}
                <FontAwesomeIcon className='pr-2' icon={faUpload as IconProp} size={'1x'} />
              </Link>
            </div>
            <>
              {!showDefaultValue && (
                <div className={`${rootClassName}`}>
                  {!!thumbs.length && thumbs}
                  {loading && (
                    <div className='h-16 flex items-center mt-2 ms-2'>
                      <Loader simple={true} className='w-6 h-6' />
                    </div>
                  )}
                </div>
              )}
            </>
            {/* </div> */}
          </section>
        </>
      )}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title={title}
        showCloseButton
        size='lg'
      >
        <ImageEditor
          imageSrc={imageSrc}
          saveCroppedImage={saveCroppedImage}
          croppedMaxHeight={croppedMaxHeight}
          croppedMaxWidth={croppedMaxWidth}
          isCircle={isCircle}
          loading={modalLoading}
        />
        {/* <ImageCropper
          imgSource={image}
          modalLoading={modalLoading}
          imgMaxHeight={imgMaxHeight}
          imgMaxWidth={imgMaxWidth}
          croppedMaxWidth={croppedMaxWidth}
          croppedMaxHeight={croppedMaxHeight}
          onSaveImage={(val: any) => saveCroppedImage(val)}
        /> */}
      </Modal>
    </>
  );
}
