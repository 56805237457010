import ErrorMessage from 'src/components/ui/error-message';
import { useAppSelector } from 'src/utils/reduxHooks';
import { RootState } from 'src/store';
import CreateOrUpdateBrandsForm from 'src/components/brand/brand-form';

export default function UpdateBrandsPage() {
  const activeBrand = useAppSelector((state: RootState) => state.shop.shopDetails?.activeBrand);
  const error = useAppSelector((state: RootState) => state.shop.shopDetails?.error);

  if (error) return <ErrorMessage message={error} />;

  return <CreateOrUpdateBrandsForm initialValues={activeBrand} />;
}
