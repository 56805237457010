/* eslint-disable @typescript-eslint/no-explicit-any */
export const Gift: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg id='Magicons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42.61 40.5' {...props}>
      <defs></defs>
      <path
        className='cls-1'
        d='M39.74,12.94h-10.77c.95-.49,1.88-1.08,2.62-1.83,1.68-1.68,1.68-4.41,0-6.09-1.63-1.63-4.47-1.63-6.09,0-1.2,1.2-2.05,2.85-2.59,4.38-.54-1.53-1.39-3.18-2.59-4.38-1.63-1.63-4.47-1.63-6.09,0-1.68,1.68-1.68,4.41,0,6.09.74.74,1.67,1.34,2.62,1.83H6.07c-.42,0-.77.34-.77.77v6.12c0,.42.34.77.77.77h.77v17.6c0,.42.34.77.77.77h30.61c.42,0,.77-.34.77-.77v-17.6h.77c.42,0,.77-.34.77-.77v-6.12c0-.42-.34-.77-.76-.77ZM19.08,37.42h-10.71v-16.83h10.71v16.83ZM19.08,19.06H6.84v-4.59h12.24v4.59ZM15.3,10.03c-1.08-1.08-1.08-2.85,0-3.93.52-.53,1.22-.81,1.96-.81s1.44.29,1.96.81c1.97,1.97,2.89,5.62,2.91,6.81,0,0,0,.02,0,.02h-.08c-1.24-.04-4.81-.97-6.76-2.91ZM25.2,37.42h-4.59v-16.83h4.59v16.83ZM25.2,19.06h-4.59v-4.59h4.59v4.59ZM23.75,12.94h-.08s0-.02,0-.02c.02-1.2.95-4.85,2.91-6.81.53-.53,1.22-.81,1.96-.81s1.44.29,1.96.81c1.08,1.08,1.08,2.85,0,3.93-1.94,1.94-5.51,2.86-6.76,2.91ZM37.45,37.42h-10.71v-16.83h10.71v16.83h0ZM38.98,19.06h-12.24v-4.59h12.24v4.59Z'
      />
    </svg>
  );
};
