import Card from 'src/components/common/card';
import { useTranslation } from 'react-i18next';
import ImportProducts from './import-products';
import { useModalAction } from '../ui/modal/modal.context';

const ExportImportView = () => {
  const { t }: any = useTranslation();
  const { closeModal } = useModalAction();

  function handleClose() {
    closeModal();
  }

  return (
    <Card className='flex min-h-screen flex-col md:min-h-0'>
      <div className='mb-5 w-full'>
        <h1 className='text-lg font-semibold text-heading'>{t('text-export-import')}</h1>
      </div>

      <div className='grid grid-cols-1 gap-5 md:grid-cols-1'>
        <ImportProducts handleClose={handleClose} />
      </div>
    </Card>
  );
};

export default ExportImportView;
