/* eslint-disable @typescript-eslint/no-explicit-any */
export const MakeUp: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg id='Magicons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42.61 40.5' {...props}>
      <defs></defs>
      <path
        className='cls-1'
        d='M34.6,22.81v-2.19c0-.24-.2-.44-.44-.44h-.44v-3.94c0-.24-.2-.44-.44-.44h-.44v-3.5c0-.1-.04-.2-.1-.28l-1.8-2.19c-.15-.19-.43-.21-.62-.06-.04.03-.07.07-.09.11l-.82,1.31c-.04.07-.07.15-.07.23v4.37h-.44c-.24,0-.44.2-.44.44v1.91c-2.32-3.38-6.95-4.24-10.33-1.92-.55.38-1.05.83-1.48,1.35v-4.8l.87-5.21s0-.05,0-.07c0-1.21-.98-2.19-2.19-2.19h-1.75c-1.21,0-2.19.98-2.19,2.19,0,.02,0,.05,0,.07l.87,5.21v17.46c0,.72.59,1.31,1.31,1.31h1.75c.27,0,.53-.08.76-.24.31.16.65.24.99.24h17.49c.24,0,.44-.2.44-.44v-7.87c0-.24-.2-.44-.44-.44ZM29.78,22.81c0-.15.01-.29.01-.44,0-.44-.04-.88-.12-1.31h4.05v1.75h-3.95ZM30.23,11.56l.43-.69,1.31,1.6v3.34h-1.75v-4.25ZM29.35,16.68h3.5v3.5h-3.39c-.03-.11-.07-.22-.11-.33v-3.17ZM22.35,15.81c3.62,0,6.56,2.94,6.56,6.56,0,1.42-.46,2.8-1.31,3.94h-1.14c2.17-2.27,2.1-5.87-.17-8.04-2.27-2.17-5.87-2.1-8.04.17-2.11,2.2-2.11,5.67,0,7.87h-1.14c-.86-1.13-1.32-2.52-1.31-3.94,0-3.62,2.94-6.56,6.56-6.56ZM20.6,27.18h3.5v.87h-3.5v-.87ZM19.59,26.31c-2.17-1.53-2.7-4.53-1.17-6.7,1.53-2.17,4.53-2.7,6.7-1.17,2.17,1.53,2.7,4.53,1.17,6.7-.32.45-.72.85-1.17,1.17h-5.53ZM15.79,18.43h-2.62v-5.25h2.62v5.25ZM13.61,6.19h1.75c.71,0,1.29.57,1.31,1.28l-.81,4.84h-2.76l-.81-4.84c.02-.71.6-1.28,1.31-1.28ZM13.61,30.68c-.24,0-.44-.2-.44-.44v-10.93h2.41c-.95,2.1-.87,4.52.21,6.56v.44h-.44c-.24,0-.44.2-.44.44v2.62c0,.47.15.93.44,1.31h-1.75ZM17.11,30.68c-.72,0-1.31-.59-1.31-1.31v-2.19h3.94v1.31c0,.24.2.44.44.44h4.37c.24,0,.44-.2.44-.44v-1.31h3.94v2.19c0,.72-.59,1.31-1.31,1.31h-10.5ZM34.16,30.68h-4.81c.29-.38.44-.84.44-1.31v-2.62c0-.24-.2-.44-.44-.44h-.69c.5-.8.84-1.69,1.01-2.62h4.49v7Z'
      />
    </svg>
  );
};
