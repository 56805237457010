/* eslint-disable @typescript-eslint/no-explicit-any */
export const ReadingTable: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='currentColor'
      {...props}
    >
      <g id='Reading_Table' data-name='Reading Table' transform='translate(-448 -584)'>
        <path
          id='Path_17417'
          data-name='Path 17417'
          d='M506.125,608.375H459.25a3.749,3.749,0,0,0-2.812-3.627v-3.873a.938.938,0,0,0-.232-.617l-5.843-6.679,9.16-5.829H463v.944a4.676,4.676,0,0,0-1.875,3.743v.938H470.5v-.937a4.676,4.676,0,0,0-1.875-3.743v-1.882a2.811,2.811,0,0,0-5.462-.937H459.25a.938.938,0,0,0-.5.146l-10.312,6.563a.938.938,0,0,0-.2,1.408l6.331,7.236v3.52a3.749,3.749,0,0,0-2.812,3.627h-1.875A1.875,1.875,0,0,0,448,610.25v31.875A1.875,1.875,0,0,0,449.875,644h1.875a1.875,1.875,0,0,0,1.875-1.875V614H478v28.125A1.875,1.875,0,0,0,479.875,644h26.25A1.875,1.875,0,0,0,508,642.125V610.25A1.875,1.875,0,0,0,506.125,608.375Zm-40.312-22.5a.939.939,0,0,1,.938.938v2.817l.747.562a2.805,2.805,0,0,1,.967,1.308h-5.3a2.805,2.805,0,0,1,.967-1.308l.747-.562v-2.817A.939.939,0,0,1,465.813,585.875ZM455.5,606.5a1.877,1.877,0,0,1,1.875,1.875h-3.75A1.877,1.877,0,0,1,455.5,606.5Zm50.625,35.625h-26.25V629h26.25Zm0-15h-26.25V614h26.25Zm0-15H451.75v30h-1.875V610.25h56.25Z'
          fill='currentColor'
        />
        <path
          id='Path_17418'
          data-name='Path 17418'
          d='M492.938,619.875h3.75a.938.938,0,0,0,0-1.875h-3.75a.938.938,0,0,0,0,1.875Z'
          transform='translate(-2.75 -2.125)'
          fill='currentColor'
        />
        <path
          id='Path_17419'
          data-name='Path 17419'
          d='M492.938,635.875h3.75a.938.938,0,0,0,0-1.875h-3.75a.938.938,0,0,0,0,1.875Z'
          transform='translate(-2.75 -3.125)'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
