/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/ui/table';
import { format } from 'date-fns';
import {
  CustomerDetails,
  Order,
  Orders,
  OrderStatus,
  PaymentDetails,
} from 'src/ts-types/custom.types';
import { SortOrder } from 'src/ts-types/generated';
import Pagination from '../ui/pagination';
import ActionButtons from '../common/action-buttons';
import { ROUTES } from 'src/utils/routes';
import { useAppDispatch } from 'src/utils/reduxHooks';
import TitleWithSort from '../ui/title-with-sort';
import { useHistory } from 'react-router-dom';
import Button from '../ui/button';
import { ORDER_STATUS } from 'src/ts-types';
import { getUserOrders } from 'src/store/shop/shopsAPIActions';
import { renderOrderStatus } from 'src/utils/shopUtils';

export type IProps = {
  orders?: Orders;
  onPagination: (current: number) => void;
  onSort: (current: any) => void;
  onOrder: (current: string) => void;
};

type SortingObjType = {
  sort: SortOrder;
  column: string | null;
};

const OrderList = ({ orders, onPagination, onSort, onOrder }: IProps) => {
  const { data, totalElements, pageSize, pageNumber, isFetching } = orders! ?? {};

  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [sortingObj, setSortingObj] = useState<SortingObjType>({
    sort: SortOrder.Desc,
    column: null,
  });

  const sortOrdersByPrice = useCallback(
    (orders: Order[]) => {
      let sortedProducts;
      if (sortingObj.sort === SortOrder.Asc) {
        sortedProducts = [...orders].sort(
          (a, b) =>
            // @ts-ignore
            a.paymentDetails.finalTotalPriceWithoutTax - b.paymentDetails.finalTotalPriceWithoutTax,
        );
      } else {
        sortedProducts = [...orders].sort(
          (a, b) =>
            // @ts-ignore
            b.paymentDetails.finalTotalPriceWithoutTax - a.paymentDetails.finalTotalPriceWithoutTax,
        );
      }
      return sortedProducts;
    },
    [sortingObj.sort],
  );

  const sortOrdersByName = useCallback(
    (orders: Order[]) => {
      let sortedOrders;
      if (sortingObj.sort === SortOrder.Asc) {
        sortedOrders = [...orders].sort((a, b) =>
          (a?.customerDetails?.customerName ?? '') > (b?.customerDetails?.customerName ?? '')
            ? -1
            : 1,
        );
      } else {
        sortedOrders = [...orders].sort((a, b) =>
          (a?.customerDetails?.customerName ?? '') > (b?.customerDetails?.customerName ?? '')
            ? 1
            : -1,
        );
      }
      return sortedOrders;
    },
    [sortingObj.sort],
  );

  useEffect(() => {
    let sortedOrders;
    if (orders?.data != null && sortingObj.column != null) {
      switch (sortingObj.column) {
        case 'price':
          sortedOrders = sortOrdersByPrice(orders!.data);
          break;
        default:
          sortedOrders = sortOrdersByName(orders!.data);
          break;
      }
      orders!.data = sortedOrders;
    }
  }, [sortingObj, orders, sortOrdersByPrice, sortOrdersByName]);

  const onPageClicked = (value: number) => {
    onPagination(value);
  };

  const onHeaderClick = (column: string | null) => ({
    onClick: () => {
      onSort((currentSortDirection: SortOrder) =>
        currentSortDirection === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
      );
      onOrder(column!);

      setSortingObj({
        sort: sortingObj.sort === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
        column: column,
      });
    },
  });

  const onFilterClick = (query: string) => {
    dispatch(getUserOrders(pageNumber ?? 1, query));
  };

  const onRowClick = (record: Order) => {
    history.push(`${ROUTES.ORDERS}${record.orderId}`);
  };

  const columns = [
    {
      title: (
        <TitleWithSort
          title={t('input-label-customer-name')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'name'}
          isActive={sortingObj.column === 'name'}
        />
      ),
      dataIndex: 'customerDetails',
      className: 'cursor-pointer',
      key: 'customerDetails',
      align: 'center',
      width: 74,
      render: (customerDetails: CustomerDetails) => {
        if (!customerDetails) return null;
        return <>{customerDetails.customerName}</>;
      },
      onHeaderCell: () => onHeaderClick('name'),
    },
    {
      title: t('creation-date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 74,
      render: (createdAt: string) => {
        return <>{format(new Date(createdAt ?? ''), 'uuuu-MM-dd')}</>;
      },
    },
    {
      title: (
        <TitleWithSort
          title={t('table-item-total')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'price'}
          isActive={sortingObj.column === 'price'}
        />
      ),
      dataIndex: 'paymentDetails',
      className: 'cursor-pointer',
      key: 'paymentDetails',
      align: 'center',
      width: 74,
      render: (paymentDetails: PaymentDetails) => {
        if (!paymentDetails) return null;
        return <>{(Math.round(paymentDetails.finalTotalPriceWithTax * 100) / 100).toFixed(2)} ₪</>;
      },
      onHeaderCell: () => onHeaderClick('price'),
    },
    {
      title: (
        <TitleWithSort
          title={t('table-item-status')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'orderStatus'}
          isActive={sortingObj.column === 'orderStatus'}
        />
      ),
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      align: 'center',
      width: 74,
      render: (orderStatus?: OrderStatus) => {
        return <>{renderOrderStatus(orderStatus)}</>;
      },
      onHeaderCell: () => onHeaderClick('orderStatus'),
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'id',
      key: 'actions',
      align: 'center',
      width: 100,
      render: (id: string, record: Order) => {
        return (
          <ActionButtons id={record?.orderId || ''} editUrl={`${ROUTES.ORDERS}${record.orderId}`} />
        );
      },
    },
  ];

  return (
    <>
      <div className='flex mb-2 items-center justify-center step2orders'>
        <Button size='small' variant='outline' onClick={() => onFilterClick('')}>
          {t('clear-filter')}
        </Button>
        <Button
          size='small'
          variant='outline'
          className='pr-2 mr-2'
          onClick={() =>
            onFilterClick(`orderStatus=${ORDER_STATUS.IN_PREPARATION.toLocaleUpperCase()}`)
          }
        >
          {t('show-pending-filter')}
        </Button>
        <Button
          size='small'
          variant='outline'
          className='pr-2 mr-2'
          onClick={() => onFilterClick('completed')}
        >
          {t('show-completed-filter')}
        </Button>
      </div>

      <div className='rounded overflow-hidden shadow mb-6 step3orders'>
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t('empty-table-data')}
          data={data}
          rowKey='orderId'
          scroll={{ x: 900 }}
          // @ts-ignore
          onRow={(record: Order, index: Order) => ({
            onClick: onRowClick.bind(null, record),
            style: {
              cursor: 'pointer',
            },
          })}
          className='cursor_table'
        />
      </div>

      {!!totalElements && (
        <div className='flex justify-center items-center'>
          <Pagination
            total={totalElements}
            current={pageNumber}
            pageSize={pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default OrderList;
