import { Table } from 'src/components/ui/table';
import { ROUTES } from 'src/utils/routes';
import { useAppDispatch } from '../../utils/reduxHooks';
import { SortOrder } from 'src/ts-types/generated';
import { useState } from 'react';
import TitleWithSort from 'src/components/ui/title-with-sort';
import { AddOnCategory, AddOnsCategories, TranslationObject } from 'src/ts-types/custom.types';
import { useTranslation } from 'react-i18next';
import { setActiveAddonCategory } from 'src/store/shop/actions';
import { useHistory } from 'react-router-dom';
import { getTranslatedItem } from 'src/common';
import ActionButtons from '../common/action-buttons';

export type IProps = {
  addonsCategories: AddOnsCategories;
  onPagination: (key: number) => void;
};
const AddOnCategoryList = ({ addonsCategories }: IProps) => {
  const { t }: any = useTranslation<any>();
  const { data, totalElements, pageSize, pageNumber } = addonsCategories;
  const rowExpandable = (record: any) => record.children?.length;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [sortingObj] = useState<{
    sort: SortOrder;
    column: string | null;
  }>({
    sort: SortOrder.Desc,
    column: null,
  });

  const columns = [
    {
      title: (
        <TitleWithSort
          title={t('table-item-title')}
          className='w-[20px]'
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'name'}
          isActive={sortingObj.column === 'name'}
        />
      ),
      className: 'cursor-pointer',
      dataIndex: 'titleWithTeanslations',
      width: 60,
      key: 'titleWithTeanslations',
      align: 'right',
      render: (titleWithTeanslations: TranslationObject[]) => {
        if (!titleWithTeanslations) return null;
        return <>{getTranslatedItem(titleWithTeanslations)}</>;
      },
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'addOnCategoryId',
      key: 'addOnCategoryId',
      width: 40,
      align: 'right',
      render: (addOnCategoryId: string, record: AddOnCategory) => {
        return (
          <ActionButtons
            id={addOnCategoryId ?? ''}
            editAction={() => onRowClick(record)}
            editUrl={ROUTES.EDIT_CATEGORY_ADDONS}
            deleteModalView='DELETE_ADDONS_CATEGORY'
          />
        );
      },
    },
  ];

  const onRowClick = (record: AddOnCategory) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dispatch(setActiveAddonCategory(record));
    history.push(ROUTES.EDIT_CATEGORY_ADDONS);
  };

  return (
    <>
      <div className='rounded overflow-hidden shadow mb-6 step4'>
        <Table
          // @ts-ignore
          columns={columns}
          emptyText={t('empty-table-data')}
          data={data ?? []}
          rowKey='categoryId'
          scroll={{ x: 1000 }}
          className='cursor_table'
          expandable={{
            rowExpandable: rowExpandable,
          }}
        />
      </div>
      {/* {!!totalElements && (
        <div className='flex justify-end items-center'>
          <Pagination
            total={totalElements}
            current={pageNumber}
            pageSize={pageSize}
            onChange={onPagination}
          />
        </div>
      )} */}
    </>
  );
};

export default AddOnCategoryList;
