// @ts-nocheck
import { useTranslation } from 'react-i18next';
import Select from 'src/components/ui/select/select';
import Label from 'src/components/ui/label';

interface SelectInputProps {
  options?: object[] | any[];
  defaultValue?: any;
  getOptionLabel?: (val: any) => any;
  getOptionValue?: (val: any) => any;
  handleOnChange?: (val: any) => any;
  isMulti?: boolean;
  label?: string;
  isLoading?: boolean;
  required?: boolean;
  isClearable?: boolean;
  setValue?: (val: string) => void;
  value?: any;
  disable?: boolean;
  idKey?: string;
  control?: any;
  errorStyle?: string;
  name?: string;
}

const UnControlledSelectInput = ({
  options,
  getOptionLabel,
  getOptionValue,
  isMulti = false,
  idKey = 'id',
  isClearable,
  isLoading,
  label,
  handleOnChange,
  defaultValue = undefined,
  setValue,
  disable = false,
  value,
  errorStyle,
  required = false,
  name,
  ...rest
}: SelectInputProps) => {
  const { t }: any = useTranslation<any>();

  // Handle default value based on whether isMulti is true or not
  const defValue = isMulti
    ? defaultValue?.map((val) => options.find((item) => item[idKey] === val)) // Map defaultValue for multiple
    : options?.find((item) => item[idKey] === defaultValue); // Find single default value

  // Handle value based on whether isMulti is true or not
  const val = isMulti
    ? value?.map((val) => options.find((item) => item[idKey] === val)) // Map value for multiple
    : options?.find((item) => item[idKey] === value); // Find single value

  const handleSetValue = (val) => {
    let selectedValue = '';
    if (isMulti) {
      selectedValue = val?.map((item) => item[idKey]) || [];
    } else {
      selectedValue = val ? val[idKey] : '';
    }
    setValue?.(name, selectedValue);
    handleOnChange?.(val);
  };

  // Custom styles for react-select to handle error styling
  const customStyles = {
    control: (base: any) => ({
      ...base,
      borderColor: errorStyle ? 'red' : base.borderColor,
      // Add border color based on the errorStyle condition
      '&:hover': {
        borderColor: errorStyle ? 'red' : base['&:hover'].borderColor,
      },
    }),
  };

  return (
    <div className={'flex-1'}>
      <Label className={`${required ? 'required-input' : ''}`}>{label}</Label>
      <Select
        isDisabled={disable}
        styles={customStyles} // Pass custom styles to react-select
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isMulti={isMulti}
        isClearable={isClearable}
        isLoading={isLoading}
        placeholder={t('select-placeholder')}
        onChange={(val) => handleSetValue(val)}
        options={options}
        value={val}
        defaultValue={defValue}
        {...rest}
      />
    </div>
  );
};

export default UnControlledSelectInput;
