import Button from 'src/components/ui/button';
import cn from 'classnames';
type Props = {
  className?: string;
  withBg?: boolean;
  children: string | JSX.Element | JSX.Element[];
  titleClassName?: string;
  withShadow?: boolean;
  title?: string | JSX.Element;
  icon?: JSX.Element;
  showConfirmButton?: boolean;
  confirmationButtonTitle?: string;
  confirmationCallBack?: () => void;
  [key: string]: unknown;
};
const Card = ({
  className,
  title,
  titleClassName = '',
  // eslint-disable-next-line react/prop-types
  children,
  icon,
  showConfirmButton,
  confirmationButtonTitle,
  confirmationCallBack,
  withShadow = true,
  withBg = true,
  ...props
}: Props) => {
  return (
    <div
      className={cn(
        `p-5 ${withBg ? 'bg-light' : ''} rounded ${withShadow ? 'shadow ' : ''}`,
        className,
      )}
      {...props}
    >
      <div className='flex '>
        <div className={`justify-start w-full ${titleClassName}`}>{title}</div>
        <div className='justify-end'>{icon}</div>
      </div>
      {children}
      {showConfirmButton && (
        <div className='mt-5 text-end'>
          <Button onClick={() => confirmationCallBack && confirmationCallBack()}>
            {confirmationButtonTitle}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Card;
