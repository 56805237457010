import cn from 'classnames';

type NotificationProps = {
  title?: string | JSX.Element;
  variant?: 'info' | 'warning' | 'error' | 'success';
  className?: string;
  children?: any;
};

const variantBgClasses = {
  info: 'bg-blue-500',
  warning: 'bg-yellow-400',
  error: 'bg-red-600',
  success: 'bg-green-600',
};

const variantBorderClasses = {
  info: 'border-blue-400',
  warning: 'border-yellow-400',
  error: 'border-red-400',
  success: 'border-green-400',
};

const variantClasses = {
  info: 'text-blue-800 border-t-4 border-blue-300 bg-blue-50 shadow',
  warning: 'flex p-4 mb-4 text-yellow-800 border-t-4 border-yellow-300 bg-yellow-50 shadow',
  error: 'flex p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 shadow',
  success: 'lex p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 shadow',
};

<div className='shadow' role='alert'>
  <div className='flex'>
    <div className='bg-blue-500 w-16 text-center p-2'>
      <div className='flex justify-center h-full items-center'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-6 w-6 text-white'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
          />
        </svg>
      </div>
    </div>
    <div className='bg-white border-r-4 border-blue-400 w-full p-4'>
      <div>
        <p className='text-gray-600 font-bold'>Information box</p>
        <p className='text-gray-600 text-sm'>Your message has been send to Jack</p>
      </div>
    </div>
  </div>
</div>;

const variantSvg = {
  info: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='fill-current text-white'
      viewBox='0 0 16 16'
      width='20'
      height='20'
    >
      <path
        fillRule='evenodd'
        d='M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8zm6.5-.25A.75.75 0 017.25 7h1a.75.75 0 01.75.75v2.75h.25a.75.75 0 010 1.5h-2a.75.75 0 010-1.5h.25v-2h-.25a.75.75 0 01-.75-.75zM8 6a1 1 0 100-2 1 1 0 000 2z'
      ></path>
    </svg>
  ),
  warning: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      className='fill-current text-white'
      width='20'
      height='20'
    >
      <path
        fillRule='evenodd'
        d='M8.22 1.754a.25.25 0 00-.44 0L1.698 13.132a.25.25 0 00.22.368h12.164a.25.25 0 00.22-.368L8.22 1.754zm-1.763-.707c.659-1.234 2.427-1.234 3.086 0l6.082 11.378A1.75 1.75 0 0114.082 15H1.918a1.75 1.75 0 01-1.543-2.575L6.457 1.047zM9 11a1 1 0 11-2 0 1 1 0 012 0zm-.25-5.25a.75.75 0 00-1.5 0v2.5a.75.75 0 001.5 0v-2.5z'
      ></path>
    </svg>
  ),
  error: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      className='fill-current text-white'
      width='20'
      height='20'
    >
      <path
        fillRule='evenodd'
        d='M4.47.22A.75.75 0 015 0h6a.75.75 0 01.53.22l4.25 4.25c.141.14.22.331.22.53v6a.75.75 0 01-.22.53l-4.25 4.25A.75.75 0 0111 16H5a.75.75 0 01-.53-.22L.22 11.53A.75.75 0 010 11V5a.75.75 0 01.22-.53L4.47.22zm.84 1.28L1.5 5.31v5.38l3.81 3.81h5.38l3.81-3.81V5.31L10.69 1.5H5.31zM8 4a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 018 4zm0 8a1 1 0 100-2 1 1 0 000 2z'
      ></path>
    </svg>
  ),
  success: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='text-white fill-current'
      viewBox='0 0 16 16'
      width='20'
      height='20'
    >
      <path
        fillRule='evenodd'
        d='M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z'
      ></path>
    </svg>
  ),
};

const Notification = ({ variant = 'info', className = '', title, children }: NotificationProps) => {
  return (
    <div className={`pb-3 ${className}`} role='alert'>
      <div>
        <div className='flex shadow-lg rounded-lg '>
          <div className={`${variantBgClasses[variant]} py-4 px-6 rounded-r-lg flex items-center`}>
            {variantSvg[variant]}
          </div>
          <div className={`bg-white border-l-4 ${variantBorderClasses[variant]} w-full p-2`}>
            <div className='text-right'>
              {title && <p className='text-gray-600 font-bold'>{title}</p>}
              <p className='text-gray-600 font-bold text-base'>{children}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div
    //   id='alert-additional-content-2'
    //   className={cn('p-4 mb-3 rounded-lg', variantClasses[variant], className)}
    //   role='alert'
    // >
    //   <div className='flex items-center'>
    //     <svg
    //       xmlns='http://www.w3.org/2000/svg'
    //       className='h-6 w-6 md:h-5 md:w-5 text-white'
    //       viewBox='-2 -4 30 30'
    //       fill={variantSvg[variant]}
    //     >
    //       <path
    //         fillRule='evenodd'
    //         d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'
    //         clipRule='evenodd'
    //       />
    //     </svg>
    //     <span className='sr-only'>Info</span>
    //     <h3 className={cn('text-lg font-medium ', variantClasses[variant])}>{title}</h3>
    //   </div>
    //   {children}
    // </div>
  );
};

export default Notification;
