import { LastYearSalesHistory, ShopAnalyticsResponse } from 'src/ts-types/custom.types';
import StickerCard from '../widgets/sticker-card';
import { DollarIcon } from '../icons/shops/dollar';
import { CartIconBig } from '../icons/cart-icon-bag';
import { CoinIcon } from '../icons/coin-icon';
import { formatPrice } from 'src/utils/use-priceNew';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'src/common';

/* eslint-disable @typescript-eslint/ban-types */
type AnalyticsWidgetProps = {
  analytics: ShopAnalyticsResponse;
};

const AnalyticsWidget: React.FC<AnalyticsWidgetProps> = ({ analytics }: AnalyticsWidgetProps) => {
  const { data, isFetching } = analytics;
  const { t }: any = useTranslation<any>();
  const chartData = data?.saleHistoryLastYear?.sort((a, b) => (a.mounth > b.mounth ? 1 : -1));
  const series = chartData?.map((item: LastYearSalesHistory) => `${item.totalRevenue} ש״ח`);
  return (
    <>
      <div className='w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-5 mb-6'>
        <div className='w-full '>
          <StickerCard
            titleTransKey='sticker-card-title-rev'
            stepName='step1'
            subtitleTransKey='sticker-card-subtitle-rev'
            icon={<DollarIcon className='w-7 h-7' color='#047857' />}
            iconBgStyle={{ backgroundColor: '#A7F3D0' }}
            count={formatPrice({
              // @ts-ignore
              price: data?.totalRevenue,
              currencyCode: 'ILS',
              locale: getLocale(),
            })}
          />
        </div>
        <div className='w-full'>
          <StickerCard
            stepName='step2'
            titleTransKey='sticker-card-title-order'
            subtitleTransKey='sticker-card-subtitle-order'
            icon={<CartIconBig />}
            count={data?.totalOrders}
          />
        </div>
        <div className='w-full'>
          <StickerCard
            stepName='step3'
            titleTransKey='sticker-card-title-today-rev'
            icon={<CoinIcon />}
            count={formatPrice({
              // @ts-ignore
              price: data?.todaysRevenue,
              currencyCode: 'ILS',
              locale: getLocale(),
            })}
          />
        </div>
      </div>
      {/* <div className='w-full flex flex-wrap mb-6'>
        <ColumnChart
          widgetTitle={t('sale-history')}
          colors={['#03D3B5']}
          series={series}
          categories={[
            t('january'),
            t('february'),
            t('march'),
            t('april'),
            t('may'),
            t('june'),
            t('july'),
            t('august'),
            t('september'),
            t('october'),
            t('november'),
            t('december'),
          ]}
        />
      </div> */}
    </>
    // <div id='accordion-flush' data-accordion='collapse'>
    //   <h2 id='accordion-flush-heading-1'>
    //     <button
    //       type='button'
    //       className='flex items-center justify-between w-full font-medium text-left '
    //       data-accordion-target='#accordion-flush-body-1'
    //       aria-expanded='true'
    //       aria-controls='accordion-flush-body-1'
    //       onClick={() => setHidden(!hidden)}
    //     >
    //       <span>Tasks</span>
    //       {hidden ? renderDownArrow() : renderUpArrow()}
    //     </button>
    //   </h2>
    //   <div
    //     id='accordion-flush-body-1'
    //     className={`${!hidden ? 'display' : 'hidden'}`}
    //     aria-labelledby='accordion-flush-heading-1'
    //   >
    //     <div
    //       id='task'
    //       className='flex justify-between items-center border-b border-slate-200 py-3 px-2 border-l-4  border-l-transparent'
    //     >
    //       <div className='inline-flex items-center space-x-2'>
    //         <div>
    //           <svg
    //             xmlns='http://www.w3.org/2000/svg'
    //             fill='none'
    //             viewBox='0 0 24 24'
    //             strokeWidth='1.5'
    //             stroke='currentColor'
    //             className='w-6 h-6 text-slate-500'
    //           >
    //             <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5' />
    //           </svg>
    //         </div>
    //         <div className='text-slate-500 line-through'>Do the homework</div>
    //       </div>
    //       <div>
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           fill='none'
    //           viewBox='0 0 24 24'
    //           strokeWidth='1.5'
    //           stroke='currentColor'
    //           className='w-4 h-4 text-slate-500 hover:text-slate-700 hover:cursor-pointer'
    //         >
    //           <path
    //             strokeLinecap='round'
    //             strokeLinejoin='round'
    //             d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
    //           />
    //         </svg>
    //       </div>
    //     </div>
    //     <div
    //       id='task'
    //       className='flex justify-between items-center border-b border-slate-200 py-3 px-2 border-l-4  border-l-transparent bg-gradient-to-r from-transparent to-transparent hover:from-slate-100 transition ease-linear duration-150'
    //     >
    //       <div className='inline-flex items-center space-x-2'>
    //         <div>
    //           <svg
    //             xmlns='http://www.w3.org/2000/svg'
    //             fill='none'
    //             viewBox='0 0 24 24'
    //             strokeWidth='1.5'
    //             stroke='currentColor'
    //             className='w-6 h-6 text-slate-500 hover:text-indigo-600 hover:cursor-pointer'
    //           >
    //             <path
    //               strokeLinecap='round'
    //               strokeLinejoin='round'
    //               d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
    //             />
    //           </svg>
    //         </div>
    //         <div>Magic stuff</div>
    //       </div>
    //       <div>
    //         <svg
    //           xmlns='http://www.w3.org/2000/svg'
    //           fill='none'
    //           viewBox='0 0 24 24'
    //           strokeWidth='1.5'
    //           stroke='currentColor'
    //           className='w-4 h-4 text-slate-500 hover:text-slate-700 hover:cursor-pointer'
    //         >
    //           <path
    //             strokeLinecap='round'
    //             strokeLinejoin='round'
    //             d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
    //           />
    //         </svg>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AnalyticsWidget;

const renderUpArrow = () => {
  return (
    <svg
      data-accordion-icon
      className='w-6 h-6 rotate-180 shrink-0'
      fill='currentColor'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

const renderDownArrow = () => {
  return (
    <svg
      data-accordion-icon
      className='w-6 h-6 shrink-0'
      fill='currentColor'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};
