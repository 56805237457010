// store/session/reducers.ts
import {
  Categories,
  Category,
  Orders,
  Product,
  Products,
  ShopAnalyticsResponse,
  VariantDetailsList,
  SaveMode,
  ShippingAdWithRules,
  SaleSubDetails,
  MyShopsResponse,
  ActiveShopResponse,
  SaleDetailsResponse,
  CreateShopResponse,
  APIResponse,
  CouponsResponse,
  CouponDetails,
  PlugInType,
  PluginResponse,
  AllMyAlertsResponse,
  ActiveOrderResponse,
  ContactUsResponse,
  AdminContactUsResponse,
  ShopEmployeesResponse,
  EmployeeDetails,
  Brand,
  Brands,
  ShopAnalyticsResponseNew,
  AddOnsCategories,
  AddOnCategory,
  ShippingAdWithRulesResponse,
} from '../../ts-types/custom.types';
import { combineReducers } from 'redux';
import { Action } from './actionTypes';

// States' definition
const initialState: ShopDetails = {
  myShopsResponse: {
    data: [],
    isFetching: false,
  },
  activeShopDetailsResponse: {
    data: { shopId: '' },
    isFetching: false,
  },
  products: { data: [] },
  analyticsResponse: {
    data: {},
    isFetching: false,
  },
  productVariants: [],
  actionType: 'SAVE',
  shippingAdWithRules: {},
  salesResponse: {
    data: [],
    isFetching: false,
  },
  createShopResponse: {
    data: { shopId: '' },
    isFetching: false,
  },
  updateShopPlugInResponse: { isFetching: false },
  updateShopPrivateDetailsResponse: { isFetching: false },
  updateProductResponse: { isFetching: false },
  updateProductsResponse: { isFetching: false },
  couponsResponse: {},
  activeCoupon: {},
  activePlugin: {
    plugInDescriptionWithTranslations: [],
    plugInTitleWithTranslations: [],
    active: true,
    icon: '',
    plugInId: '',
  },
  pulgins: {},
  updateContactUsResponse: {},
  shopEmployees: {},
  refund: { isFetching: false },
  activeProduct: {
    active: true,
  },
  analyticsResponseNew: {},
};

export interface ShopDetails {
  myShopsResponse?: MyShopsResponse;
  allMyAlerts?: AllMyAlertsResponse;
  activeShopDetailsResponse: ActiveShopResponse;
  activeCategory?: Category;
  activeAddonsCategory?: AddOnCategory;
  activeAddonCategory?: AddOnCategory;
  activeBrand?: Brand;
  brands?: Brands;
  activeEmployee?: EmployeeDetails;
  activeProduct?: Product;
  products?: Products;
  categories?: Categories;
  addonsCategories?: AddOnsCategories;
  error?: string;
  analyticsResponse?: ShopAnalyticsResponse;
  productVariants?: VariantDetailsList[];
  currentVariant?: VariantDetailsList;
  orders?: Orders;
  actionType: SaveMode;
  shippingAdWithRules: ShippingAdWithRulesResponse;
  salesResponse: SaleDetailsResponse;
  activeSale?: SaleSubDetails;
  createShopResponse?: CreateShopResponse;
  activeOrderResponse?: ActiveOrderResponse;
  updateShopPrivateDetailsResponse?: APIResponse;
  updateProductResponse?: APIResponse;
  updateShopPlugInResponse?: APIResponse;
  couponsResponse: CouponsResponse;
  activeCoupon: CouponDetails;
  activePlugin: PlugInType;
  pulgins: PluginResponse;
  updateProductsResponse: APIResponse;
  updateContactUsResponse?: ContactUsResponse;
  contactUsResponse?: AdminContactUsResponse;
  shopEmployees?: ShopEmployeesResponse;
  refund: APIResponse;
  analyticsResponseNew?: ShopAnalyticsResponseNew;
}

export interface State {
  shopDetails?: ShopDetails;
}
const shopDetails = (state = initialState, action: Action): ShopDetails => {
  switch (action.type) {
    case 'SET_MY_SHOPS_RESPONSE':
      return { ...state, myShopsResponse: action.response };
    case 'UPDATE_SHOP_PRIVATE_DETAILS_RESPONSE':
      return { ...state, updateShopPrivateDetailsResponse: action.response };
    case 'SET_ALL_MY_ALERTS_RESPONSE':
      return { ...state, allMyAlerts: action.response };
    case 'UPDATE_CONTACT_US_RESPONSE':
      return { ...state, updateContactUsResponse: action.response };
    case 'SET_ACTIVE_SHOP_DETAILS_RESPONSE':
      return { ...state, activeShopDetailsResponse: action.response };
    case 'SET_PRODUCTS':
      return { ...state, products: action.products };
    case 'SET_ADDONS_CATEGORIES':
      return { ...state, addonsCategories: action.addonsCategories };
    case 'SET_CATEGORIES':
      return { ...state, categories: action.categories };
    case 'SET_ACTIVE_CATEGORY':
      return { ...state, activeCategory: action.category };
    case 'SET_ACTIVE_ADDON_CATEGORY':
      return { ...state, activeAddonsCategory: action.addon };
    case 'SET_BRANDS':
      return { ...state, brands: action.brands };
    case 'SET_ACTIVE_BRAND':
      return { ...state, activeBrand: action.brand };
    case 'SET_ACTIVE_EMPLOYEE':
      return { ...state, activeEmployee: action.employee };
    case 'SET_ACTIVE_PRODUCT':
      return {
        ...state,
        activeProduct: action.product,
        actionType: action.saveMode,
      };
    case 'SET_PRODUCT_VARIANTS':
      return { ...state, productVariants: action.variantDetailsList };
    case 'SET_PRODUCT_CURRENT_VARIANT':
      return { ...state, currentVariant: action.currentVariant };
    case 'SET_ORDERS':
      return { ...state, orders: action.orders };
    case 'SET_USER_ORDERS':
      return { ...state, orders: action.orders };
    case 'FETCH_ORDER_ID':
      return { ...state, activeOrderResponse: action.order };
    case 'SET_SHOP_ANALYTICS_RESPONSE':
      return { ...state, analyticsResponse: action.response };
    case 'SET_SHOP_ANALYTICS_RESPONSE_NEW':
      return { ...state, analyticsResponseNew: action.response };
    case 'SET_SHIPPING_AD_AND_RULES':
      return { ...state, shippingAdWithRules: action.shippingAdWithRules };
    case 'SET_ALL_SALES_RESPONSE':
      return { ...state, salesResponse: action.response };
    case 'UPDATE_SHOP_PLUGIN_RESPONSE':
      return { ...state, updateShopPlugInResponse: action.response };
    case 'UPDATE_PRODUCT_RESPONSE':
      return { ...state, updateProductResponse: action.response };
    case 'UPDATE_PRODUCTS_RESPONSE':
      return { ...state, updateProductsResponse: action.response };
    case 'SET_CREATE_SHOP_RESPONSE':
      return { ...state, createShopResponse: action.response };
    case 'SET_SHOP_EMPLOYEES_RESPONSE':
      return { ...state, shopEmployees: action.response };
    case 'SET_ACTIVE_SALE':
      return {
        ...state,
        activeSale: action.saleDetails,
        actionType: action.saveMode,
      };
    case 'CLEAR_STATE':
      return initialState;
    case 'CLEAR_SHOP_STATE':
      return {
        ...initialState,
        myShopsResponse: state.myShopsResponse,
      };
    case 'SET_COUPONS':
      return { ...state, couponsResponse: action.coupons };

    case 'SET_ACTIVE_COUPON':
      return {
        ...state,
        activeCoupon: action.couponDetails,
        actionType: action.saveMode,
      };
    case 'SET_ACTIVE_PLUGIN':
      return {
        ...state,
        activePlugin: action.plugin,
        actionType: action.saveMode,
      };
    case 'SET_PLUGINS':
      return {
        ...state,
        pulgins: action.plugins,
      };
    case 'REFUND_ORDER_RESPONSE':
      return {
        ...state,
        refund: action.response,
      };
    case 'ADMIN_CONTACT_US_RESPONSE':
      return {
        ...state,
        contactUsResponse: action.response,
      };
    default:
      return state;
  }
};
export default combineReducers<State>({ shopDetails });
