import Card from 'src/components/common/card';
import Loader from 'src/components/ui/loader/loader';
import { useTranslation } from 'react-i18next';
import ShopList from 'src/components/shop/shop-list';
import { useState } from 'react';
import Search from 'src/components/common/search';
import { SortOrder } from 'src/ts-types/generated';
import { useAppSelector } from 'src/utils/reduxHooks';
import { isSuperAdmin } from 'src/utils/authUtils';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import { RootState } from 'src/store';

export default function AllShopPage() {
  const { t }: any = useTranslation();
  const [, setSearchTerm] = useState('');
  const history = useHistory();
  const [, setPage] = useState(1);
  const [, setOrder] = useState('created_at');
  const [, setColumn] = useState<SortOrder>(SortOrder.Desc);
  const shopDetails = useAppSelector((state: RootState) => state.shop.shopDetails?.myShopsResponse);

  if (shopDetails?.isFetching) return <Loader text={t('text-loading')} />;
  // if (error) return <ErrorMessage message={shopDetails?.error} />;
  function handleSearch({ searchText }: { searchText: string }) {
    setSearchTerm(searchText);
  }
  function handlePagination(current: any) {
    setPage(current);
  }
  if (!isSuperAdmin()) {
    history.push(ROUTES.ENTRY);
  }
  return (
    <>
      <Card className='flex flex-col md:flex-row items-center justify-between mb-8'>
        <div className='md:w-1/4 mb-4 md:mb-0'>
          <h1 className='text-lg font-semibold text-heading'>{t('sidebar-nav-item-shops')}</h1>
        </div>

        <div className='w-full md:w-1/2 flex flex-col md:flex-row items-center ms-auto'>
          <Search onSearch={handleSearch} />
        </div>
      </Card>
      <ShopList
        shops={shopDetails?.data}
        onPagination={handlePagination}
        onOrder={setOrder}
        onSort={setColumn}
      />
    </>
  );
}
