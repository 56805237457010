import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { useEffect } from 'react';
import { getAllCoupons, setActiveCoupon } from '../../store/shop/actions';
import { CouponDetails } from '../../ts-types/custom.types';
import ActionButtons from '../../components/common/action-buttons';
import { SAVE_MODE } from '../../ts-types';
import { ROUTES } from '../../utils/routes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import Loader from 'src/components/ui/loader/loader';
import { withPermission } from 'src/HOC/withPermission';
import { formatPrice } from 'src/utils/use-priceNew';
import { RootState } from 'src/store';
import { getLocale, isDateExpired } from 'src/common';

const CustomerPage = () => {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const couponResponse = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.couponsResponse,
  );

  useEffect(() => {
    dispatch(getAllCoupons());
  }, [dispatch]);

  if (couponResponse?.isFetching) return <Loader text={t('text-loading')} />;

  const columns = [
    {
      title: t('coupon-name'),
      dataIndex: 'couponName',
      key: 'couponName',
      align: 'center',
      width: 50,
      render: (item: any) => <span className='whitespace-nowrap truncate'>{item}</span>,
    },
    {
      title: t('coupon-discount'),
      dataIndex: 'percentToDiscount',
      key: 'percentToDiscount',
      align: 'center',
      width: 50,
      render: (item: any) => (
        <span className='whitespace-nowrap truncate'>
          {formatPrice({
            // @ts-ignore
            price: item,
            currencyCode: 'ILS',
            locale: getLocale(),
            displaySign: false,
          })}
        </span>
      ),
    },
    {
      title: t('coupon-minimal-price'),
      dataIndex: 'couponRule',
      key: 'couponRule',
      align: 'center',
      width: 50,
      render: (item: any) => (
        <span className='whitespace-nowrap truncate'>
          {formatPrice({
            // @ts-ignore
            price: item?.minPriceToUseCoupon,
            currencyCode: 'ILS',
            locale: getLocale(),
            displaySign: false,
          })}
        </span>
      ),
    },
    {
      title: t('table-item-amount'),
      dataIndex: 'avilableCoupons',
      key: 'avilableCoupons',
      align: 'center',
      width: 50,
      render: (item: any) => <span className='whitespace-nowrap truncate'>{item}</span>,
    },
    {
      title: t('coupon-expire-at'),
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      align: 'center',
      width: 50,
      render: (item: any) =>
        isDateExpired(item) ? (
          <span className='whitespace-nowrap truncate text-red-500'>
            {item.substring(0, 10) + ` (${t('table-item-expired')})`}
          </span>
        ) : (
          <span className='whitespace-nowrap truncate'>{item.substring(0, 10)}</span>
        ),
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'couponName',
      key: 'couponName',
      align: 'center',
      width: 80,
      render: (couponName: string, record: CouponDetails) => (
        <ActionButtons
          id={couponName || ''}
          editAction={() => {
            dispatch(setActiveCoupon(record, SAVE_MODE.EDIT)),
              history.push(ROUTES.EDIT_CREATE_COUPON);
          }}
          editUrl={ROUTES.EDIT_CREATE_COUPON}
          deleteModalView='DELETE_COUPON'
        />
      ),
    },
  ];
  const handleCreateNewCoupon = () => {
    dispatch(
      setActiveCoupon(
        {
          couponName: '',
          couponRule: { minPriceToUseCoupon: -1 },
          percentToDiscount: 0,
          avilableCoupons: 0,
          expirationDate: format(new Date(), "uuuu-MM-dd'T'HH:mmXXXXX"),
        },
        SAVE_MODE.SAVE,
      ),
    );
    history.push(ROUTES.EDIT_CREATE_COUPON);
  };
  const onRowClick = (record: any) => {
    dispatch(
      setActiveCoupon(
        {
          ...record,
        },
        SAVE_MODE.EDIT,
      ),
    );
    history.push(ROUTES.EDIT_CREATE_COUPON);
  };
  return (
    <>
      עולה בקרוב
      {/* <Card className='flex flex-col mb-8'>
        <div className='w-full flex md:flex-row smx:flex-row items-center'>
          <div className='md:w-1/4 mb-4 md:mb-0'>
            <h1 className='text-xl font-semibold text-heading'>{t('input-label-coupons')}</h1>
          </div>

          <div className='justify-end w-full xl:w-3/4 flex md:flex-row space-y-4 md:space-y-0 items-center ms-auto'>
            <Button onClick={() => handleCreateNewCoupon()} className='h-12 ms-4 md:ms-6'>
              <span className='hidden md:block'>{t('button-label-add-sale')} +</span>
              <span className='md:hidden'>+ {t('button-label-add')}</span>
            </Button>
          </div>
        </div>
      </Card>
      <div className='rounded overflow-hidden shadow mb-6'>
        <Table
          columns={columns}
          className='cursor_table'
          onRow={(record: any, index: any) => ({
            onClick: onRowClick.bind(null, record),
            style: {
              cursor: 'pointer',
            },
          })}
          emptyText={t('empty-table-data')}
          data={couponResponse?.data}
          rowKey='id'
          scroll={{ x: 900 }}
        />
      </div> */}
    </>
  );
};
export default withPermission(CustomerPage);
