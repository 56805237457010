/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useForm } from 'react-hook-form';
// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import Button from '../ui/button';
import Input from '../ui/input';
// @ts-ignore
import { toast } from 'react-toastify';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { packagesDetailsModalValidation } from './packagesDetailsModalValidation';
import Checkbox from '../ui/checkbox/checkbox';
import Card from '../common/card';
import { CreatePackage, fetchPlans, UpdatePackage } from 'src/store/utilData/actions';
import { UpdatePlanDetails } from 'src/ts-types/custom.types';
import InputWithTranslation from '../ui/inputWithTranslation';
import TextAreaWithTranslation from '../ui/text-areaWithTranslation';
import { RootState } from 'src/store';
import { TOAST_MESSAGE } from 'src/utils/constants';

const PackageDetailsModal = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const modalData = useModalState();

  const planDetailsByIdResponse = useAppSelector(
    (state: RootState) => state.util?.UtilData?.planDetailsByIdResponse,
  );
  const mode = useAppSelector((state: RootState) => state.shop.shopDetails?.actionType);

  const { closeModal } = useModalAction();
  let { data } = planDetailsByIdResponse!;

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<UpdatePlanDetails>({
    mode: 'onBlur',
    ...(data
      ? {
          defaultValues: {
            ...data,
          },
        }
      : {}),
    resolver: yupResolver(packagesDetailsModalValidation),
  });

  const { active } = data;

  useEffect(() => {
    if (mode == 'SAVE') {
      data = {
        price: 0,
        durationMonths: 0,
        maxNumberOfProducts: 0,
        packageTitle: '',
        packageId: '',
      };
    }
  }, []);

  const onSubmit = (values: UpdatePlanDetails) => {
    setLoading(true);
    values.PackageTitleWithTranslations = getValues('PackageTitleWithTranslations');
    values.packageDescriptionWithTranslations = getValues('packageDescriptionWithTranslations');
    values.packageId = modalData.data?.packageId;

    if (values.packageId) {
      dispatch(UpdatePackage(values))
        .then(() => {
          toast.success(t(TOAST_MESSAGE.PACKAGE_INFO_UPDATED_SUCCESSFULLY));
          setLoading(false);
          dispatch(fetchPlans());
          closeModal();
        })
        .catch(() => {
          toast.success(t(TOAST_MESSAGE.PACKAGE_INFO_UPDATED_FAILED));
          closeModal();
          setLoading(false);
        });
    } else {
      dispatch(CreatePackage(values))
        .then(() => {
          toast.success(t(TOAST_MESSAGE.PACKAGE_CREATED_SUCCESSFULLY));
          setLoading(false);
          dispatch(fetchPlans());
          closeModal();
        })
        .catch(() => {
          toast.success(t(TOAST_MESSAGE.PACKAGE_FAILED_TO_CREATE));
          closeModal();
          setLoading(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='p-4 pb-6 bg-light m-auto w-full rounded-md md:rounded-xl sm:w-[24rem] md:w-full'>
        <div className='w-full h-full text-center'>
          <div className='mb-38'>
            <Card className='md:w-full mt-5' withShadow={false}>
              <div className='flex flex-col md:flex-row'>
                <InputWithTranslation
                  label={t('table-item-package-title')}
                  values={getValues('PackageTitleWithTranslations')}
                  setValue={(val: any) => setValue('PackageTitleWithTranslations', val)}
                  variant='outline'
                  containerClassName='md:ml-4'
                  placeholder={t('table-item-package-title')}
                  error={t(errors.PackageTitleWithTranslations?.message)}
                  name='PackageTitleWithTranslations'
                />
                <Input
                  label={t('table-item-package-price')}
                  {...register('price')}
                  variant='outline'
                  placeholder={t('table-item-package-price')}
                  error={t(errors.price?.message)}
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <Input
                  label={t('table-item-package-duration')}
                  {...register('durationMonths')}
                  variant='outline'
                  containerClassName='md:ml-4'
                  placeholder={t('table-item-package-duration')}
                  error={t(errors.durationMonths?.message)}
                />
                <Input
                  label={t('table-item-package-maxNumberOfProducts')}
                  {...register('maxNumberOfProducts')}
                  variant='outline'
                  placeholder={t('table-item-package-maxNumberOfProducts')}
                  error={t(errors.maxNumberOfProducts?.message)}
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <Input
                  label={t('table-item-package-price')}
                  {...register('priceForOnePayment')}
                  variant='outline'
                  placeholder={t('table-item-package-price')}
                  error={t(errors.price?.message)}
                />
              </div>
              <div className='flex flex-col '>
                <div className='mt-5 pr-3'>
                  <Checkbox
                    onChange={(e: any) => setValue('active', e.target.checked)}
                    defaultChecked={active}
                    label={t('input-label-disable-variant')}
                    name='isActive'
                  />
                </div>
                <TextAreaWithTranslation
                  label={t('input-label-description')}
                  values={getValues('packageDescriptionWithTranslations')}
                  setValue={(val: any) => setValue('packageDescriptionWithTranslations', val)}
                  variant='outline'
                  error={t(errors.packageDescriptionWithTranslations?.message)}
                  name={'packageDescriptionWithTranslations'}
                />
              </div>
            </Card>
            <div className='flex items-end space-s-8 float-left sm:pl-12 md:pl-41'>
              <Button
                className='ms-4 md:ms-6 float-left'
                type='submit'
                loading={loading}
                disabled={!isValid}
              >
                {t('button-label-save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PackageDetailsModal;
