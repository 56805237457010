/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AttributeValue,
  Brand,
  Product,
  ProductFormValues,
  ProductInfoWithTranslations,
  TranslationObject,
  VariantAllOptions,
  VariantAllOptionsOptionName,
  VariantAllOptionsOptionsValues,
  VariantDetailsList,
  VariantsOptionsCombineList,
} from 'src/ts-types/custom.types';
import { ProductType } from 'src/ts-types/generated';
import { ATTRIBUTES } from './variants';
import { siteSettings } from 'src/settings/site.settings';
import { getEndOfDayDate } from 'src/common';
import { SAVE_MODE } from 'src/ts-types';

export const convertToTranslatedObject = (values?: ProductInfoWithTranslations[]) => {
  const nameObj: TranslationObject[] = [];
  const shortDescriptionObj: TranslationObject[] = [];
  const descriptionObj: TranslationObject[] = [];

  values?.map?.((item: ProductInfoWithTranslations) => {
    const obj: TranslationObject = {
      value: '',
      Language: '',
    };
    obj.Language = item.language;
    obj.value = item.name ?? '';
    nameObj.push(obj);
  });
  values?.map?.((item: ProductInfoWithTranslations) => {
    const obj: TranslationObject = {
      value: '',
      Language: '',
    };
    obj.Language = item.language;
    obj.value = item.shortDescription ?? '';
    shortDescriptionObj.push(obj);
  });
  values?.map?.((item: ProductInfoWithTranslations) => {
    const obj: TranslationObject = {
      value: '',
      Language: '',
    };
    obj.Language = item.language;
    obj.value = item.description ?? '';
    descriptionObj.push(obj);
  });
  return {
    name: nameObj,
    shortDescription: shortDescriptionObj,
    description: descriptionObj,
  };
};

export const getProductDetails = (
  values: ProductFormValues,
  isSubmitCall?: boolean,
  actionType?: string,
): Product => {
  const product: Product = {
    productId: values?.productId ?? '',
    mainImage: '',
    moreImages: values?.moreImages ?? [],
    brandId: values?.brandId ?? '',
    categoryId: values?.categoryId ?? '',
    variantAllOptions: [],
    variantDetailsList: [],
    name: [],
    productType: values?.productType ?? ProductType.Simple,
  };

  product.active = values?.active ?? false;
  product.topProduct = values?.topProduct ?? false;
  product.hotProduct = values?.hotProduct ?? false;
  product.productSEO = values?.productSEO ?? '';
  product.price = values?.price;
  product.discountPrice =
    values.productType === 'simple' && typeof values?.discountPrice === 'number'
      ? values.discountPrice
      : 0;
  product.productMilli = values.productMilli;
  product.productCode = values?.productCode ?? '';
  product.addOnsCategories = values.addOnsCategories ?? [];
  product.productInfoWithTranslations = getProductInfoWithTranslations?.(values) ?? [];
  // prettier-ignore
  product.mainImage = Array.isArray(values?.mainImage)
    ? (values?.mainImage?.[0] ?? '')
    : (values?.mainImage ?? '');
  product.variantDetailsList = getVariantDetailsList(values, isSubmitCall, product.productType);
  product.variantAllOptions = getVariantAllOptions(product.variantDetailsList);
  if (product.productType === ProductType.Variable) {
    product.variantDetailsList = cleanVariants(
      product.variantDetailsList,
      product.variantAllOptions,
    );
  }
  return product;
};

const cleanVariants = (
  variantDetailsList: VariantDetailsList[],
  variantAllOptions: VariantAllOptions[],
): VariantDetailsList[] => {
  variantDetailsList.forEach((variantDetails: VariantDetailsList) => {
    variantDetails?.variantsOptionsCombineList?.forEach((item: VariantsOptionsCombineList) => {
      if (
        item?.optionNameId === 'k4' &&
        item.optionValueId &&
        !item.optionValueId.startsWith('v')
      ) {
        item.optionValueId = getAttributeMeta(item.optionValueId, variantAllOptions);
      }
    });
  });

  return variantDetailsList;
};

const getAttributeMeta = (optionValueId: string, variantAllOptions: VariantAllOptions[]) => {
  const element = variantAllOptions?.find(
    (item: VariantAllOptions) => item.optionName?.optionNameId === 'k4',
  );
  return element?.optionsValues?.find(
    (item: VariantAllOptionsOptionsValues) => item.meta === optionValueId,
  )?.optionValueId;
};

const getVariantDetailsList = (
  productValues: ProductFormValues,
  isSubmitCall?: boolean,
  productType?: ProductType,
): VariantDetailsList[] => {
  if (isSubmitCall) {
    if (productType === ProductType.Simple) {
      const variantDetailsList: VariantDetailsList = {
        price: Number(productValues?.price),
        active: productValues?.active,
        mainImage: productValues?.mainImage,
        countInStock: productValues?.outOfStock ? 0 : 1000,
        discountPrice:
          Number(productValues?.discountPrice) === 0
            ? 0
            : productValues.price - (productValues?.discountPrice ?? 0),
        endDiscountDate: getEndOfDayDate(getEndDiscountDate(productValues, undefined)),
      };

      const variantsOptionsCombineList: VariantsOptionsCombineList[] = [];
      variantDetailsList.variantsOptionsCombineList = variantsOptionsCombineList;
      // prettier-ignore
      variantDetailsList.mainImage = Array.isArray(productValues?.mainImage)
        ? (productValues?.mainImage?.[0] ?? '')
        : (productValues?.mainImage ?? siteSettings.product.placeholder);

      productValues.variantDetailsList[0] = variantDetailsList;
    } else if (productType === ProductType.Variable) {
      productValues?.variantDetailsList.forEach((item: VariantDetailsList) => {
        const price = Number(productValues.price);
        item.price = item.price ? Number(item.price) : price;
        if (item.discountPrice) {
          if (item.discountPrice) {
            item.discountPrice =
              Number(item.discountPrice) === 0 ? 0 : item.price - (item.discountPrice ?? 0);
            item.endDiscountDate = getEndOfDayDate(getEndDiscountDate(productValues, item));
          }
        }
      });
      return productValues?.variantDetailsList ?? [];
    }
  }
  return productValues?.productVariants ?? productValues?.variantDetailsList ?? [];
};

const getVariantAllOptions = (variantDetailsList: VariantDetailsList[]): VariantAllOptions[] => {
  const variants: VariantAllOptions[] = [];

  ATTRIBUTES.forEach((element) => {
    const optionNameObject: VariantAllOptionsOptionName = {};
    const optionValuesArrayObject: VariantAllOptionsOptionsValues[] = [];
    const { meta, optionName, optionNameId } = element;

    optionNameObject.meta = meta ?? '';
    optionNameObject.optionNameId = optionNameId ?? '';
    optionNameObject.valueWithTeanslations = [
      {
        value: optionName ?? '',
        Language: 'he',
      },
    ];

    if (optionNameId === 'k4') {
      let optionIdCounter = 1;
      variantDetailsList?.forEach((variantDetail) => {
        variantDetail?.variantsOptionsCombineList?.forEach((variantsOptionsCombine) => {
          if (variantsOptionsCombine?.optionNameId === 'k4') {
            const dynamicOptionValuesObject: VariantAllOptionsOptionsValues = {};
            dynamicOptionValuesObject.optionValueId = `v${optionIdCounter++}`;
            dynamicOptionValuesObject.meta = variantDetail?.variantMeta ?? '';
            dynamicOptionValuesObject.valueWithTeanslations = variantDetail?.variantMetaText ?? [
              {
                value: '',
                Language: 'he',
              },
            ];
            optionValuesArrayObject.push(dynamicOptionValuesObject);
          }
        });
      });
    } else {
      element?.values?.forEach((val) => {
        const { meta, optionValueId, optionValue } = val ?? {};
        const optionValuesObject: VariantAllOptionsOptionsValues = {};
        optionValuesObject.optionValueId = optionValueId ?? '';
        optionValuesObject.meta = meta ?? '';
        optionValuesObject.valueWithTeanslations = [
          {
            value: optionValue ?? '',
            Language: 'he',
          },
        ];
        optionValuesArrayObject.push(optionValuesObject);
      });
    }

    variants.push({
      optionName: optionNameObject,
      optionsValues: optionValuesArrayObject,
    });
  });

  return variants.filter((entry) => entry?.optionsValues?.length > 0);
};

export const getProductInfoWithTranslations = (values: ProductFormValues) => {
  const productInfoWithTranslations: any = [];
  siteSettings.languages.map((item: any) => {
    const productInfo: ProductInfoWithTranslations = {
      language: item.value,
    };
    productInfo.name =
      values.name.find((nameItem: TranslationObject) => nameItem.Language === item.value)?.value ??
      '';
    productInfo.shortDescription = values.shortDescription?.find?.(
      (nameItem: TranslationObject) => nameItem.Language === item.value,
    )?.value;
    productInfo.description = values.description?.find?.(
      (nameItem: TranslationObject) => nameItem.Language === item.value,
    )?.value;
    productInfoWithTranslations.push(productInfo);
  });

  return productInfoWithTranslations;
};

export const getActiveProduct = (data: Product[], productId = '') => {
  return data.find((product: Product) => product.productId === productId);
};

export const getActiveBrand = (data: Brand[], brandId = '') => {
  return data.find((brand: Brand) => brand.brandId === brandId);
};

// export const getVariantsOptions = (variantDetailsList: VariantAllOptionsOptionsValues[]) => {};

export const getSelectedVariantOptions = (
  variantDetailsList: VariantDetailsList[],
  setValue: (name: string, val: unknown) => void,
) => {
  variantDetailsList?.forEach((detailsVariant: VariantDetailsList) => {
    const variantsOptionsCombineList = detailsVariant.variantsOptionsCombineList;
    variantsOptionsCombineList?.forEach((item: VariantsOptionsCombineList, index: number) => {
      const variantName = ATTRIBUTES.find((attr) => attr.optionNameId === item.optionNameId);
      const variantValue = variantName?.values.find(
        (attVal) => attVal.optionValueId === item.optionValueId,
      );
      setValue(`variations[${index}].attribute`, variantName);
      setValue(`variations[${index}].value`, variantValue);
    });
  });
};

export const getSelectedVariantAttribute = (
  variantsOptionsCombineList: VariantsOptionsCombineList[],
  attributeId: string,
  attributes: AttributeValue[],
): AttributeValue | undefined => {
  const selectedAttributeId = variantsOptionsCombineList.find(
    (item: VariantsOptionsCombineList) => item.optionNameId === attributeId,
  );
  return attributes.find(
    (item: AttributeValue) => selectedAttributeId?.optionValueId === item.optionValueId,
  );
};

export const getVariantsData = (productVariants: VariantDetailsList[]) => {
  const data: VariantDetailsList[] = [];
  if (productVariants && productVariants.length > 0) {
    productVariants?.forEach((item: VariantDetailsList, index: number) => {
      data.push({
        id: index.toString(),
        mainImage: item?.mainImage,
        countInStock: item.countInStock,
        active: item.active,
        price: item?.price,
        discountPrice: item?.discountPrice,
        variantAttributeValues: renderVariantAttributeValue(item.variantsOptionsCombineList),
        variantsOptionsCombineList: item.variantsOptionsCombineList,
      });
    });
  }
  return data;
};

export const renderVariantAttributeValue = (data?: VariantsOptionsCombineList[]) => {
  let attributeValues = '';
  data?.forEach((combineList: VariantsOptionsCombineList) => {
    const attrValues = ATTRIBUTES.find((attr) => attr.optionNameId === combineList.optionNameId);
    attributeValues +=
      attrValues?.values.find((item) => item.optionValueId === combineList.optionValueId)
        ?.optionValue + ' ';
  });
  return attributeValues;
};

export function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const getOptionValueFromVariants = (
  optionNameId: string,
  optionValueId: string,
  activeProduct: Product,
) => {
  const { variantAllOptions } = activeProduct;

  if (!variantAllOptions || !variantAllOptions.length) return null;

  for (const option of variantAllOptions) {
    const { optionName, optionsValues } = option;

    if (optionName?.optionNameId === optionNameId) {
      const matchingOptionValue = optionsValues?.find(
        (value: VariantAllOptionsOptionsValues) => value.optionValueId === optionValueId,
      );

      if (matchingOptionValue) {
        return matchingOptionValue;
      }
    }
  }

  return null;
};

// discountPrice:

export const getPrice = (product: Product) => {
  const { price = undefined } = product;
  if (product?.variantDetailsList) {
    if (product?.variantDetailsList[0]?.price) {
      return product.variantDetailsList[0].price;
    } else {
      return price;
    }
  } else {
    return price;
  }
};

export const getEndDiscountDate = (product: Product, variantDetailsList?: VariantDetailsList) => {
  const { endDiscountDate: endProductDiscount } = product ?? {};
  const { endDiscountDate: endVariantDiscount } = variantDetailsList ?? {};

  if (variantDetailsList) {
    if (endVariantDiscount) {
      return endVariantDiscount;
    }
  } else if (product) {
    if (endProductDiscount) {
      return endProductDiscount;
    }
  }
  return undefined;
};

export const getDiscountPrice = (product: Product): number | undefined => {
  let discountPrice = undefined;
  const price = getPrice(product) ?? 0;

  if (price > 0) {
    if (product.variantDetailsList) {
      if (product.variantDetailsList[0].discountPrice) {
        discountPrice = price - product.variantDetailsList[0].discountPrice;
      }
    }
    if (discountPrice === undefined) {
      if (product.discountPrice) {
        discountPrice = price - product.discountPrice;
      }
    }
  }
  return discountPrice;
};
