import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CouponDetails } from 'src/ts-types/custom.types';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Card from 'src/components/common/card';
import Input from 'src/components/ui/input';
import { createCoupon, updateCoupon } from 'src/store/shop/actions';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import { ROUTES } from 'src/utils/routes';
import Label from 'src/components/ui/label';
import { DatePicker } from 'src/components/ui/date-picker';
import Button from 'src/components/ui/button';
import { RootState } from 'src/store';
import { TOAST_MESSAGE } from 'src/utils/constants';
import { getEndOfDayDate } from 'src/common';

export default function EditOrCreateCoupon() {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [, setErrorMessage] = useState<string | null>(null);

  let couponDetails = useAppSelector((state: RootState) => state.shop.shopDetails?.activeCoupon);
  const actionType = useAppSelector((state: RootState) => state.shop.shopDetails?.actionType);
  const { register, handleSubmit, setError } = useForm<CouponDetails>({
    defaultValues: couponDetails
      ? couponDetails
      : {
          couponName: '',
          couponRule: { minPriceToUseCoupon: -1 },
          percentToDiscount: 0,
          avilableCoupons: 0,
          expirationDate: moment.utc().format(),
        },
  });

  const [date, setDate] = useState<Date>(
    couponDetails?.expirationDate
      ? moment.utc(couponDetails.expirationDate).toDate()
      : moment.utc().toDate(),
  );

  const updateOrCreateCoupon = (value: CouponDetails) => {
    const newCouponRule = couponDetails?.couponRule;
    if (newCouponRule) {
      newCouponRule.minPriceToUseCoupon = value?.couponRule?.minPriceToUseCoupon ?? 0;
    }

    couponDetails = {
      couponName: value.couponName,
      couponRule: newCouponRule!,
      percentToDiscount: value.percentToDiscount,
      avilableCoupons: value.avilableCoupons,
      expirationDate: getEndOfDayDate(value.expirationDate),
    };

    callApiToCreateOrUpdate();
  };

  const callApiToCreateOrUpdate = () => {
    if (actionType === 'EDIT') {
      dispatch(updateCoupon(couponDetails!))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        })
        .catch((error: { response: { data: { [x: string]: any[]; message?: any } } }) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            animateScroll.scrollToTop();
          } else {
            Object.keys(error?.response?.data).forEach((field: any) => {
              setError(field, {
                type: 'manual',
                message: error?.response?.data[field][0],
              });
            });
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    } else {
      dispatch(createCoupon(couponDetails!))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
          history.push(ROUTES.COUPONS);
        })
        .catch((error: { response: { data: { [x: string]: any[]; message?: any } } }) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            animateScroll.scrollToTop();
          } else {
            Object.keys(error?.response?.data).forEach((field: any) => {
              setError(field, {
                type: 'manual',
                message: error?.response?.data[field][0],
              });
            });
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    }
  };

  const onChangeDate = (value: Date) => {
    setDate(moment.utc(value).toDate());
  };

  return (
    <form onSubmit={handleSubmit(updateOrCreateCoupon)}>
      <Card
        title={<h1 className='text-lg font-semibold text-heading'>{t('input-label-coupons')}</h1>}
      >
        <div className='w-full md:w-2/3'>
          <Input
            label={t('coupon-name')}
            {...register('couponName')}
            type='string'
            variant='outline'
          />
          <Input
            label={t('input-label-sales-money-to-discount-text')}
            {...register('percentToDiscount')}
            type='number'
            variant='outline'
          />
          <Input
            label={t('coupon-availability')}
            {...register('avilableCoupons')}
            type='number'
            variant='outline'
          />
          <Input
            label={t('input-label-sales-min-price-to-get-sale-text')}
            type='number'
            variant='outline'
            {...register('couponRule.minPriceToUseCoupon')}
            className='mb-5 '
          />
          <div className='mb-5'>
            <Label className='mt-3'>{t('input-label-sales-expiration-date-text')}</Label>
            {/* @ts-ignore */}
            <DatePicker
              dateFormat='dd/MM/yyyy'
              onChange={(date: Date) => onChangeDate(date)}
              selected={date}
              minDate={moment.utc().toDate()}
              className='border border-border-base'
            />
          </div>
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {t('form-save')}
        </Button>
        <Button variant='outline' onClick={() => history.push(ROUTES.COUPONS)}>
          {t('form-return')}
        </Button>
      </Card>
    </form>
  );
}
