import {
  CardDetails,
  CreateShopInput,
  CreateShopResponse,
  MyShopsResponse,
  PlanDetails,
  PlansDetailsResponse,
  ProductFormValues,
  ShopDetailsType,
} from 'src/ts-types/custom.types';
import { useEffect, useState } from 'react';
import ChoseBusiness from 'src/components/createShop/choseBusiness';
import BusinessDetails from 'src/components/createShop/businessDetails';
import { useTranslation } from 'react-i18next';
import ChoseSiteTemplate from 'src/components/createShop/choseSiteTemplate';
import { useHistory, useParams } from 'react-router-dom';
import get from 'lodash/get';
import { ROUTES } from 'src/utils/routes';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
// @ts-ignore
import { FormProvider, useForm } from 'react-hook-form';
// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';
import {
  anonymousCreateShopValidation,
  upgradeShopValidation,
} from './anonymousCreateShopValidation';
import PlansForm from 'src/components/createShop/plansForm';
import Card from 'src/components/common/card';
import CreditCard from 'src/components/creditCard/creditCard';
import { formatPrice } from 'src/utils/use-priceNew';
import { getAuthCredentials, isAuthenticated } from 'src/utils/authUtils';
import { UpdateContactUs, createProduct, createShop } from 'src/store/shop/shopsAPIActions';
import { CREATE_SHOP_FLOW, getShopDetails, setShopId } from 'src/utils/shopUtils';
// @ts-ignore
import { toast } from 'react-toastify';
import { setShopDataForCreate } from 'src/store/utilData/actionTypes';
import { fetchPlans } from 'src/store/utilData/actions';
// @ts-ignore
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @ts-ignore
import { IconProp } from '@fortawesome/fontawesome-svg-core';
// @ts-ignore
import { faClose, faShield } from '@fortawesome/free-solid-svg-icons';
import Loader from 'src/components/ui/loader/loader';
import Logo from 'src/components/ui/logo';
import Button from 'src/components/ui/button';
import Title from 'src/components/ui/title';
import RegistrationForm from 'src/components/auth/registrationForm';
import { siteSettings } from 'src/settings/site.settings';
import { RootState } from 'src/store';
import { createCategory } from 'src/store/shop/actions';
import { exampleCategory, productData } from './exampleData/data';
import { ActionTypeEnum, CategoryTypeEnum, logEvent } from 'src/ga';
import { extractMonthAndYearFormCreditCardField, getLocale } from 'src/common';

type Props = {
  pickMyPlan: boolean;
};
export default function AnonymousCreateShop({ pickMyPlan = false }: Props) {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const upgradeShop = useAppSelector((state: RootState) => state.util.UtilData?.upgradeShop);
  const recurrencePayment = useAppSelector(
    (state: RootState) => state.util?.UtilData?.recurrencePayment,
  );
  const [currentStep, setCurrentStep] = useState<number>(upgradeShop ? 3 : 0);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isFirstRenderCredit, setIsFirstRenderCredit] = useState<boolean>(true);
  const [firstStepIsDisabled, setFirstStepIsDisabled] = useState(true);
  const [secondStepIsDisabled, setSecondStepIsDisabled] = useState(true);
  const [thirdStepIsDisabled, setThirdStepIsDisabled] = useState(true);
  const [newUserLoading, setNewUserLoading] = useState(true);
  const [domainDirty, setDomainDirty] = useState(true);
  // const { id: packageId }: any = useParams();

  const { email: userEmail } = getAuthCredentials();
  const shopDetails = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse?.data,
  ) as ShopDetailsType;
  const isAuthenticatedUser = isAuthenticated();
  const newShopData = useAppSelector((state: RootState) => state.util.UtilData?.shopForCreateData);
  const plansData = useAppSelector((state: RootState) => state.util.UtilData);
  const createShopResponse = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.createShopResponse,
  );
  const myShopsResponse = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.myShopsResponse,
  );
  const { data: myShops }: MyShopsResponse = myShopsResponse || {
    data: [],
    isFetching: false,
  };
  const validateDomainResponse = useAppSelector(
    (state: RootState) => state.util.UtilData?.validateDomainResponse,
  );

  const plans: PlansDetailsResponse = get(plansData, 'planDetailsResponse', {
    data: [],
    isFetching: false,
  });

  const methods = useForm<ProductFormValues>({
    resolver: yupResolver(upgradeShop ? upgradeShopValidation : anonymousCreateShopValidation),
    mode: 'onBlur',
    // @ts-ignore
    defaultValues: {},
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors, isValid },
  } = methods;

  const { isNewShop } = newShopData ?? { isNewShop: false };
  useEffect(() => {
    dispatch(fetchPlans());
    dispatch(setShopDataForCreate(undefined));
    setValue('companyDetails.companyType', 'Company');
    setValue('toValidateCardDetails', false);
    // setValue('packageId', packageId ?? '');
    // dispatch(setShopDataForCreate({ ...newShopData, packageId }));
  }, []);

  useEffect(() => {
    if (newShopData?.isNewShop) {
      setCurrentStep(currentStep);
      setTimeout(() => {
        setNewUserLoading(false);
      }, 2000);
    }
  }, [newShopData]);

  useEffect(() => {
    setDomainDirty(true);
  }, [getValues('website')]);

  useEffect(() => {
    if (currentStep === 2) {
      setTimeout(() => {
        setIsFirstRender(false);
      }, 2000);
      if (!validateDomainResponse?.isFetching) {
        setDomainDirty(false);
      }
    }
    if (currentStep === 4) {
      setTimeout(() => {
        setIsFirstRenderCredit(false);
      }, 2000);
    }
  }, [currentStep, validateDomainResponse?.validDomain]);

  const selectedPackage = plans?.data?.find(
    (item: PlanDetails) => item.packageId === getValues('packageId'),
  );

  const getStepAvailability = () => {
    switch (currentStep) {
      case 0:
        return firstStepIsDisabled;
      case 1:
        return secondStepIsDisabled;
      case 2:
        return (
          domainDirty ||
          validateDomainResponse?.isFetching ||
          !validateDomainResponse?.validDomain ||
          !isValid
        );
      case 3:
        return thirdStepIsDisabled;
      case 4:
        return isValid;
    }
  };

  const onSubmit = (values: CreateShopInput) => {
    if (currentStep === 4) {
      if (upgradeShop) {
        const data: CreateShopInput = {};
        const { shopId } = getShopDetails();
        data.shopId = shopId;
        data.packageId = selectedPackage?.packageId;
        data.customerDetails = shopDetails.customerDetails;
        const cardDetails: CardDetails = {
          creditCardNumber: values?.cardDetails?.creditCardNumber ?? '',
          creditCardCVV: values?.cardDetails?.creditCardCVV ?? '',
          creditCardCitizenID: values?.cardDetails?.creditCardCitizenID,
        };
        const { month, year } = extractMonthAndYearFormCreditCardField(
          values?.cardDetails?.creditCardExpirationMonthYear ?? '',
        );
        cardDetails.creditCardExpirationMonth = month;
        cardDetails.creditCardExpirationYear = year;
        data.cardDetails = cardDetails;
        if (data.customerDetails) {
          data.customerDetails.customerName = data.companyDetails?.companyName ?? '';
        }
        dispatch(createShop({ ...data }))
          .then((response: CreateShopResponse) => {
            const { shopId } = response.data;
            toast.success(t('package_pay_success'));
            setShopId(shopId);
            history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`);
          })
          .catch((e: any) => {
            const { shopId } = getShopDetails();
            toast.error(t('package_pay_failed'));
            history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`);
          });
      } else {
        const data: CreateShopInput = values;
        data.packageId = selectedPackage?.packageId;
        const cardDetails: CardDetails = {
          creditCardNumber: values?.cardDetails?.creditCardNumber || '',
          creditCardCVV: values?.cardDetails?.creditCardCVV || '',
          creditCardCitizenID: values?.cardDetails?.creditCardCitizenID,
        };
        const { month, year } = extractMonthAndYearFormCreditCardField(
          values?.cardDetails?.creditCardExpirationMonthYear || '',
        );
        cardDetails.creditCardExpirationMonth = month;
        cardDetails.creditCardExpirationYear = year;
        data.cardDetails = cardDetails;
        data.customerDetails = values.customerDetails;
        data.website = `${values.website}.${process.env.REACT_APP_MAIN_DOMAIN}`;
        data.recurrence = recurrencePayment;
        if (isAuthenticatedUser) {
          dispatch(createShop(data))
            .then(async (response: CreateShopResponse) => {
              const { shopId } = response.data;
              toast.success(t('shop_created_successfully'));
              setShopId(shopId);
              history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`);

              dispatch(createCategory(exampleCategory)).then(async (res: any) => {
                await dispatch(createProduct(productData));
              });
              dispatch(UpdateContactUs({}, true));
            })
            .catch((e: any) => {
              const { shopId } = getShopDetails();
              toast.error(t('shop_created_failed'));
              history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`);
            });
        } else {
          dispatch(setShopDataForCreate(data));
          history.push(ROUTES.REGISTER);
        }
      }
    }
  };

  const handleSubmitWithoutPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)(e);
  };

  function handleCreteShopWithoutPackage() {
    const values: CreateShopInput = getValues();
    if (isAuthenticatedUser) {
      const data: CreateShopInput = values;
      data.customerDetails = values.customerDetails;
      data.website = `${values.website}.${process.env.REACT_APP_MAIN_DOMAIN}`;
      delete values.cardDetails;
      delete values.packageId;
      dispatch(
        createShop({
          ...values,
          website: values.website,
        }),
      )
        .then(() => {
          const { shopId } = getShopDetails();
          history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`);
          dispatch(createCategory(exampleCategory)).then(async (res: any) => {
            await dispatch(createProduct(productData));
          });
          toast.success(t('shop_created_successfully'));
        })
        .catch((e: any) => {
          toast.error(t('shop_created_failed'));
        });
    } else {
      dispatch(setShopDataForCreate(values));
      history.push(ROUTES.REGISTER);
    }
  }

  const handleSetBusinessTypeValue = (val: string) => {
    setValue('businessType', val);
    dispatch(setShopDataForCreate({ ...newShopData, businessType: val }));
    setFirstStepIsDisabled(false);
    logEvent(CategoryTypeEnum.CreateShop, 'select', `businessType = ${val}`);
  };

  const handleSetSiteTemplateValue = (val: string) => {
    setValue('layoutType', val);
    dispatch(setShopDataForCreate({ ...newShopData, layoutType: val }));
    setSecondStepIsDisabled(false);
  };

  const handleSetPackageValue = (val: string) => {
    setValue('packageId', val);
    dispatch(setShopDataForCreate({ ...newShopData, packageId: val }));
    setThirdStepIsDisabled(false);
  };

  const renderButtons = () => {
    const renderButtons = (
      <div className='flex md:pl-10 justify-center smx:mt-6 md:mt-10'>
        <div className='flex'>
          <Button
            className={'ml-2'}
            size='medium'
            theme='rounded'
            disabled={getStepAvailability()}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            {t('next-button')}
          </Button>
          <div>
            <Button
              size='medium'
              variant='outline'
              theme='rounded'
              type='button'
              disabled={currentStep === 0 || (upgradeShop && currentStep === 3)}
              onClick={(e) => {
                e.preventDefault(), setCurrentStep(currentStep - 1);
              }}
            >
              {t('previous-button')}
            </Button>
          </div>
        </div>
      </div>
    );

    if (currentStep !== 4) {
      if (currentStep === 2) {
        if (isAuthenticatedUser) {
          return renderButtons;
        }
      } else {
        return renderButtons;
      }
    }
  };
  return (
    <div
      className={
        'flex flex-col text-accent animate-fade-in-down text-xl overflow-hidden min-h-[500px]'
      }
    >
      <header className='py-5 sm:py-2 text-center'>
        <div className='grid grid-cols-3 gap-4 content-end  '>
          <div>
            <FontAwesomeIcon
              className='cursor-pointer mt-2'
              style={{ height: '38px' }}
              onClick={() => {
                dispatch(setShopDataForCreate(undefined)), history.push(ROUTES.SHOPS);
              }}
              icon={faClose as IconProp}
              size={'1x'}
            />
          </div>
          <div className='flex justify-center mb-2'>
            <Logo />
          </div>
          {/* <div onClick={() => goPreviousStep()} className='cursor-pointer'>
            <FontAwesomeIcon icon={faChevronLeft as IconProp} size={'2x'} />
          </div> */}
        </div>
      </header>
      <main className='max-w-screen-2xl mx-auto'>
        {createShopResponse?.isFetching || (newUserLoading && isNewShop) ? (
          <>
            {createShopResponse?.isFetching ? (
              <Loader text={t('text-create-new-shop')} showText />
            ) : (
              <Loader text={t('text-loading')} />
            )}
          </>
        ) : (
          <div className='flex text-center items-center justify-center'>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmitWithoutPropagation}
                id='form-create-shop'
                name='form-create-shop'
                className='sm:p-2 md:p-2'
              >
                {currentStep === 0 && (
                  <div className='animate-fade-in-down'>
                    <Title className='md:pb-8 sm:mb-3'>{t('chose-your-business-type')}</Title>
                    <ChoseBusiness
                      defaultValue={newShopData?.businessType}
                      show={0 === currentStep}
                      updateBusinessType={(val: string) => handleSetBusinessTypeValue(val)}
                    />
                  </div>
                )}
                {currentStep === 1 && (
                  <div className='animate-fade-in-down'>
                    <Title className='md:pb-8 sm:mb-3'>{t('chose-your-site-layout')}</Title>
                    <ChoseSiteTemplate
                      defaultValue={newShopData?.layoutType}
                      show={1 === currentStep}
                      updateSiteTemplateType={(val: string) => handleSetSiteTemplateValue(val)}
                    />
                  </div>
                )}
                {currentStep === 2 && (
                  <>
                    {isAuthenticatedUser ? (
                      <div className={`${isFirstRender ? 'animate-fade-in-down' : ''} `}>
                        <div className='mt-5 md:p-8 sm:p-2 bg-white md:flex justify-center items-center'>
                          <BusinessDetails
                            defaultValue={newShopData?.companyDetails}
                            domainDefaultValue={newShopData?.website}
                            register={register}
                            watch={watch}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                            userEmail={userEmail ?? ''}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={`${isFirstRender ? 'animate-fade-in-down' : ''} `}>
                        <div className='p-4 bg-white flex justify-center items-center'>
                          <RegistrationForm callbackUrl={ROUTES.ANONY_CREATE_SHOP} />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {currentStep === 3 && (
                  <div className='animate-fade-in-down'>
                    <PlansForm
                      show={3 === currentStep}
                      onClickTryForFree={() => handleCreteShopWithoutPackage()}
                      errors={errors}
                      showFreePackage={!upgradeShop}
                      setValue={(val: any) => handleSetPackageValue(val)}
                    />
                  </div>
                )}
                {currentStep === 4 && (
                  <div
                    className={`${
                      isFirstRenderCredit ? 'animate-fade-in-down text-accent' : 'text-accent'
                    }`}
                  >
                    <div
                      role='listitem'
                      className='bg-white cursor-pointer shadow rounded-lg mt-3 flex relative z-30'
                    >
                      <div className='w-2.5 h-auto rounded-tl-md rounded-bl-md bg-accent'></div>
                      <div className='w-full p-8'>
                        <div className='md:flex items-center justify-between'>
                          <h2 className='text-2xl font-semibold leading-6'>
                            {selectedPackage?.packageTitle}
                          </h2>
                          <p className='text-2xl md:mt-0 mt-4 font-semibold leading-6'>
                            {formatPrice({
                              // @ts-ignore
                              price: recurrencePayment
                                ? selectedPackage?.price
                                : selectedPackage?.priceForOnePayment,
                              currencyCode: 'ILS',
                              displaySign: true,
                              locale: getLocale(),
                              signToDisplay: recurrencePayment
                                ? t('input-expiration-month')
                                : t('year'),
                            })}
                          </p>
                        </div>
                        {/* <p className='md:w-80 text-base leading-6 mt-4 '>
                          {selectedPackage?.packageDescription}
                        </p> */}
                      </div>
                    </div>
                    <Card
                      className='w-full mt-5 p-8 shadow-lg bg-white'
                      title={t('payments-details-title')}
                      icon={<FontAwesomeIcon icon={faShield as IconProp} size={'1x'} />}
                    >
                      <CreditCard
                        formName='form-create-shop'
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        onBackClick={() => setCurrentStep(3)}
                      />
                    </Card>
                  </div>
                )}
                {renderButtons()}
                {/* )} */}
                {/* {isNewShop && siteSettings.crateShopFlow.type === CREATE_SHOP_FLOW.SHORT && (
                  <div className='flex md:pl-10 justify-center mt-4'>
                    <div className='flex'>
                      <Button
                        className={'ml-2'}
                        size='medium'
                        theme='rounded'
                        type='submit'
                        disabled={getStepAvailability()}
                      >
                        {t('text-create-shop')}
                      </Button>
                    </div>
                  </div>
                )} */}
              </form>
            </FormProvider>
          </div>
        )}
      </main>
    </div>
  );
}
