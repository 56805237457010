import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MySitesView from 'src/components/shop/user/mySitesView';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { isSuperAdmin } from 'src/utils/authUtils';
import AllShopPage from '../createShop';
import { getAllMyShops } from 'src/store/shop/shopsAPIActions';
import { RootState } from 'src/store';
/**
 *
 * @returns entry page.
 */
export default function HomePage() {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: RootState) => state.session.sessionDetails);

  const superUser = isSuperAdmin();

  useEffect(() => {
    dispatch(getAllMyShops());
  }, []);

  if (superUser) {
    return <AllShopPage />;
  }
  return <MySitesView />;
}
