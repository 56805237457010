/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/ui/table';
import { PlanDetails } from 'src/ts-types/custom.types';
import { useAppDispatch } from 'src/utils/reduxHooks';
import Badge from '../ui/badge/badge';
import { formatPrice } from 'src/utils/use-priceNew';
import { useModalAction } from '../ui/modal/modal.context';
import { GetPackageById } from 'src/store/utilData/actions';
import { getLocale } from 'src/common';

export type IProps = {
  plans?: PlanDetails[];
};

const PackageList = ({ plans }: IProps) => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const { openModal } = useModalAction();

  const getPackageDetailsModalTitle = () => {
    return <span className='text-lg font-bold text-accent'>{t('package-details-title')}</span>;
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onRowClick = (record: any) => {
    dispatch(GetPackageById(record.packageId)).then((res: any) => {
      openModal(
        'PACKAGE_DETAILS_MODAL',
        { ...record, packageId: record.packageId },
        getPackageDetailsModalTitle(),
      );
    });
  };

  const columns = [
    {
      title: t('table-item-package-title'),
      dataIndex: 'packageTitle',
      key: 'packageTitle',
      align: 'center',
      width: 74,
    },
    {
      title: t('table-item-package-price'),
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      width: 74,
      render: (price: string) => (
        <div className='bg-color-red'>
          <>
            {formatPrice({
              // @ts-ignore
              price: price,
              displaySign: true,
              currencyCode: 'ILS',
              locale: getLocale(),
            })}
          </>
        </div>
      ),
    },
    {
      title: t('table-item-package-duration'),
      dataIndex: 'durationMonths',
      key: 'durationMonths',
      align: 'center',
      width: 74,
    },
    {
      title: t('table-item-package-maxNumberOfProducts'),
      dataIndex: 'maxNumberOfProducts',
      key: 'maxNumberOfProducts',
      align: 'center',
      width: 74,
    },
    {
      title: t('table-item-package-status'),
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 100,
      render: (active: string) => (
        <Badge
          text={active ? t('text-active') : t('text-inactive')}
          color={active?.toString().toLocaleLowerCase() === 'false' ? 'bg-yellow-400' : 'bg-accent'}
        />
      ),
    },
  ];
  return (
    <>
      <div className='rounded overflow-hidden shadow mb-6'>
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t('empty-table-data')}
          data={plans}
          rowKey='orderId'
          scroll={{ x: 900 }}
          className='cursor_table'
          onRow={(record: any, index: any) => ({
            onClick: onRowClick.bind(null, record),
            style: {
              cursor: 'pointer',
            },
          })}
        />
      </div>
    </>
  );
};

export default PackageList;
