/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const ContactUsIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg
    fill='#000000'
    width='20px'
    height='20px'
    className='w-6 h-6 ml-4'
    viewBox='-4 -4 28 28'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='contact-us'>
      <g>
        <path d='M4,24v-5H0V0h23v19h-9.3L4,24z M2,17h4v3.7l7.3-3.7H21V2H2V17z' />
      </g>
      <g>
        <rect x='5' y='8' width='3' height='3' />
      </g>
      <g>
        <rect x='10' y='8' width='3' height='3' />
      </g>
      <g>
        <rect x='15' y='8' width='3' height='3' />
      </g>
    </g>
  </svg>
);
