import { useTranslation } from 'react-i18next';
import {
  faPersonDress,
  faBook,
  faBaby,
  faGem,
  faDog,
  faPhone,
  faVoteYea,
  faBorderNone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BusinessTypeEnum } from 'src/ts-types';
import { BusinessType } from 'src/ts-types/custom.types';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export type BusinessArray = {
  name: BusinessType;
  icon: any;
};

export type Props = {
  show: boolean;
  defaultValue?: string;
  updateBusinessType: (val: BusinessType) => void;
};

const shopTypes: BusinessArray[] = [
  {
    name: BusinessTypeEnum.Accessories,
    icon: faGem,
  },
  {
    name: BusinessTypeEnum.ArtsBooks,
    icon: faBook,
  },
  {
    name: BusinessTypeEnum.Electronics,
    icon: faPhone,
  },
  {
    name: BusinessTypeEnum.Fashion,
    icon: faPersonDress,
  },
  {
    name: BusinessTypeEnum.Food,
    icon: faVoteYea,
  },
  {
    name: BusinessTypeEnum.KidsBabies,
    icon: faBaby,
  },
  {
    name: BusinessTypeEnum.PetsSndAnimals,
    icon: faDog,
  },
  {
    name: BusinessTypeEnum.Other,
    icon: faBorderNone,
  },
];

const ChoseBusiness = ({ show, updateBusinessType, defaultValue }: Props) => {
  const { t }: any = useTranslation<any>();
  const [type, setType] = useState<string>(defaultValue ?? '');

  if (!show) return null;

  const handleBusinessSelect = (name: BusinessType) => {
    updateBusinessType(name);
    setType(name);
  };

  return (
    <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-4 content-evenly animate-in slide-in-from-top '>
      {shopTypes.map((item: BusinessArray, index: number) => (
        <div
          key={`${item.name}_${index}`}
          className={`flex block p-6 rounded-lg shadow-lg bg-white md:w-3/4 place-items-center cursor-pointer
          border-2 ${
            type === item.name ? 'border-accent ' : 'border-border-100'
          } hover:border-accent sm:h-[120px] sm:w-[134px] md:h-[160px] `}
          onClick={() => handleBusinessSelect(item.name)}
        >
          <div className='m-auto self-center'>
            <div className='flex flex-col justify-center items-center pt-2'>
              <h5 className='text-gray-900 md:text-xl sm:text-[16px] leading-tight md:font-medium sm:font-small mb-2'>
                {t(item.name)}
              </h5>
            </div>
            <div className='flex flex-col justify-center items-center pt-2'>
              <FontAwesomeIcon
                className='self-center smx:h-[22px] smx:w-[20px] md:h-[30px] md:w-[30px]'
                icon={item.icon}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChoseBusiness;
