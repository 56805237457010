import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PlugInType } from 'src/ts-types/custom.types';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
// @ts-ignore
import { useForm } from 'react-hook-form';
import Card from 'src/components/common/card';
import { getIcon } from 'src/utils/get-icon';
import { createNewPlugin, updateShopPlugin } from 'src/store/shop/actions';
// @ts-ignore
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import { ROUTES } from 'src/utils/routes';
import Description from '../ui/description';
import Button from '../ui/button';
import * as categoriesIcon from 'src/components/icons/category';
import InputWithTranslation from '../ui/inputWithTranslation';
import { categoryIcons } from '../category/category-icons';
// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';
import { pluginDetailsValidator } from './pluginDetailsValidator';
import Input from '../ui/input';
import TextAreaWithTranslation from '../ui/text-areaWithTranslation';
import { RootState } from 'src/store';
import { TOAST_MESSAGE } from 'src/utils/constants';

// for now add these icons
export const updatedIcons = categoryIcons.map((item: any) => {
  item.label = (
    <div className='flex space-s-5 items-center'>
      <span className='flex w-5 h-5 items-center justify-center'>
        {getIcon({
          iconList: categoriesIcon,
          iconName: item.value,
          className: 'max-h-full max-w-full',
        })}
      </span>
      <span>{item.label}</span>
    </div>
  );
  return item;
});

export default function CreateOrEditPlugin() {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  let pluginDetails = useAppSelector((state: RootState) => state.shop.shopDetails?.activePlugin);
  const actionType = useAppSelector((state: RootState) => state.shop.shopDetails?.actionType);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { isValid },
  } = useForm<PlugInType>({
    defaultValues: pluginDetails
      ? pluginDetails
      : {
          active: true,
          icon: '',
          plugInDescriptionWithTranslations: [],
          plugInTitleWithTranslations: [],
          plugInId: '',
        },
    resolver: yupResolver(pluginDetailsValidator),
  });

  const updateOrCreatePlugin = (value: PlugInType) => {
    pluginDetails = {
      plugInDescriptionWithTranslations: getValues('plugInDescriptionWithTranslations'),
      plugInTitleWithTranslations: getValues('plugInTitleWithTranslations'),
      plugInId: value.plugInId,
      icon: value?.icon,
      active: value.active,
    };
    callApiToCreateOrUpdate();
  };

  useEffect(() => {
    console.log(pluginDetails);
  }, []);

  const callApiToCreateOrUpdate = () => {
    if (actionType === 'EDIT') {
      dispatch(updateShopPlugin(pluginDetails!))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        })
        .catch((error: { response: { data: { [x: string]: any[]; message?: any } } }) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            animateScroll.scrollToTop();
          } else {
            Object.keys(error?.response?.data).forEach((field: any) => {
              setError(field, {
                type: 'manual',
                message: error?.response?.data[field][0],
              });
            });
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    } else {
      dispatch(createNewPlugin(pluginDetails!))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
          history.push(ROUTES.PLUGINS);
        })
        .catch((error: { response: { data: { [x: string]: any[]; message?: any } } }) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            animateScroll.scrollToTop();
          } else {
            Object.keys(error?.response?.data).forEach((field: any) => {
              setError(field, {
                type: 'manual',
                message: error?.response?.data[field][0],
              });
            });
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    }
  };

  return (
    <>
      <Card className=''>
        <form onSubmit={handleSubmit(updateOrCreatePlugin)} noValidate>
          <div className='flex flex-wrap my-5 sm:my-8'>
            <Description
              title={t('input-title')}
              details={t('plugin-title-helper-text')}
              className='w-full px-0 sm:pe-4 md:pe-5 pb-5 sm:w-4/12 md:w-1/3 sm:py-8 '
            />

            <Input
              required
              label={t('plugin-id-helper-text')}
              {...register('plugInId')}
              variant='outline'
              placeholder={t('plugin-id-helper-text')}
              name='plugInId'
            />
          </div>
          <div className='flex flex-wrap my-5 sm:my-8'>
            <Description
              title={t('input-title')}
              details={t('plugin-title-helper-text')}
              className='w-full px-0 sm:pe-4 md:pe-5 pb-5 sm:w-4/12 md:w-1/3 sm:py-8 '
            />

            <InputWithTranslation
              required
              label={t('plugin-title-helper-text')}
              values={getValues('plugInTitleWithTranslations')}
              setValue={(val: any) => setValue('plugInTitleWithTranslations', val)}
              variant='outline'
              placeholder={t('table-item-package-title')}
              name='plugInTitleWithTranslations'
            />
          </div>

          <div className='flex flex-wrap my-5 sm:my-8'>
            <Description
              title={t('input-label-description')}
              details={t('plugin-description-helper-text')}
              className='w-full px-0 sm:pe-4 md:pe-5 pb-5 sm:w-4/12 md:w-1/3 sm:py-8 '
            />

            <TextAreaWithTranslation
              required
              label={t('input-label-description')}
              values={getValues('plugInDescriptionWithTranslations')}
              setValue={(val: any) => setValue('plugInDescriptionWithTranslations', val)}
              variant='outline'
              name='plugInDescriptionWithTranslations'
            />
          </div>

          <div className='flex flex-wrap pb-8 border-b border-dashed border-border-base my-5 sm:my-8'>
            <Description
              title={t('input-label-image')}
              details={t('category-image-helper-text')}
              className='w-full px-0 sm:pe-4 md:pe-5 pb-5 sm:w-4/12 md:w-1/3 sm:py-8'
            />

            <div className='mb-5 mt-5'>
              <Input
                label={t('input-label-select-icon')}
                {...register('icon')}
                variant='outline'
                placeholder={t('table-item-package-title')}
                name='plugInDescriptionWithTranslations'
              />
            </div>
          </div>

          <Button type='submit' disabled={!isValid} className='ml-5 mt-5'>
            {t('form-save')}
          </Button>
          <Button
            onClick={() => history.push(ROUTES.PLUGINS)}
            className='h-10 mt-5 w-40 text-white m-auto rounded-lg hover:bg-red-600'
          >
            {t('form-return')}
          </Button>
        </form>
      </Card>
    </>
  );
}
