import ErrorMessage from 'src/components/ui/error-message';
import Loader from 'src/components/ui/loader/loader';
import ShopForm from 'src/components/shop/shopForm';
import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import { useEffect } from 'react';
import { setShopId, setShopStatus } from 'src/utils/shopUtils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getActiveShopDetails } from 'src/store/shop/shopsAPIActions';
import { RootState } from 'src/store';

export default function UpdateShopPage() {
  const { id: shop }: any = useParams();
  const { t }: any = useTranslation();
  const shopDetails = useAppSelector((state: RootState) => state.shop.shopDetails);
  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shop && !shopDetails) {
      setShopId(shop.toString());
      setShopStatus(activeShop?.data?.shopCurrentStatus);
      dispatch(getActiveShopDetails());
    }
  }, [shopDetails, shop, dispatch, activeShop?.data?.shopCurrentStatus]);

  if (activeShop?.isFetching) return <Loader text={t('text-loading')} />;
  if (shopDetails?.error) return <ErrorMessage message={shopDetails?.error} />;

  return (
    <ShopForm initialValues={shopDetails?.activeShopDetailsResponse?.data} createPage={false} />
  );
}
