import cn from 'classnames';
import { LabelHTMLAttributes } from 'react';

export interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
}

const Title = ({ className = 'mb-3', ...rest }: Props) => {
  return (
    <span
      className={cn('block text-body-dark font-bold text-md leading-none', className)}
      {...rest}
    />
  );
};

export default Title;
