import { useTranslation } from 'react-i18next';
import UnControlledSelectInput from 'src/components/ui/unControlled-select-input';
import { productTypeArray } from 'src/ts-types';

type Props = {
  className?: string;
  disable?: boolean;
  required?: boolean;
  defaultValue?: string;
  error?: string;
  onChange?: (e: any) => void;
};
const ProductTypeInput = ({
  onChange,
  className,
  disable,
  required = false,
  defaultValue,
  error,
}: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <div className={`${className} pt-4`}>
      <UnControlledSelectInput
        name='id'
        disable={disable}
        label={t('form-title-product-type')}
        required={required}
        defaultValue={defaultValue}
        errorStyle={error ? 'border-red-500 focus:border-red-500' : ''} // Pass errorStyle if error exists
        handleOnChange={(e) => onChange?.(e.value)}
        getOptionLabel={(option: any) => t(option.name)}
        getOptionValue={(option: any) => option.id}
        options={productTypeArray}
        value={productTypeArray.find((item) => defaultValue === item.value)?.id}
      />
      {error && <span className='my-2 text-xs text-end text-red-500'>{error}</span>}
    </div>
  );
};

export default ProductTypeInput;
