import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
// import { useAppDispatch } from 'src/utils/reduxHooks';
import Button from 'src/components/ui/button';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useState } from 'react';
// import { validateOTP } from 'src/store/session/actions';

const OtpModal = () => {
  const { t }: any = useTranslation<any>();
  const [otp, setOtp] = useState('');
  const { data } = useModalState();
  const { onSubmitNewUser, name, email, password } = data;
  const { closeModal } = useModalAction();
  // const dispatch = useAppDispatch();

  const handleChange = (val: any) => {
    setOtp(val.target.value);
  };

  const handleSubmit = async () => {
    // await dispatch(validateOTP(otp, '')).then((res: any) => {
    //   if(res.data.valid){
    //     setOtpCode(otp);
    //   }else {
    //     setOtpCode(null)
    //   }
    // }).catch(() => {
    //   setOtpCode(null)
    // });
    // closeModal();
    // onSubmitNewUser();
    closeModal();
    onSubmitNewUser(name, email, password, otp);
  };

  return (
    <div className='w-2/3 p-4 pb-6 bg-light m-auto rounded-md md:rounded-xl sm:w-[24rem]'>
      <div className='w-full h-full text-center'>
        <div className='flex h-full flex-col justify-between'>
          <p className='text-heading text-xl font-bold mt-4'>{t('enter_otp')}</p>
          <p className='text-body-dark dark:text-muted leading-relaxed py-2 px-6'>
            <input
              type='text'
              className='border border-gray-300 p-2 rounded w-full'
              onChange={handleChange}
              autoFocus
            />
            <div className='mt-4 flex justify-end space-x-2'>
              <div className='w-1/2'>
                <Button
                  variant='custom'
                  onClick={handleSubmit}
                  className={cn(
                    'w-full py-2 px-4 bg-accent focus:outline-none hover:bg-accent-hover focus:bg-accent-hover text-light transition ease-in duration-200 text-center text-base font-semibold rounded shadow-md',
                  )}
                >
                  {t('button-label-submit')}
                </Button>
              </div>
              <div className='w-1/2'>
                <Button
                  onClick={closeModal}
                  variant='custom'
                  className={cn(
                    'w-full py-2 px-4 bg-red-600 focus:outline-none hover:bg-red-700 focus:bg-red-700 text-light transition ease-in duration-200 text-center text-base font-semibold rounded shadow-md',
                  )}
                >
                  {t('button-cancel')}
                </Button>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
