/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const Snacks: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.143'
      height='22'
      viewBox='0 0 25.143 22'
      fill='currentColor'
      {...props}
    >
      <g id='Snacks' transform='translate(0 -32.001)'>
        <g id='Group_5714' data-name='Group 5714' transform='translate(3.142 40.381)'>
          <g id='Group_5713' data-name='Group 5713' transform='translate(0 0)'>
            <path
              id='Path_17398'
              data-name='Path 17398'
              d='M80.227,202.654H65.559a1.573,1.573,0,0,0-1.571,1.571v1.048a9.928,9.928,0,0,0,5.388,8.847.524.524,0,1,0,.481-.931,8.883,8.883,0,0,1-4.821-7.916v-1.048a.524.524,0,0,1,.524-.524H80.227a.524.524,0,0,1,.524.524v1.048a8.879,8.879,0,0,1-4.822,7.915.524.524,0,0,0,.241.99.517.517,0,0,0,.24-.059,9.923,9.923,0,0,0,5.389-8.846v-1.048A1.573,1.573,0,0,0,80.227,202.654Z'
              transform='translate(-63.988 -202.654)'
              fill='currentColor'
            />
          </g>
        </g>
        <g id='Group_5716' data-name='Group 5716' transform='translate(0 50.857)'>
          <g id='Group_5715' data-name='Group 5715' transform='translate(0 0)'>
            <path
              id='Path_17399'
              data-name='Path 17399'
              d='M24.056,416.29a.525.525,0,0,0-.484-.324H.524a.524.524,0,0,0-.371.895l1.174,1.175a3.645,3.645,0,0,0,2.593,1.074H20.174a3.651,3.651,0,0,0,2.6-1.074l1.174-1.175A.523.523,0,0,0,24.056,416.29ZM22.027,417.3a2.6,2.6,0,0,1-1.852.767H3.921a2.6,2.6,0,0,1-1.852-.767l-.281-.281h20.52Z'
              transform='translate(0 -415.966)'
              fill='currentColor'
            />
          </g>
        </g>
        <g id='Group_5718' data-name='Group 5718' transform='translate(17.438 42.474)'>
          <g id='Group_5717' data-name='Group 5717'>
            <path
              id='Path_17400'
              data-name='Path 17400'
              d='M361.785,245.653c-1.411-.907-3.666.078-3.918.192a.524.524,0,0,0,.435.954c.505-.229,2.125-.773,2.917-.263a1.488,1.488,0,0,1,.532,1.358c0,2.1-4.23,3.365-5.864,3.677l-.371.073a.523.523,0,0,0,.1,1.037.486.486,0,0,0,.1-.01l.368-.072c.274-.052,6.712-1.315,6.712-4.705A2.471,2.471,0,0,0,361.785,245.653Z'
              transform='translate(-355.093 -245.272)'
              fill='currentColor'
            />
          </g>
        </g>
        <g id='Group_5720' data-name='Group 5720' transform='translate(14.667 32.001)'>
          <g id='Group_5719' data-name='Group 5719' transform='translate(0 0)'>
            <path
              id='Path_17401'
              data-name='Path 17401'
              d='M300.125,35.994a3.139,3.139,0,0,0,0-3.8.524.524,0,1,0-.818.655,2.113,2.113,0,0,1,0,2.488,3.135,3.135,0,0,0,0,3.8.524.524,0,1,0,.818-.655A2.11,2.11,0,0,1,300.125,35.994Z'
              transform='translate(-298.666 -32.001)'
              fill='currentColor'
            />
          </g>
        </g>
        <g id='Group_5722' data-name='Group 5722' transform='translate(11.52 32.001)'>
          <g id='Group_5721' data-name='Group 5721' transform='translate(0 0)'>
            <path
              id='Path_17402'
              data-name='Path 17402'
              d='M236.055,36a3.135,3.135,0,0,0,0-3.8.524.524,0,1,0-.818.655,2.11,2.11,0,0,1,0,2.488,3.139,3.139,0,0,0,0,3.8.524.524,0,1,0,.818-.655A2.113,2.113,0,0,1,236.055,36Z'
              transform='translate(-234.597 -32.009)'
              fill='currentColor'
            />
          </g>
        </g>
        <g id='Group_5724' data-name='Group 5724' transform='translate(8.381 32.002)'>
          <g id='Group_5723' data-name='Group 5723' transform='translate(0 0)'>
            <path
              id='Path_17403'
              data-name='Path 17403'
              d='M172.125,36.015a3.139,3.139,0,0,0,0-3.8.524.524,0,0,0-.818.654,2.113,2.113,0,0,1,0,2.488,3.135,3.135,0,0,0,0,3.8.524.524,0,1,0,.818-.654A2.11,2.11,0,0,1,172.125,36.015Z'
              transform='translate(-170.666 -32.022)'
              fill='currentColor'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
