import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import Notification from '../ui/notification';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { setUpgradePackage } from 'src/store/utilData/actionTypes';

const UpgradePackageAlert = () => {
  const { t }: any = useTranslation<any>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setUpgradePackage(true));
    history.push(ROUTES.ANONY_CREATE_SHOP);
  };
  return (
    <Notification variant='error'>
      {' '}
      <>
        {t('upgrade-your-plan')}
        <a
          onClick={() => handleClick()}
          className='text-red-600 text-lg
         cursor-pointer underline font-bold py-1.5 mr-1 text-center inline-flex items-center'
        >
          {t('upgrade-button')}
        </a>
      </>
    </Notification>
  );
};

export default UpgradePackageAlert;
