import Logo from 'src/components/ui/logo';
import { useUI } from 'src/contexts/ui.context';
import AuthorizedMenu from './authorized-menu';
import { NavbarIcon } from 'src/components/icons/navbar-icon';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/utils/routes';
import { siteSettings } from 'src/settings/site.settings';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/ui/button';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import NotificationTopBar from 'src/components/ui/notification-top-bar';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { setUpgradePackage } from 'src/store/utilData/actionTypes';
import { isMobile } from 'react-device-detect';
import { getLocale, setLocale } from 'src/common';

// eslint-disable-next-line react/prop-types
const Navbar = ({ showBurgerMenu = true }) => {
  // @ts-ignore
  const { t, i18n }: any = useTranslation<any>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { toggleSidebar } = useUI();
  const handleChangeLocale = (value: string) => {
    const { asPath } = history;
    history.push('/', asPath, { locale: value });
    setLocale(value, i18n);
  };

  const handleCreateShop = () => {
    dispatch(setUpgradePackage(false));
    history.replace(createShopUrl);
  };
  // const createShopUrl = isSuperAdmin() ? ROUTES.CHOSE_PLAN : ROUTES.ANONY_CREATE_SHOP;
  const createShopUrl = ROUTES.ANONY_CREATE_SHOP;
  return (
    <header className='bg-white shadow fixed w-full z-40'>
      <nav className='px-5 md:px-8 py-4 flex items-center justify-between max-h-[68px]'>
        {/* <!-- Mobile menu button --> */}
        {showBurgerMenu && (
          <motion.button
            whileTap={{ scale: 0.88 }}
            onClick={toggleSidebar}
            className='flex p-2 h-full items-center justify-center focus:outline-none focus:text-accent lg:hidden'
          >
            <NavbarIcon />
          </motion.button>
        )}

        <div className='md:flex me-auto'>
          <Logo width={`${isMobile ? '70' : '100'}`} />
        </div>

        <div className='flex items-center space-s-8 smx:space-s-4'>
          {(history.location.pathname !== ROUTES.CREATE_SHOP ||
            history.location.pathname !== ROUTES.CHOSE_PLAN) && (
            <>
              <Button
                className='ms-4 md:ms-6'
                onClick={() => handleCreateShop()}
                // size="small"
              >
                {t('CREATE_SHOP')}
              </Button>
            </>
          )}
          <NotificationTopBar />

          <Menu as='div'>
            <Menu.Button className='flex items-center focus:outline-none'>
              <FontAwesomeIcon icon={faGlobe as IconProp} />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                as='ul'
                className='absolute left-0 w-48 py-4 mt-1 origin-top-right bg-white rounded shadow-700 focus:outline-none'
              >
                {siteSettings.languageSidebarMenu.map(({ label, value }) => (
                  // eslint-disable-next-line react/jsx-key
                  <Menu.Item key={label}>
                    <div
                      key={label}
                      className='border-b border-gray-100 cursor-pointer last:border-0'
                    >
                      <button
                        onClick={() => handleChangeLocale(value)}
                        className={cn(
                          'block px-4 py-3 text-sm capitalize font-semibold transition duration-200 hover:text-accent',
                          getLocale() === value ? 'text-accent' : 'text-heading',
                        )}
                      >
                        {t(label)}
                      </button>
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          <AuthorizedMenu />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
