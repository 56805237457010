import ConfirmationCard from 'src/components/common/confirmation-card';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { RootState } from 'src/store';
import { deleteProduct } from 'src/store/shop/actions';
import { getAllProducts } from 'src/store/shop/shopsAPIActions';
import { PAGE_SIZE } from 'src/ts-types';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';

const ProductDeleteView = () => {
  const { data } = useModalState();
  const { closeModal } = useModalAction();
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((state: RootState) => state.util.UtilData?.searchTerm) ?? {
    categoryId: '',
    text: '',
  };
  async function handleDelete() {
    dispatch(deleteProduct(data)).then((res: any) => {
      dispatch(getAllProducts({ pageNumber: 1, pageSize: PAGE_SIZE }, searchTerm));
    });
    // history.push(ROUTES.PRODUCTS);
    closeModal();
  }
  return (
    <ConfirmationCard onCancel={closeModal} onDelete={handleDelete} deleteBtnLoading={false} />
  );
};

export default ProductDeleteView;
