/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const Pants: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='9' height='18' fill='currentColor' {...props}>
      <g data-name='Group 12529'>
        <path
          data-name='Path 21704'
          d='M8.678 18H6.107a.321.321 0 01-.318-.277L4.5 8.443l-1.289 9.28a.322.322 0 01-.318.277H.322A.322.322 0 010 17.679V4.821a.353.353 0 010-.045l.643-4.5A.321.321 0 01.961 0h7.072a.321.321 0 01.318.276l.643 4.5a.314.314 0 010 .045v12.858a.321.321 0 01-.316.321zm-2.292-.643h1.97V4.844l-.6-4.2H1.242l-.6 4.2v12.514h1.97L4.181 6.063a.321.321 0 01.318-.277.321.321 0 01.318.277z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12530'>
        <path
          data-name='Path 21705'
          d='M4.179 4.5a.322.322 0 01-.322-.321V.322a.322.322 0 11.643 0v3.48a.966.966 0 00.643-.909V1.608a.322.322 0 11.643 0v1.285A1.609 1.609 0 014.179 4.5z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12531'>
        <path
          data-name='Path 21706'
          d='M8.222 1.928H.774a.322.322 0 110-.643h7.444a.322.322 0 010 .643z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12532'>
        <path
          data-name='Path 21707'
          d='M8.678 5.143a.319.319 0 01-.227-.094L7.165 3.763a.321.321 0 11.455-.454l1.285 1.286a.321.321 0 01-.227.549z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12533'>
        <path
          data-name='Path 21708'
          d='M.321 5.143a.321.321 0 01-.227-.549L1.38 3.308a.321.321 0 11.454.454L.549 5.048a.32.32 0 01-.228.095z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
