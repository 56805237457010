/* eslint-disable @typescript-eslint/ban-types */
import { FieldErrors } from 'react-hook-form';
import { Switch } from '@headlessui/react';
import ValidationError from './form-validation-error';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

interface Props {
  errors?: FieldErrors;
  label?: string;
  className?: string;
  name: string;
  disabled?: boolean;
  value?: boolean;
  defaultValue?: boolean;
  setValue?: Function;
  onChange?: (value: any) => void;
  [key: string]: unknown;
}

const SwitchInput = ({
  value,
  onChange,
  label,
  name,
  errors,
  disabled,
  defaultValue,
  setValue,
  className,
  ...rest
}: Props) => {
  const { t }: any = useTranslation<any>();

  useEffect(() => {
    setValue?.(name, defaultValue ?? false);
  }, []);
  const handleOnChanged = (val: boolean) => {
    setValue?.(name, val ?? false);
    onChange?.(val);
  };
  const handleClick = (event: any) => {
    event.stopPropagation(); // This stops the event from propagating
  };
  return (
    <div className={`flex ${className}`}>
      <Switch
        onClick={handleClick}
        checked={value}
        onChange={(val: any) => handleOnChanged(val)}
        disabled={disabled}
        className={`${
          value ? 'bg-accent' : 'bg-gray-300'
        } relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none ${
          disabled ? 'bg-[#EEF1F4] cursor-not-allowed' : ''
        }`}
        dir='ltr'
      >
        <div className='sr-only text-sm'>Enable {label}</div>
        <span
          className={`${
            value ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transition-transform transform bg-light rounded-full`}
        />
      </Switch>
      {label && <div className='pr-3 text-sm'>{label}</div>}
      <ValidationError message={t(errors?.[name]?.message)} />
    </div>
  );
};

export default SwitchInput;
