import { useTranslation } from 'react-i18next';
import Input from '../ui/input';
// @ts-ignore
import { faEarth } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @ts-ignore
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import DomainInput from '../ui/domainInput';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { ValidateDomain } from 'src/store/utilData/actions';
import { useEffect, useState } from 'react';
import { setShopDataForCreate, setValidateDomainResponse } from 'src/store/utilData/actionTypes';
import { CompanyDetails } from 'src/ts-types/custom.types';
import Title from '../ui/title';
import { RootState } from 'src/store';

export type Props = {
  register: any;
  errors: any;
  getValues: any;
  watch: any;
  defaultValue?: CompanyDetails;
  domainDefaultValue?: string;
  userEmail: string;
  setValue: (key: string, val: string) => void;
};

const BusinessDetails = ({
  register,
  errors,
  userEmail,
  getValues,
  watch,
  setValue,
  domainDefaultValue,
  defaultValue,
}: Props) => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const [domainIsNotValid, setDomainIsNotValid] = useState(false);
  const validateDomainResponse = useAppSelector(
    (state: RootState) => state.util.UtilData?.validateDomainResponse,
  );
  const newShopData = useAppSelector((state: RootState) => state.util.UtilData?.shopForCreateData);

  useEffect(() => {
    const validateDomain = async () => {
      dispatch(
        setValidateDomainResponse({
          validDomain: validateDomainResponse?.validDomain ?? false,
          suggestedDomain: [],
          isFetching: validateDomainResponse?.isFetching,
        }),
      );
    };
    validateDomain();
    setDomainIsNotValid(!validateDomainResponse?.validDomain);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(ValidateDomain(getValues('website')));
      dispatch(setShopDataForCreate({ ...newShopData, layoutType: getValues('website') }));
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [watch('website')]);

  useEffect(() => {
    dispatch(
      setShopDataForCreate({
        ...newShopData,
        customerDetails: { emailAddress: getValues('customerDetails.emailAddress') },
        companyDetails: { companyName: getValues('companyDetails.companyName') },
      }),
    );
  }, [watch('companyDetails.companyName'), watch('customerDetails.emailAddress')]);

  const handleSetDomain = async (domain: string) => {
    await dispatch(
      setValidateDomainResponse({
        validDomain: validateDomainResponse?.validDomain ?? false,
        suggestedDomain: [],
        isFetching: true,
      }),
    );
    const val = domain.replace(`.${process.env.REACT_APP_MAIN_DOMAIN}` ?? '', '');
    setValue('website', val);
  };
  return (
    <div className='flex-1 w-[300px] '>
      <Title className='mb-6 sm:mb-3 '>{t('business-details')}</Title>
      <div className='flex flex-col md:flex-row'>
        <Input
          tabIndex={1}
          label={t('input-label-company-name')}
          variant='outline'
          defaultValue={defaultValue?.companyName}
          required
          {...register('companyDetails.companyName')}
          error={t(errors?.companyDetails?.companyName?.message)}
        />
      </div>
      <div className='flex flex-col md:flex-row'>
        <Input
          tabIndex={2}
          required
          defaultValue={userEmail}
          label={t('input-label-email')}
          variant='outline'
          type='email'
          {...register('customerDetails.emailAddress')}
          error={t(errors?.customerDetails?.emailAddress?.message)}
        />
      </div>
      <div className='flex flex-col md:flex-row'>
        <DomainInput
          tabIndex={3}
          required
          watch={watch}
          validateDomainResponse={validateDomainResponse}
          suffix='.www'
          prefix='ngoshops.com.'
          defaultValue={domainDefaultValue}
          domainIsNotValid={domainIsNotValid}
          loading={validateDomainResponse?.isFetching}
          setNewDomainAction={(val: string) => handleSetDomain(val)}
          icon={<FontAwesomeIcon icon={faEarth as IconProp} />}
          label={t('input-label-domain')}
          variant='outline'
          {...register('website')}
          error={t(errors?.website?.message)}
        />
      </div>
      {/* <div className='flex flex-col md:flex-row'>
        <Input
          tabIndex={4}
          label={t('input-cpmany-id')}
          variant='outline'
          {...register('companyDetails.companyNumber')}
          error={t(errors?.companyDetails?.companyNumber?.message)}
        />
        <Input
          tabIndex={5}
          label={t('input-label-phone')}
          variant='outline'
          {...register('customerDetails.Phone')}
          error={t(errors?.customerDetails?.Phone?.message)}
        />
      </div> */}
      {/* <div className='flex flex-col md:flex-row'>
        <Input
          tabIndex={4}
          label={t('input-label-email')}
          variant='outline'
          type='email'
          {...register('customerDetails.emailAddress')}
          error={t(errors?.customerDetails?.emailAddress?.message)}
        />
      </div> */}
    </div>
  );
};

export default BusinessDetails;
