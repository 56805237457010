import RadioCard from './radio-card/radio-card';
import { useTranslation } from 'react-i18next';
import { siteSettings } from 'src/settings/site.settings';

interface Props {
  register: any;
  onSelect?: () => void;
}

export const ProductCarousel = ({ register, onSelect }: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <div className='w-3/4'>
      <div className='grid md:grid-cols-3 sm:grid-cols-1'>
        {/* <Swiper
          // install Swiper modules
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          dir='rtl'
          pagination={{ clickable: false }}
          scrollbar={{ draggable: true }}
        > */}
        {siteSettings.productCards?.map((productCard, index) => {
          return (
            // <SwiperSlide className='w-3/4 pt-35'>
            <RadioCard
              key={`product-card-${index}`}
              {...register?.('productCardType')}
              label={t(productCard?.label)}
              onSelected={() => onSelect?.()}
              value={productCard.value}
              src={productCard.img}
              id={`product-card-${index}`}
            />
            // </SwiperSlide>
          );
        })}
        {/* </Swiper> */}
      </div>
    </div>
  );
};
