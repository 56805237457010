import ErrorMessage from 'src/components/ui/error-message';
import { useAppSelector } from 'src/utils/reduxHooks';
import { RootState } from 'src/store';
import EditOrCreateSale from 'src/components/sale/saleForm';

export default function UpdateSalePage() {
  const activeSale = useAppSelector((state: RootState) => state.shop.shopDetails?.activeSale);
  const error = useAppSelector((state: RootState) => state.shop.shopDetails?.error);
  console.log('activeSale', activeSale);
  if (error) return <ErrorMessage message={error} />;

  return <EditOrCreateSale initialValues={activeSale} />;
}
