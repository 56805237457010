import { getAuthCredentials, setAuthCredentials } from '../utils/authUtils';
import { getShopDetails } from '../utils/shopUtils';
import axios from 'axios';
import { ROUTES } from 'src/utils/routes';
import { API_ENDPOINTS_NEW } from './endpoints';
import { BlackList } from './config';

const baseUrl = 'https://api.ngoshops.com/';
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Language': 'he',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
};

const http = axios.create({
  baseURL: baseUrl, // TODO: take this api URL from env
  timeout: 30000,
  headers: headers,
});

// Change request data/error here
http.interceptors.request.use(
  (config) => {
    const { token } = getAuthCredentials();
    const { shopId } = getShopDetails();
    if (BlackList.includes(config.url ?? '')) {
      config.headers = {
        ...config.headers,
      };
    } else {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    if (shopId) {
      config.headers = {
        ...config.headers,
        shopId: shopId,
      };
    }
    if (
      config.url?.includes(API_ENDPOINTS_NEW.GET_ALL_MY_SHOPS) ||
      config.url?.includes(API_ENDPOINTS_NEW.ALL_MY_ALERTS)
    ) {
      delete config?.headers?.shopId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Change response data/error here
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalConfig = error.config;
    const { status, message } = error?.response?.data || {};
    if (
      status === 401 &&
      (message === 'TOKEN_IS_EXPIRED' || message === 'TOKEN_IS_NOT_VALID') &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;
      const authCred = getAuthCredentials();
      delete originalConfig.headers.Authorization;
      axios
        .post(
          `${baseUrl}${API_ENDPOINTS_NEW.REFRESH_TOKEN}`,
          { refreshToken: authCred?.refreshToken },
          {
            headers: originalConfig.headers,
          },
        )
        .then((response) => {
          const { refreshToken, accessToken } = response.data;
          setAuthCredentials(accessToken, refreshToken);
          return http(originalConfig);
        })
        .catch((e) => {
          setAuthCredentials(undefined, undefined);
          window.location.replace(`${ROUTES.LOGIN}`);
        });
    }

    return Promise.reject(error);
  },
);

export default http;
