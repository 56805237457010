import CreateOrUpdateCategoriesForm from 'src/components/category/category-form';
import ErrorMessage from 'src/components/ui/error-message';
import { useAppSelector } from 'src/utils/reduxHooks';
import { RootState } from 'src/store';

export default function UpdateCategoriesPage() {
  const activeCategory = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeCategory,
  );
  const error = useAppSelector((state: RootState) => state.shop.shopDetails?.error);

  if (error) return <ErrorMessage message={error} />;

  return <CreateOrUpdateCategoriesForm initialValues={activeCategory} />;
}
