import Pagination from 'src/components/ui/pagination';
import ActionButtons from 'src/components/common/action-buttons';
import { siteSettings } from 'src/settings/site.settings';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'src/ts-types/generated';
import { useCallback, useEffect, useState } from 'react';
import TitleWithSort from 'src/components/ui/title-with-sort';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { setActiveProduct, setProductVariant } from 'src/store/shop/actions';
import { Product, ProductInfoWithTranslations, Products } from 'src/ts-types/custom.types';
import { ROUTES } from 'src/utils/routes';
import { getActiveProduct } from 'src/utils/productUtils';
import { SAVE_MODE } from 'src/ts-types';
import { useHistory } from 'react-router-dom';
import { formatPrice } from 'src/utils/use-priceNew';
import SwitchInput from '../ui/switch-input';
import { updateProduct } from 'src/store/shop/shopsAPIActions';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ProductCard from '../ui/productCard';
import { RootState } from 'src/store';
import { getActiveCategory } from 'src/utils/categoryUtils';
import { setProductSearch } from 'src/store/utilData/actionTypes';
import { TOAST_MESSAGE } from 'src/utils/constants';
import { getLocale, getTranslatedItem, getTranslatedProductItem } from 'src/common';

export type IProps = {
  products?: Products;
  searchText?: string;
  onPagination: (current: number) => void;
  onSort: (current: any) => void;
  onOrder: (current: string) => void;
  onUpdate: () => void;
};

type SortingObjType = {
  sort: SortOrder;
  column: string | null;
};

const ProductList = ({ products, searchText, onPagination, onSort, onOrder, onUpdate }: IProps) => {
  const categories = useAppSelector((state: RootState) => state.shop.shopDetails?.categories);

  const { data, totalElements, pageSize, pageNumber } = products! ?? {};
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    // dispatch(setProductSearch({ categoryId: '', text: '' }));
    dispatch(setProductVariant([]));
  }, []);
  const [sortingObj, setSortingObj] = useState<SortingObjType>({
    sort: SortOrder.Desc,
    column: null,
  });

  const onHeaderClick = (column: string | null) => ({
    onClick: () => {
      onSort((currentSortDirection: SortOrder) =>
        currentSortDirection === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
      );
      onOrder(column!);

      setSortingObj({
        sort: sortingObj.sort === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
        column: column,
      });
    },
  });

  const sortProductsByPrice = useCallback(
    (products: Product[]) => {
      let sortedProducts;
      if (sortingObj.sort === SortOrder.Asc) {
        // @ts-ignore
        sortedProducts = [...products].sort((a, b) => a?.price - b?.price);
      } else {
        // @ts-ignore
        sortedProducts = [...products].sort((a, b) => b?.price - a?.price);
      }
      return sortedProducts;
    },
    [sortingObj.sort],
  );

  const sortProductsName = useCallback(
    (products: Product[]) => {
      let sortedProducts;
      if (sortingObj.sort === SortOrder.Asc) {
        sortedProducts = [...products].sort((a, b) =>
          // @ts-ignore
          a?.productInfoWithTranslations?.[0]?.name > b?.productInfoWithTranslations?.[0]?.name
            ? -1
            : 1,
        );
      } else {
        sortedProducts = [...products].sort((a, b) =>
          // @ts-ignore
          a?.productInfoWithTranslations?.[0]?.name > b?.productInfoWithTranslations?.[0]?.name
            ? 1
            : -1,
        );
      }
      return sortedProducts;
    },
    [sortingObj.sort],
  );

  useEffect(() => {
    let sortedProducts;
    if (products!.data != null && sortingObj.column != null) {
      switch (sortingObj.column) {
        case 'price':
          sortedProducts = sortProductsByPrice(products!.data);
          break;
        default:
          sortedProducts = sortProductsName(products!.data);
          break;
      }
      products!.data = sortedProducts;
    }
  }, [sortingObj, products, sortProductsByPrice, sortProductsName]);

  const onRowClick = (record: any) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const product = getActiveProduct(data, record?.productId);
    dispatch(setActiveProduct(product!, SAVE_MODE.EDIT));
    // dispatch(setProductVariant(product?.variantDetailsList));
    history.push(ROUTES.EDIT_PRODUCT);
  };
  const updateStatus = (status: boolean, product: Product) => {
    dispatch(updateProduct({ ...product, active: status }))
      .then((res: any) => {
        onUpdate();
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        history.push(ROUTES.PRODUCTS);
      })
      .catch((error: { response: { data: { [x: string]: any[]; message?: any } } }) => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
      });
  };

  const columns = [
    {
      title: t('table-item-image'),
      dataIndex: 'mainImage',
      key: 'productId',
      align: 'center',
      width: 100,
      render: (mainImage: any, product: Product) => {
        const { variantDetailsList } = product;
        const img = _.isEmpty(mainImage) ? variantDetailsList?.[0].mainImage : mainImage;
        return (
          <img
            src={img ?? siteSettings.product.placeholder}
            alt={'name'}
            width={42}
            height={42}
            className='rounded overflow-hidden'
            style={{ display: 'inline' }}
          />
        );
      },
    },
    {
      title: (
        <TitleWithSort
          title={t('table-item-title')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'name'}
          isActive={sortingObj.column === 'name'}
        />
      ),
      className: 'cursor-pointer',
      dataIndex: 'productInfoWithTranslations',
      key: 'productId',
      align: 'center',
      width: 140,
      ellipsis: true,
      render: (productInfoWithTranslations: ProductInfoWithTranslations[]) => {
        if (!productInfoWithTranslations) return null;
        return <>{getTranslatedProductItem(productInfoWithTranslations).name}</>;
      },
      onHeaderCell: () => onHeaderClick('name'),
    },
    {
      title: (
        <TitleWithSort
          title={t('table-category-title')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'name'}
          isActive={sortingObj.column === 'name'}
        />
      ),
      className: 'cursor-pointer',
      dataIndex: 'categoryId',
      key: 'categoryId',
      align: 'right',
      width: 100,
      maxWidth: 100,
      render: (categoryId: string) => {
        if (categories) {
          return (
            <>
              {getTranslatedItem(
                getActiveCategory(categories.data, categoryId)?.categoryNameWithTeanslations,
              )}
            </>
          );
        }
        return null;
      },
    },
    // {
    //   title: t("table-item-group"),
    //   dataIndex: "type",
    //   key: "type",
    //   width: 120,
    //   align: "center",
    //   ellipsis: true,
    //   render: (type: any) => (
    //     <span className="whitespace-nowrap truncate">{type?.name}</span>
    //   ),
    // },
    // {
    //   title: t("table-item-shop"),
    //   dataIndex: "shop",
    //   key: "shop",
    //   width: 120,
    //   align: "center",
    //   ellipsis: true,
    //   render: (shop: Shop) => (
    //     <span className="whitespace-nowrap truncate">{shop?.name}</span>
    //   ),
    // },
    // {
    //   title: "Product Type",
    //   dataIndex: "product_type",
    //   key: "product_type",
    //   width: 120,
    //   align: "center",
    //   render: (product_type: string) => (
    //     <span className="whitespace-nowrap truncate">{product_type}</span>
    //   ),
    // },
    {
      title: (
        <TitleWithSort
          title={t('table-item-unit')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'price'}
          isActive={sortingObj.column === 'price'}
        />
      ),
      className: 'cursor-pointer',
      dataIndex: 'price',
      key: 'productId',
      align: 'center',
      width: 100,
      onHeaderCell: () => onHeaderClick('price'),
      render: (price: number, record: Product) => {
        return (
          <span className='whitespace-nowrap' title={price?.toString()}>
            {formatPrice({
              // @ts-ignore
              price,
              displaySign: false,
              currencyCode: 'ILS',
              locale: getLocale(),
            })}
          </span>
        );
      },
    },
    // {
    //   title: (
    //     <TitleWithSort
    //       title={t("table-item-quantity")}
    //       ascending={
    //         sortingObj.sort === SortOrder.Asc &&
    //         sortingObj.column === "quantity"
    //       }
    //       isActive={sortingObj.column === "quantity"}
    //     />
    //   ),
    //   className: "cursor-pointer",
    //   dataIndex: "quantity",
    //   key: "quantity",
    //   align: "center",
    //   width: 100,
    //   onHeaderCell: () => onHeaderClick("quantity"),
    // },
    {
      title: t('table-item-status'),
      dataIndex: 'active',
      key: 'productId',
      align: 'center',
      width: 100,
      render: (active: boolean, record: Product) => (
        <SwitchInput
          className='items-center justify-center text-center'
          name='status'
          onChange={(val) => updateStatus(val, record)}
          defaultValue={true}
          value={active}
        />
        // <Badge
        //   text={active}
        //   color={active?.toString().toLocaleLowerCase() === 'draft' ? 'bg-yellow-400' : 'bg-accent'}
        // />
      ),
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'productId',
      key: 'productId',
      align: 'center',
      width: 80,
      render: (slug: string, record: Product) => (
        <ActionButtons
          id={record?.productId || ''}
          editAction={() => onRowClick(getActiveProduct(data, record?.productId))}
          editUrl={ROUTES.EDIT_PRODUCT}
          deleteModalView='DELETE_PRODUCT'
        />
      ),
    },
  ];

  // if (router?.query?.shop) {
  //   columns = columns?.filter((column) => column?.key !== "shop");
  // }

  return (
    <>
      <div className='rounded overflow-hidden shadow mb-6'>
        <div className='w-full grid grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-5 mb-6'>
          {data?.map((product: Product, index: number) => {
            return <ProductCard key={index} product={product} data={data} />;
          })}
        </div>
      </div>

      {!!totalElements && (
        <div className='flex justify-end items-center'>
          <Pagination
            total={totalElements}
            current={pageNumber}
            pageSize={pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default ProductList;
