import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ConfirmationCard from 'src/components/common/confirmation-card';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { deleteSale } from 'src/store/shop/actions';
import { getAllSalesForAmin } from 'src/store/shop/shopsAPIActions';
import { TOAST_MESSAGE } from 'src/utils/constants';
import { useAppDispatch } from 'src/utils/reduxHooks';

const SaleDeleteView = () => {
  const { t }: any = useTranslation<any>();
  const { data } = useModalState();
  const { closeModal } = useModalAction();
  const dispatch = useAppDispatch();

  async function handleDelete() {
    dispatch(deleteSale(data)).then((res: any) => {
      dispatch(getAllSalesForAmin());
      toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
    });
    closeModal();
  }
  return (
    <ConfirmationCard onCancel={closeModal} onDelete={handleDelete} deleteBtnLoading={false} />
  );
};

export default SaleDeleteView;
