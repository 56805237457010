/* eslint-disable react/no-deprecated */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalProvider } from 'src/components/ui/modal/modal.context';
import { ToastContainer } from 'react-toastify';
import App from './App';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css'; // import styles
import './main.css';

import './i18n';
import { useTranslation } from 'react-i18next';
import { initGA } from './ga';
import { getLocale, setLocale } from './common';

const MainApp = () => {
  const { i18n }: any = useTranslation();
  useEffect(() => {
    const lang = navigator.language;
    setLocale(getLocale() || lang, i18n);
    initGA(process.env.REACT_APP_GA ?? '');
  }, []);

  return (
    <React.StrictMode>
      <ModalProvider>
        <App />
        <ToastContainer autoClose={2000} theme='colored' rtl />
      </ModalProvider>
    </React.StrictMode>
  );
};
ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
