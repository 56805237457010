import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import Notification from '../ui/notification';
import { SYSTEM_MESSAGE } from 'src/utils/constants';

const NoShopAlert = () => {
  // @ts-ignore
  const { t }: any = useTranslation<any>();
  const history = useHistory();

  return (
    <Notification variant='warning' className='mt-4'>
      <>
        <span>{t(SYSTEM_MESSAGE.USER_HAS_NO_SHOPS)}</span>
        <a
          href={ROUTES.ANONY_CREATE_SHOP}
          className='ms-1 underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-700 hover:no-underline focus:no-underline'
        >
          {t('text-create-shop')}
        </a>
      </>
    </Notification>
  );
};

export default NoShopAlert;
