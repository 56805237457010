/* eslint-disable react/display-name */
import React, { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import './radio-card.module.css';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  labelKey?: string;
  name: string;
  id: string;
  error?: string;
  errorKey?: string;
  onSelected?: () => void;
}

const RadioCard = React.forwardRef<HTMLInputElement, Props>(
  ({ className, label, labelKey, name, id, src, error, errorKey, onSelected, ...rest }, ref) => {
    const { t }: any = useTranslation<any>();
    return (
      <>
        <div className='flex w-3/4'>
          <input
            id={id}
            name={name}
            type='radio'
            ref={ref}
            onSelect={() => onSelected?.()}
            className='radio_input'
            {...rest}
          />

          <label htmlFor={id} className='flex flex-col rounded cursor-pointer '>
            <div className='p-3 pb-0 w-full max-h-72 flex items-center justify-center overflow-hidden'>
              <img
                src={src ?? '/product-placeholder-borderless.svg'}
                alt={t(labelKey ? labelKey : label!)}
                className='w-auto h-full object-contain'
              />
            </div>

            <h3 className='text-body text-sm text-center font-semibold p-5 mt-auto'>
              <span>{t(labelKey ? labelKey : label!)}</span>
            </h3>
          </label>
        </div>

        {errorKey && <p className='text-sm text-start text-red-500'>{t(errorKey)}</p>}
      </>
    );
  },
);

export default RadioCard;
