import _ from 'lodash';
import { AddOnCategory, Category, ImageType } from 'src/ts-types/custom.types';

export const getActiveCategory = (categories: Category[], categoryId: string): Category => {
  let category: Category = {};
  _.filter(categories);
  categories.forEach((element: Category) => {
    if (element.categoryId === categoryId) {
      category = element;
    }
    element.children?.forEach((subElement: Category) => {
      if (subElement.categoryId === categoryId) {
        category = subElement;
        category.parentId = element.categoryId;
      }
    });
  });
  return category;
};

export const getImages = (images?: string[] | string): ImageType[] => {
  const imageUrls: ImageType[] = [];
  if (Array.isArray(images)) {
    images.forEach((img) => {
      imageUrls.push({ imageUrl: img, name: img });
    });
  } else {
    imageUrls.push({ imageUrl: images || '', name: images || '' });
  }
  return imageUrls;
};
