import React, { FC, useEffect, useState } from 'react';
interface Props {
  promotionsActions: string[];
  onInputListChange: (val: string[]) => void;
}

const DynamicInputList: FC<Props> = ({ promotionsActions, onInputListChange }) => {
  const [inputs, setInputs] = useState(promotionsActions || []);

  // Effect to update the input list in the parent component
  useEffect(() => {
    onInputListChange(inputs);
  }, [inputs, onInputListChange]);

  // Function to handle adding new input field
  const addInput = () => {
    setInputs([...inputs, '']);
  };

  // Function to handle input change
  const handleInputChange = (index: number, value: string) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  };

  return (
    <div className='p-4'>
      {inputs.map(
        (
          input: string | number | readonly string[] | undefined,
          index: React.Key | null | undefined,
        ) => (
          <div key={index} className='flex items-center mb-2'>
            <input
              type='text'
              value={input}
              onChange={(e) => handleInputChange(index as number, e.target.value)}
              className='border border-gray-300 rounded-md p-2 flex-1'
              placeholder={`Input ${index ?? 0 + 1}`}
            />
          </div>
        ),
      )}
      <button
        onClick={addInput}
        className='flex items-center justify-center mt-2 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600'
      >
        <span className='mr-2'>Add Input</span>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-5 w-5'
          viewBox='0 0 20 20'
          fill='currentColor'
        >
          <path
            fillRule='evenodd'
            d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z'
            clipRule='evenodd'
          />
        </svg>
      </button>
    </div>
  );
};

export default DynamicInputList;
