import TrashIcon from 'src/components/icons/trash';
import Button from 'src/components/ui/button';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

type ConfirmationCardProps = {
  onCancel: () => void;
  onDelete: () => void;
  title?: string;
  children?: any;
  icon?: any;
  description?: string;
  cancelBtnClassName?: string;
  deleteBtnClassName?: string;
  cancelBtnText?: string;
  deleteBtnText?: string;
  cancelBtnLoading?: boolean;
  deleteBtnLoading?: boolean;
};

const ConfirmationCard = ({
  onCancel,
  onDelete,
  icon,
  children,
  title = '',
  description = 'delete-item-confirm',
  cancelBtnText = 'button-cancel',
  deleteBtnText = 'button-okay',
  cancelBtnClassName,
  deleteBtnClassName,
  cancelBtnLoading,
  deleteBtnLoading,
}: ConfirmationCardProps) => {
  const { t }: any = useTranslation<any>();
  return (
    <div className='p-4 pb-6 bg-light m-auto max-w-sm w-full rounded-md md:rounded-xl sm:w-[24rem]'>
      <div className='w-full h-full text-center'>
        <div className='flex h-full flex-col justify-between'>
          {icon ? icon : <TrashIcon className='mt-4 w-12 h-12 m-auto text-accent' />}
          <p className='text-heading text-xl font-bold mt-4'>{t(title)}</p>
          <p className='text-body-dark dark:text-muted leading-relaxed py-2 px-6'>
            {t(description)}
          </p>
          <div>{children}</div>
          <div className='flex items-center justify-between space-s-2 w-full mt-8'>
            <div className='w-1/2'>
              <Button
                onClick={onDelete}
                loading={deleteBtnLoading}
                disabled={deleteBtnLoading}
                variant='custom'
                className={cn(
                  'w-full py-2 px-4 focus:outline-none text-light transition ease-in duration-200 text-center text-base font-semibold rounded shadow-md',
                  deleteBtnClassName
                    ? deleteBtnClassName
                    : 'bg-red-600 hover:bg-red-700 focus:bg-red-700',
                )}
              >
                {t(deleteBtnText)}
              </Button>
            </div>
            <div className='w-1/2'>
              <Button
                onClick={onCancel}
                loading={cancelBtnLoading}
                disabled={cancelBtnLoading}
                variant='custom'
                className={cn(
                  'w-full py-2 px-4 focus:outline-none text-light transition ease-in duration-200 text-center text-base font-semibold rounded shadow-md',
                  cancelBtnClassName
                    ? cancelBtnClassName
                    : 'bg-accent hover:bg-accent-hover focus:bg-accent-hover',
                )}
              >
                {t(cancelBtnText)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationCard;
