/* eslint-disable @typescript-eslint/no-explicit-any */
export const Perfume: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      fill='#000000'
      height='200px'
      width='200px'
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      {...props}
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <g>
          {' '}
          <g>
            {' '}
            <path d='M445.452,177.982c-14.993-39.358-36.02-61.211-59.206-61.211h-35.678v-71.45C350.568,20.526,330.143,0,305.349,0h-98.695 c-24.796,0-44.717,20.526-44.717,45.322v71.45h-36.182c-23.186,0-44.213,21.853-59.206,61.211 C52.597,214.605,44.912,263.2,44.912,314.651s7.684,99.822,21.636,136.447C81.542,490.456,102.568,512,125.754,512h260.491 c23.186,0,44.213-21.5,59.206-60.86c13.952-36.623,21.636-85.127,21.636-136.579S459.403,214.605,445.452,177.982z M179.901,45.322c0-14.89,11.862-27.357,26.752-27.357h98.695c14.888,0,27.254,12.467,27.254,27.357v71.45H179.901V45.322z M386.246,494.035H125.754c-14.983,0-30.443-17.851-42.419-49.289c-13.193-34.632-20.458-80.954-20.458-130.272 c0-105.871,33.138-179.737,62.877-179.737h44.912h170.667h44.912c14.983,0,30.443,18.206,42.419,49.64 c13.193,34.631,20.458,80.776,20.458,130.096C449.123,420.344,415.985,494.035,386.246,494.035z'></path>{' '}
          </g>{' '}
        </g>{' '}
        <g>
          {' '}
          <g>
            {' '}
            <path d='M256,27.3c-22.287,0-40.421,18.134-40.421,40.421s18.134,40.421,40.421,40.421c22.287,0,40.421-18.134,40.421-40.421 S278.287,27.3,256,27.3z M256,90.178c-12.382,0-22.456-10.074-22.456-22.456c0-12.381,10.074-22.456,22.456-22.456 c12.382,0,22.456,10.075,22.456,22.456C278.456,80.103,268.382,90.178,256,90.178z'></path>{' '}
          </g>{' '}
        </g>{' '}
        <g>
          {' '}
          <g>
            {' '}
            <path d='M316.305,213.412c-16.967-27.338-39.98-42.393-64.796-42.393c-24.816,0-47.827,15.055-64.796,42.393 c-16.14,26.004-25.029,60.395-25.029,96.835c0,36.441,8.888,70.831,25.029,96.833c16.969,27.34,39.98,42.395,64.796,42.395 c24.816,0,47.829-15.055,64.796-42.395c16.14-26.002,25.028-60.392,25.028-96.833 C341.333,273.805,332.445,239.417,316.305,213.412z M251.509,431.511c-39.623,0-71.86-54.399-71.86-121.263 s32.237-121.263,71.86-121.263s71.86,54.399,71.86,121.263S291.132,431.511,251.509,431.511z'></path>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};
