/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const HomeCleaning: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.59'
      height='25.05'
      viewBox='0 0 20.59 25.05'
      fill='currentColor'
      {...props}
    >
      <g id='home_cleaner' data-name='home cleaner' transform='translate(-2142.193 -787.635)'>
        <path
          id='Path_17440'
          data-name='Path 17440'
          d='M2188.815,831.346a2.538,2.538,0,0,1,2.6-1.81v-1.584a27.676,27.676,0,0,0-3.734-.111,3.962,3.962,0,0,0-3.169,2.034c-.33.684,2.49-.339,2.716,1.471'
          transform='translate(-39.669 -37.554)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <path
          id='Path_17441'
          data-name='Path 17441'
          d='M2289.466,862.334a10.742,10.742,0,0,0,1.89,3.765c.082.119-.025.28-.137.207a6.8,6.8,0,0,1-2.94-3.5'
          transform='translate(-138.167 -70.32)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <path
          id='Path_17442'
          data-name='Path 17442'
          d='M2226.052,898.788a2.93,2.93,0,0,0,1.4.214,3.341,3.341,0,0,0,1.319-.214v-1.436a.17.17,0,0,0-.169-.17h-2.377a.17.17,0,0,0-.169.17Z'
          transform='translate(-79.113 -103.39)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <path
          id='Path_17443'
          data-name='Path 17443'
          d='M2156.93,1189.207'
          transform='translate(-13.516 -380.522)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <path
          id='Path_17444'
          data-name='Path 17444'
          d='M2320.353,830.622h1.328v1.584h-1.328'
          transform='translate(-168.605 -40.225)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <path
          id='Path_17445'
          data-name='Path 17445'
          d='M2153.421,936.6a15.113,15.113,0,0,1-1.134,4.707.744.744,0,0,1-.68.515h-7.4a.751.751,0,0,1-.688-.544,12.078,12.078,0,0,1,2.065-12.242,3.527,3.527,0,0,0,.679-3.055,1.052,1.052,0,0,1,.675-1.195'
          transform='translate(0 -129.588)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <path
          id='Path_17446'
          data-name='Path 17446'
          d='M2278.913,926.025c1.471.905-.25,1.974.006,2.2.847.757-.006,1.8-.006,1.8a1.4,1.4,0,0,1,.369,1.741,8.765,8.765,0,0,1,2.391,2.01'
          transform='translate(-129.259 -130.762)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <line
          id='Line_15'
          data-name='Line 15'
          y1='1.511'
          x2='3.16'
          transform='translate(2154.628 788.235)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <line
          id='Line_16'
          data-name='Line 16'
          x2='3.16'
          y2='1.511'
          transform='translate(2154.628 792.632)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <line
          id='Line_17'
          data-name='Line 17'
          x2='4.259'
          transform='translate(2154.628 791.189)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <circle
          id='Ellipse_9'
          data-name='Ellipse 9'
          cx='2.047'
          cy='2.047'
          r='2.047'
          transform='translate(2158.239 799.709)'
          strokeWidth='0.9'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <path
          id='Path_17447'
          data-name='Path 17447'
          d='M2383.738,957.387a1.489,1.489,0,0,0,2.978,0,1.489,1.489,0,0,0-2.978,0Z'
          transform='translate(-228.757 -159.112)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.9'
        />
        <circle
          id='Ellipse_10'
          data-name='Ellipse 10'
          cx='2.764'
          cy='2.764'
          r='2.764'
          transform='translate(2152.212 802.109)'
          strokeWidth='0.9'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
      </g>
    </svg>
  );
};
