/* eslint-disable @typescript-eslint/no-explicit-any */
export const HomeProducts: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg id='Magicons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42.61 40.5' {...props}>
      <defs></defs>
      <path
        className='cls-1'
        d='M30.9,22.61c.33,0,.59-.27.59-.59v-1.33c0-1.03-.84-1.88-1.88-1.88h-2.79v-1.14c0-.95-.77-1.72-1.72-1.72h-2.34l6.6-11.75c.16-.29.06-.65-.23-.81-.29-.16-.65-.06-.81.23l-5.1,9.08,3.32-10.82c.1-.31-.08-.65-.39-.74-.31-.1-.65.08-.74.39l-3.39,11.05.87-11.94c.02-.33-.22-.61-.55-.64-.33-.02-.61.22-.64.55l-.66,9.07-.66-9.07c-.02-.33-.31-.57-.64-.55-.33.02-.57.31-.55.64l.87,11.94-3.39-11.05c-.1-.31-.43-.49-.74-.39-.31.1-.49.43-.39.74l3.32,10.82L13.76,3.61c-.16-.29-.52-.39-.81-.23-.29.16-.39.52-.23.81l6.6,11.75h-2.34c-.95,0-1.72.77-1.72,1.72v1.14h-2.79c-1.03,0-1.88.84-1.88,1.88v18.03c0,1.03.84,1.88,1.88,1.88h17.14c1.03,0,1.88-.84,1.88-1.88v-14.4c0-.33-.27-.59-.59-.59s-.59.27-.59.59v14.4c0,.38-.31.69-.69.69H12.48c-.38,0-.69-.31-.69-.69v-18.03c0-.38.31-.69.69-.69h6.65l-4.3,7.66c-.16.29-.06.65.23.81.09.05.19.08.29.08.21,0,.41-.11.52-.3l3-5.34-1.82,5.94c-.1.31.08.65.39.74.06.02.12.03.17.03.25,0,.49-.16.57-.42l1.89-6.17-.47,6.47c-.02.33.22.61.55.64.01,0,.03,0,.04,0,.31,0,.57-.24.59-.55l.26-3.6.26,3.58c.02.31.28.55.59.55.01,0,.03,0,.04,0,.33-.02.57-.31.55-.64l-.47-6.45,1.89,6.17c.08.26.31.42.57.42.06,0,.12,0,.17-.03.31-.1.49-.43.39-.74l-1.82-5.94,3,5.34c.11.19.31.3.52.3.1,0,.2-.02.29-.08.29-.16.39-.52.23-.81l-4.3-7.66h6.65c.38,0,.69.31.69.69v1.33c0,.33.27.59.59.59h0ZM16.46,18.81v-1.14c0-.29.24-.53.53-.53h8.13c.29,0,.53.24.53.53v1.14h-9.19Z'
      />
      <path
        className='cls-1'
        d='M25.77,36.63h-5.2c-.33,0-.59.27-.59.59s.27.59.59.59h5.2c.68,0,1.23-.55,1.23-1.23v-2.36c0-.68-.55-1.23-1.23-1.23h-9.43c-.68,0-1.23.55-1.23,1.23v2.36c0,.68.55,1.23,1.23,1.23h1.93c.33,0,.59-.27.59-.59s-.27-.59-.59-.59h-1.93s-.04-.02-.04-.04v-2.36s.02-.04.04-.04h9.43s.04.02.04.04v2.36s-.02.04-.04.04Z'
      />
    </svg>
  );
};
