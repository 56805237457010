import ConfirmationCard from 'src/components/common/confirmation-card';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { RootState } from 'src/store';
import { setProductVariant } from 'src/store/shop/actions';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';

const VariantDeleteView = () => {
  const { data } = useModalState();
  const { closeModal } = useModalAction();
  const dispatch = useAppDispatch();
  const productVariants = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.productVariants,
  );
  async function handleDelete() {
    const variants = productVariants?.filter((item) => item.id !== data);
    dispatch(setProductVariant(variants));
    closeModal();
  }
  return (
    <ConfirmationCard onCancel={closeModal} onDelete={handleDelete} deleteBtnLoading={false} />
  );
};

export default VariantDeleteView;
