/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const Skirts: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16.661'
      height='18'
      fill='currentColor'
      {...props}
    >
      <g data-name='Group 12570'>
        <path
          data-name='Path 21742'
          d='M12.198 3.124H4.463a.3.3 0 01-.3-.3V.301a.3.3 0 01.3-.3h7.735a.3.3 0 01.3.3V2.83a.3.3 0 01-.3.294zm-7.438-.6h7.14V.59H4.763z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12571'>
        <path
          data-name='Path 21743'
          d='M13.685 16.81a.3.3 0 110-.6 3.567 3.567 0 002.323-.96L11.925 2.941a.3.3 0 01.545-.239l4.165 12.5a.3.3 0 01-.051.318 4.189 4.189 0 01-2.899 1.29z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12572'>
        <path
          data-name='Path 21744'
          d='M2.975 16.81a4.188 4.188 0 01-2.9-1.289.3.3 0 01-.051-.318l4.165-12.5a.3.3 0 01.545.239L.651 15.25a3.588 3.588 0 002.323.961.3.3 0 110 .6z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12573'>
        <path
          data-name='Path 21745'
          d='M8.33 18a5.393 5.393 0 01-2.216-.329.3.3 0 01-.162-.3l1.19-12.793a.3.3 0 01.591.072L6.572 17.208a7.848 7.848 0 003.517 0L8.927 4.65a.3.3 0 11.591-.072l1.19 12.793a.3.3 0 01-.162.3A5.394 5.394 0 018.33 18z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12574'>
        <path
          data-name='Path 21746'
          d='M10.414 17.702a.3.3 0 110-.595 5.293 5.293 0 002.933-.713L11.019 4.688a.3.3 0 11.575-.153l2.38 11.9a.3.3 0 01-.086.3 5.237 5.237 0 01-3.474.967z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12575'>
        <path
          data-name='Path 21747'
          d='M6.247 17.702a5.239 5.239 0 01-3.475-.972.3.3 0 01-.086-.3l2.38-11.9a.3.3 0 11.575.153L3.312 16.39a5.3 5.3 0 002.934.713.3.3 0 010 .6z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
