/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const Deodorant: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.564'
      height='18'
      viewBox='0 0 17.564 18'
      fill='currentColor'
      {...props}
    >
      <g id='Deodorant' transform='translate(-836.064 -1170.056)'>
        <path
          id='Path_21770'
          data-name='Path 21770'
          d='M874.318,1242.182a.35.35,0,0,0-.276,0l-4.617,1.982a.349.349,0,0,0-.2.4l0,.009a9.5,9.5,0,0,0,1.752,3.6.35.35,0,0,0,.275.133h5.856a.35.35,0,0,0,.275-.134,9.53,9.53,0,0,0,1.759-3.6.35.35,0,0,0-.2-.406Zm2.618,5.427h-5.512a8.783,8.783,0,0,1-1.445-2.922l4.2-1.8,4.207,1.805A8.814,8.814,0,0,1,876.936,1247.609Z'
          transform='translate(-29.337 -63.805)'
          fill='currentColor'
        />
        <path
          id='Path_21771'
          data-name='Path 21771'
          d='M853.62,1178.428a.349.349,0,0,0-.211-.3l-5.73-2.459v-2.459a1.591,1.591,0,0,0-.291-3.156H842.3a1.591,1.591,0,0,0-.291,3.156v2.459l-5.73,2.459a.349.349,0,0,0-.211.3,12.9,12.9,0,0,0,4.048,9.532.349.349,0,0,0,.241.1h0c2.388-.006,6.579-.006,8.967,0h0a.349.349,0,0,0,.242-.1A12.9,12.9,0,0,0,853.62,1178.428Zm-10.907-2.881v-2.306h4.268v2.306Zm-1.3-3.9a.894.894,0,0,1,.893-.893h5.085a.893.893,0,1,1,0,1.786H842.3A.894.894,0,0,1,841.411,1171.648Zm7.779,15.709c-2.33-.005-7.938,0-8.687,0a12.5,12.5,0,0,1-3.737-8.673l5.682-2.438h4.8l5.682,2.438A12.463,12.463,0,0,1,849.19,1187.357Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
