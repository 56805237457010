/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const ShavingNeeds: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.602'
      height='17.6'
      viewBox='0 0 17.602 17.6'
      fill='currentColor'
      {...props}
    >
      <g id='Shaving_Needs' data-name='Shaving Needs' transform='translate(-449.7 -599.7)'>
        <path
          id='Path_21792'
          data-name='Path 21792'
          d='M830.4,1063.839v3.179a.673.673,0,0,0,.673.673h3.146a.673.673,0,0,0,.673-.673v-3.179Z'
          transform='translate(-369.623 -450.697)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.6'
        />
        <path
          id='Path_21793'
          data-name='Path 21793'
          d='M773.349,802.277s-4.709-.151-3.877,2.594c.681,2.247,1.631,4.816,1.631,4.816h4.491s.952-2.568,1.632-4.816C778.058,802.126,773.349,802.277,773.349,802.277Z'
          transform='translate(-310.325 -196.544)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.6'
        />
        <g id='Group_12593' data-name='Group 12593' transform='translate(462.009 610.598)'>
          <line
            id='Line_6'
            data-name='Line 6'
            x2='0.268'
            y2='2.544'
            transform='translate(0 0)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.6'
          />
          <line
            id='Line_7'
            data-name='Line 7'
            x1='0.268'
            y2='2.544'
            transform='translate(1.76 0)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.6'
          />
        </g>
        <g id='Group_12594' data-name='Group 12594' transform='translate(460.778 615.068)'>
          <line
            id='Line_8'
            data-name='Line 8'
            x2='0.876'
            transform='translate(0)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.6'
          />
          <line
            id='Line_9'
            data-name='Line 9'
            x2='0.876'
            transform='translate(3.616)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.6'
          />
        </g>
        <path
          id='Path_21794'
          data-name='Path 21794'
          d='M835.976,638.026a2.336,2.336,0,0,1,4.584.519h0a1.468,1.468,0,0,1,1.468,1.468'
          transform='translate(-375.04 -35.287)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.6'
        />
        <rect
          id='Rectangle_2040'
          data-name='Rectangle 2040'
          width='9.149'
          height='3.937'
          rx='1'
          transform='translate(450 600)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.6'
        />
        <g id='Group_12595' data-name='Group 12595' transform='translate(453.411 609.155)'>
          <line
            id='Line_10'
            data-name='Line 10'
            x1='0.886'
            transform='translate(0 0)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.6'
          />
          <line
            id='Line_11'
            data-name='Line 11'
            x1='0.886'
            transform='translate(0 1.97)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.6'
          />
          <line
            id='Line_12'
            data-name='Line 12'
            x1='0.886'
            transform='translate(0 3.94)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.6'
          />
        </g>
        <path
          id='Path_21795'
          data-name='Path 21795'
          d='M570.392,802.1v10.111a1.163,1.163,0,1,0,2.326,0V802.1'
          transform='translate(-116.981 -196.379)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.6'
        />
        <line
          id='Line_13'
          data-name='Line 13'
          x2='5.883'
          transform='translate(451.633 601.968)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.6'
        />
        <path
          id='Path_21796'
          data-name='Path 21796'
          d='M507.283,738.947l.9,1.79h4.112l.9-1.79Z'
          transform='translate(-55.66 -135.011)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.6'
        />
      </g>
    </svg>
  );
};
