/* eslint-disable react/prop-types */
import React from 'react';
export const Refund = ({ ...props }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56' {...props}>
      <g data-name='Group 2541' transform='translate(-1719 -150)'>
        <path
          data-name='Path 2087'
          d='M28,0A28,28,0,1,1,0,28,28,28,0,0,1,28,0Z'
          transform='translate(1719 150)'
          fill='#2170ff'
          opacity='0.2'
        />
        <g data-name='Group 2543' transform='translate(1733 164)'>
          <g data-name='Group 2544' transform='translate(4.304 4.304)'>
            <g data-name='Group 2543' transform='translate(0 0)'>
              <path
                data-name='Path 2096'
                d='M5368.315,4398.011a9.7,9.7,0,1,1,9.7-9.7A9.708,9.708,0,0,1,5368.315,4398.011Zm0-18.171a8.475,8.475,0,1,0,8.476,8.475A8.484,8.484,0,0,0,5368.315,4379.84Z'
                transform='translate(-5358.62 -4378.62)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2097'
                d='M5371.153,4381.62a9.534,9.534,0,1,0,9.533,9.533A9.545,9.545,0,0,0,5371.153,4381.62Zm0,18.171a8.638,8.638,0,1,1,8.638-8.638A8.647,8.647,0,0,1,5371.153,4399.791Z'
                transform='translate(-5361.456 -4381.458)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2546' transform='translate(10.041 8.13)'>
            <g data-name='Group 2545' transform='translate(0)'>
              <path
                data-name='Path 2098'
                d='M5468.736,4461.133a.611.611,0,0,1-.61-.61v-.375c-1.949-.181-3.348-1.2-3.348-2.465a.611.611,0,0,1,1.222,0c0,.5.847,1.09,2.126,1.239v-3.194c-1.642-.465-3.348-1.128-3.348-2.886,0-1.263,1.4-2.284,3.348-2.465V4450a.61.61,0,1,1,1.22,0v.374c1.949.181,3.348,1.2,3.348,2.465a.61.61,0,0,1-1.22,0c0-.495-.848-1.091-2.127-1.238v3.194c1.643.466,3.348,1.126,3.348,2.886,0,1.263-1.4,2.284-3.348,2.465v.375A.61.61,0,0,1,5468.736,4461.133Zm.61-2.21c1.28-.148,2.127-.744,2.127-1.239,0-.7-.562-1.128-2.127-1.611Zm-1.22-7.318c-1.279.147-2.126.743-2.126,1.238,0,.7.562,1.128,2.126,1.611Z'
                transform='translate(-5464.777 -4449.392)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2099'
                d='M5472.021,4457.758v-3.5c1.465.118,2.452.8,2.452,1.417a.448.448,0,1,0,.9,0c0-1.214-1.438-2.183-3.348-2.314v-.525a.448.448,0,1,0-.9,0v.525c-1.911.131-3.348,1.1-3.348,2.314,0,1.626,1.545,2.26,3.348,2.763v3.495c-1.465-.118-2.452-.8-2.452-1.416a.448.448,0,1,0-.9,0c0,1.213,1.437,2.183,3.348,2.316v.523a.448.448,0,1,0,.9,0v-.523c1.91-.133,3.348-1.1,3.348-2.316C5475.369,4458.895,5473.824,4458.26,5472.021,4457.758Zm-.9-.249c-1.819-.537-2.452-1.026-2.452-1.83,0-.618.987-1.3,2.452-1.417Zm.9,4.428v-3.246c1.818.538,2.452,1.025,2.452,1.83C5474.474,4461.139,5473.486,4461.82,5472.021,4461.937Z'
                transform='translate(-5467.616 -4452.229)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2548' transform='translate(2.391 0)'>
            <g data-name='Group 2547' transform='translate(0)'>
              <path
                data-name='Path 2100'
                d='M5334.839,4327a.61.61,0,1,1,0-1.221,12.786,12.786,0,1,0-9.167-21.685l.844-.281a.6.6,0,0,1,.194-.031.61.61,0,0,1,.192,1.19l-2.869.956a.626.626,0,0,1-.193.031.61.61,0,0,1-.6-.7l.478-3.348a.613.613,0,0,1,.6-.522.667.667,0,0,1,.087,0,.611.611,0,0,1,.518.691l-.173,1.2A14,14,0,1,1,5334.839,4327Z'
                transform='translate(-5323.23 -4299)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2101'
                d='M5347.463,4306.052a13.838,13.838,0,0,0-20.106.568l.246-1.718a.449.449,0,0,0-.888-.128l-.478,3.348a.451.451,0,0,0,.443.513.449.449,0,0,0,.144-.025l2.869-.956a.448.448,0,0,0-.284-.851l-1.442.48a12.941,12.941,0,1,1,9.712,21.493.449.449,0,0,0,0,.9,13.838,13.838,0,0,0,9.785-23.623Z'
                transform='translate(-5326.069 -4301.836)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2550' transform='translate(11.005 26.567)'>
            <g data-name='Group 2549' transform='translate(0 0)'>
              <path
                data-name='Path 2102'
                d='M5483.667,4791.79a.585.585,0,0,1-.087-.006c-.165-.024-.333-.05-.493-.08a.61.61,0,0,1,.106-1.21.563.563,0,0,1,.108.009c.147.025.3.051.451.072a.61.61,0,0,1-.085,1.215Z'
                transform='translate(-5482.584 -4790.493)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2103'
                d='M5486.569,4793.572c-.153-.021-.307-.045-.457-.073a.448.448,0,1,0-.159.882c.161.03.325.055.487.078a.373.373,0,0,0,.065.006.449.449,0,0,0,.063-.894Z'
                transform='translate(-5485.422 -4793.329)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2552' transform='translate(8.34 25.795)'>
            <g data-name='Group 2551' transform='translate(0 0)'>
              <path
                data-name='Path 2104'
                d='M5434.763,4777.729a.579.579,0,0,1-.19-.032c-.305-.1-.611-.212-.909-.333a.611.611,0,1,1,.459-1.131c.272.11.552.213.83.305a.611.611,0,0,1-.19,1.191Z'
                transform='translate(-5433.283 -4776.188)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2105'
                d='M5437.738,4779.53c-.281-.092-.565-.2-.84-.308a.448.448,0,1,0-.339.83c.3.12.6.231.9.331a.449.449,0,0,0,.279-.853Z'
                transform='translate(-5436.119 -4779.026)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2554' transform='translate(0.141 15.345)'>
            <g data-name='Group 2553' transform='translate(0 0)'>
              <path
                data-name='Path 2106'
                d='M5282.4,4585.042a.614.614,0,0,1-.6-.481c-.069-.312-.126-.634-.174-.952a.613.613,0,0,1,.516-.693.771.771,0,0,1,.089,0,.614.614,0,0,1,.6.521c.042.29.1.583.158.87a.607.607,0,0,1-.082.459.614.614,0,0,1-.384.267A.653.653,0,0,1,5282.4,4585.042Z'
                transform='translate(-5281.624 -4582.911)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2107'
                d='M5285.676,4587.172c-.062-.29-.116-.586-.16-.88a.448.448,0,0,0-.887.13c.046.314.1.631.17.942a.45.45,0,0,0,.438.354.389.389,0,0,0,.1-.012A.446.446,0,0,0,5285.676,4587.172Z'
                transform='translate(-5284.462 -4585.747)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2556' transform='translate(0.262 9.839)'>
            <g data-name='Group 2555' transform='translate(0 0)'>
              <path
                data-name='Path 2108'
                d='M5284.464,4483.11a.72.72,0,0,1-.121-.011.611.611,0,0,1-.478-.719c.063-.316.138-.632.223-.943a.614.614,0,0,1,.589-.45.635.635,0,0,1,.161.023.611.611,0,0,1,.428.751c-.077.282-.146.572-.2.86A.611.611,0,0,1,5284.464,4483.11Z'
                transform='translate(-5283.853 -4480.987)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2109'
                d='M5287.632,4484a.449.449,0,0,0-.552.314c-.083.306-.156.619-.22.931a.448.448,0,0,0,.352.528.461.461,0,0,0,.087.009.452.452,0,0,0,.441-.36c.059-.292.127-.585.206-.87A.45.45,0,0,0,5287.632,4484Z'
                transform='translate(-5286.689 -4483.826)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2558' transform='translate(0.829 18.034)'>
            <g data-name='Group 2557' transform='translate(0 0)'>
              <path
                data-name='Path 2110'
                d='M5295.269,4634.7a.61.61,0,0,1-.557-.358c-.132-.293-.257-.594-.368-.9a.612.612,0,0,1,.36-.785.6.6,0,0,1,.211-.039.613.613,0,0,1,.573.4c.1.275.215.55.335.818a.611.611,0,0,1-.306.807A.592.592,0,0,1,5295.269,4634.7Z'
                transform='translate(-5294.306 -4632.626)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2111'
                d='M5298.513,4636.747c-.122-.271-.236-.549-.34-.829a.448.448,0,0,0-.84.311c.11.3.233.6.363.886a.45.45,0,0,0,.409.264.469.469,0,0,0,.185-.04A.447.447,0,0,0,5298.513,4636.747Z'
                transform='translate(-5297.143 -4635.463)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2560' transform='translate(0 12.575)'>
            <g data-name='Group 2559' transform='translate(0)'>
              <path
                data-name='Path 2112'
                d='M5279.609,4533.781a.612.612,0,0,1-.611-.606c0-.039,0-.077,0-.116,0-.282.01-.569.027-.85a.608.608,0,0,1,.607-.574h.038a.612.612,0,0,1,.573.646c-.015.258-.023.52-.023.778,0,.035,0,.07,0,.107a.612.612,0,0,1-.606.615h0Z'
                transform='translate(-5278.998 -4531.634)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2113'
                d='M5282.5,4534.634a.449.449,0,0,0-.475.421c-.016.277-.025.561-.025.84v.117a.448.448,0,0,0,.449.443h0a.45.45,0,0,0,.446-.451v-.108c0-.262.006-.526.022-.787A.447.447,0,0,0,5282.5,4534.634Z'
                transform='translate(-5281.836 -4534.47)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2562' transform='translate(5.893 24.485)'>
            <g data-name='Group 2561' transform='translate(0 0)'>
              <path
                data-name='Path 2114'
                d='M5389.413,4753.693a.618.618,0,0,1-.306-.083c-.28-.161-.556-.335-.82-.515a.61.61,0,1,1,.685-1.011c.243.165.5.323.748.47a.612.612,0,0,1,.223.834A.615.615,0,0,1,5389.413,4753.693Z'
                transform='translate(-5388.019 -4751.979)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2115'
                d='M5392.479,4755.533c-.257-.149-.512-.309-.759-.476a.449.449,0,0,0-.5.743c.264.178.537.348.811.509a.454.454,0,0,0,.225.061.449.449,0,0,0,.226-.836Z'
                transform='translate(-5390.858 -4754.817)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2564' transform='translate(2.054 20.521)'>
            <g data-name='Group 2563' transform='translate(0 0)'>
              <path
                data-name='Path 2116'
                d='M5318.141,4680.616a.613.613,0,0,1-.493-.25c-.19-.26-.375-.529-.544-.8a.61.61,0,1,1,1.032-.65c.158.248.324.494.5.731a.611.611,0,0,1-.493.971Z'
                transform='translate(-5317.009 -4678.629)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2117'
                d='M5321.343,4682.579c-.175-.239-.344-.489-.5-.74a.448.448,0,1,0-.758.479c.169.27.35.535.537.792a.449.449,0,0,0,.724-.53Z'
                transform='translate(-5319.85 -4681.467)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2566' transform='translate(3.769 22.7)'>
            <g data-name='Group 2565' transform='translate(0 0)'>
              <path
                data-name='Path 2118'
                d='M5350,4720.845a.609.609,0,0,1-.408-.156c-.238-.216-.473-.442-.7-.673a.61.61,0,0,1,.877-.849c.205.211.419.417.637.614a.61.61,0,0,1-.408,1.064Z'
                transform='translate(-5348.718 -4718.981)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2119'
                d='M5353.132,4722.74c-.221-.2-.437-.408-.644-.623a.448.448,0,1,0-.644.625c.221.228.453.452.688.664a.443.443,0,0,0,.3.116.449.449,0,0,0,.3-.782Z'
                transform='translate(-5351.555 -4721.819)'
                fill='#2170ff'
              />
            </g>
          </g>
          <g data-name='Group 2568' transform='translate(1.091 7.652)'>
            <g data-name='Group 2567' transform='translate(0)'>
              <path
                data-name='Path 2120'
                d='M5299.792,4442.2a.61.61,0,0,1-.566-.385.609.609,0,0,1,.005-.466c.067-.153.136-.308.208-.457a.616.616,0,0,1,.552-.348.607.607,0,0,1,.261.058.614.614,0,0,1,.29.814c-.066.136-.128.276-.19.415A.608.608,0,0,1,5299.792,4442.2Z'
                transform='translate(-5299.182 -4440.542)'
                fill='#2170ff'
              />
              <path
                data-name='Path 2121'
                d='M5303.023,4443.585a.449.449,0,0,0-.6.212c-.07.148-.139.3-.2.45a.449.449,0,0,0,.824.356c.061-.141.125-.282.191-.421A.45.45,0,0,0,5303.023,4443.585Z'
                transform='translate(-5302.022 -4443.379)'
                fill='#2170ff'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
