import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../common/card';
import Input from '../ui/input';

interface Props {
  register: any;
  errors: any;
  show: boolean;
  setValue: (name: string, val: unknown) => void;
}
const ShopCompanyInfo = ({ register, errors, show, setValue }: Props) => {
  const { t }: any = useTranslation<any>();

  useEffect(() => {
    setValue('companyDetails.companyType', 'Company');
  }, [setValue]);

  return (
    <div className='mb-100' hidden={!show}>
      <Card className='md:w-full mt-5' title={t('shop-company-details')}>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-company-name')}
            {...register('companyDetails.companyName')}
            variant='outline'
            name='companyName'
            error={t(errors.companyDetails?.companyName?.message)}
          />
          <Input
            label={t('input-company-type')}
            {...register('companyDetails.companyType')}
            variant='outline'
            name='companyType'
            error={t(errors.companyDetails?.companyType?.message)}
          />
        </div>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-phone')}
            {...register('companyDetails.phoneNumber')}
            variant='outline'
            error={t(errors.companyDetails?.phoneNumber?.message)}
          />
          <Input
            label={t('input-cpmany-id')}
            {...register('companyDetails.companyNumber')}
            variant='outline'
            error={t(errors.companyDetails?.companyNumber?.message)}
          />
        </div>
      </Card>
      <Card className='md:w-full mt-5' title={t('shop-company-pay-system')}>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-apiKey')}
            {...register('companyDetails.Officeguy.apiKey')}
            variant='outline'
            error={t(errors.companyDetails?.Officeguy?.apiKey?.message)}
          />
          <Input
            label={t('input-label-send-invoice-by-email')}
            {...register('companyDetails.Officeguy.companyID')}
            variant='outline'
            error={t(errors.companyDetails?.Officeguy?.companyID?.message)}
          />
        </div>
      </Card>
    </div>
  );
};

export default ShopCompanyInfo;
