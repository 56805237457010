import { ROUTES } from 'src/utils/routes';
import { ArrowPrev } from '../icons/arrow-prev';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSiteDomain } from 'src/utils/shopUtils';
import { ShopDetailsType } from 'src/ts-types/custom.types';
import { EditLinkIcon } from '../icons/editLink';
type Props = {
  activeShop?: ShopDetailsType;
};
const Breadcrumb = ({ activeShop }: Props) => {
  const history = useHistory();
  const { website, shopId } = activeShop || {};

  const baseRoute = '/shops/shop/';
  const getPathDetails = (name: string): string => {
    switch (name) {
      case 'shops':
        return ROUTES.SHOPS;
      case 'shop':
      case 'activeShop':
        return `${ROUTES.ACTIVE_SHOP}${shopId}`;
      default:
        return `${baseRoute}${name}`;
    }
  };
  const { t }: any = useTranslation();
  let arr: string[] = history.location.pathname.split('/');
  arr = arr.filter((x) => x !== '');
  const paths: [{ name?: string; path?: string }] | any = [];
  arr.forEach((item) => {
    if (!/[^a-zA-Z]/.test(item)) {
      paths.push({ name: `breadcrumb_${item}`, path: getPathDetails(item) });
    }
  });

  const handleLinkClick = (e: any, domain: string, shopId?: string) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(getSiteDomain(domain, shopId), '_blank');
  };
  if (arr.length <= 1) return null;
  if (history.location.pathname.includes('template')) return null;

  return (
    <div className='flex mb-2'>
      <div className={'justify-start w-full'}>
        <nav className='flex py-1 text-gray-700 rounded-lg' aria-label='Breadcrumb'>
          <ol className='inline-flex items-center space-x-1 '>
            <>
              {paths.map((item: { name?: string; path?: string }, key: number) => {
                return (
                  <li className='inline-flex items-center' key={key}>
                    <a
                      href={key === 0 ? ROUTES.ENTRY : item.path}
                      className='inline-flex items-center text-sm font-medium text-accent 
                          hover:texlt-gray-700  dark:text-accent dark:hover:text-gray-700 truncate hover:text-clip'
                    >
                      {key === 0 ? (
                        <svg
                          className='w-4 h-4'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z'></path>
                        </svg>
                      ) : (
                        <ArrowPrev color='currentColor' />
                      )}
                      {t(item.name)}
                    </a>
                  </li>
                );
              })}
            </>
          </ol>
        </nav>
      </div>
      <div
        className='text-ellipsis overflow-hidden justify-end flex text-blue-600 text-accent '
        style={{ direction: 'ltr' }}
      >
        {/* <span className='w-40 pt-20'>{companyDetails?.companyName ?? ''}</span> */}
        <span className='pt-0.5'>
          <EditLinkIcon />
        </span>
        <Link
          to=''
          className='  mr-4 items-center text-blue-600 text-accent inline-block max-w-xs overflow-hidden text-ellipsis whitespace-nowrap'
          onClick={(e) => handleLinkClick(e, website!, shopId)}
        >
          {website ?? process.env.REACT_APP_DEFAULT_SITE}
        </Link>
      </div>
    </div>
  );
};

export default Breadcrumb;
