import LoginForm from '../../components/auth/loginForm';
import Logo from 'src/components/ui/logo';
import { useHistory } from 'react-router-dom';
import { isAuthenticated } from 'src/utils/authUtils';
import { ROUTES } from 'src/utils/routes';
import { useEffect } from 'react';

export default function LoginPage() {
  const history = useHistory();
  useEffect(() => {
    if (isAuthenticated()) {
      history.replace(ROUTES.SHOPS);
    }
  }, [history]);

  return (
    <div className='flex items-center justify-center h-screen bg-light'>
      <div className='m-auto max-w-md w-full bg-light sm:shadow p-5 sm:p-8 rounded'>
        <div className='flex justify-center mb-2'>
          <Logo />
        </div>
        <LoginForm />
      </div>
    </div>
  );
}
