import Card from '../../components/common/card';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { getAllCoupons } from '../../store/shop/actions';
import { UPLOAD_SECTION } from '../../ts-types';
import Button from '../../components/ui/button';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/ui/loader/loader';
import { withPermission } from 'src/HOC/withPermission';
import { RootState } from 'src/store';
import { storage } from 'src/firebase';
import { getShopDetails } from 'src/utils/shopUtils';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';

const ImagesUploader = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const couponResponse = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.couponsResponse,
  );

  useEffect(() => {
    dispatch(getAllCoupons());
  }, [dispatch]);

  if (couponResponse?.isFetching) return <Loader text={t('text-loading')} />;

  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(e.target.files);
  };

  const handleUpload = () => {
    if (selectedFiles) {
      const { shopId } = getShopDetails();
      // const storageRef = ref(storage, `${shopId}/${UPLOAD_SECTION.PRODUCTS}/${file.name}`);
      const imagePromises: Promise<string>[] = [];
      const imgUrls: any[] = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const { shopId } = getShopDetails();
        const storageRef = ref(storage, `${shopId}/${UPLOAD_SECTION.PRODUCTS}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          'state_changed',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          (snapshot) => {},
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
              imgUrls.push({
                imageUrl: downloadURL,
                name: uploadTask.snapshot.metadata.name,
              });
            });
          },
        );
      }
      Promise.all(imagePromises)
        .then((downloadURLs) => {
          console.log('Uploaded images URLs:', downloadURLs);
          // You can handle the uploaded URLs here
        })
        .catch((error) => {
          console.error('Error uploading images:', error);
        });
    }
  };
  return (
    <>
      <Card className='flex flex-col mb-8'>
        <div className='w-full flex md:flex-row smx:flex-row items-center'>
          <div className='md:w-1/4 mb-4 md:mb-0'>
            <h1 className='text-xl font-semibold text-heading'>{t('input-label-coupons')}</h1>
          </div>

          <div className='justify-end w-full xl:w-3/4 flex md:flex-row space-y-4 md:space-y-0 items-center ms-auto'>
            <Button className='h-12 ms-4 md:ms-6'>
              <span className='hidden md:block'>{t('button-label-add-sale')} +</span>
              <span className='md:hidden'>+ {t('button-label-add')}</span>
            </Button>
          </div>
        </div>
      </Card>
      <div className='rounded overflow-hidden shadow mb-6'>
        <div>
          <input type='file' multiple onChange={handleFileChange} />
          <button onClick={handleUpload}>Upload Images</button>
        </div>
      </div>
    </>
  );
};
export default withPermission(ImagesUploader);
