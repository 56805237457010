/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const AttributeIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32.737 19.2' {...props}>
    <g id='attribute' transform='translate(-6019.363 -2902.9)'>
      <path
        id='settings-outline'
        d='M57.257,48.321a2.784,2.784,0,0,0-2.775,1.527,2.636,2.636,0,0,0,.545,3.054,2.828,2.828,0,0,0,3.142.53,2.679,2.679,0,0,0,1.571-2.7A2.737,2.737,0,0,0,57.257,48.321ZM63.925,51a6.312,6.312,0,0,1-.066.874l1.956,1.491a.446.446,0,0,1,.106.578l-1.85,3.112a.474.474,0,0,1-.569.193l-1.943-.76a.714.714,0,0,0-.656.074,7.168,7.168,0,0,1-.932.527.672.672,0,0,0-.382.511L59.3,59.614a.475.475,0,0,1-.462.386h-3.7a.477.477,0,0,1-.463-.373l-.291-2.011a.678.678,0,0,0-.389-.514,6.767,6.767,0,0,1-.928-.529.709.709,0,0,0-.654-.072l-1.942.76a.474.474,0,0,1-.569-.193l-1.85-3.112a.446.446,0,0,1,.106-.578l1.653-1.262a.667.667,0,0,0,.26-.592c-.016-.175-.025-.35-.025-.526s.009-.348.025-.519a.665.665,0,0,0-.263-.586l-1.652-1.262a.446.446,0,0,1-.1-.575l1.85-3.112a.474.474,0,0,1,.569-.193l1.943.76a.714.714,0,0,0,.656-.074A7.168,7.168,0,0,1,54,44.91a.672.672,0,0,0,.382-.511l.291-2.014A.475.475,0,0,1,55.135,42h3.7a.477.477,0,0,1,.463.373l.291,2.011a.678.678,0,0,0,.389.514,6.767,6.767,0,0,1,.928.529.709.709,0,0,0,.654.072l1.942-.76a.474.474,0,0,1,.569.193l1.85,3.112a.446.446,0,0,1-.106.578l-1.653,1.262a.667.667,0,0,0-.262.592C63.915,50.65,63.925,50.825,63.925,51Z'
        transform='translate(5972.015 2861.5)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <g id='Group_36413' data-name='Group 36413' transform='translate(6042.5 2904.625)'>
        <line
          id='Line_1'
          data-name='Line 1'
          x2='9'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeWidth='2'
        />
        <line
          id='Line_2'
          data-name='Line 2'
          x2='9'
          transform='translate(0 3.75)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeWidth='2'
        />
        <line
          id='Line_3'
          data-name='Line 3'
          x2='9'
          transform='translate(0 7.5)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeWidth='2'
        />
        <line
          id='Line_4'
          data-name='Line 4'
          x2='9'
          transform='translate(0 11.25)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeWidth='2'
        />
        <line
          id='Line_5'
          data-name='Line 5'
          x2='4.5'
          transform='translate(0 15)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeWidth='2'
        />
      </g>
    </g>
  </svg>
);
