/* eslint-disable react/prop-types */
// @ts-nocheck
import Select from 'src/components/ui/select/select';
import { Controller } from 'react-hook-form';
import Label from 'src/components/ui/label';

interface SelectInputProps {
  control: any;
  label?: string;
  required?: boolean;
  rules?: any;
  name: string;
  options: object[];
  menuPlacement?: MenuPlacement;
  [key: string]: unknown;
}

const SelectInput = ({
  control,
  options,
  name,
  rules,
  required = false,
  getOptionLabel,
  getOptionValue,
  isMulti,
  label,
  isClearable,
  isLoading,
  menuPlacement = 'auto',
  ...rest
}: SelectInputProps) => {
  return (
    <div className='flex-1 '>
      <Label className={`${required ? 'required-input' : ''}`}>{label}</Label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        {...rest}
        render={({ field }) => (
          <Select
            {...field}
            menuPlacement={menuPlacement}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isMulti={isMulti}
            isClearable={isClearable}
            isLoading={isLoading}
            options={options}
          />
        )}
      />
    </div>
  );
};

export default SelectInput;
