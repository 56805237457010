import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/common/card';
import Button from 'src/components/ui/button';
import cn from 'classnames';
import get from 'lodash/get';
import Loader from 'src/components/ui/loader/loader';
import { setActionType } from 'src/store/shop/actions';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { fetchPlans } from 'src/store/utilData/actions';
import { PlanDetails } from 'src/ts-types/custom.types';
import PackageList from 'src/components/packages/packageList';
import { useModalAction } from 'src/components/ui/modal/modal.context';
import { RootState } from 'src/store';

export default function PackagePage() {
  const { t } = useTranslation();
  const [visible] = useState(false);
  const dispatch = useAppDispatch();
  const planDetailsResponse = useAppSelector(
    (state: RootState) => state.util.UtilData?.planDetailsResponse,
  );
  const isFetching = useAppSelector(
    (state: RootState) => state.util.UtilData?.planDetailsResponse?.isFetching,
  );
  const { openModal } = useModalAction();

  const plansData: PlanDetails[] = get(planDetailsResponse, 'data', []);

  useEffect(() => {
    dispatch(fetchPlans());
  }, [dispatch]);

  if (isFetching) return <Loader text={t('text-loading')} />;

  const handleCreateNewPackage = () => {
    dispatch(setActionType('SAVE'));

    openModal('PACKAGE_DETAILS_MODAL', {}, 'הוספת חבילה חדשה');
  };
  return (
    <>
      <Card className='flex flex-col mb-8'>
        <div className='w-full flex md:flex-row smx:flex-row items-center'>
          <div className='md:w-1/4 mb-4 md:mb-0'>
            <h1 className='text-lg font-semibold text-heading'>{t('input-label-packages')}</h1>
          </div>

          <div className='justify-end w-full md:w-3/4 flex md:flex-row items-center'>
            <Button onClick={() => handleCreateNewPackage()} className='h-12 ms-4 md:ms-6'>
              <span className='hidden md:block'>+ {t('button-label-add-package')}</span>
              <span className='md:hidden'>+ {t('button-label-add')}</span>
            </Button>
          </div>
        </div>
        <div
          className={cn('w-full flex transition', {
            'h-auto visible': visible,
            'h-0 invisible': !visible,
          })}
        ></div>
      </Card>
      <PackageList plans={plansData} />
      <ToastContainer autoClose={2000} />
    </>
  );
}
