/* eslint-disable react-hooks/rules-of-hooks */
import { Table } from 'src/components/ui/table';
import ActionButtons from 'src/components/common/action-buttons';
import { siteSettings } from 'src/settings/site.settings';
import Badge from 'src/components/ui/badge/badge';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import {
  Product,
  TranslationObject,
  VariantAllOptions,
  VariantDetailsList,
} from 'src/ts-types/custom.types';
import { useModalAction } from '../ui/modal/modal.context';
import { ROUTES } from 'src/utils/routes';
import { RootState } from 'src/store';
import { getTranslatedItem } from 'src/common';
import { SAVE_MODE } from 'src/ts-types';
import { setProductCurrentVariant } from 'src/store/shop/actions';
import { getOptionValueFromVariants } from 'src/utils/productUtils';

const ProductVariantList = () => {
  const { t }: any = useTranslation();
  const { openModal } = useModalAction();
  const dispatch = useAppDispatch();
  const productVariants = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.productVariants,
  );
  const activeProduct = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeProduct,
  ) as Product;
  const data = productVariants || [];

  const columns = [
    {
      title: t('input-label-name'),
      className: 'cursor-pointer',
      dataIndex: 'nameWithTranslation',
      key: 'nameWithTranslation',
      align: 'center',
      width: 100,
      ellipsis: true,
      render: (nameWithTranslation: TranslationObject[], record: VariantDetailsList) => {
        if (!nameWithTranslation) return null;
        return (
          <div className='flex flex-col'>
            <span>{getTranslatedItem(nameWithTranslation)}</span>
            <span>{record.productCode}</span>
          </div>
        );
      },
    },
    {
      title: t('table-item-image'),
      dataIndex: 'mainImage',
      key: 'mainImage',
      align: 'center',
      width: 74,
      render: (mainImage: any) => (
        <div>
          <img
            src={mainImage ?? siteSettings.product.placeholder}
            alt=''
            width={42}
            height={42}
            className='rounded overflow-hidden'
          />
        </div>
      ),
    },
    {
      title: t('input-label-price'),
      className: 'cursor-pointer',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      width: 100,
      ellipsis: true,
    },
    {
      title: t('table-item-status'),
      dataIndex: 'mainVariant',
      key: 'active',
      align: 'center',
      width: 100,
      render: (variantStatus: string) => (
        <Badge text={variantStatus} color={variantStatus ? 'bg-accent' : 'bg-yellow-400'} />
      ),
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'producCode',
      key: 'producCode',
      align: 'center',
      width: 80,
      render: (slug: string, record: Product) => (
        <ActionButtons
          id={record?.id || ''}
          editAction={() => onRowClick(record)}
          editUrl={ROUTES.EDIT_PRODUCT}
          deleteModalView='DELETE_VARIANT'
        />
      ),
    },
  ];

  const onRowClick = (record: VariantDetailsList) => {
    const optionValue = getOptionValueFromVariants(
      record?.variantsOptionsCombineList?.[0].optionNameId ?? '',
      record?.variantsOptionsCombineList?.[0].optionValueId ?? '',
      activeProduct,
    );

    if (optionValue) {
      record.variantMetaText = optionValue?.valueWithTeanslations;
      record.variantMeta = optionValue?.meta;
      record.variantTypeId = record?.variantsOptionsCombineList?.[0].optionNameId;
    }
    dispatch(setProductCurrentVariant(record));
    openModal('PRODUCT_VARIANT_VIEW', { isEditMode: true }, t('variant-form-title'));
  };

  // if (router?.query?.shop) {
  //   columns = columns?.filter((column) => column?.key ! == "shop");
  // }
  const rowExpandable = (record: any) => true;
  return (
    <>
      {productVariants && productVariants.length > 0 && (
        <div className='rounded overflow-hidden shadow mb-6 w-full'>
          <Table
            /* @ts-ignore */
            className='pt-25'
            /* @ts-ignore */
            columns={columns}
            emptyText={t('empty-table-data')}
            data={data}
            rowKey='id'
            scroll={{ x: 900 }}
            expandable={{
              // expandedRowRender: (recode, index, indent, expanded) => { console.log(recode) },
              rowExpandable: rowExpandable,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <div onClick={(e) => onExpand(record, e)}>open</div>
                ) : (
                  <div onClick={(e) => onExpand(record, e)}>close</div>
                ),
            }}
          />
        </div>
      )}
    </>
  );
};

export default ProductVariantList;
