import { useTranslation } from 'react-i18next';
import Card from '../common/card';
import RadioCard from '../ui/radio-card/radio-card';

interface Props {
  register: any;
  errors: any;
  show: boolean;
}

const layoutTypes = [
  {
    label: 'Classic',
    value: 'classic',
    img: '/image/layout-classic.png',
  },
  {
    label: 'Modern',
    value: 'modern',
    img: '/image/layout-modern.png',
  },
  {
    label: 'Standard',
    value: 'standard',
    img: '/image/layout-standard.png',
  },
  {
    label: 'Minimal',
    value: 'minimal',
    img: '/image/layout-minimal.png',
  },
];

const ShopLayoutInfo = ({ register, errors, show }: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <div className='mb-100' hidden={!show}>
      <Card className='md:w-full mt-5' title={t('input-label-layout-type')}>
        <div className='mb-10'>
          <div className='grid grid-cols-3 gap-5'>
            {layoutTypes?.map((layout, index) => {
              return (
                <RadioCard
                  key={index}
                  {...register('layoutType')}
                  label={t(layout.label)}
                  value={layout.value}
                  src={layout.img}
                  id={layout?.value}
                />
              );
            })}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ShopLayoutInfo;
