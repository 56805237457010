import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import Notification from '../ui/notification';

const AddCategoryFirstAlert = () => {
  const { t }: any = useTranslation<any>();
  const history = useHistory();

  const renderTitle = () => {
    return (
      <>
        {t('ADD_CATEGORY_FIRST_ALERT')}
        <a
          onClick={() => history.push(ROUTES.CREATE_CATEGORY)}
          className=' text-blue-600 text-lg
         cursor-pointer underline font-bold py-1.5 mr-1 text-center inline-flex items-center'
        >
          {t('ADD_CATEGORY_FIRST_ALERT_BUTTON')}
        </a>
      </>
    );
  };

  return <Notification variant='info'>{renderTitle()}</Notification>;
};

export default AddCategoryFirstAlert;
