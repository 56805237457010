/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const Face: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.267'
      height='18'
      fill='currentColor'
      {...props}
    >
      <g data-name='Group 12576'>
        <path
          data-name='Path 21748'
          d='M7.634 13.537a5.9 5.9 0 115.9-5.9 5.91 5.91 0 01-5.9 5.9zm0-11.113a5.211 5.211 0 105.211 5.211 5.216 5.216 0 00-5.211-5.212z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12577'>
        <path
          data-name='Path 21749'
          d='M12.54 18H2.727A2.73 2.73 0 010 15.273v-.353a.346.346 0 01.346-.346h14.575a.346.346 0 01.346.346v.352A2.73 2.73 0 0112.54 18zM.689 15.267v.006a2.037 2.037 0 002.035 2.035h9.813a2.037 2.037 0 002.035-2.035v-.006z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12578'>
        <path
          data-name='Path 21750'
          d='M12.346 13.536a.346.346 0 01-.224-.61 6.941 6.941 0 10-8.977 0 .346.346 0 11-.448.528 7.634 7.634 0 119.873 0 .345.345 0 01-.224.082z'
          fill='currentColor'
        />
      </g>
      <g data-name='Group 12581'>
        <g data-name='Group 12579'>
          <path
            data-name='Path 21751'
            d='M6.071 7.55a.346.346 0 01-.159-.654l3.125-1.609a.346.346 0 11.317.615L6.229 7.511a.345.345 0 01-.158.039z'
            fill='currentColor'
          />
        </g>
        <g data-name='Group 12580'>
          <path
            data-name='Path 21752'
            d='M6.071 10.018a.346.346 0 01-.159-.654l3.125-1.609a.346.346 0 11.317.615L6.229 9.979a.345.345 0 01-.158.039z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};
