/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const DashboardIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.2 16.2' {...props}>
    <g data-name='menu (1)'>
      <g data-name='Group 36282'>
        <path
          data-name='Path 22647'
          d='M6.433 5.433H1.1a1 1 0 01-1-1V1.1a1 1 0 011-1h5.333a1 1 0 011 1v3.333a1 1 0 01-1 1zM1.1.767a.334.334 0 00-.333.333v3.333a.334.334 0 00.333.334h5.333a.334.334 0 00.333-.333V1.1a.334.334 0 00-.333-.333z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='.2'
        />
      </g>
      <g data-name='Group 36283'>
        <path
          data-name='Path 22648'
          d='M6.433 16.1H1.1a1 1 0 01-1-1V7.767a1 1 0 011-1h5.333a1 1 0 011 1V15.1a1 1 0 01-1 1zM1.1 7.434a.334.334 0 00-.333.333V15.1a.334.334 0 00.333.334h5.333a.334.334 0 00.333-.333V7.767a.334.334 0 00-.333-.333z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='.2'
        />
      </g>
      <g data-name='Group 36284'>
        <path
          data-name='Path 22649'
          d='M15.1 16.1H9.767a1 1 0 01-1-1v-3.333a1 1 0 011-1H15.1a1 1 0 011 1V15.1a1 1 0 01-1 1zm-5.333-4.666a.334.334 0 00-.333.333V15.1a.334.334 0 00.333.333H15.1a.334.334 0 00.333-.333v-3.333a.334.334 0 00-.333-.333z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='.2'
        />
      </g>
      <g data-name='Group 36285'>
        <path
          data-name='Path 22650'
          d='M15.1 9.433H9.767a1 1 0 01-1-1V1.1a1 1 0 011-1H15.1a1 1 0 011 1v7.333a1 1 0 01-1 1zM9.767.767a.334.334 0 00-.333.333v7.333a.334.334 0 00.333.334H15.1a.334.334 0 00.333-.333V1.1A.334.334 0 0015.1.767z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='.2'
        />
      </g>
    </g>
  </svg>
);
