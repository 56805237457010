import { siteSettings } from 'src/settings/site.settings';
import RadioCard from './radio-card/radio-card';
import { useTranslation } from 'react-i18next';

interface Props {
  register: any;
  onSelect?: () => void;
}

export const SiteLayoutCarousel = ({ register, onSelect }: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <div className='w-3/4'>
      <div className='grid md:grid-cols-2 sm:grid-cols-1'>
        {/* <Swiper
          // install Swiper modules
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          dir='rtl'
          pagination={{ clickable: false }}
          scrollbar={{ draggable: true }}
        > */}
        {siteSettings.siteLayoutCards?.map((siteLayout, index) => {
          return (
            // <SwiperSlide className='w-3/4 pt-35'>
            <RadioCard
              key={`product-card-${index}`}
              {...register?.('layoutType')}
              label={t(siteLayout.label)}
              onSelected={() => onSelect?.()}
              value={siteLayout.value}
              src={siteLayout.img}
              id={`product-card-${index}`}
            />
            // </SwiperSlide>
          );
        })}
        {/* </Swiper> */}
      </div>
    </div>
  );
};
