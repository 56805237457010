import { getIcon } from 'src/utils/get-icon';
import * as sidebarIcons from 'src/components/icons/sidebar';
import { useUI } from 'src/contexts/ui.context';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { setProductSearch } from 'src/store/utilData/actionTypes';

const SidebarItem = ({ href, icon, label, key, stepName = '', isDisabled = false }: any) => {
  const { closeSidebar } = useUI();
  const dispatch = useAppDispatch();

  return (
    <div className={stepName}>
      <Link
        key={key}
        to={isDisabled ? '#' : href}
        onClick={() => {
          dispatch(setProductSearch({ categoryId: '', text: '', page: 1 }));
        }}
        className={`${stepName} flex w-full items-center text-base text-body-dark text-start focus:text-accent`}
      >
        {getIcon({
          iconList: sidebarIcons,
          iconName: icon,
          className: 'w-5 h-5 me-4',
        })}
        <span onClick={() => closeSidebar()}>{label}</span>
      </Link>
    </div>
  );
};

export default SidebarItem;
