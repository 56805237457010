import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import shop, { State as ShopState } from './shop/reducers';
import util, { State as utilDataState } from './utilData/reducers';
import session, { State as SessionState } from './session/reducers';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['shop', 'session', 'util'],
};

export interface RootState {
  shop: ShopState;
  session: SessionState;
  util: utilDataState;
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers<RootState>({
    shop,
    session,
    util,
  }),
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
