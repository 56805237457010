import cn from 'classnames';
import { CloseIcon } from 'src/components/icons/close-icon';

type AlertProps = {
  message?: string | undefined | null | JSX.Element;
  variant?:
    | 'info'
    | 'warning'
    | 'error'
    | 'success'
    | 'infoOutline'
    | 'warningOutline'
    | 'errorOutline'
    | 'successOutline';
  closeable?: boolean;
  onClose?: () => void;
  className?: string;
  children?: any;
};

const variantClasses = {
  info: 'bg-blue-100 text-blue-600',
  warning: 'bg-yellow-100 text-yellow-400',
  error: 'bg-red-100 text-red-500',
  success: 'bg-green-100 text-accent',
  infoOutline: 'border border-blue-200 text-blue-600',
  warningOutline: 'border border-yellow-200 text-yellow-400',
  errorOutline: 'border border-red-200 text-red-600',
  successOutline: 'border border-green-200 text-green-600',
};

const Alert = ({
  message = '',
  closeable = false,
  variant = 'info',
  className,
  children,
  onClose,
}: AlertProps) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between relative rounded py-4 px-5 shadow-sm',
        variantClasses[variant],
        className,
      )}
      role='alert'
    >
      {children}
      <p className='text-sm'>{message}</p>
      {closeable && (
        <button
          data-dismiss='alert'
          aria-label='Close'
          onClick={onClose}
          title='Close alert'
          className='-me-0.5 -mt-3 flex items-center justify-center rounded-full flex-shrink-0 w-6 h-6 text-red-500 absolute end-2 top-1/2 transition-colors duration-200 hover:bg-gray-300 hover:bg-opacity-25 focus:outline-none focus:bg-gray-300 focus:bg-opacity-25'
        >
          <span aria-hidden='true'>
            <CloseIcon className='w-3 h-3' />
          </span>
        </button>
      )}
    </div>
  );
};

export default Alert;
