import * as yup from 'yup';
export const categoryValidationSchema = yup.object().shape({
  categoryNameWithTeanslations: yup
    .array()
    .min(1, 'required')
    .of(
      yup.object().shape({
        value: yup.string().required('required'),
        Language: yup.string().required('required'),
      }),
    )
    .required('required'),
});
