/* eslint-disable @typescript-eslint/no-redeclare */
import {
  APIResponse,
  CreateShopInput,
  PlanDetailsResponse,
  PlansDetailsResponse,
  ValidateDomainResponse,
} from '../../ts-types/custom.types';
import { combineReducers } from 'redux';
import { Action } from './actionTypes';

// States' definition
const initialState: UtilData = {
  planDetailsResponse: undefined,
  planDetailsByIdResponse: {
    data: {
      packageDescription: '',
      active: false,
      packageTitle: '',
      durationMonths: 0,
      maxNumberOfProducts: 0,
      packageId: '',
      price: 0,
    },
    isFetching: false,
  },
  updatePlanDetailsResponse: { isFetching: false },
  error: '',
  shopForCreateData: undefined,
  validateDomainResponse: {
    isFetching: false,
    suggestedDomain: [],
    validDomain: false,
  },
  recurrencePayment: true,
  display: 'list',
  searchTerm: { categoryId: '', text: '', page: 1 },
};

export interface UtilData {
  shopForCreateData?: CreateShopInput;
  upgradeShop?: boolean;
  recurrencePayment?: boolean;
  planDetailsByIdResponse: PlanDetailsResponse;
  planDetailsResponse?: PlansDetailsResponse | undefined;
  updatePlanDetailsResponse: APIResponse;
  validateDomainResponse?: ValidateDomainResponse;
  error?: string;
  display?: string;
  searchTerm: { categoryId: string; text: string; page: number };
}

export interface State {
  UtilData?: UtilData;
}
const UtilData = (state = initialState, action: Action): UtilData => {
  switch (action.type) {
    case 'SET_PLANS':
      return { ...state, planDetailsResponse: action.response };
    case 'SET_SHOP_DATA_FOR_CREATE':
      return { ...state, shopForCreateData: action.shopData };
    case 'SET_UPGRADE_PACKAGE':
      return { ...state, upgradeShop: action.upgrade };
    case 'SET_PLAN_DETAILS_BY_ID_RESPONSE':
      return { ...state, planDetailsByIdResponse: action.response };
    case 'SET_UPDATE_PLANS':
      return { ...state, updatePlanDetailsResponse: action.response };
    case 'SET_VALIDATE_DOMAIN_RESPONSE':
      return { ...state, validateDomainResponse: action.response };
    case 'SET_RECURRENCE':
      return { ...state, recurrencePayment: action.recurrence };
    case 'SET_DISPLAY':
      return { ...state, display: action.display };
    case 'SET_PRODUCT_SEARCH':
      return { ...state, searchTerm: action.searchTerm };
    case 'SET_ERROR':
      return { ...state, error: action.error };
    case 'CLEAR_STATE':
      return initialState;
    default:
      return state;
  }
};
export default combineReducers<State>({ UtilData });
