/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SHOP_STATUS } from 'src/ts-types';
import { ShopDetailsType } from 'src/ts-types/custom.types';
import { useAppSelector } from 'src/utils/reduxHooks';
import Notification from 'src/components/ui/notification';

export const withPermission = (Component: FC<any>) => (props: any) => {
  const { t }: any = useTranslation<any>();

  const shopDetails: ShopDetailsType = useAppSelector(
    (state) => state.shop.shopDetails?.activeShopDetailsResponse?.data,
  ) || { companyDetails: {} };
  const { shopCurrentStatus } = shopDetails;
  return shopCurrentStatus !== SHOP_STATUS.RUNNING && shopCurrentStatus !== SHOP_STATUS.TRAILER ? (
    <Notification variant='warning'> {t('SHOP_PERMISSION_ALERT')}</Notification>
  ) : (
    <Component {...props} />
  );
};
