import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationCard from 'src/components/common/confirmation-card';
import { Refund } from 'src/components/icons/refund';
import Input from 'src/components/ui/input';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { refundAction, setFetchOrderById } from 'src/store/shop/actions';
import { RefundPayload } from 'src/ts-types/custom.types';
import { useAppDispatch } from 'src/utils/reduxHooks';

const RefundView = () => {
  const { t }: any = useTranslation<any>();
  const { data } = useModalState();
  const { amount } = data;
  const [refundValue, setRefundValue] = useState<number>(amount);
  const { closeModal } = useModalAction();
  const dispatch = useAppDispatch();

  const handleRefund = () => {
    const { amount, orderId } = data;
    const refundPayload: RefundPayload = {
      amount: amount,
      orderId: orderId,
      description: `refund for order id ${orderId}`,
    };
    dispatch(refundAction(refundPayload)).then((res: any) => {
      dispatch(setFetchOrderById(orderId));
    });
    closeModal();
  };

  return (
    <ConfirmationCard
      icon={<Refund className='mt-4 w-12 h-12 m-auto text-accent' color='bg-accent' />}
      onCancel={closeModal}
      onDelete={handleRefund}
      deleteBtnLoading={false}
      deleteBtnClassName='bg-accent'
      cancelBtnClassName='bg-red-400'
      description={t('refund-description')}
    >
      <Input
        label={t('text-refund')}
        type='text'
        variant='outline'
        name={''}
        value={refundValue}
        onChange={(e: any) => setRefundValue(e.target.value)}
      />
    </ConfirmationCard>
  );
};

export default RefundView;
