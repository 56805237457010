/* eslint-disable @typescript-eslint/no-empty-function */
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import ImportCsv from '../ui/import-csv';
import * as XLSX from 'xlsx';
import { createProductList } from 'src/store/shop/shopsAPIActions';
import { RootState } from 'src/store';
import { APIResponse } from 'src/ts-types/custom.types';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'src/utils/constants';

async function convertCSVtoJSON(file: File): Promise<any[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      const products = jsonData.map((item: any) => ({
        productCode: item.productCode,
        productInfoWithTranslations: [
          {
            language: 'he',
            name: item.productName,
          },
        ],
        variantDetailsList: [
          {
            price: parseFloat(item.productPrice),
            countInStock: 1000,
            active: true,
            variantsOptionsCombineList: [],
            discountPrice: 0,
            mainImage: item.productImage,
          },
        ],
        discountPrice: 0,
        categoryId: item.categoryId,
        productName: item.productName,
        price: parseFloat(item.productPrice),
        mainImage: item.productImage,
      }));
      resolve(products);
    };

    reader.onerror = (event: ProgressEvent<FileReader>) => {
      reject(event.target?.error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export default function ImportProducts({ handleClose }: any) {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const updateProductsResponse = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.updateProductsResponse ?? { isFetching: false },
  ) as APIResponse;

  const handleDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    try {
      const jsonArray = await convertCSVtoJSON(file);
      dispatch(createProductList(jsonArray))
        .then(() => {
          handleClose();
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        })
        .catch(() => {
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
          handleClose();
        });
    } catch (error) {
      handleClose();
    }
  };

  return (
    <ImportCsv
      onDrop={handleDrop}
      loading={updateProductsResponse?.isFetching}
      title={t('text-import-products')}
    />
  );
}
