import * as yup from 'yup';

export const pluginDetailsValidator = yup.object().shape({
  plugInTitleWithTranslations: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        Language: yup.string(),
      }),
    )
    .compact((v) => !v.checked)
    .required('required'),
  plugInDescriptionWithTranslations: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        Language: yup.string(),
      }),
    )
    .compact((v) => !v.checked)
    .required('required'),
});
