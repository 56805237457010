import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { sendContactUs } from 'src/store/shop/shopsAPIActions';
import { AdminContactUsPayload } from 'src/ts-types/custom.types';
import { RootState } from 'src/store';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import { setAdminContactUsResponse } from 'src/store/shop/actionTypes';
import { TOAST_MESSAGE } from 'src/utils/constants';

const contactFormSchema = yup.object().shape({
  name: yup.string().required('error-name-required'),
  // phoneNumber: yup
  //   .string()
  //   .required('required')
  //   .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'error-phone-required'),
  emailAddress: yup.string().email('error-email-format').required('error-email-required'),
  messageTitle: yup.string().required('error-subject-required'),
  messageText: yup.string().required('error-description-required'),
});

const AdminContactPage = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const contactUsResponse = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.contactUsResponse,
  ) ?? { isFetching: false, success: false };
  const { isFetching, success } = contactUsResponse ?? { isFetching: false };
  const methods = useForm<AdminContactUsPayload>({
    resolver: yupResolver(contactFormSchema),
    mode: 'onBlur',
    // @ts-ignore
    defaultValues: {},
  });

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    if (success) {
      toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
      history.push(ROUTES.SHOPS);
      dispatch(setAdminContactUsResponse({ isFetching: false, success: false }));
    }
  }, [success]);

  const onSubmit = (values: AdminContactUsPayload) => {
    dispatch(sendContactUs(values));
    console.log('submit');
  };

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
    <!doctype html>
    <html class="no-js" lang="en">
    
    <head>
        <meta charset="utf-8">
    
        <!--====== Title ======-->
        <title>NGO - Shops</title>
    
        <meta name="description" content="">
        <meta name="viewport" content="width=device-width, initial-scale=1">
    
        <!--====== Favicon Icon ======-->
        <link rel="shortcut icon" href="/image/newIconeOnly.svg" type="image/png">
    
        <!--====== Animate CSS ======-->
        <link rel="stylesheet" href="/css/animate.css">
    
        <!--====== Slick CSS ======-->
        <link rel="stylesheet" href="/css/tiny-slider.css">
    
        <!--====== Line Icons CSS ======-->
        <link rel="stylesheet" href="/fonts/lineicons/font-css/LineIcons.css">
    
        <!--====== Tailwind CSS ======-->
        <link rel="stylesheet" href="/css/tailwindcss.css">
        <!-- Google tag (gtag.js) -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WMLKT1F8K0">
        </script>
    
        <script>
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
    
            gtag('config', 'G-WMLKT1F8K0');
        </script>
        <!-- Meta Pixel Code -->
        <script>
            !function (f, b, e, v, n, t, s) {
                if (f.fbq) return; n = f.fbq = function () {
                    n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                n.queue = []; t = b.createElement(e); t.async = !0;
                t.src = v; s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            }(window, document, 'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1039916637054411');
            fbq('track', 'PageView');
        </script>
        <noscript><img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=1039916637054411&ev=PageView&noscript=1" /></noscript>
        <!-- End Meta Pixel Code -->
        <style>
            .float {
                position: fixed;
                width: 60px;
                height: 60px;
                bottom: 40px;
                right: 40px;
                background-color: #25d366;
                color: #FFF;
                border-radius: 50px;
                text-align: center;
                font-size: 30px;
                box-shadow: 2px 2px 3px #999;
                z-index: 100;
            }
    
            .my-float {
                margin-top: 16px;
            }
    
            @media only screen and (max-width: 767px) {
    
                /* CSS rules here will only apply to screens smaller than 768px wide */
                .marginRight {
                    /* Your specific styles for mobile view */
                    margin-right: 9px;
                }
            }
        </style>
    </head>
    
    <body>
        <!--[if IE]>
        <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
      <![endif]-->
    
    
        <!--====== PRELOADER PART START ======-->
    
        <div class="preloader">
            <div class="loader">
                <div class="ytp-spinner">
                    <div class="ytp-spinner-container">
                        <div class="ytp-spinner-rotator">
                            <div class="ytp-spinner-left">
                                <div class="ytp-spinner-circle"></div>
                            </div>
                            <div class="ytp-spinner-right">
                                <div class="ytp-spinner-circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!--====== PRELOADER PART ENDS ======-->
    
        <!--====== HEADER PART START ======-->
        <section class="header_area">
            <div class="navbar-area bg-white">
                <div class="container relative">
                    <div class="row items-center rtl">
                        <div class="w-full">
                            <nav class="flex items-center justify-between py-4 navbar navbar-expand-lg">
                                <a class="navbar-brand" href="index.html">
                                    <img src="/image/ngoshops2.svg" alt="Logo" width="80px">
                                </a>
                                <button class="block navbar-toggler focus:outline-none lg:hidden" type="button"
                                    data-toggle="collapse" data-target="#navbarOne" aria-controls="navbarOne"
                                    aria-expanded="false" aria-label="Toggle navigation" onclick="myFunction()">
                                    <span class="toggler-icon"></span>
                                    <span class="toggler-icon"></span>
                                    <span class="toggler-icon"></span>
                                </button>
                                <div class="rtl absolute left-0 z-20 hidden w-full px-5 py-3 duration-300 bg-white lg:w-auto collapse navbar-collapse lg:block top-full mt-full lg:static lg:bg-transparent shadow lg:shadow-none"
                                    id="navbarOne">
                                    <ul id="nav"
                                        class="items-center content-start mr-auto lg:justify-end navbar-nav lg:flex">
                                        <li class="nav-item ml-5 lg:ml-11">
                                            <a class="page-scroll active" href="#home">בית</a>
                                        </li>
                                        <li class="nav-item ml-5 lg:ml-11">
                                            <a class="page-scroll" href="#services">שירותים</a>
                                        </li>
                                        <li class="nav-item ml-5 lg:ml-11">
                                            <a class="page-scroll" href="#work">פרויקטים</a>
                                        </li>
                                        <li class="nav-item ml-5 lg:ml-11">
                                            <a class="page-scroll" href="#pricing">מחירים</a>
                                        </li>
                                        <li class="nav-item ml-5 lg:ml-11">
                                            <a class="page-scroll" href="#contact"> צור קשר </a>
                                        </li>
                                    </ul>
                                </div> <!-- navbar collapse -->
                            </nav> <!-- navbar -->
                        </div>
                    </div> <!-- row -->
                </div> <!-- container -->
            </div> <!-- header navbar -->
    
            <div id="home" class="header_hero bg-gray relative z-10 overflow-hidden lg:flex items-center container">
                <div class="rtl">
                    <div class="row">
                        <div class="w-full lg:w-1/2">
                            <div class="header_hero_content pt-150 lg:pt-0 rtl">
                                <h2
                                    class="hero_title text-2xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl font-extrabold">
                                    הפוך את האינטרנט לשותף עסקי שמנצח בקלות ובמהירות
                                    <span class="text-theme-color">NGO</span>
                                </h2>
                                <p class="mt-8 ">
                                    הפתרון הפשוט והיעיל ביותר לבניית אתרי מסחר אלקטרוני בשלוש שלבים פשוטים
                                    עם ממשק הניהול המקיף והאתר המוכן לשימוש.
                                </p>
                                <div class="hero_btn mt-10 float-left">
                                    <a class="main-btn" target='_blank' onclick="startClick()"
                                        href="https://shops-admin.com/acrShop">בוא
                                        נתחיל</a>
                                </div>
                            </div> <!-- header hero content -->
                        </div>
                    </div> <!-- row -->
                </div> <!-- container -->
                <div class="hero_shape shape_1">
                    <img src="/image/shape-1.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_2">
                    <img src="/image/shape-2.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_3">
                    <img src="/image/shape-3.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_4">
                    <img src="/image/shape-4.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_6">
                    <img src="/image/shape-1.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_7">
                    <img src="/image/shape-4.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_8">
                    <img src="/image/shape-3.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_9">
                    <img src="/image/shape-2.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_10">
                    <img src="/image/shape-4.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_11">
                    <img src="/image/shape-1.svg" alt="shape">
                </div><!-- hero shape -->
                <div class="hero_shape shape_12">
                    <img src="/image/shape-2.svg" alt="shape">
                </div><!-- hero shape -->
    
                <!-- <div class="header_shape hidden lg:block"></div> -->
    
                <!-- <div class="header_shape hidden lg:block"></div> -->
    
                <div class="header_image flex items-center">
                    <div class="image 2xl:pl-25">
                        <img src="/image/Cart-cuate.png" alt="Header Image">
                    </div>
                </div> <!-- header image -->
                <!-- <div class="header_image flex items-center">
                    <div class="image 2xl:pl-25">
                        <img src="/image/header-image.svg" alt="Header Image">
                    </div>
                </div> -->
            </div> <!-- header hero -->
        </section>
    
        <!--====== HEADER PART ENDS ======-->
    
        <!--====== SERVICES PART START ======-->
        <!--====== SERVICES PART ENDS ======-->
    
        <!--====== ABOUT PART START ======-->
    
        <section id="why" class="about_area pt-60 relative ">
            <div class="container">
                <div class="row justify-start">
                    <div class="w-full lg:w-1/2">
                        <div class="about_content mx-4 pt-11 lg:pt-15 lg:pb-15 rtl">
                            <div class="section_title pb-9">
                                <h5 class="sub_title">למה אנחנו</h5>
                                <h4 class="main_title">המטרה שלך היא ההישג שלנו</h4>
                            </div> <!-- section title -->
                            <p>
                                בזמן שאנחנו נדאג לבניית האתר
                                ולניהול המחשבות. תרכוש את הניסיון והמומחיות שלנו ותוכל להשיג את השיא של העסק שלך.
                            </p>
                            <ul class="about_list pt-3">
                                <li class="flex mt-5">
                                    <div class="about_check">
                                        <i class="lni lni-checkmark-circle"></i>
                                    </div>
                                    <div class="about_list_content pl-5 pr-2">
                                        <p>
                                            איכות ומקצועיות: אנחנו מספקים פתרונות מתקדמים ומקצועיים ברמה הגבוהה ביותר.
                                        </p>
                                    </div>
                                </li>
                                <li class="flex mt-5">
                                    <div class="about_check">
                                        <i class="lni lni-checkmark-circle"></i>
                                    </div>
                                    <div class="about_list_content pl-5 pr-2">
                                        <p> תוכלו לנהל את המלאי, לעקוב אחרי הזמנות, ולשלוט בכל פרט של העסק.
                                        </p>
                                    </div>
                                </li>
                                <li class="flex mt-5">
                                    <div class="about_check">
                                        <i class="lni lni-checkmark-circle"></i>
                                    </div>
                                    <div class="about_list_content pl-5 pr-2">
                                        <p>
                                            צוות
                                            התמיכה שלנו יהיה תמיד כאן עבורכם כדי לספק מענה מקצועי ולעזור בכל שאלה או בעיה
                                            שתהיה לכם.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div> <!-- about content -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
            <div class="about_image items-end justify-end">
                <div class="image lg:pl-[180px] pb-20">
                    <img src="/image/about-us-1.svg" class="max-w-[300px] " alt="about">
                </div>
            </div> <!-- about image -->
        </section>
    
        <!--====== ABOUT PART ENDS ======-->
    
        <!--====== SERVICES PART START ======-->
    
        <section id="services" class="services_area pb-80">
            <div class="container rtl">
                <div class="row justify-center pt-40">
                    <div class="w-full lg:w-1/2">
                        <div class="section_title text-center pb-6">
                            <h5 class="sub_title">מה אנחנו עושים</h5>
                            <h4 class="main_title">השירותים שלנו</h4>
                        </div> <!-- section title -->
                    </div>
                </div> <!-- row -->
                <div class="row justify-center rtl">
                    <div class="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                        <div class="single_services text-center mt-8 mx-3">
                            <div class="services_icon">
                                <svg fill="#37C0AF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="80px" height="80px"
                                    viewBox="0 0 396.494 396.494" xml:space="preserve">
                                    <g>
                                        <path d="M304.42,330.901h-43.219c-1.56,0-2.834-1.273-2.834-2.834v-10.52c0-1.559-1.275-2.834-2.834-2.834h-60.475
                                   c-1.56,0-2.835,1.275-2.835,2.834v10.52c0,1.561-1.275,2.834-2.835,2.834h-32.491c-1.56,0-2.835,1.275-2.835,2.834v12.17
                                   c0,1.559,1.275,2.834,2.835,2.834h147.521c1.559,0,2.834-1.275,2.834-2.834v-12.17C307.254,332.179,305.979,330.901,304.42,330.901
                                   z" />
                                        <g>
                                            <path d="M377.032,46.368h-308.4c-10.73,0-19.459,8.73-19.459,19.462c0,0,0,37.109,0,50.761c0,6.711-0.498,7.66,4.004,7.66
                                       c2.391,0,5.039,0,7.72,0c3.778,0,3.003-0.949,3.003-7.785c0-13.621,0-50.636,0-50.636c0-2.565,2.168-4.733,4.732-4.733h308.4
                                       c2.563,0,4.731,2.168,4.731,4.733v189.999h-219.87c0,0-7.749-0.014-7.749,10.006c0,5.941,0,17.823,0,23.766
                                       c0,10.006,8,10.006,8,10.006h214.888c10.729,0,19.461-8.729,19.461-19.459V65.83C396.493,55.099,387.763,46.368,377.032,46.368z
                                        M222.833,282.804c-4.845,0-8.771-3.926-8.771-8.768s3.926-8.77,8.771-8.77c4.84,0,8.767,3.928,8.767,8.77
                                       S227.673,282.804,222.833,282.804z" />
                                            <path d="M124.125,141.633H12.559C5.632,141.633,0,147.266,0,154.193v183.372c0,6.926,5.632,12.561,12.559,12.561h111.566
                                       c6.923,0,12.559-5.635,12.559-12.561V154.193C136.684,147.266,131.048,141.633,124.125,141.633z M54.58,154.443h27.523
                                       c1.313,0,2.38,1.064,2.38,2.381c0,1.314-1.066,2.379-2.38,2.379H54.58c-1.315,0-2.379-1.065-2.379-2.379
                                       C52.201,155.507,53.265,154.443,54.58,154.443z M68.175,341.304c-3.59,0-6.5-2.91-6.5-6.5s2.91-6.5,6.5-6.5s6.5,2.91,6.5,6.5
                                       S71.765,341.304,68.175,341.304z M125.893,322.2c0,1.676-1.342,3.047-2.98,3.047H13.774c-1.639,0-2.98-1.371-2.98-3.047V169.825
                                       c0-1.676,1.342-3.046,2.98-3.046h109.139c1.639,0,2.98,1.371,2.98,3.046V322.2z" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div class="services_content mt-5 xl:mt-10">
                                <h3
                                    class="services_title text-black font-semibold text-xl md:text-2xl lg:text-xl xl:text-3xl">
                                    עיצוב</h3>
                                <p class="mt-4">
                                    צור אתרי אינטרנט שנראים נהדר בכל המכשירים, ממחשבים שולחניים
                                    ועד לנייד
                                </p>
                            </div>
                        </div> <!-- single services -->
                    </div>
    
                    <div class="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                        <div class="single_services text-center mt-8 mx-3">
                            <div class="services_icon">
                                <svg width="80px" height="80px" viewBox="-2 0 22 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 6V16C17 20 16 21 12 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H12C16 1 17 2 17 6Z"
                                        stroke="#37C0AF" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path opacity="0.4" d="M11 4.5H7" stroke="#37C0AF" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path opacity="0.4"
                                        d="M9.0002 18.1C9.85624 18.1 10.5502 17.406 10.5502 16.55C10.5502 15.694 9.85624 15 9.0002 15C8.14415 15 7.4502 15.694 7.4502 16.55C7.4502 17.406 8.14415 18.1 9.0002 18.1Z"
                                        stroke="#37C0AF" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div class="services_content mt-5 xl:mt-10">
                                <h3
                                    class="services_title text-black font-semibold text-xl md:text-2xl lg:text-xl xl:text-3xl">
                                    תמיכה בסלולר</h3>
                                <p class="mt-4">
                                    ודא שהאתר שלך מותאם למכשירים ניידים ומגיע לקהל רחב יותר של
                                    משתמשים בסמארטפונים ובטאבלטים
                                </p>
                            </div>
                        </div> <!-- single services -->
                    </div>
    
                    <div class="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                        <div class="single_services text-center mt-8 mx-3">
                            <div class="services_icon">
                                <div class="services_icon">
                                    <svg width="80px" height="80px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                        fill="none">
                                        <path stroke="#37C0AF" stroke-width="2"
                                            d="M4 5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5ZM14 5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V5ZM4 16a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3ZM14 13a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-6Z" />
                                    </svg>
                                </div>
                                <div class="services_content mt-5 xl:mt-10">
                                    <h3
                                        class="services_title text-black font-semibold text-xl md:text-2xl lg:text-xl xl:text-3xl">
                                        ניהול קל של המוצר</h3>
                                    <p class="mt-4">
                                        הוסף, עדכן ושנה מוצרים באתר שלך ללא מאמץ בעזרת מערכת ניהול
                                        המוצרים הידידותית למשתמש שלנו
                                    </p>
                                </div>
                            </div> <!-- single services -->
                        </div>
                    </div>
                    <div class="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                        <div class="single_services text-center mt-8 mx-3">
                            <div class="services_icon">
                                <svg height="80px" width="80px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                                    <style type="text/css">
                                        .st0 {
                                            fill: #37C0AF;
                                        }
                                    </style>
                                    <g>
                                        <path class="st0" d="M435.244,391.19c32.805-41.18,52.518-93.432,52.503-150.118c0.015-66.486-27.029-126.93-70.608-170.464
        C373.593,27.021,313.149-0.016,246.667,0C180.181-0.016,119.742,27.021,76.2,70.608C32.616,114.142,5.576,174.586,5.591,241.072
        C5.576,307.55,32.616,367.994,76.2,411.536c43.542,43.587,103.982,70.624,170.468,70.608c50.948,0.016,98.312-15.91,137.281-42.94
        L456.756,512l49.653-49.653L435.244,391.19z M378.508,372.916c-33.826,33.773-80.267,54.591-131.841,54.606
        c-51.574-0.015-98.015-20.833-131.845-54.606c-33.784-33.834-54.595-80.271-54.61-131.844
        c0.015-51.574,20.826-98.011,54.61-131.844c33.83-33.78,80.271-54.591,131.845-54.606c51.574,0.015,98.014,20.826,131.841,54.606
        c33.791,33.833,54.602,80.271,54.617,131.844C433.11,292.646,412.299,339.082,378.508,372.916z" />
                                        <path class="st0" d="M155.926,235.105l-5.696-0.792c-14.558-2.058-19.782-7.117-19.782-14.555c0-8.389,6.012-14.402,16.932-14.402
        c9.018,0,17.088,2.691,25.794,8.39c0.789,0.473,1.582,0.313,2.214-0.48l7.597-11.704c0.633-0.953,0.473-1.585-0.32-2.218
        c-8.542-6.484-21.359-10.76-34.809-10.76c-22.156,0-36.398,12.977-36.398,32.44c0,17.877,11.712,27.852,32.44,30.694l5.696,0.792
        c14.875,2.058,19.622,7.125,19.622,14.875c0,9.022-7.437,15.507-20.255,15.507c-12.024,0-22.312-5.852-29.273-11.392
        c-0.632-0.632-1.581-0.632-2.214,0.16l-9.811,11.392c-0.632,0.792-0.316,1.737,0.316,2.378
        c8.386,7.909,23.733,14.234,39.561,14.234c26.739,0,40.509-14.234,40.509-33.384C188.049,247.762,176.652,237.956,155.926,235.105z
        " />
                                        <path class="st0" d="M275.566,190.322h-67.408c-0.949,0-1.581,0.632-1.581,1.585v104.435c0,0.953,0.632,1.585,1.581,1.585h67.408
        c0.953,0,1.585-0.632,1.585-1.585v-13.602c0-0.952-0.632-1.585-1.585-1.585h-49.368l-0.949-0.953V252.99l0.949-0.952h41.614
        c0.953,0,1.586-0.633,1.586-1.586v-13.609c0-0.945-0.633-1.577-1.586-1.577h-41.614l-0.949-0.953v-26.266l0.949-0.945h49.368
        c0.953,0,1.585-0.64,1.585-1.585v-13.61C277.151,190.954,276.519,190.322,275.566,190.322z" />
                                        <path class="st0"
                                            d="M334.448,188.584c-18.514,0-32.439,8.863-37.975,25.954c-2.218,6.645-2.85,12.658-2.85,29.589
        c0,16.932,0.632,22.944,2.85,29.589c5.536,17.092,19.462,25.947,37.975,25.947c18.677,0,32.591-8.855,38.139-25.947
        c2.21-6.645,2.85-12.657,2.85-29.589c0-16.932-0.64-22.944-2.85-29.589C367.039,197.447,353.124,188.584,334.448,188.584z
         M354.542,268.81c-3.003,8.702-9.327,14.082-20.095,14.082c-10.6,0-16.932-5.38-19.938-14.082
        c-1.265-3.955-1.898-9.495-1.898-24.682c0-15.355,0.633-20.734,1.898-24.69c3.007-8.702,9.339-14.082,19.938-14.082
        c10.768,0,17.092,5.38,20.095,14.082c1.265,3.955,1.904,9.335,1.904,24.69C356.447,259.315,355.807,264.854,354.542,268.81z" />
                                    </g>
                                </svg>
                            </div>
                            <div class="services_content mt-5 xl:mt-10">
                                <h3
                                    class="services_title text-black font-semibold text-xl md:text-2xl lg:text-xl xl:text-3xl">
                                    תמיכה בקידום אתרים</h3>
                                <p class="mt-4">
                                    הפלטפורמה שלנו מעוצבת עם תמיכת SEO מובנית, מה שמקל על האתר
                                    שלך לדרג גבוה יותר בתוצאות מנועי החיפוש ולמשוך יותר תנועה אורגנית
                                </p>
                            </div>
                        </div> <!-- single services -->
                    </div>
    
                    <div class="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                        <div class="single_services text-center mt-8 mx-3">
                            <div class="services_icon">
                                <svg fill="#37C0AF" width="80px" height="80px" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z" />
                                </svg>
                            </div>
                            <div class="services_content mt-5 xl:mt-10">
                                <h3
                                    class="services_title text-black font-semibold text-xl md:text-2xl lg:text-xl xl:text-3xl">
                                    שירות לקוחות</h3>
                                <p class="mt-4">
                                    אנו מספקים תמיכת לקוחות מסביב לשעון כדי לסייע לך בכל שאלה,
                                    דאגה או בעיה טכנית שבה אתה עשוי להיתקל בעת השימוש בפלטפורמה שלנו.
                                </p>
    
                            </div>
                        </div> <!-- single services -->
                    </div>
    
                    <div class="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                        <div class="single_services text-center mt-8 mx-3">
                            <div class="services_icon">
                                <svg width="80px" height="80px" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 8L3 12L7 16" stroke="#37C0AF" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M17 8L21 12L17 16" stroke="#37C0AF" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M14 4L9.8589 19.4548" stroke="#37C0AF" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div class="services_content mt-5 xl:mt-10">
                                <h3
                                    class="services_title text-black font-semibold text-xl md:text-2xl lg:text-xl xl:text-3xl">
                                    ביצועים הטובים
                                    ביותר</h3>
                                <p class="mt-4">
                                    הפלטפורמה שלנו בנויה עם הטכנולוגיה העדכנית והמובילה כדי לספק
                                    ביצועים מצוינים ולהבטיח חוויה חלקה ללקוחותינו.
                                </p>
                            </div>
                        </div> <!-- single services -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
        </section>
    
        <!--====== SERVICES PART ENDS ======-->
    
        <!--====== WORK PART START ======-->
    
        <section id="work" class="work_area bg-gray pt-60 pb-80">
            <div class="container">
                <div class="row justify-center">
                    <div class="w-ull lg:w-1/2">
                        <div class="section_title text-center pb-6">
                            <h5 class="sub_title">עבודות</h5>
                            <h4 class="main_title">כמה מהעבודות האחרונות שלנו</h4>
                        </div> <!-- section title -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
            <div class="container-fluid">
                <div class="work_wrapper relative">
                    <div class="row work_active">
                        <div class="w-full lg:w-3/12">
                            <div class="single_item mx-auto">
                                <div class="single_work mx-3">
                                    <div class="work_image">
                                        <img src="/image/example-8.png" alt="work" class="w-full h-[250px]">
                                    </div>
                                    <div class="work_content">
                                        <a href="https://suzi.ngoshops.com/" target='_blank' class="arrow"><i
                                                class="lni lni-chevron-right"></i></a>
                                        <h4 class="work_title text-xl md:text-2xl rtl"><a href="https://suzi.ngoshops.com/"
                                                target='_blank'>חנות טכשיטים</a></h4>
                                        <p class="mt-2 rtl">חנות טכשיטים</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12">
                            <div class="single_item mx-auto">
                                <div class="single_work mx-3">
                                    <div class="work_image">
                                        <img src="/image/example-5.png" alt="work" class="w-full h-[250px]">
                                    </div>
                                    <div class="work_content">
                                        <a href="https://zaki-shaeer.ngoshops.com/" target='_blank' class="arrow"><i
                                                class="lni lni-chevron-right"></i></a>
                                        <h4 class="work_title text-xl md:text-2xl rtl"><a
                                                href="https://zaki-shaeer.ngoshops.com/" target='_blank'>מכולת</a>
                                        </h4>
                                        <p class="mt-2 rtl">מכולת</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12">
                            <div class="single_item mx-auto">
                                <div class="single_work mx-3">
                                    <div class="work_image">
                                        <img src="/image/example-6.png" alt="work" class="w-full h-[250px]">
                                    </div>
                                    <div class="work_content">
                                        <a href="https://bookshoptest1.ngoshops.com/" target='_blank' class="arrow"><i
                                                class="lni lni-chevron-right"></i></a>
                                        <h4 class="work_title text-xl md:text-2xl rtl"><a
                                                href="https://bookshoptest1.ngoshops.com/" target='_blank'>חנות ספרים</a>
                                        </h4>
                                        <p class="mt-2 rtl">חנות ספרים</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12">
                            <div class="single_item mx-auto">
                                <div class="single_work mx-3">
                                    <div class="work_image h-32">
                                        <img src="/image/example-9.png" alt="work" class="w-full">
                                    </div>
                                    <div class="work_content">
                                        <a href="https://optica-tuma.ngoshops.com/" target='_blank' class="arrow"><i
                                                class="lni lni-chevron-right"></i></a>
                                        <h4 class="work_title text-xl md:text-2xl rtl"><a
                                                href="https://optica-tuma.ngoshops.com/" target='_blank'>חנות אופטיקה</a>
                                        </h4>
                                        <p class="mt-2 rtl">חנות אופטיקה</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-3/12">
                            <div class="single_item mx-auto">
                                <div class="single_work mx-3">
                                    <div class="work_image h-32">
                                        <img src="/image/example-7.png" alt="work" class="w-full">
                                    </div>
                                    <div class="work_content">
                                        <a href="https://pharma.ngoshops.com/" target='_blank' class="arrow"><i
                                                class="lni lni-chevron-right"></i></a>
                                        <h4 class="work_title text-xl md:text-2xl rtl"><a
                                                href="https://pharma.ngoshops.com/" target='_blank'>חנות פארם</a>
                                        </h4>
                                        <p class="mt-2 rtl">חנות פארם</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- container -->
        </section>
        <!--====== WORK PART ENDS ======-->
    
        <!--====== PRICING PLAN PART START ======-->
    
        <section id="pricing" class="pricing_area pt-60 pb-80">
            <div class="container rtl">
                <div class="row justify-center">
                    <div class="w-full lg:w-1/2">
                        <div class="section_title text-center pb-6">
                            <h5 class="sub_title">חבילות ומחירים</h5>
                            <h4 class="main_title">בחר את החבילה המתאימה לעסק שלך</h4>
                        </div> <!-- section title -->
                        <h4 class="text-center main_title">
                            <div class="text-center">
                                <div class="hero_btn ">
                                    <button id="monthlyButton" class="main-btn pricing_btn"
                                        onclick="monthlyClick()">חודשי</button>
                                    <button id="yearlyButton" class="main-btn pricing_btn"
                                        onclick="yearlyClick()">שנתי</button>
                                </div>
                            </div>
                        </h4>
                    </div>
                </div> <!-- row -->
                <div class="row">
                    <div class="w-full">
    
    
                        <div class="pricing_content mt-6_area">
                            <div class="tab-content">
                                <div class="active tab-pane" id="monthlyPlanPrice" data-tab-content>
                                    <div class="row justify-center">
                                        <div class="w-full sm:w-9/12 md:w-7/12 lg:w-4/12">
                                            <div class="single_pricing text-center mt-8 mx-3">
                                                <div class="pricing_title relative inline-block">
                                                    <h4 class="title group-hover:text-white">חבילה בסיסית</h4>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="110"
                                                        viewBox="0 0 112 110">
                                                        <path class="services_shape" id="Polygon_15" data-name="Polygon 15"
                                                            d="M51.2,2.329a11,11,0,0,1,9.6,0L96.15,19.478a11,11,0,0,1,5.927,7.466l8.76,38.665a11,11,0,0,1-2.1,9.258l-24.508,30.96A11,11,0,0,1,75.6,110H36.4a11,11,0,0,1-8.625-4.173L3.266,74.867a11,11,0,0,1-2.1-9.258l8.76-38.665a11,11,0,0,1,5.927-7.466Z"
                                                            fill="#aef3db" />
                                                    </svg>
                                                </div>
                                                <div class="pricing_content mt-6">
                                                    <span id="monthlyPlanPriceInner"
                                                        class="pricing_price font-bold text-black text-4xl">180 ש״ח /
                                                        <span class="text-black text-xl">חודש</span></span>
                                                    <span id="yearlyPlanPrice"
                                                        class="pricing_price font-bold text-black text-4xl hidden">1800 ש״ח
                                                        /
                                                        <span class="text-black text-xl">שנה</span></span>
                                                    <div class="section_title pt-4 pb-4">
                                                        <h5 class="sub_title">
                                                            חבילה בסיסית עד 100 מוצרים
                                                        </h5>
                                                    </div>
                                                    <ul class="w-full mt-6 mb-6 text-md text-gray-600 dark:text-gray-100">
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 ">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                height="20" width="24" viewBox="0 0 512 512"
                                                                stroke="currentColor" fill="#3e91ea">
                                                                <path
                                                                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                                            </svg>
                                                            <span class="pr-2">
                                                                חנות עד 100 מוצרים
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                אתר עם תת דומיין של NgoShops
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                לוח בקרה מתקדם לניהול העסק שלך
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                התאים אישית את האתר שלך
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                תבניות ללא הגבלה
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                ניהול לקוחות והזמנות
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                height="20" width="24" viewBox="0 0 512 512"
                                                                stroke="currentColor" fill="#3e91ea">
                                                                <path
                                                                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                                            </svg>
                                                            <span class="pr-2">
                                                                לא כולל תשלום עבור מערכתת הסליקה
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 ">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" class="w-6 h-6 mr-2" fill="red"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                אין אפשרות לשדרג ל דומין אישי
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 marginRight"
                                                            style="margin-right: -4px;">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="52" height="24" class="w-6 h-6 mr-2" fill="red"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
    
                                                            <div class="pr-2 text-right">
                                                                לא תומך בשלחת הודעות ללקוחות או למנהלים על מצב ההזמנה
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <a href="https://shops-admin.com/acrShop?packageId=63c08f52e5be4926f6f85516"
                                                        class="main-btn pricing_btn" target="_blank">בחר חבילה</a>
                                                </div>
                                            </div> <!-- single pricing -->
                                        </div>
    
                                        <div class="w-full sm:w-9/12 md:w-7/12 lg:w-4/12">
                                            <div class="single_pricing text-center mt-8 mx-3 active">
                                                <div class="pricing_title relative inline-block">
                                                    <h4 class="title group-hover:text-white">חבילה סטנדרטית</h4>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="110"
                                                        viewBox="0 0 112 110">
                                                        <path class="services_shape" id="Polygon_15" data-name="Polygon 15"
                                                            d="M51.2,2.329a11,11,0,0,1,9.6,0L96.15,19.478a11,11,0,0,1,5.927,7.466l8.76,38.665a11,11,0,0,1-2.1,9.258l-24.508,30.96A11,11,0,0,1,75.6,110H36.4a11,11,0,0,1-8.625-4.173L3.266,74.867a11,11,0,0,1-2.1-9.258l8.76-38.665a11,11,0,0,1,5.927-7.466Z"
                                                            fill="#aef3db" />
                                                    </svg>
                                                </div>
                                                <div class="pricing_content mt-6">
                                                    <span id="monthlyPlanPriceInner1"
                                                        class="pricing_price font-bold text-black text-4xl">240 ש״ח /
                                                        <span class="text-black text-xl">חודש</span></span>
                                                    <span id="yearlyPlanPrice1"
                                                        class="pricing_price font-bold text-black text-4xl hidden">2400 ש״ח
                                                        /
                                                        <span class="text-black text-xl">שנה</span></span>
                                                    <div class="section_title pt-4 pb-4">
                                                        <h5 class="sub_title">
                                                            חבילה סטנדרטית עד 200 מוצרים
                                                        </h5>
                                                    </div>
                                                    <ul class="w-full mt-6 mb-6 text-md text-gray-600 dark:text-gray-100">
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 ">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                height="20" width="24" viewBox="0 0 512 512"
                                                                stroke="currentColor" fill="#3e91ea">
                                                                <path
                                                                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                                            </svg>
                                                            <span class="pr-2">
                                                                חנות עד 200 מוצרים
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                אתר עם תת דומיין של NgoShops
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                לוח בקרה מתקדם לניהול העסק שלך
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                התאים אישית את האתר שלך
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                תבניות ללא הגבלה
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                ניהול לקוחות והזמנות
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                height="20" width="24" viewBox="0 0 512 512"
                                                                stroke="currentColor" fill="#3e91ea">
                                                                <path
                                                                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                                            </svg>
                                                            <span class="pr-2">
                                                                לא כולל תשלום עבור מערכתת הסליקה
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 ">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                כולל אפשרות לשדרג ל דומין אישי
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 marginRight"
                                                            style="margin-right: -3px;">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="52" height="24" class="w-6 h-6 mr-2" fill="red"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
    
                                                            <div class="pr-2 text-right">
                                                                לא תומך בשלחת הודעות ללקוחות או למנהלים על מצב ההזמנה
                                                            </div>
                                                        </li>
    
                                                    </ul>
                                                    <a href="https://shops-admin.com/acrShop?packageId=63c43d5c7c07a05167111d5a"
                                                        class="main-btn pricing_btn" target="_blank">בחר חבילה</a>
                                                </div>
                                            </div> <!-- single pricing -->
                                        </div>
    
                                        <div class="w-full sm:w-9/12 md:w-7/12 lg:w-4/12">
                                            <div class="single_pricing text-center mt-8 mx-3">
                                                <div class="pricing_title relative inline-block">
                                                    <h4 class="title group-hover:text-white">חבילת פרימיום</h4>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="110"
                                                        viewBox="0 0 112 110">
                                                        <path class="services_shape" id="Polygon_15" data-name="Polygon 15"
                                                            d="M51.2,2.329a11,11,0,0,1,9.6,0L96.15,19.478a11,11,0,0,1,5.927,7.466l8.76,38.665a11,11,0,0,1-2.1,9.258l-24.508,30.96A11,11,0,0,1,75.6,110H36.4a11,11,0,0,1-8.625-4.173L3.266,74.867a11,11,0,0,1-2.1-9.258l8.76-38.665a11,11,0,0,1,5.927-7.466Z"
                                                            fill="#aef3db" />
                                                    </svg>
                                                </div>
                                                <div class="pricing_content mt-6">
                                                    <span id="monthlyPlanPriceInner2"
                                                        class="pricing_price font-bold text-black text-4xl">320 ש״ח /
                                                        <span class="text-black text-xl">חודש</span></span>
                                                    <span id="yearlyPlanPrice2"
                                                        class="pricing_price font-bold text-black text-4xl hidden">3200 ש״ח
                                                        /
                                                        <span class="text-black text-xl">שנה</span></span>
                                                    <div class="section_title pt-4 pb-4">
                                                        <h5 class="sub_title">
                                                            חבילה פרימיום עד 300 מוצרים
                                                        </h5>
                                                    </div>
                                                    <ul class="w-full mt-6 mb-6 text-md text-gray-600 dark:text-gray-100">
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 ">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                height="20" width="24" viewBox="0 0 512 512"
                                                                stroke="currentColor" fill="#3e91ea">
                                                                <path
                                                                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                                            </svg>
                                                            <span class="pr-2">
                                                                חנות עד 300 מוצרים
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                אתר עם תת דומיין של NgoShops
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                לוח בקרה מתקדם לניהול העסק שלך
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                התאים אישית את האתר שלך
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                תבניות ללא הגבלה
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                ניהול לקוחות והזמנות
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center  mt-2 text-right">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                height="20" width="24" viewBox="0 0 512 512"
                                                                stroke="currentColor" fill="#3e91ea">
                                                                <path
                                                                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                                            </svg>
                                                            <span class="pr-2">
                                                                לא כולל תשלום עבור מערכתת הסליקה
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 ">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <span class="pr-2">
                                                                כולל אפשרות לשדרג ל דומין אישי
                                                            </span>
                                                        </li>
                                                        <li class="mb-3 flex items-center opacity-50 mt-2 marginRight"
                                                            style="margin-right: -9px;">
                                                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                width="52" height="24" stroke="currentColor" fill="#10b981"
                                                                viewBox="0 0 1792 1792">
                                                                <path
                                                                    d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                                </path>
                                                            </svg>
                                                            <div class="text-right">
                                                                תומך בשליחת הודעות עדכון לגבי מצה הזמנה
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <a href="https://shops-admin.com/acrShop?packageId=63c43fb27c07a05167111d5b"
                                                        class="main-btn pricing_btn" target="_blank">בחר חבילה</a>
                                                </div>
                                            </div> <!-- single pricing -->
                                        </div>
                                    </div> <!-- row -->
                                </div>
                            </div>
                        </div> <!-- pricing menu -->
                        <div class="pricing_menu mt-4 pb-2">
                            <div class="w-full ">
                                <div class="single_pricing text-center mt-8 mx-3">
                                    לקבלת הצעה עובר חנות מעל 300 מוצרים
                                    <a href="https://api.whatsapp.com/send?phone=+9720524769749&text=הי Ngo Shops "
                                        class="pricing_btn" target="_blank">צור קשר</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
        </section>
    
        <!--====== PRICING PLAN PART ENDS ======-->
    
        <!--====== TEAM PART START ======-->
    
        <!--====== TEAM PART ENDS ======-->
    
        <!--====== BLOG PART START ======-->
    
        <!--====== BLOG PART ENDS ======-->
    
        <!--====== CONTACT PART START ======-->
    
        <section id="contact" class="contact_area relative pb-80">
            <div class="contact_image flex items-center justify-end">
                <div class="image lg:pl-13">
                    <img src="/image/contact-new.svg" alt="about">
                </div>
            </div> <!-- about image -->
    
            <div class="container">
                <div class="row justify-end">
                    <div class="w-full lg:w-1/2 rtl">
                        <div class="contact_wrapper mt-11">
                            <div class="section_title pb-4">
                                <h5 class="sub_title">פרטי קשר</h5>
                                <h4 class="main_title">צור קשר</h4>
                                <p>נשמח לשמוע ממך! אנא מלא את הטופס הבא ונחזור אליך בהקדם האפשרי</p>
                            </div>
                            <div class="contact_form">
                                <form id="contact-form" enctype="text/plain">
                                    <div class="row">
                                        <div class="w-full md:w-1/2">
                                            <div class="mx-3">
                                                <div class="single_form mt-8">
                                                    <input name="name" id="name" type="text" placeholder="שם" class="w-full rounded-md py-4 px-6 border border-solid
                                                         border-body-color hover">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full md:w-1/2">
                                            <div class="mx-3">
                                                <div class="single_form mt-8">
                                                    <input name="emailAddress" id="emailAddress" type="email"
                                                        placeholder="אימיל"
                                                        class="w-full rounded-md py-4 px-6 border border-solid border-body-color">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full md:w-1/2">
                                            <div class="mx-3">
                                                <div class="single_form mt-8">
                                                    <input name="phoneNumber" id="phoneNumber" type="number"
                                                        placeholder="פלאפון"
                                                        class="w-full rounded-md py-4 px-6 border border-solid border-body-color">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full">
                                            <div class="mx-3">
                                                <div class="single_form mt-8">
                                                    <textarea name="messageText" id="messageText" placeholder="הודעה"
                                                        rows="5"
                                                        class="w-full rounded-md py-4 px-6 border border-solid border-body-color resize-none"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="messageS" class="hidden">
                                            <div class="section_title pb-9">
                                                <h5 class="sub_title">תודה שיצרת קשר , נחזור לך בהקדם האפשרי</h5>
                                                <p>
                                            </div>
                                        </div>
                                        <p class="form-message mx-3"></p>
                                        <div class="w-full">
                                            <div class="mx-3">
                                                <div class="single_form mt-8 pb-8">
                                                    <button type="submit" class="main-btn contact-btn">שלח
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- row -->
                                </form>
                            </div> <!-- contact form -->
                        </div> <!-- contact wrapper -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->
        </section>
    
        <!--====== CONTACT PART ENDS ======-->
    
        <!--====== FOOTER PART START ======-->
    
        <footer id="footer" class="footer_area bg-black relative z-10">
            <div class="shape absolute left-0 top-0 opacity-5 h-full overflow-hidden w-1/3">
                <img src="/image/footer-shape-left.png" alt="">
            </div>
            <div class="shape absolute right-0 top-0 opacity-5 h-full overflow-hidden w-1/3">
                <img src="/image/footer-shape-right.png" alt="">
            </div>
            <div class="container rtl">
                <div class="footer_widget pb-80">
                    <div class="row justify-center">
                        <div class="w-full md:w-1/2 lg:w-3/12">
                            <div class="footer_about mt-8 mx-3">
                                <div class="footer_logo">
                                    <a href="#"><img src="/image/ngoshops2.svg" alt="" width="200"></a>
                                </div>
                            </div> <!-- footer about -->
                        </div>
                        <div class="w-full md:w-1/2 lg:w-5/12">
                            <div class="footer_link_wrapper flex flex-wrap mx-3">
                                <div class="footer_link md:pl-13 mt-13">
                                    <h2 class="footer_title text-xl font-semibold text-white">קישורים מהירים</h2>
                                    <ul class="link pt-4">
                                        <!-- <li><a href="#" class="text-white mt-4 hover:text-theme-color">Company</a></li> -->
                                        <li><a href="https://www.shops-admin.com/termsandcondition" target="_blank"
                                                class="text-white mt-4 hover:text-theme-color">תנאים שימוש</a>
                                        </li>
                                        <li><a href="https://www.shops-admin.com/privacypolicy" target="_blank"
                                                class="text-white mt-4 hover:text-theme-color">מדיניות הפרטיות</a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="footer_link w-1/2 md:pl-13 mt-13">
                                    <h2 class="footer_title text-xl font-semibold text-white">Resources</h2>
                                    <ul class="link pt-4">
                                        <li><a href="#" class="text-white mt-4 hover:text-theme-color">Support</a></li>
                                        <li><a href="#" class="text-white mt-4 hover:text-theme-color">Contact</a></li>
                                        <li><a href="#" class="text-white mt-4 hover:text-theme-color">Terms</a></li>
                                    </ul>
                                </div>  -->
                            </div>
                        </div>
                        <div class="footer_content mt-8">
                            <p class="text-white">
                                הפתרון הפשוט והיעיל ביותר לבניית אתרי מסחר אלקטרוני בשלוש שלבים פשוטים
                                עם ממשק הניהול המקיף והאתר המוכן לשימוש.
                            </p>
                        </div>
                    </div> <!-- row -->
                </div> <!-- footer widget -->
                <div
                    class="footer_copyright pt-3 pb-6 border-t-2 border-solid border-white border-opacity-10 sm:flex justify-between">
                    <div class="footer_social pt-4 mx-3 text-center">
                        <ul class="social flex justify-center sm:justify-start">
                            <li class="mr-3"><a href="https://www.facebook.com/profile.php?id=100093302590930"
                                    target='_blank'><i class="lni lni-facebook-filled"></i></a></li>
                            <li class="mr-3"><a href="https://instagram.com/ngoshops" target='_blank'><i
                                        class="lni lni-instagram-original"></i></a></li>
                        </ul>
                    </div> <!-- footer social -->
                    <div class="footer_copyright_content pt-4 text-center">
                        <p class="text-white">NGO Shops</p>
                    </div> <!-- footer copyright content -->
                </div> <!-- footer copyright -->
            </div> <!-- container -->
        </footer>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
        <a href="https://api.whatsapp.com/send?phone=+9720524184979&text=הי Ngo Shops " class="float" target="_blank">
            <i class="fa fa-whatsapp my-float"></i>
        </a>
        <!--====== FOOTER PART ENDS ======-->
    
        <!--====== BACK TOP TOP PART START ======-->
    
        <a href="#" class="scroll-top"><i class="lni lni-chevron-up"></i></a>
    
        <!--====== BACK TOP TOP PART ENDS ======-->
    
        <!--====== PART START ======-->
    
        <!--
        <section class="">
            <div class="container">
                <div class="row">
                    <div class="col-lg-">
                        
                    </div>
                </div>
            </div>
        </section>
    -->
    
        <!--====== PART ENDS ======-->
    
    
    
    
        <!--====== Tiny Slider js ======-->
        <script src="assets/js/tiny-slider.js"></script>
    
        <!--====== Wow js ======-->
        <script src="assets/js/wow.min.js"></script>
    
        <!--====== Main js ======-->
        <script src="assets/js/main.js"></script>
    
        <!-- Accessibility Code for "suzi.ngoshops.com" -->
        <script>
            window.interdeal = {
                "sitekey": "32bf8890130577f00124f8db176c2f7c",
                "Position": "Left",
                "Menulang": "HE",
                "domains": {
                    "js": "https://cdn.equalweb.com/",
                    "acc": "https://access.equalweb.com/"
                },
                "btnStyle": {
                    "vPosition": [
                        "80%",
                        null
                    ],
                    "scale": [
                        "0.8",
                        "0.8"
                    ],
                    "color": {
                        "main": "#1876c9",
                        "second": ""
                    },
                    "icon": {
                        "type": 7,
                        "shape": "semicircle",
                        "outline": false
                    }
                }
            };
            (function (doc, head, body) {
                var coreCall = doc.createElement('script');
                coreCall.src = interdeal.domains.js + 'core/4.5.2/accessibility.js';
                coreCall.defer = true;
                coreCall.integrity = 'sha512-GVvo5c2SV7jwI6rUxQrAjIT6u0WHdJ+pbzRZyzfhOUGMaiKekbDs26ipItwEjD9jCvaV1qWbWurNBQGF5eY9aw==';
                coreCall.crossOrigin = 'anonymous';
                coreCall.setAttribute('data-cfasync', true);
                body ? body.appendChild(coreCall) : head.appendChild(coreCall);
            })(document, document.head, document.body);
        </script>
        <script>
            function startClick() {
                gtag('event', 'start_button');
                fbq('track', 'start_button');
            }
    
            function monthlyClick() {
                gtag('event', 'monthly click');
                fbq('track', 'monthly click');
            }
    
            function yearlyClick() {
                gtag('event', 'yearly click');
                fbq('track', 'yearly click');
            }
    
            function buyClick() {
                fbq('track', 'Purchase');
                gtag('event', 'Purchase');
            }
    
            function myFunction() {
                var x = document.getElementById("navbarOne");
                if (x.style.display === "block") {
                    x.style.display = "none";
                } else {
                    x.style.display = "block";
                }
            }
    
            document.getElementById("contact-form").addEventListener("submit", function (event) {
                event.preventDefault(); // Prevent form submission
                var form = event.target;
                var formData = new FormData(form);
                fetch("http://ngo-alb-1693390591.eu-central-1.elb.amazonaws.com/shop/v1/send-to-contact-us", {
                    method: "POST",
                    body: JSON.stringify({
                        emailAddress: form.emailAddress.value,
                        name: form.emailAddress.value,
                        phoneNumber: form.phoneNumber.value,
                        messageTitle: "LANDING PAGE",
                        messageText: form.messageText.value
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                }).then(res => {
                    document.getElementById("messageS").className = 'display'
                }).catch(err => {
    
                });
    
    
                // var request = new XMLHttpRequest();
                // request.open("POST", "http://ngo-alb-1693390591.eu-central-1.elb.amazonaws.com/shop/v1/send-to-contact-us", true);
                // request.setRequestHeader("Content-Type", "application/json", "charset=UTF-8");
                // request.onreadystatechange = function () {
                //     if (request.readyState === 4 && request.status === 200) {
                //         // Handle the response
                //         console.log(reque {st.responseText);
                //     }
                // };
    
                // request.onerror = function () {
                //     // Handle errors
                //     console.error("An error occurred during the request");
                // };
    
                // request.send(new URLSearchParams(JSON.stringify(formData)));
            });
            function contactUs(emailAddress, name, phoneNumber = "0000", messageText) {
                fetch("http://ngo-alb-1693390591.eu-central-1.elb.amazonaws.com/shop/v1/send-to-contact-us", {
                    method: "POST",
                    body: JSON.stringify({
                        emailAddress,
                        name,
                        phoneNumber,
                        messageTitle: "LANDING PAGE",
                        messageText
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                });
            }
            // JavaScript to handle plan selection
            const monthlyButton = document.getElementById('monthlyButton');
            const yearlyButton = document.getElementById('yearlyButton');
            const monthlyPlanPriceInner = document.getElementById('monthlyPlanPriceInner');
            const yearlyPlanPrice = document.getElementById('yearlyPlanPrice');
            const monthlyPlanPriceInner1 = document.getElementById('monthlyPlanPriceInner1');
            const yearlyPlanPrice1 = document.getElementById('yearlyPlanPrice1');
            const monthlyPlanPriceInner2 = document.getElementById('monthlyPlanPriceInner2');
            const yearlyPlanPrice2 = document.getElementById('yearlyPlanPrice2');
    
            monthlyButton.addEventListener('click', () => {
                monthlyButton.classList.add('selected-button');
                yearlyButton.classList.remove('selected-button');
                monthlyPlanPriceInner.style.display = 'block';
                yearlyPlanPrice.style.display = 'none';
                monthlyPlanPriceInner1.style.display = 'block';
                yearlyPlanPrice1.style.display = 'none';
                monthlyPlanPriceInner2.style.display = 'block';
                yearlyPlanPrice2.style.display = 'none';
            });
    
            yearlyButton.addEventListener('click', () => {
                yearlyButton.classList.add('selected-button');
                monthlyButton.classList.remove('selected-button');
                monthlyPlanPriceInner.style.display = 'none';
                yearlyPlanPrice.style.display = 'block';
                monthlyPlanPriceInner1.style.display = 'none';
                yearlyPlanPrice1.style.display = 'block';
                monthlyPlanPriceInner2.style.display = 'none';
                yearlyPlanPrice2.style.display = 'block';
            });
        </script>
    </body>
    
    </html>
    `,
      }}
    />
  );
};

export default AdminContactPage;
