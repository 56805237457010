/* eslint-disable @typescript-eslint/no-empty-function */
import { UploadIcon } from 'src/components/icons/upload-icon';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Attachment } from 'src/ts-types/generated';
import { ImageType } from 'src/ts-types/custom.types';
import { CloseIcon } from 'src/components/icons/close-icon';
import Loader from 'src/components/ui/loader/loader';
import { useTranslation } from 'react-i18next';
import { storage } from 'src/firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import Label from '../ui/label';
import { getShopDetails } from 'src/utils/shopUtils';
import { getImages } from 'src/utils/categoryUtils';
import cn from 'classnames';

const classes = {
  small: 'w-24 h-30',
  large: 'h-36',
};

const getPreviewImage = (value: any) => {
  let images: any[] = [];
  if (value) {
    images = Array.isArray(value) ? value : [{ ...value }];
  }
  const indexOfObject = images.findIndex((object) => {
    return object.imageUrl === '';
  });
  if (indexOfObject !== -1) {
    images.splice(indexOfObject, 1);
  }
  return images;
};
export default function Uploader({
  onChange,
  value,
  multiple,
  onImageUploaded,
  defaultValue,
  uploadSection,
  classContainer = '',
  showDefaultValue = false,
  title = '',
  size = 'large',
}: any) {
  const { t }: any = useTranslation<any>();
  const [files, setFiles] = useState<Attachment[] | string[]>(getPreviewImage(value));
  // eslint-disable-next-line prefer-const
  let [imgUrls, setImgUrls] = useState<ImageType[]>(getPreviewImage(value));
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue) {
      const img = getImages(defaultValue);
      setImgUrls(getPreviewImage(img));
    }
  }, [defaultValue]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length) {
        setLoading(true);
        const file = acceptedFiles[0];
        if (!file) return;
        const { shopId } = getShopDetails();
        const storageRef = ref(storage, `${shopId}/${uploadSection}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
              setLoading(false);
              if (multiple) {
                imgUrls.push({
                  imageUrl: downloadURL,
                  name: uploadTask.snapshot.metadata.name,
                });
                setImgUrls(imgUrls);
                // @ts-ignore
                setFiles(files?.concat?.(acceptedFiles[0]));
              } else {
                const imgArray: ImageType[] = [];
                imgUrls = imgArray;
                imgUrls.push({
                  imageUrl: downloadURL,
                  name: uploadTask.snapshot.metadata.name,
                });
                setImgUrls(imgUrls);
                // @ts-ignore
                setFiles(files?.concat?.(acceptedFiles[0]));
              }
              setTimeout(() => {
                if (onChange) {
                  onChange(imgUrls);
                }
              }, 0);
              if (multiple) {
                onImageUploaded(imgUrls);
              } else {
                onImageUploaded(imgUrls?.[0].imageUrl);
              }
            });
          },
        );
      }
    },
  });

  const rootClassName = cn(
    'border-dashed border-2 border-border-bas rounded flex flex-col justify-center items-center cursor-pointer focus:border-accent-400 focus:outline-none',
    {
      [classes.small]: size === 'small',
      [classes.large]: size === 'large',
    },
  );

  const handleDelete = (image: string) => {
    // @ts-ignore
    const images = files?.filter?.((file: { name: string }) => file.name !== image);
    const images1 = imgUrls.filter((file: { name: string }) => file.name !== image);

    setFiles(images);
    setImgUrls(images1);
    if (onChange) {
      onChange(images);
    }
    onImageUploaded(imgUrls);
  };

  const thumbs = imgUrls?.map((file: ImageType, idx) => {
    if (file.imageUrl) {
      return (
        <div
          className='inline-flex flex-col overflow-hidden border border-border-200 rounded mt-2 me-2 relative max-h-[120px]'
          key={idx}
        >
          <div className='flex items-center justify-center min-w-0 w-16 h-16 overflow-hidden'>
            <img src={file.imageUrl} />
          </div>
          <button
            className='w-4 h-4 flex items-center justify-center rounded-full bg-red-600 text-xs text-light absolute top-1 end-1 shadow-xl outline-none'
            onClick={() => handleDelete(file.name)}
          >
            <CloseIcon width={10} height={10} />
          </button>
        </div>
      );
    }
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.thumbnail));
    },
    [files],
  );

  return (
    <section className={`${classContainer} flex flex-col mr-2 upload`}>
      <div className='flex items-center justify-between mt-3'>
        <Label className='float-right'> {title} </Label>
      </div>
      <div {...getRootProps({})} className={rootClassName}>
        {showDefaultValue && !!thumbs.length ? (
          thumbs
        ) : (
          <>
            <input {...getInputProps()} />
            <UploadIcon className='text-muted-light' />
            <p className='text-body text-sm mt-4 text-center'>
              <span className='text-accent font-semibold'>{t('text-upload-highlight')}</span>{' '}
              {t('text-upload-message')} <br />
              <span className='text-xs text-body'>{t('text-img-format')}</span>
            </p>
          </>
        )}
      </div>
      {!showDefaultValue && (
        <aside className='flex flex-wrap mt-2'>
          {!!thumbs.length && thumbs}
          {loading && (
            <div className='h-16 flex items-center mt-2 ms-2'>
              <Loader simple={true} className='w-6 h-6' />
            </div>
          )}
        </aside>
      )}
    </section>
  );
}
