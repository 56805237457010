import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import {
  ActiveShopResponse,
  CompanyDetails,
  CustomerDetails,
  ShopDetailsType,
} from 'src/ts-types/custom.types';
import { toast } from 'react-toastify';
import { useModalAction } from 'src/components/ui/modal/modal.context';
import { getActiveShopDetails, updateShopDetails } from 'src/store/shop/shopsAPIActions';
import { getShopDetails } from 'src/utils/shopUtils';
import Card from 'src/components/common/card';
import Input from 'src/components/ui/input';
import { businessDetailsModalValidation } from 'src/components/shop/businessDetailsModalValidation';
import { RootState } from 'src/store';
import { TOAST_MESSAGE } from 'src/utils/constants';

type Props = {
  updateValues: (companyDetails: CompanyDetails, customerDetails: CustomerDetails) => void;
};

const BusinessDetails = ({ updateValues }: Props) => {
  const { t }: any = useTranslation();
  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse,
  );

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { closeModal } = useModalAction();
  const { shopId } = getShopDetails();
  const { data } = activeShop as ActiveShopResponse;

  const getDefaultValues = (data: {
    companyDetails: { phoneNumber: string; companyNumber: string };
  }) => {
    if (data?.companyDetails?.phoneNumber === '0') {
      data.companyDetails.phoneNumber = '';
    }
    if (data?.companyDetails?.companyNumber === '0') {
      data.companyDetails.companyNumber = '';
    }
    return data;
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    control,
    formState: { errors, isValid },
    // @ts-ignore
  } = useForm<ShopDetailsType>({
    mode: 'onBlur',
    ...(data
      ? {
          defaultValues: {
            // @ts-ignore
            ...getDefaultValues(data),
          },
        }
      : {}),
    resolver: yupResolver(businessDetailsModalValidation),
  });

  useEffect(() => {
    setValue('companyDetails.companyType', 'Company');
  }, [setValue]);
  useEffect(() => {
    const companyName = getValues('companyDetails.companyName');
    const companyType = getValues('companyDetails.companyType');
    const companyNumber = getValues('companyDetails.companyNumber');
    const Phone = getValues('customerDetails.Phone');
    const emailAddress = getValues('customerDetails.emailAddress');

    updateValues({ companyName, companyType, companyNumber }, { Phone, emailAddress });
  }, [
    watch('companyDetails.companyName'),
    watch('companyDetails.companyNumber'),
    watch('customerDetails.Phone'),
    watch('customerDetails.emailAddress'),
  ]);

  // const handleLogoUrlUpload = (val?: string) => {
  //   setValue('logoUrl', val);
  //   trigger('logoUrl');
  // };

  const onSubmit = (values: ShopDetailsType) => {
    setLoading(true);
    values.shopId = shopId;
    dispatch(updateShopDetails({ ...data, ...values }))
      .then((response: any) => {
        toast.success(t(TOAST_MESSAGE.COMPANY_INFO_UPDATED_SUCCESSFULLY));
        dispatch(getActiveShopDetails());
        setLoading(false);
        closeModal();
      })
      .catch((err: any) => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        closeModal();
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        className='md:w-full mt-12'
        title={<h1 className='text-lg font-semibold text-heading'>{t('shop-company-details')}</h1>}
      >
        <div className='flex flex-col md:flex-row'>
          <Input
            containerClassName='ml-6'
            label={t('input-label-company-name')}
            {...register('companyDetails.companyName')}
            variant='outline'
            placeholder={t('input-label-company-name-placeholder')}
            error={t(errors.companyDetails?.companyName?.message)}
          />
          <Input
            label={t('input-cpmany-id')}
            {...register('companyDetails.companyNumber')}
            variant='outline'
            placeholder={t('input-cpmany-id')}
            error={t(errors.companyDetails?.companyNumber?.message)}
          />
        </div>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-phone')}
            containerClassName='ml-6'
            {...register('customerDetails.Phone')}
            variant='outline'
            placeholder={t('input-label-phone-placeholder')}
            error={t(errors.customerDetails?.Phone?.message)}
          />
          <Input
            label={t('input-label-email')}
            {...register('customerDetails.emailAddress')}
            variant='outline'
            placeholder={t('input-label-email')}
            error={t(errors.customerDetails?.emailAddress?.message)}
          />
        </div>
      </Card>
    </form>
  );
};

export default BusinessDetails;
