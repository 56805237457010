import { useState } from 'react';
import _ from 'lodash';
import SiteTemplates from './siteTemplates';

export type SiteTemplate = {
  label: string;
  value: string;
  img: string;
};

export type Props = {
  show: boolean;
  defaultValue?: string;
  updateSiteTemplateType: (val: any) => void;
  register?: () => void;
};

const ChoseSiteTemplate = ({ show, updateSiteTemplateType, defaultValue }: Props) => {
  const [type, setType] = useState<string>(defaultValue ?? '');

  if (!show) return null;

  const handleSelect = (e: any, value: string) => {
    e.preventDefault();
    updateSiteTemplateType(value);
    setType(value);
  };

  return <SiteTemplates selectedType={type} updateSiteTemplateType={handleSelect} />;
};

export default ChoseSiteTemplate;
