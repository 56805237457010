/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/prop-types */
// @ts-nocheck
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { useEffect } from 'react';
import { getAllAlerts } from '../../store/shop/shopsAPIActions';
import { ROUTES } from '../../utils/routes';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/store';

const NotificationTopBar: React.FC<React.AnchorHTMLAttributes<{}>> = ({ className, ...props }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const allMyAlerts = useAppSelector((state: RootState) => state.shop.shopDetails?.allMyAlerts);

  useEffect(() => {
    if (allMyAlerts == undefined) {
      dispatch(getAllAlerts(1));
    }
  }, [dispatch]);

  const openAlerts = () => {
    dispatch(getAllAlerts(1));
    history.push(ROUTES.OPEN_ALERTS);
  };

  return (
    <div>
      <div className='relative mt-2' style={{ cursor: 'pointer' }} onClick={() => openAlerts()}>
        {allMyAlerts?.numberOfUnseenAlerts > 0 ? (
          <div className='absolute left-0 top-0  bg-red-500 rounded-full'>
            <span className='text-sm text-white p-1'>{allMyAlerts?.numberOfUnseenAlerts}</span>
          </div>
        ) : null}
        <div className='p-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            className='text-gray-600 w-6 h-6'
            viewBox='0 0 16 16'
          >
            <path d='M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z' />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NotificationTopBar;
