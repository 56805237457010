// store/session/reducers.ts
import { AuthUserResponse, LogIn, ValidateOTPResponse } from '../../ts-types/custom.types';
import { combineReducers } from 'redux';
import { Action } from './actions';

// States' definition
const initialState: Session = {
  isFetching: false,
  logIn: undefined,
  otpResponse: false,
  otpValidateResponse: { data: { valid: false } },
};
export interface Session {
  isFetching?: boolean;
  logIn?: LogIn;
  error?: string;
  userDetails?: AuthUserResponse;
  otpResponse?: boolean;
  otpValidateResponse?: ValidateOTPResponse;
}

export interface State {
  sessionDetails?: Session;
}
const sessionDetails = (state = initialState, action: Action): Session => {
  switch (action.type) {
    case 'SET_LOG_IN':
      return { ...state, logIn: action.logIn };
    case 'SET_FETCHING':
      return { ...state, isFetching: action.isFetching };
    case 'SET_ERROR':
      return { ...state, error: action.error };
    case 'SET_OTP_RESPONSE':
      return { ...state, otpResponse: action.response };
    case 'SET_AUTH_USER_RESPONSE':
      return { ...state, userDetails: action.response };
    case 'LOG_OUT':
      return { otpResponse: false };
    case 'SET_VALIDATE_OTP_RESPONSE':
      return { ...state, otpValidateResponse: action.response };
    default:
      return state;
  }
};
export default combineReducers<State>({ sessionDetails });
