/* eslint-disable @typescript-eslint/no-explicit-any */
export const DressingTable: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='39.867'
      height='62'
      viewBox='0 0 39.867 62'
      fill='currentColor'
      {...props}
    >
      <g id='Dressing_Table' data-name='Dressing Table' transform='translate(-942.634 -346.008)'>
        <rect
          id='Rectangle_1064'
          data-name='Rectangle 1064'
          width='18.934'
          height='19.835'
          transform='translate(943.634 384.083)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <path
          id='Path_17426'
          data-name='Path 17426'
          d='M970.947,347.008h0a14.519,14.519,0,0,1,14.476,14.476v22.6H956.471v-22.6A14.519,14.519,0,0,1,970.947,347.008Z'
          transform='translate(-8.38)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <rect
          id='Rectangle_1065'
          data-name='Rectangle 1065'
          width='18.934'
          height='19.835'
          transform='translate(962.568 384.083)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <line
          id='Line_1'
          data-name='Line 1'
          y2='3.09'
          transform='translate(958.781 392.455)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_2'
          data-name='Line 2'
          y2='3.09'
          transform='translate(966.355 392.455)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_3'
          data-name='Line 3'
          y2='3.09'
          transform='translate(945.729 403.918)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_4'
          data-name='Line 4'
          y2='3.09'
          transform='translate(979.034 403.918)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_5'
          data-name='Line 5'
          x1='15.39'
          y2='15.39'
          transform='translate(954.873 359.746)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_6'
          data-name='Line 6'
          x1='8.437'
          y2='8.437'
          transform='translate(955.706 360.578)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
