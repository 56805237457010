/* eslint-disable @typescript-eslint/ban-types */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import BaseService from '../../api/base';
import { API_ENDPOINTS_NEW } from '../../api/endpoints';
import {
  LogIn,
  Register,
  AuthUserResponse,
  ValidateOTPResponse,
} from '../../ts-types/custom.types';
import { AUTH_CRED } from '../../utils/constants';
import { getAuthCredentials, setAuthCredentials } from 'src/utils/authUtils';
import jwtDecode from 'jwt-decode';

const service = new BaseService();
// Action Definition
export interface SetLogInAction {
  type: 'SET_LOG_IN';
  logIn: LogIn;
}
export interface LogOutAction {
  type: 'LOG_OUT';
}
export interface SetRegisterAction {
  type: 'SET_REGISTER';
  registerPayload: Register;
}
export interface SetFetching {
  type: 'SET_FETCHING';
  isFetching: boolean;
}
export interface SetError {
  type: 'SET_ERROR';
  error: string;
}

export interface SetAuthUserResponseAction {
  type: 'SET_AUTH_USER_RESPONSE';
  response: AuthUserResponse;
}

export interface SetOTPResponseAction {
  type: 'SET_OTP_RESPONSE';
  response: boolean;
}

export interface SetValidateOTPResponseAction {
  type: 'SET_VALIDATE_OTP_RESPONSE';
  response: ValidateOTPResponse;
}

export const setAuthUserResponse = (response: AuthUserResponse): SetAuthUserResponseAction => {
  return { type: 'SET_AUTH_USER_RESPONSE', response };
};

export const setOTPResponse = (response: boolean): SetOTPResponseAction => {
  return { type: 'SET_OTP_RESPONSE', response };
};

export const setValidateOTPResponse = (
  response: ValidateOTPResponse,
): SetValidateOTPResponseAction => {
  return { type: 'SET_VALIDATE_OTP_RESPONSE', response };
};

// Union Action Types
export type Action =
  | SetLogInAction
  | SetFetching
  | SetError
  | SetRegisterAction
  | LogOutAction
  | SetAuthUserResponseAction
  | SetOTPResponseAction
  | SetValidateOTPResponseAction;

// Action Creators
export const set = (logIn: LogIn): SetLogInAction => {
  return { type: 'SET_LOG_IN', logIn };
};
export const isFetching = (isFetching: boolean): SetFetching => {
  return { type: 'SET_FETCHING', isFetching };
};
export const setError = (error: string): SetError => {
  return { type: 'SET_ERROR', error };
};
export const register = (registerPayload: Register): SetRegisterAction => {
  return { type: 'SET_REGISTER', registerPayload };
};
export const logOutAction = (): LogOutAction => {
  return { type: 'LOG_OUT' };
};

export const logOut = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(isFetching(true));
        dispatch(logOutAction());
        localStorage?.clear();
        dispatch(isFetching(false));
      } catch (e) {
        localStorage?.remove(AUTH_CRED);
        dispatch(setError('error'));
        dispatch(isFetching(false));
        reject(e);
      }
    });
  };
};

export const googleLogIn = (
  googleToken?: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      const appType = process.env.NODE_ENV === 'production' ? 'admin' : 'dev';
      try {
        dispatch(isFetching(true));
        service
          .create(API_ENDPOINTS_NEW.GOOGLE_LOG_IN, { googleToken, appType })
          .then((response) => {
            const { accessToken, refreshToken } = response.data;
            const decoded = jwtDecode(accessToken) as { id: ''; email: ''; rule: '' };
            dispatch(set({ accessToken, refreshToken, id: decoded.id }));
            dispatch(setError(''));
            setTimeout(() => {
              dispatch(fetchUserDetails(decoded.id));
            }, 100);
            dispatch(isFetching(false));
            resolve(response);
          })
          .catch((e) => {
            localStorage?.clear();
            dispatch(setError('error'));
            dispatch(isFetching(false));
            reject(e);
          });
      } catch (e) {
        localStorage?.remove(AUTH_CRED);
        dispatch(setError('error'));
        dispatch(isFetching(false));
        reject(e);
      }
    });
  };
};

export const loginByMobile = (
  mobileNumber: string,
  password: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(isFetching(true));
        service
          .create(API_ENDPOINTS_NEW.LOGIN_BY_MOBILE, { mobileNumber, password })
          .then((response) => {
            dispatch(isFetching(false));
            const { accessToken, refreshToken } = response.data;
            const decoded = jwtDecode(accessToken) as { id: ''; email: ''; rule: '' };
            dispatch(set({ accessToken, refreshToken, id: decoded.id }));
            dispatch(setError(''));
            resolve(response);
          });
      } catch (e) {
        localStorage?.remove(AUTH_CRED);
        dispatch(setError('error'));
        dispatch(isFetching(false));
        reject(e);
      }
    });
  };
};

export const loginByEmail = (
  email: string,
  password: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(isFetching(true));
        service
          .create(API_ENDPOINTS_NEW.LOGIN_BY_EMAIL, { email, password })
          .then((response) => {
            dispatch(isFetching(false));
            dispatch(setError(''));
            const { accessToken, refreshToken } = response.data;
            const decoded = jwtDecode(accessToken) as { id: ''; email: ''; rule: '' };
            dispatch(set({ accessToken, refreshToken, id: decoded.id }));
            resolve(response);
          })
          .catch((e) => {
            localStorage?.clear();
            dispatch(setError('error'));
            dispatch(isFetching(false));
            reject(e);
          });
      } catch (e) {
        localStorage?.clear();
        dispatch(setError('error'));
        dispatch(isFetching(false));
        reject(e);
      }
    });
  };
};

export const registerAction = (
  name: string,
  email: string,
  password: string,
  otpCode: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(isFetching(true));
        service
          .create(API_ENDPOINTS_NEW.REGISTER, { name, email, password, otpCode })
          .then((response) => {
            dispatch(isFetching(false));
            dispatch(set(response.data));
            dispatch(setError(''));
            resolve(response);
          })
          .catch((e) => {
            localStorage?.clear();
            dispatch(setError('error'));
            dispatch(isFetching(false));
            reject(e);
          });
      } catch (e) {
        localStorage?.remove(AUTH_CRED);
        dispatch(setError('error'));
        dispatch(isFetching(false));
        reject(e);
      }
    });
  };
};

export const refreshToken = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(isFetching(true));
        const authCred = getAuthCredentials();
        service
          .create(API_ENDPOINTS_NEW.REFRESH_TOKEN, {
            refreshToken: authCred?.refreshToken,
          })
          .then((response) => {
            dispatch(isFetching(false));
            const { refreshToken, accessToken } = response.data;
            setAuthCredentials(accessToken, refreshToken);
            resolve(response);
          });
      } catch (e) {
        localStorage?.remove(AUTH_CRED);
        dispatch(setError('error'));
        dispatch(isFetching(false));
        reject(e);
      }
    });
  };
};

export const fetchUserDetails = (userId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        if (userId !== '') {
          dispatch(isFetching(true));
          const authCred = getAuthCredentials();
          const url =
            API_ENDPOINTS_NEW.GET_USER_DETAILS.prefix +
            userId +
            API_ENDPOINTS_NEW.GET_USER_DETAILS.suffix;
          service.find(url).then((response: any) => {
            dispatch(isFetching(false));
            dispatch(setAuthUserResponse({ data: response.data, isFetching: false }));
            resolve(response);
          });
        }
      } catch (e) {
        dispatch(setError('error'));
        dispatch(isFetching(false));
        reject(e);
      }
    });
  };
};

export const getOTP = (
  email: string,
  mobileNumber: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setOTPResponse(false));
        const url = API_ENDPOINTS_NEW.GET_OTP;
        service.create(url, { mobileNumber, email }).then((response: any) => {
          dispatch(isFetching(false));
          dispatch(setOTPResponse(true));
          resolve(response);
        });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setOTPResponse(false));
        reject(e);
      }
    });
  };
};

export const validateOTP = (
  otpCode: string,
  mobileNumber: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setValidateOTPResponse({ data: { valid: false }, isFetching: false }));
        const url = API_ENDPOINTS_NEW.VALIDATE_OTP;
        service.create(url, { mobileNumber, otpCode }).then((response: any) => {
          const { data } = response;
          dispatch(setValidateOTPResponse({ data, isFetching: false }));
          resolve(response);
        });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setValidateOTPResponse({ data: { valid: false }, isFetching: false }));
        reject(e);
      }
    });
  };
};
