import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Loader from 'src/components/ui/loader/loader';
import { MyShopsResponse, ShopDetailsType } from 'src/ts-types/custom.types';
import { useAppSelector } from 'src/utils/reduxHooks';
import { ROUTES } from 'src/utils/routes';
import { getSiteDomain, renderShopStatus } from 'src/utils/shopUtils';
import { EditLinkIcon } from '../../icons/editLink';
import Title from '../../ui/title';
import NoShopAlert from '../noShopAlert';
import { RootState } from 'src/store';
import Card from 'src/components/common/card';
import Logo from 'src/components/ui/logo';
import { isMobile } from 'react-device-detect';

const MySitesView = () => {
  const { t }: any = useTranslation();
  const myShopsResponse = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.myShopsResponse,
  );

  const history = useHistory();

  const { data: myShops, isFetching }: MyShopsResponse = myShopsResponse || {
    data: [],
    isFetching: false,
  };

  const handleSelectSite = (shopId?: string) => {
    history.push(`${ROUTES.ACTIVE_SHOP}${shopId}`);
  };

  const handleLinkClick = (e: any, domain?: string, shopId?: string) => {
    e.stopPropagation();
    window.open(getSiteDomain(domain, shopId), '_blank');
  };

  if (isFetching) return <Loader text={t('text-loading')} />;

  const renderEditIcon = (item: ShopDetailsType) => {
    return (
      <div className=''>
        <div className='font-bold w-2/3 float-right'>
          <Title className=''>{item.companyDetails?.companyName}</Title>
        </div>
        <div
          className='float-left cursor-pointer text-accent font-bold'
          style={{ marginRight: '10px' }}
        >
          <div className=''>{renderShopStatus(item?.shopCurrentStatus)}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {myShopsResponse?.data?.length === 0 ? (
        <NoShopAlert />
      ) : (
        <>
          <Title className='text-2xl mt-4'>{t('my-sites-title')}</Title>
          <Title className='pt-1 text-sm font-thin'>{t('my-sites-sub-title')}</Title>
          <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4 content-evenly animate-in slide-in-from-top mt-10'>
            {myShops?.map?.((item: ShopDetailsType, index: number) => (
              <Card
                key={`${item.shopExpirationDate}_${index}`}
                title={renderEditIcon(item)}
                className='cursor-pointer border border-border-100 hover:border-accent
              shadow-md'
              >
                <div className='' onClick={() => handleSelectSite(item?.shopId)}>
                  <Logo width={`${isMobile ? '70' : '100'}`} />
                  {/* <img
                    className='pb-4 h-[100px] w-[80px]'
                    src={gteImageUrl(item?.logoUrl, '/bag.png')}
                  ></img> */}
                  <hr className='h-px bg-gray-100 border-0 dark:bg-gray-200 mb-4'></hr>
                  <div className='flex flex-col'>
                    <Link
                      to=''
                      className='inline-flex items-center text-blue-600 text-accent'
                      onClick={(e) => handleLinkClick(e, item?.website, item.shopId)}
                    >
                      {item?.website ?? process.env.REACT_APP_DEFAULT_SITE}
                      <EditLinkIcon />
                    </Link>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default MySitesView;
