import Select from 'src/components/ui/select/select';

import { useTranslation } from 'react-i18next';
import Label from 'src/components/ui/label';
import cn from 'classnames';
import { useAppSelector } from 'src/utils/reduxHooks';
import { RootState } from 'src/store';
import { Category } from 'src/ts-types/custom.types';
import { get as getObj } from 'lodash';
import { getActiveCategory } from 'src/utils/categoryUtils';
import { getTranslatedItem } from 'src/common';

type Props = {
  onCategoryFilter: (val: any) => void;
  onTypeFilter: ({ slug }: { slug: string }) => void;
  className?: string;
  category?: Category;
};

export default function CategoryTypeFilter({
  onTypeFilter,
  onCategoryFilter,
  className,
  category,
}: Props) {
  const searchTerm = useAppSelector((state: RootState) => state.util.UtilData?.searchTerm) ?? {
    categoryId: '',
    text: '',
  };
  const categories = useAppSelector((state: RootState) => state.shop.shopDetails?.categories);
  const { t }: any = useTranslation();
  if (searchTerm.categoryId) {
    category = getActiveCategory(categories?.data ?? [], searchTerm.categoryId);
  }
  categories?.data?.map(
    (item: Category) => (item.name = getObj(item, 'categoryNameWithTeanslations[0].value', '')),
  );

  const cate: Category[] = [];
  categories?.data?.forEach((item: Category) => {
    item.name = getTranslatedItem(item?.categoryNameWithTeanslations ?? undefined);
    item.id = item.categoryId;
    cate.push(item);
    item.children?.forEach((subItemCategories: Category) => {
      subItemCategories.name = `\u23CE  ${getTranslatedItem(
        subItemCategories?.categoryNameWithTeanslations,
      )}`;
      subItemCategories.id = subItemCategories.categoryId;
      cate.push(subItemCategories);
    });
  });
  return (
    <div
      className={cn(
        'flex flex-col md:flex-row md:space-x-5 md:items-end space-y-5 md:space-y-0 w-full',
        className,
      )}
    >
      {/* <div className="w-full">
        <Label>{t("filter-by-group")}</Label>
        <Select
          options={data?.types}
          isLoading={loading}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.slug}
          placeholder={t("filter-by-group-placeholder")}
          onChange={onTypeFilter}
        />
      </div> */}
      <div className='w-full'>
        <Label>{t('filter-by-category')}</Label>
        <Select
          name='categoryId'
          options={cate}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          placeholder={t('filter-by-category-placeholder')}
          isLoading={false}
          value={category}
          defaultValue={category?.id}
          onChange={onCategoryFilter}
        />
      </div>
    </div>
  );
}
