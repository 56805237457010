import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../utils/reduxHooks';
import { useModalAction } from 'src/components/ui/modal/modal.context';
import { ProductCarousel } from '../ui/productCarousel';
import Stepper from 'src/components/stepper';
import { useHistory } from 'react-router-dom';
import { SiteLayoutCarousel } from '../ui/siteLayoutCarousel';
import { siteSettingsModalValidation } from './siteSettingsModalValidation';

type Props = {
  initialValues?: FormValues;
};

type FormValues = {
  layoutType: string;
  productCardType: string;
};
const SiteSettingsModal = ({ initialValues }: Props) => {
  const { t }: any = useTranslation<any>();
  const dispatch = useAppDispatch();
  const { closeModal } = useModalAction();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    ...(initialValues
      ? {
          defaultValues: {
            ...initialValues,
          },
        }
      : {}),
    resolver: yupResolver(siteSettingsModalValidation),
  });

  // const onSubmit = data => {
  //     console.log(data);
  // };
  const onSubmit = (values: FormValues) => {
    console.log('vales ', values);
    alert('asdad');
    // dispatch(updateShopDetails(values)).then((response: any) => {
    //     toast.success(t(TOAST_MESSAGE.COMPANY_INFO_UPDATED_SUCCESSFULLY));
    //     closeModal();
    // }).catch(err => {
    //     toast.success(t(TOAST_MESSAGE.ERROR_TOAST));
    //     closeModal();
    // })
  };

  return (
    <form name='site-settings-form' id='site-settings-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='p-4 pb-6 bg-light m-auto w-full rounded-md md:rounded-xl sm:w-[24rem] md:w-full'>
        <div className='w-full h-full text-center'>
          <div className='mb-38'>
            <Stepper
              updateSelectedStep={(val) => setCurrentStep(val)}
              // gotToPreviousPage={() => history.push(ROUTES.SHOPS)}
              steps={[
                {
                  element: () => (
                    <>
                      {0 === currentStep && (
                        <SiteLayoutCarousel
                          onSelect={() => setTimeout(() => setCurrentStep(1), 500)}
                          register={register}
                        />
                      )}
                    </>
                  ),
                  isNextDisabled: () => !!watch('layoutType'),
                },
                {
                  element: () => (
                    <>
                      {1 === currentStep && (
                        <ProductCarousel
                          onSelect={() => setTimeout(() => setCurrentStep(1), 500)}
                          register={register}
                        />
                      )}
                    </>
                  ),
                  isNextDisabled: () => !!watch('productCardType'),
                },
              ]}
            />
            {/* <Carousel plugins={['arrows']}>
                            {
                                siteSettings.productCards?.map((productCard, index) => {
                                    return (
                                        <RadioCard
                                            key={`product-card-${index}`}
                                            {...register("settings.productCard")}
                                            label={t(productCard.label)}
                                            value={productCard.value}
                                            src={productCard.img}
                                            id={`product-card-${index}`}
                                        />
                                    );
                                })
                            }
                        </Carousel> */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default SiteSettingsModal;
