import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Card from 'src/components/common/card';
import SiteTemplates from 'src/components/createShop/siteTemplates';
import SiteProductType from 'src/components/createShop/siteProductType';
import Button from 'src/components/ui/button';
import UnControlledFileInput from 'src/components/ui/unControlled-file-input';
import { withPermission } from 'src/HOC/withPermission';
import { getActiveShopDetails, updateShopDetails } from 'src/store/shop/shopsAPIActions';
import { ProductCardTypeEnum, UPLOAD_SECTION } from 'src/ts-types';
import {
  CompanyDetails,
  CustomerDetails,
  ShopDetailsType,
  ImageType,
  TranslationObject,
  Promotion,
} from 'src/ts-types/custom.types';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import {
  faUpload,
  faBriefcase,
  faWrench,
  faFile,
  faFillDrip,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import BusinessDetails from './businessDetails';
import Label from 'src/components/ui/label';
import ColorPicker from 'src/components/colorPicker';
import { getShopDetails } from 'src/utils/shopUtils';
import TextAreaWithTranslation from 'src/components/ui/text-areaWithTranslation';
import SwitchInput from 'src/components/ui/switch-input';
import DynamicInputList from 'src/components/ui/dynamicInputList';

const TemplatePage = () => {
  const { t }: any = useTranslation<any>();
  const dispatch = useAppDispatch();
  const activeShopDetails = useAppSelector(
    (state) => state.shop.shopDetails?.activeShopDetailsResponse?.data,
  ) as ShopDetailsType;
  const [step, setStep] = useState<number>(0);
  // @ts-ignore
  const {
    layoutType,
    productCardType,
    logoUrl,
    siteImages,
    primaryColor,
    secondaryColor,
    customerDetails,
    shopDescription,
    promotionSection,
    companyDetails,
    notificationsByWhatsapp,
    validateOtpNeeded,
  } = activeShopDetails;

  const { siteMainImages, siteMainPageImages } = siteImages ?? {};
  const [, ...rest] = siteMainPageImages ?? [];
  const [logo, setLogo] = useState<string>(logoUrl || '');
  const [mainImages, setMainImages] = useState<any[]>(siteMainImages ?? ['']);
  const [pageImages, setPageImages] = useState<any>(siteMainPageImages?.[0] ?? '');
  const [aboutUsImage, setAboutUsImage] = useState<any>(siteMainPageImages?.[1] ?? '');
  const [promotionImages, setPromotionImages] = useState(
    promotionSection?.promotions?.map((promotion: Promotion) => promotion.image) ?? [''],
  );
  const [promotionActions, setPromotionsActionsValues] = useState(
    promotionSection?.promotions?.map((promotion: Promotion) => promotion.action) ?? [''],
  );
  const [aboutUsDescription, setAboutUsDescription] = useState<TranslationObject[]>(
    shopDescription ?? [],
  );
  const [promotionDescription, setPromotionDescription] = useState<TranslationObject[]>(
    promotionSection?.description ?? [],
  );
  const [siteType, setSiteType] = useState<string>(layoutType || '');
  const [getNotificationsByWhatsapp, setGetNotificationsByWhatsapp] = useState<boolean>(
    notificationsByWhatsapp || false,
  );
  const [getButOtpVerification, setButOtpVerification] = useState<boolean>(
    validateOtpNeeded || false,
  );
  const [customerDetailsT, setCustomerDetails] = useState<CustomerDetails>(customerDetails || {});
  const [companyDetailsT, setCompanyDetails] = useState<CompanyDetails>(companyDetails || {});
  const [sitePrimaryColor, setSitePrimaryColor] = useState<string>(primaryColor || '#23AD92');
  const [siteSecondaryColor, setSiteSecondaryColor] = useState<string>(secondaryColor || '#23AD92');
  const [productType, setProductType] = useState<string>(
    productCardType || ProductCardTypeEnum.HELIUM,
  );

  const presetColors = ['#6231af', '#db2777', '#f59e0b', '#2dd4bf', '#06b6d4', '#10b981'];

  const handleSelect = (e: any, value: string) => {
    setSiteType(value);
    e.preventDefault();
  };

  const handleProductSelect = (e: any, value: string) => {
    e.preventDefault();
    setProductType(value);
  };

  const updateSettings = () => {
    const { shopId } = getShopDetails();
    const promotionObject = promotionImages?.map((img, index) => ({
      image: img,
      action: promotionActions[index] ?? '',
    }));

    const shopDetails: ShopDetailsType = {
      shopId,
      layoutType: siteType,
      productCardType: productType || 'helium',
      siteImages: {
        siteMainImages: mainImages,
        siteMainPageImages: [pageImages].concat(aboutUsImage),
      },
      logoUrl: logo,
      customerDetails: customerDetailsT,
      companyDetails: companyDetailsT,
      primaryColor: sitePrimaryColor,
      secondaryColor: siteSecondaryColor,
      shopDescription: aboutUsDescription,
      promotionSection: {
        promotions: promotionObject,
        description: promotionDescription,
      },
      notificationsByWhatsapp: getNotificationsByWhatsapp,
      validateOtpNeeded: getButOtpVerification,
    };
    dispatch(updateShopDetails(shopDetails))
      .then(() => {
        toast.success(t('SETTINGS_UPDATED_SUCCESSFULLY'));
        dispatch(getActiveShopDetails());
      })
      .catch(() => {
        toast.error(t('SETTINGS_UPDATED_FAILED'));
      });
  };

  const handleLogoUrlUpload = (val: string) => {
    setLogo(val.length === 0 ? '' : val);
  };

  const handelMainImageUpload = (val: ImageType[]) => {
    const images: string[] = [];
    for (let index = 0; index < val.length; index++) {
      images.push(val[index].imageUrl);
    }
    setMainImages(images);
  };

  const handelAboutUsImageUpload = (val: string) => {
    setAboutUsImage(val);
  };

  const handelMainPageImageUpload = (val: string) => {
    setPageImages(val);
  };

  const handelPromotionImageUpload = (val: string | ImageType[]) => {
    const images: string[] = [];
    for (let index = 0; index < val.length; index++) {
      images.push((val[index] as ImageType).imageUrl);
    }
    setPromotionImages(images);
  };

  const handlePrimaryColorChange = (val: any) => {
    setSitePrimaryColor(val);
  };

  const handleSecondaryColorChange = (val: any) => {
    setSiteSecondaryColor(val);
  };

  // Callback to handle updates from the child component
  const handleInputListUpdate = (updatedInputs: string[]) => {
    setPromotionsActionsValues(updatedInputs);
  };

  const activeClass =
    'text-accent-600 border-b-2 border-accent-600  rounded-t-lg active dark:text-accent-500 dark:border-accent-500 group';
  const regularClass =
    'border-b-2 border-transparent rounded-t-lg hover:text-accent-600 hover:border-accent-300 dark:hover:text-accent-300 group';

  const filteredPromotionActions = (promotionActions ?? ['']).filter(
    (action) => action !== undefined,
  );
  return (
    <>
      <div className='fixed inset-x-0 top-20 bg-white md:ml-5 md:mr-5 rounded shadow mt-0 lg:right-[287px] smx:top-16 z-50'>
        <div className='items-center justify-between dark:border-accent-300 float-right '>
          <ul className='flex flex-wrap -mb-px text-sm font-medium text-center text-accent-500 dark:text-accent-400 '>
            <li className='mr-2'>
              <a
                href='#'
                className={`inline-flex p-4  ${step === 0 ? activeClass : regularClass}`}
                onClick={() => setStep(0)}
              >
                <FontAwesomeIcon
                  icon={faBriefcase as IconProp}
                  className='pt-1 pl-2'
                  style={{ color: '#23AD92' }}
                />
                {t('SITE_TEMPLATE_BUSINESS_DETAILS')}
              </a>
            </li>
            <li className='mr-2'>
              <a
                href='#'
                className={`inline-flex p-4  ${step === 1 ? activeClass : regularClass}`}
                onClick={() => setStep(1)}
              >
                <FontAwesomeIcon
                  icon={faWrench as IconProp}
                  className='pt-1 pl-2'
                  style={{ color: '#23AD92' }}
                />
                {t('SITE_TEMPLATE')}
              </a>
            </li>
            <li className='mr-2'>
              <a
                href='#'
                className={`inline-flex p-4  ${step === 2 ? activeClass : regularClass}`}
                onClick={() => setStep(2)}
              >
                <FontAwesomeIcon
                  icon={faFillDrip as IconProp}
                  className='pt-1 pl-2'
                  style={{ color: '#23AD92' }}
                />

                {t('SITE_DESIGN')}
              </a>
            </li>
          </ul>
        </div>
        <div className='flex float-left cursor-pointer text-accent font-bold align px-2 pt-2 smx:mt-2 smx:pb-4'>
          <Button
            className='ml-2'
            size='small'
            icon={<FontAwesomeIcon icon={faFile as IconProp} size={'1x'} />}
            onClick={() => updateSettings()}
          >
            {t('preview')}
          </Button>
          <Button
            className=''
            size='small'
            onClick={() => updateSettings()}
            icon={<FontAwesomeIcon icon={faUpload as IconProp} size={'1x'} />}
          >
            {t('publish')}
          </Button>
        </div>
      </div>
      <div className='lg:mt-18 md:mt-20 smx:mt-32'>
        {step === 0 && (
          <>
            <BusinessDetails
              updateValues={(
                _companyDetails: CompanyDetails,
                _customerDetails: CustomerDetails,
              ) => {
                setCustomerDetails({ ...customerDetailsT, ..._customerDetails }),
                  setCompanyDetails({ ...companyDetailsT, ..._companyDetails });
              }}
            />
            <Card
              className='flex flex-col mb-4'
              title={t('site-settings-details')}
              titleClassName='text-lg font-semibold text-heading'
            >
              <div className=''>
                <SwitchInput
                  className='pt-2'
                  label={t('input-label-get-notification-by-whatsapp')}
                  onChange={(val) => setGetNotificationsByWhatsapp(val)}
                  name='status'
                  defaultValue={true}
                  value={getNotificationsByWhatsapp}
                />
              </div>
              <div className=''>
                <SwitchInput
                  className='pt-2'
                  label={t('input-label-set-otp-buy-verification')}
                  onChange={(val) => setButOtpVerification(val)}
                  name='validateOtpNeeded'
                  defaultValue={true}
                  value={getButOtpVerification}
                />
              </div>
            </Card>
          </>
        )}
        {step === 1 && (
          <>
            <Card
              className='flex flex-col mb-4'
              title={t('SITE_LAYOUT')}
              titleClassName='text-lg font-semibold text-heading'
            >
              <SiteTemplates selectedType={siteType} updateSiteTemplateType={handleSelect} />
            </Card>
            <Card
              title={t('PRODUCT_LAYOUT')}
              className='flex flex-col mb-4'
              titleClassName='text-lg font-semibold text-heading'
            >
              <SiteProductType
                updateProductType={handleProductSelect}
                selectedType={productType ?? ProductCardTypeEnum.HELIUM}
              />
            </Card>
          </>
        )}
        {step === 2 && (
          <>
            <Card
              className='flex flex-col mb-8'
              title={t('SITE_SETTINGS')}
              titleClassName='text-lg font-semibold text-heading'
            >
              <div className='flex flex-col md:flex-row mt-2'>
                <div className='svelte-1l8159 pb-4'>
                  <Label>{t('primary-color')}</Label>
                  <ColorPicker
                    color={sitePrimaryColor}
                    onChange={handlePrimaryColorChange}
                    presetColors={presetColors}
                  />
                </div>
                <div className='svelte-1l8159pb-4 md:pr-8'>
                  <Label>{t('secondary-color')}</Label>
                  <ColorPicker
                    color={siteSecondaryColor}
                    onChange={handleSecondaryColorChange}
                    presetColors={presetColors}
                  />
                </div>
              </div>
              {/* <div className='w-full mx-2 flex-1 svelte-1l8159u '>
                </div> */}
            </Card>
            <Card
              className='flex flex-col mb-8'
              title={t('SITE_IMAGES')}
              titleClassName='text-lg font-semibold text-heading'
            >
              <div className='flex flex-col md:flex-row pt-[12px]'>
                <div className='w-full mx-2 flex-1 svelte-1l8159u '>
                  <UnControlledFileInput
                    withCrop
                    croppedMaxWidth={80}
                    croppedMaxHeight={80}
                    multiple={false}
                    isCircle
                    title={t('text-logo')}
                    // @ts-ignore
                    onImageUploaded={(val: string) => handleLogoUrlUpload(val)}
                    defaultValue={logo}
                    uploadSection={UPLOAD_SECTION.SITE_LOGO}
                  />
                </div>
                <div className='w-full mx-2 flex-1 svelte-1l8159u '>
                  <UnControlledFileInput
                    withCrop
                    multiple={true}
                    title={t('main_site_image')}
                    croppedMaxWidth={150}
                    croppedMaxHeight={100}
                    // @ts-ignore
                    onImageUploaded={(val: ImageType[]) => handelMainImageUpload(val)}
                    defaultValue={mainImages}
                    uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
                  />
                </div>
              </div>
            </Card>
            <Card
              className='flex flex-col mb-8'
              title={t('SITE_ABOUT_US_SECTION')}
              titleClassName='text-lg font-semibold text-heading'
            >
              <div className='flex flex-col md:flex-row'>
                <TextAreaWithTranslation
                  label={`${t('input-label-description')}`}
                  values={aboutUsDescription}
                  setValue={(val: any) => setAboutUsDescription(val)}
                  variant='outline'
                  className='mb-5'
                  name='description'
                />
              </div>
              <div className='w-full mx-2 flex-1 svelte-1l8159u '>
                <UnControlledFileInput
                  withCrop
                  multiple={false}
                  title={t('main_site_image_about_us')}
                  croppedMaxWidth={150}
                  croppedMaxHeight={100}
                  // @ts-ignore
                  onImageUploaded={(val: ImageType[]) => handelAboutUsImageUpload(val)}
                  defaultValue={aboutUsImage}
                  uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
                />
              </div>
            </Card>
            <Card
              className='w-full'
              title={<h1 className='text-lg font-semibold text-heading'>{t('ADDS_SECTION')}</h1>}
            >
              <div className='w-full mx-2 flex-1 svelte-1l8159u '>
                <UnControlledFileInput
                  withCrop
                  multiple={false}
                  title={t('ADDS_SECTION')}
                  croppedMaxWidth={150}
                  croppedMaxHeight={100}
                  // @ts-ignore
                  onImageUploaded={(val: string) => handelMainPageImageUpload(val)}
                  defaultValue={pageImages}
                  uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
                />
              </div>
            </Card>
            <Card
              className='w-full'
              title={
                <h1 className='text-lg font-semibold text-heading'>{t('PROMOTION_SECTION')}</h1>
              }
            >
              <div className='flex flex-col md:flex-row'>
                <TextAreaWithTranslation
                  label={`${t('input-label-description')}`}
                  values={promotionDescription}
                  setValue={(val: any) => setPromotionDescription(val)}
                  variant='outline'
                  className='mb-5'
                  name='description'
                />
              </div>
              <div className='w-full mx-2 flex-1 svelte-1l8159u '>
                <UnControlledFileInput
                  withCrop
                  multiple={true}
                  title={t('PROMOTION_SECTION')}
                  croppedMaxWidth={150}
                  croppedMaxHeight={100}
                  onImageUploaded={(val: ImageType[]) => handelPromotionImageUpload(val)}
                  defaultValue={promotionImages}
                  uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
                />
              </div>
              <DynamicInputList
                promotionsActions={filteredPromotionActions}
                onInputListChange={handleInputListUpdate}
              />
            </Card>
          </>
        )}
        {step === 3 && <></>}
        {step === 4 && <></>}
      </div>
    </>
  );
};

export default withPermission(TemplatePage);

{
  /* <li className='mr-2'>
              <a
                href='#'
                className={`inline-flex p-4  ${step === 1 ? activeClass : regularClass}`}
                onClick={() => setStep(1)}
              >
                {/* <svg
                aria-hidden='true'
                className='w-5 h-5 mr-2 text-accent-600 dark:text-accent-500'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'></path>
              </svg> 
                {t('main_page')}
              </a>
            </li>
            <li className='mr-2'>
              <a
                href='#'
                className={`inline-flex p-4  ${step === 2 ? activeClass : regularClass}`}
                onClick={() => setStep(2)}
              >
                {/* <svg
                aria-hidden='true'
                className='w-5 h-5 mr-2 text-accent-400 group-hover:text-accent-500 dark:text-accent-500 dark:group-hover:text-accent-300'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z'></path>
              </svg> 
                Settings
              </a>
            </li>
            <li className='mr-2'>
              <a
                href='#'
                className={`inline-flex p-4  ${step === 3 ? activeClass : regularClass}`}
                onClick={() => setStep(3)}
              >
                {/* <svg
                aria-hidden='true'
                className='w-5 h-5 mr-2 text-accent-400 group-hover:text-accent-500 dark:text-accent-500 dark:group-hover:text-accent-300'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M9 2a1 1 0 000 2h2a1 1 0 100-2H9z'></path>
                <path
                  fillRule='evenodd'
                  d='M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z'
                  clipRule='evenodd'
                ></path>
              </svg> 
                Contacts
              </a>
            </li> */
}
