/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const AnalyticsIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg
    fill='#000000'
    width='20px'
    height='20px'
    version='1.1'
    className='w-5 h-5 me-4'
    id='Layer_1'
    viewBox='0 0 491.52 491.52'
    xmlSpace='preserve'
  >
    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
    <g id='SVGRepo_iconCarrier'>
      {' '}
      <g>
        {' '}
        <g>
          {' '}
          <path d='M491.52,399.36V20.48H0v378.88h153.6v51.2h-40.96v20.48h266.24v-20.48h-40.96v-51.2H491.52z M20.48,40.96h450.56v276.48 H20.48V40.96z M317.44,450.56H174.08v-51.2h143.36V450.56z M20.48,378.88v-40.96h450.56v40.96H20.48z'></path>{' '}
        </g>{' '}
      </g>{' '}
      <g>
        {' '}
        <g>
          {' '}
          <polygon points='378.88,71.68 378.88,92.16 415.6,92.16 281.6,226.16 220.16,164.72 128,256.88 97.28,226.16 49.08,274.36 63.56,288.84 97.28,255.12 128,285.84 220.16,193.68 281.6,255.12 430.08,106.64 430.08,143.36 450.56,143.36 450.56,71.68 '></polygon>{' '}
        </g>{' '}
      </g>{' '}
    </g>
  </svg>
);
