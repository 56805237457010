/* eslint-disable @typescript-eslint/no-explicit-any */
export const Chair: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40.4'
      height='62'
      viewBox='0 0 40.4 62'
      fill='currentColor'
      {...props}
    >
      <g id='Chair' transform='translate(-312 -308)'>
        <circle
          id='Ellipse_111'
          data-name='Ellipse 111'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(322 314.4)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <circle
          id='Ellipse_112'
          data-name='Ellipse 112'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(341.2 314.4)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <circle
          id='Ellipse_113'
          data-name='Ellipse 113'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(331.6 314.4)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <circle
          id='Ellipse_114'
          data-name='Ellipse 114'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(322 324)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <circle
          id='Ellipse_115'
          data-name='Ellipse 115'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(341.2 324)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <circle
          id='Ellipse_116'
          data-name='Ellipse 116'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(331.6 324)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <circle
          id='Ellipse_117'
          data-name='Ellipse 117'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(322 333.6)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <circle
          id='Ellipse_118'
          data-name='Ellipse 118'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(341.2 333.6)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <circle
          id='Ellipse_119'
          data-name='Ellipse 119'
          cx='0.6'
          cy='0.6'
          r='0.6'
          transform='translate(331.6 333.6)'
          strokeWidth='2'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <path
          id='Path_17427'
          data-name='Path 17427'
          d='M351.4,339.8H313v-2.4a2.4,2.4,0,0,1,2.4-2.4H349a2.4,2.4,0,0,1,2.4,2.4Z'
          transform='translate(0 5.2)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_7'
          data-name='Line 7'
          y1='24'
          transform='translate(315.4 345)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          id='Line_8'
          data-name='Line 8'
          y2='24'
          transform='translate(349 345)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_17428'
          data-name='Path 17428'
          d='M348.6,340.2H315V313.8a4.8,4.8,0,0,1,4.8-4.8h24a4.8,4.8,0,0,1,4.8,4.8Z'
          transform='translate(0.4 0)'
          fill='none'
          stroke='#212121'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
