import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../utils/reduxHooks';
import Button from '../ui/button';
import { ShopDetailsType } from 'src/ts-types/custom.types';
import { businessDetailsModalValidation } from '../shop/businessDetailsModalValidation';
import Card from '../common/card';
import Input from '../ui/input';
import { toast } from 'react-toastify';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { getActiveShopDetails, updateShopDetails } from 'src/store/shop/shopsAPIActions';
import { getShopDetails } from 'src/utils/shopUtils';
import { TOAST_MESSAGE } from 'src/utils/constants';

type Props = {
  initialValues?: ShopDetailsType;
};

const BusinessDetailsModal = () => {
  const { t }: any = useTranslation<any>();
  const dispatch = useAppDispatch();
  const modalData = useModalState();
  const [loading, setLoading] = useState<boolean>(false);
  const { closeModal } = useModalAction();
  const { shopId } = getShopDetails();
  const { data } = modalData;
  const getDefaultValues = (data: {
    companyDetails: { phoneNumber: string; companyNumber: string };
  }) => {
    if (data?.companyDetails?.phoneNumber === '0') {
      data.companyDetails.phoneNumber = '';
    }
    if (data?.companyDetails?.companyNumber === '0') {
      data.companyDetails.companyNumber = '';
    }
    return data;
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors, isValid },
    // @ts-ignore
  } = useForm<ShopDetailsType>({
    mode: 'onBlur',
    ...(data
      ? {
          defaultValues: {
            ...getDefaultValues(data),
          },
        }
      : {}),
    resolver: yupResolver(businessDetailsModalValidation),
  });

  useEffect(() => {
    setValue('companyDetails.companyType', 'Company');
  }, [setValue]);

  // const handleLogoUrlUpload = (val?: string) => {
  //   setValue('logoUrl', val);
  //   trigger('logoUrl');
  // };

  const onSubmit = (values: ShopDetailsType) => {
    setLoading(true);
    values.shopId = shopId;
    dispatch(updateShopDetails(values))
      .then((response: any) => {
        toast.success(t(TOAST_MESSAGE.COMPANY_INFO_UPDATED_SUCCESSFULLY));
        dispatch(getActiveShopDetails());
        setLoading(false);
        closeModal();
      })
      .catch((err: any) => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        closeModal();
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='p-4 pb-6 bg-light m-auto w-full rounded-md md:rounded-xl sm:w-[24rem] md:w-full'>
        <div className='w-full h-full text-center'>
          <div className='mb-38'>
            <Card className='md:w-full mt-5' withShadow={false}>
              <div className='flex flex-col md:flex-row'>
                <Input
                  label={t('input-label-company-name')}
                  {...register('companyDetails.companyName')}
                  variant='outline'
                  placeholder={t('input-label-company-name-placeholder')}
                  error={t(errors.companyDetails?.companyName?.message)}
                />
                <Input
                  label={t('input-cpmany-id')}
                  {...register('companyDetails.companyNumber')}
                  variant='outline'
                  placeholder={t('input-cpmany-id')}
                  error={t(errors.companyDetails?.companyNumber?.message)}
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <Input
                  label={t('input-label-phone')}
                  {...register('customerDetails.Phone')}
                  variant='outline'
                  placeholder={t('input-label-phone-placeholder')}
                  error={t(errors.customerDetails?.Phone?.message)}
                />
                <Input
                  label={t('input-label-email')}
                  {...register('customerDetails.emailAddress')}
                  variant='outline'
                  placeholder={t('input-label-email')}
                  error={t(errors.customerDetails?.emailAddress?.message)}
                />
              </div>
            </Card>
            <div className='flex items-end space-s-8 float-left sm:pl-12 md:pl-41'>
              <Button
                className='ms-4 md:ms-6 float-left'
                type='submit'
                loading={loading}
                disabled={!isValid}
              >
                {t('button-label-save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BusinessDetailsModal;
