import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Card from 'src/components/common/card';
import ProgressBox from 'src/components/common/ProgressBox';
import Button from 'src/components/ui/button';
import Loader from 'src/components/ui/loader/loader';
import Select from 'src/components/ui/select/select';
import { Table } from 'src/components/ui/table';
import { siteSettings } from 'src/settings/site.settings';
import { setFetchOrderById } from 'src/store/shop/actions';
import { updateOrderByAdmin } from 'src/store/shop/shopsAPIActions';
import { ORDER_STATUS, ORDER_STATUS_PROCESS } from 'src/ts-types';
import { OrderItem, OrderStatus } from 'src/ts-types/custom.types';
import { formatAddress } from 'src/utils/format-address';
import { formatContact } from 'src/utils/format-contact';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { formatPrice } from 'src/utils/use-priceNew';
import Joyride from 'react-joyride';
import { useModalAction } from 'src/components/ui/modal/modal.context';
import { getLocale } from 'src/common';

type FormValues = {
  orderStatus: OrderStatus;
};

export default function OrderDetailsPage() {
  const { t }: any = useTranslation<any>();
  const { openModal } = useModalAction();
  const { id: orderId }: any = useParams();
  const [loading, setLoading] = useState(true);
  const { isFetching, data: order } = useAppSelector(
    (state) =>
      state.shop.shopDetails?.activeOrderResponse ?? {
        data: { orderComments: '', orderId: undefined },
        isFetching: false,
      },
  );
  const [orderStatus, setOrderStatus] = useState(order?.orderStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      dispatch(setFetchOrderById(orderId)).then(() => {
        setLoading(false);
      });
    }, 1000);
  }, []);
  // const { isLoading: updating } = updateOrderByAdmin(order);

  const { handleSubmit } = useForm<FormValues>({
    defaultValues: { orderStatus: order?.orderStatus },
  });

  const ChangeStatus = () => {
    if (order) {
      order.orderStatus = orderStatus;
      dispatch(updateOrderByAdmin(order));
    }
  };

  const GetOrderStatuses = () => {
    return Object.entries(ORDER_STATUS).map(([key, value]) => ({ key, value }));
  };

  const HandleStatusChanged = (value: any) => {
    setOrderStatus(value.key);
  };

  const handleModal = () => {
    const { paymentDetails } = order ?? {};
    openModal('REFUND', {
      amount: paymentDetails?.finalTotalPriceWithTax ?? 0,
      orderId: order?.orderId,
    });
  };

  const columns = [
    {
      dataIndex: 'image',
      key: 'image',
      width: 70,
      align: 'right',
      render: (image: any, item: OrderItem) => (
        <img
          src={item?.orderedVariantsList?.[0].mainImage ?? siteSettings.product.placeholder}
          alt='alt text'
          // layout="fixed"
          width={50}
          height={50}
        />
      ),
    },
    {
      title: t('table-item-products'),
      dataIndex: 'name',
      key: 'name',
      width: 70,
      align: 'right',
      render: (name: string, item: OrderItem) => (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
          <div>{item.name}</div>
          <div
            className='font-semibold'
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {`${t('units')} : ${item?.orderedVariantsList?.[0].quantity}`}
          </div>
          <div
            className='font-semibold'
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {`${t('product-code')} ${item?.productCode ?? ''}`}
          </div>
        </div>
      ),
    },
    {
      title: t('input-label-price'),
      dataIndex: 'price',
      key: 'price',
      width: 70,
      align: 'right',
      render: (_: any, item: OrderItem) => (
        <div>
          <span>
            {formatPrice({
              // @ts-ignore
              price: item.totalPriceAfterAllDiscounts,
              currencyCode: 'ILS',
              locale: getLocale(),
              displaySign: false,
            })}
          </span>
        </div>
      ),
    },
  ];
  if (isFetching || loading) return <Loader text={t('text-loading')} />;
  const steps = [
    {
      target: '.step4orders',
      content: t('step4orders'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step5orders',
      content: t('step5orders'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step6orders',
      content: t('step6orders'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step7orders',
      content: t('step7orders'),
      locale: {
        last: 'סיום',
        next: 'סיום', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
  ];
  [
    {
      target: '.tourSelector',
      content: 'This is the coolest feature on this page',
      title: 'You can have a title here!',
      styles: {
        // this styles override the styles in the props
        options: {
          textColor: 'tomato',
        },
      },
      locale: {
        next: <span>GO GO GO</span>,
        back: <span>BACK BACK</span>,
      },
      placement: 'top',
    },
  ];

  // Convert to Date object
  const date = new Date(order?.createdAt ?? '');

  // Format the date and time
  const formattedDate = date.toLocaleDateString('en-GB');
  const formattedTime = date.toLocaleTimeString('en-GB');

  return (
    <>
      {process.env.TOUR_IS_ACTIVE === 'true' && (
        <Joyride
          steps={steps}
          // callback={handleCallback}
          scrollToFirstStep
          showSkipButton
          styles={{
            options: {
              zIndex: 2000000,
            },
          }}
          continuous
          hideCloseButton
          showProgress
        />
      )}
      {order && (
        <Card>
          <h3 className='mb-8 w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-2xl font-semibold text-heading lg:mb-0 lg:text-start'>
            {t('input-label-order-id')} - {order?.orderId}
          </h3>
          <div className='flex md:flex-row flex-wrap'>
            <div className='w-full p-4 text-gray-200'>
              <div className='w-full mb-4 sm:mb-0 sm:pe-8'>
                <div className='mb-3 border-b border-border-200 pb-2 font-semibold text-heading'>
                  <p>
                    {t('order-date')} - <span className='text-sm text-body'> {formattedDate}</span>
                  </p>
                  <p>
                    {t('order-time')} - <span className='text-sm text-body'> {formattedTime}</span>
                  </p>
                  {t('customer-details')}
                  <div className='text-sm text-body'>
                    {order?.customerDetails && <span>{formatContact(order.customerDetails!)}</span>}
                  </div>
                  {t('address-details')}
                  <div className='text-sm text-body'>
                    {order?.customerDetails && <span>{formatAddress(order.customerDetails!)}</span>}
                  </div>
                  {t('payment-details')}
                  <div className='text-md text-body font-bold'>
                    {order?.cashMoney ? (
                      <span className='text-accent'>{t('payment-details-cash')}</span>
                    ) : (
                      <span className='text-accent'>{t('payment-details-visa')}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full md:w-1/4 text-center'>
              <div className='w-full sm:w-1/3 sm:mb-0'>
                <Button
                  className='w-full sm:w-auto'
                  variant='outline'
                  disabled={order.cashMoney || order.orderStatus?.toLocaleLowerCase() === 'failed'}
                  onClick={() => handleModal()}
                >
                  <span className='hidden sm:block'>{t('text-refund')}</span>
                </Button>
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center lg:flex-row md:col-3 step5orders mt-8'>
            <form
              onSubmit={handleSubmit(ChangeStatus)}
              className='flex w-full items-start ms-auto '
            >
              <div className='z-20 w-full me-5'>
                <Select
                  isDisabled={order.orderStatus?.toLocaleLowerCase() === 'failed'}
                  getOptionLabel={(option: any) => t(`order_status_${option.value}`)}
                  getOptionValue={(option: any) => option.key}
                  options={GetOrderStatuses()}
                  onChange={HandleStatusChanged}
                  placeholder={t('input-placeholder-order-status')}
                />
              </div>
              <Button
                loading={false}
                disabled={order.orderStatus?.toLocaleLowerCase() === 'failed'}
              >
                <span className='hidden sm:block'>{t('button-label-change-status')}</span>
                <span className='block sm:hidden'>{t('button-label-change')}</span>
              </Button>
            </form>
          </div>

          <div className='my-5 flex items-center justify-center lg:my-10 step6orders'>
            <ProgressBox data={Object.values(ORDER_STATUS_PROCESS)} status={order.orderStatus} />
          </div>

          <div className='mb-10 step7orders'>
            {order ? (
              <Table
                // @ts-ignore
                columns={columns}
                emptyText={t('empty-table-data')}
                data={order?.items}
                rowKey='productId'
                scroll={{ x: 300 }}
              />
            ) : (
              <span>{t('no-order-found')}</span>
            )}

            <div className='flex w-full flex-col space-y-2 border-t-4 border-double border-border-200 px-4 py-4 ms-auto sm:w-1/2 md:w-1/3'>
              <div className='flex items-center justify-between text-sm text-body'>
                <span>{t('products-price')}</span>
                <span>
                  {formatPrice({
                    // @ts-ignore
                    price: order?.paymentDetails?.productsPrice ?? 0,
                    currencyCode: 'ILS',
                    locale: getLocale(),
                    displaySign: false,
                  })}
                </span>
              </div>
              <div className='flex items-center justify-between text-sm text-body'>
                <span>{t('order-delivery-fee')}</span>
                <span>
                  {formatPrice({
                    // @ts-ignore
                    price: order?.paymentDetails?.shippingPrice ?? 0,
                    currencyCode: 'ILS',
                    locale: getLocale(),
                    displaySign: false,
                  })}
                </span>
              </div>
              <div className='flex items-center justify-between text-sm text-body'>
                <span>{t('order-sub-total')}</span>
                <span>
                  {formatPrice({
                    // @ts-ignore
                    price:
                      (order?.paymentDetails?.finalTotalPriceWithTax ?? 0) +
                      (order?.paymentDetails?.totalDiscount ?? 0),
                    currencyCode: 'ILS',
                    locale: getLocale(),
                    displaySign: false,
                  })}
                </span>
              </div>
              <div className='flex items-center justify-between text-sm text-body'>
                <span>{t('order-discount')}</span>
                <span>
                  {formatPrice({
                    // @ts-ignore
                    price: order?.paymentDetails?.totalDiscount ?? 0,
                    currencyCode: 'ILS',
                    locale: getLocale(),
                    displaySign: false,
                  })}
                </span>
              </div>
              <div className='flex items-center justify-between text-base font-semibold text-heading'>
                <span>{t('order-price-to-pay')}</span>
                <span>
                  {formatPrice({
                    // @ts-ignore
                    price: order?.paymentDetails?.finalTotalPriceWithTax ?? 0,
                    currencyCode: 'ILS',
                    locale: getLocale(),
                    displaySign: false,
                  })}
                </span>
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
}
