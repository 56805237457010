/* eslint-disable @typescript-eslint/no-explicit-any */
export const Watch: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      viewBox='0 0 512.00 512.00'
      xmlSpace='preserve'
      fill='currentColor'
      {...props}
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <g>
          {' '}
          <g>
            {' '}
            <g>
              {' '}
              <path d='M241.99,89.613c-91.746,0-166.387,74.641-166.387,166.387s74.641,166.388,166.387,166.388 s166.388-74.642,166.388-166.388S333.736,89.613,241.99,89.613z M392.491,263.842c-3.961,76.87-65.788,138.697-142.658,142.657 v-26.421h-15.685v26.421c-76.869-3.96-138.696-65.787-142.657-142.657h26.421v-15.685H91.491 c3.961-76.869,65.788-138.696,142.657-142.656v26.42h15.685v-26.42c76.87,3.96,138.697,65.787,142.658,142.656h-26.422v15.685 H392.491z'></path>{' '}
              <rect x='234.148' y='249.102' width='15.685' height='13.787'></rect>{' '}
              <rect x='234.148' y='269.786' width='15.685' height='55.146'></rect>{' '}
              <rect x='255.773' y='248.161' width='89.615' height='15.685'></rect>{' '}
              <rect x='235.099' y='179.23' width='13.787' height='15.685'></rect>{' '}
              <rect x='207.525' y='179.23' width='13.786' height='15.685'></rect>{' '}
              <rect x='262.674' y='179.23' width='13.787' height='15.685'></rect>{' '}
              <rect x='152.376' y='0' width='179.23' height='15.685'></rect>{' '}
              <path d='M332.552,84.525V20.679H151.428v63.845C89.984,117.106,48.029,181.74,48.029,255.999 c0,74.259,41.955,138.894,103.399,171.475v63.845h181.123v-63.845c61.444-32.581,103.399-97.216,103.399-171.475 C435.951,181.74,393.996,117.106,332.552,84.525z M316.866,36.365v40.717c-23.051-9.683-48.348-15.042-74.876-15.042 s-51.826,5.359-74.876,15.042V36.365H316.866z M167.113,475.634v-40.717c23.051,9.683,48.348,15.042,74.876,15.042 c26.528,0,51.826-5.359,74.876-15.042v40.717H167.113z M241.99,434.276c-98.301,0-178.275-79.974-178.275-178.275 S143.689,77.724,241.99,77.724s178.275,79.974,178.275,178.275S340.292,434.276,241.99,434.276z'></path>{' '}
              <rect x='152.376' y='496.312' width='179.23' height='15.685'></rect>
              <rect x='448.282' y='239.994' width='15.685' height='32.02'></rect>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};
