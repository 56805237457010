import { useTranslation } from 'react-i18next';
import Card from '../common/card';
import Input from '../ui/input';

interface Props {
  register: any;
  errors: any;
  getValues: any;
  show: boolean;
}
const ShopOwnerInfo = ({ register, errors, getValues, show }: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <div className='mb-100' hidden={!show}>
      <Card className='md:w-full mt-5' title={t('shop-customer-details')}>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-customer-name')}
            {...register('customerDetails.customerName')}
            variant='outline'
            error={t(errors.customerDetails?.customerName?.message)}
          />

          <Input
            label={t('input-label-phone')}
            {...register('customerDetails.Phone')}
            variant='outline'
            error={t(errors.customerDetails?.Phone?.message)}
          />
        </div>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-email-address')}
            {...register('customerDetails.emailAddress')}
            variant='outline'
            error={t(errors.customerDetails?.emailAddress?.message)}
          />
        </div>
      </Card>
      <Card className='md:w-full mt-5' title={t('shop-shop-address-title')}>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-city')}
            {...register('customerDetails.city')}
            variant='outline'
            error={t(errors.customerDetails?.city?.message)}
          />
          <Input
            label={t('input-street-address')}
            {...register('customerDetails.streetAddress')}
            variant='outline'
            error={t(errors.customerDetails?.streetAddress?.message)}
          />
          <Input
            label={t('input-zip-code')}
            {...register('customerDetails.zipCode')}
            variant='outline'
            error={t(errors.customerDetails?.zipCode?.message)}
          />
          <Input
            label={t('input-postal-number')}
            {...register('customerDetails.postalNumber')}
            variant='outline'
            error={t(errors.customerDetails?.postalNumber?.message)}
          />
        </div>
      </Card>
    </div>
  );
};

export default ShopOwnerInfo;
