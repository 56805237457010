/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const FacebookIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' {...props}>
    <path
      data-name='_ionicons_svg_logo-facebook (6)'
      d='M11.338 0H.662A.663.663 0 000 .663v10.674a.663.663 0 00.662.662H6V7.25H4.566V5.5H6V4.206a2.28 2.28 0 012.459-2.394c.662 0 1.375.05 1.541.072V3.5H8.9c-.753 0-.9.356-.9.881V5.5h1.794L9.56 7.25H8V12h3.338a.663.663 0 00.662-.663V.662A.663.663 0 0011.338 0z'
      fill='currentColor'
    />
  </svg>
);
