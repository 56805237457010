/* eslint-disable react/jsx-key */
import cn from 'classnames';
import React, { InputHTMLAttributes, useState } from 'react';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { siteSettings } from 'src/settings/site.settings';
import { TranslationObject } from 'src/ts-types/custom.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import _ from 'lodash';
import { getTranslatedLang, getTranslatedValue } from 'src/common';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  containerClassName?: string;
  label?: string;
  required?: boolean;
  note?: string;
  icon?: any;
  onBlur?: any;
  name: string;
  trigger?: () => void;
  values?: TranslationObject[];
  setValue: (name: any) => void;
  labelClass?: string;
  error?: string;
  type?: string;
  shadow?: boolean;
  variant?: 'normal' | 'solid' | 'outline';
  size?: any | 'small' | 'medium' | 'large';
}

const classes = {
  root: 'px-4 h-12 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0',
  normal: 'bg-gray-100 border border-border-base focus:shadow focus:bg-light focus:border-accent',
  solid: 'bg-gray-100 border border-border-100 focus:bg-light focus:border-accent',
  outline: 'border border-border-base focus:border-accent',
  shadow: 'focus:shadow',
  small: 'md:w-1/3',
  medium: 'md:w-2/3',
  large: 'md:w-3/3',
};

// eslint-disable-next-line react/display-name
const InputWithTranslation = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      size = 'large',
      label,
      icon,
      note,
      name,
      required,
      trigger,
      error,
      containerClassName = '',
      values,
      setValue,
      children,
      variant = 'normal',
      shadow = false,
      type = 'text',
      labelClass = '',
      inputClassName,
      onBlur,
      ...rest
    },
    ref,
  ) => {
    const [activeLanguage, setActiveLanguage] = useState<string>(siteSettings.languages[0].value);
    const [translatedObject, setTranslatedObject] = useState<TranslationObject[]>(values! || []);
    const [inputValue, setInputValue] = useState<any>(
      getTranslatedValue(values ?? [], activeLanguage),
    );
    const { t }: any = useTranslation<any>();

    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === 'normal',
        [classes.solid]: variant === 'solid',
        [classes.outline]: variant === 'outline',
      },
      {
        [classes.shadow]: shadow,
      },
      {
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
      },
      inputClassName,
      error ? 'border-red-500 focus:border-red-500' : '', // Apply red border if there's an error
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let _translatedObject = translatedObject;
      const textValue = e.target.value;
      const isLanExist = getTranslatedLang(_translatedObject, activeLanguage);
      if (isLanExist) {
        _translatedObject?.map((item: TranslationObject) => {
          if (item.Language === activeLanguage) {
            item.value = textValue;
          }
        });
        if (_.isEmpty(textValue)) {
          _translatedObject = _translatedObject.filter(
            (obj: TranslationObject) => obj.Language !== activeLanguage,
          );
        }
      } else {
        _translatedObject?.push({
          Language: activeLanguage,
          value: textValue,
        });
      }

      setTranslatedObject(_translatedObject);
      setValue(_translatedObject);
      setInputValue(getTranslatedValue(_translatedObject, activeLanguage));
    };

    const handleChangeActiveLanguage = (language: string) => {
      setActiveLanguage(language);
      setInputValue(getTranslatedValue(translatedObject, language) || '');
    };

    return (
      <div className={`svelte-1l8159u ${containerClassName}`}>
        <div className='flex items-center justify-between mb-1 mt-3'>
          <label
            htmlFor={name}
            className={`${
              required ? 'required-input' : ''
            } text-body-dark font-semibold text-sm leading-none`}
          >
            {label}
          </label>
          <div className='flex justify-end'>
            <span className='opacity-80 text-sm'>{t('translated_field')}</span>
            <FontAwesomeIcon icon={faLanguage as IconProp} size={'1x'} className='pr-2 pt-1' />
          </div>
        </div>
        <div className='relative h-12 inline-block items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0'>
          <input
            id={name}
            key={name}
            name={name}
            type={type}
            ref={ref}
            className={`p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:outline-none focus:ring-0 ${rootClassName}`}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck='false'
            value={inputValue}
            onBlur={onBlur}
            onChange={(e) => handleInputChange(e)}
            aria-invalid={error ? 'true' : 'false'}
            {...rest}
          />
          <div className={'flex text-xs '}>
            <div className='text-start text-xs text-start w-full flex-col md:flex-row items-center'>
              {siteSettings.languages?.map((item) => (
                <span
                  onClick={() => handleChangeActiveLanguage(item.value)}
                  className={` cursor-pointer ${
                    item.value === activeLanguage ? 'font-bold text-lg text-accent' : 'text-sm'
                  }`}
                >
                  {item.displayValue}
                </span>
              ))}
            </div>
            {error && (
              <div className='my-2 text-xs text-end text-red-500 justify-end w-full md:w-3/4 flex flex-col md:flex-row '>
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default InputWithTranslation;
