import Table from 'rc-table';
import { useTranslation } from 'react-i18next';
import { EmployeeDetails, ShopEmployeesResponse } from 'src/ts-types/custom.types';
import { useAppDispatch } from 'src/utils/reduxHooks';
import ActionButtons from '../common/action-buttons';
import { setActiveEmployee } from 'src/store/shop/actions';
import { SAVE_MODE } from 'src/ts-types';
import { ROUTES } from 'src/utils/routes';
import { useHistory } from 'react-router-dom';

export type IProps = {
  employees?: ShopEmployeesResponse;
};

const ShopEmployeeList = ({ employees }: IProps) => {
  const { t }: any = useTranslation<any>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const columns = [
    {
      title: t('text-email'),
      dataIndex: 'employeeEmail',
      key: 'employeeEmail',
      align: 'center',
      width: 74,
    },
    {
      title: t('table-item-phone'),
      dataIndex: 'employeeMobile',
      key: 'employeeMobile',
      align: 'center',
      width: 74,
    },
    {
      title: t('table-item-role'),
      dataIndex: 'employeeRole',
      key: 'employeeRole',
      align: 'center',
      width: 80,
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 80,
      render: (id: string, record: EmployeeDetails) => (
        <ActionButtons
          id={id || ''}
          editAction={() => {
            dispatch(setActiveEmployee(record, SAVE_MODE.EDIT)),
              history.push(ROUTES.EDIT_SHOP_EMPLOYEE);
          }}
          editUrl={ROUTES.EDIT_SHOP_EMPLOYEE}
          deleteModalView='DELETE_EMPLOYEE'
        />
      ),
    },
  ];

  return (
    <>
      <div className='rounded overflow-hidden shadow mb-6'>
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t('empty-table-data')}
          data={employees?.data ?? []}
          rowKey='plugInId'
          scroll={{ x: 900 }}
          rowClassName={() => 'cursor-pointer'}
          onRow={(record, index) => ({
            style: {
              cursor: 'pointer',
            },
          })}
        />
      </div>
    </>
  );
};

export default ShopEmployeeList;
