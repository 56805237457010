// ga.ts

import ReactGA from 'react-ga';

export const initGA = (trackingID: string) => {
  ReactGA.initialize(trackingID);
};

export const logPageView = () => {
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export enum CategoryTypeEnum {
  CreateShop = 'CREATE_NEW_SHOP',
}

export enum ActionTypeEnum {
  Fashion = 'Fashion',
  Accessories = 'Accessories',
  Food = 'Food',
  Electronics = 'Electronics',
  ArtsBooks = 'ArtsBooks',
  KidsBabies = 'KidsBabies',
  PetsSndAnimals = 'PetsSndAnimals',
  Other = 'Other',
}
