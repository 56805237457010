/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const AlertsIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg
    fill='#000000'
    viewBox='0 0 1920 1920'
    width='20px'
    height='20px'
    className='w-5 h-5 me-4'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='SVGRepo_bgCarrier' strokeWidth='0' />

    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />

    <g id='SVGRepo_iconCarrier'>
      {' '}
      <path
        d='M1185.928 1581.176c0 124.575-101.309 225.883-225.883 225.883-124.574 0-225.882-101.308-225.882-225.883h451.765ZM960.045 225.882c342.438 0 621.177 278.626 621.177 621.177v395.294c0 86.739 32.753 165.91 86.4 225.882H252.356c53.76-59.971 86.513-139.143 86.513-225.882V847.059c0-342.55 278.626-621.177 621.176-621.177Zm734.118 1016.47V847.06c0-385.694-299.294-702.268-677.647-731.294V0H903.575v115.765c-378.466 29.026-677.647 345.6-677.647 731.294v395.294c0 124.574-101.309 225.882-225.883 225.882v112.941h621.177c0 186.805 151.906 338.824 338.823 338.824 186.805 0 338.824-152.019 338.824-338.824h621.176v-112.94c-124.574 0-225.882-101.309-225.882-225.883Z'
        fillRule='evenodd'
      />{' '}
    </g>
  </svg>
);
