/* eslint-disable @typescript-eslint/no-explicit-any */
export const FacialCare: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg id='Magicons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42.61 40.5' {...props}>
      <defs></defs>
      <path
        className='cls-1'
        d='M19.35,23.67c-1.61,0-3.14.24-4.29.67-1.7.64-2.06,1.48-2.06,2.06.02,2.09,4.15,2.78,6.35,2.74,2.19.04,6.33-.65,6.35-2.74,0-.59-.36-1.42-2.06-2.06-1.15-.43-2.68-.67-4.29-.67h0ZM24.57,26.41c0,.25-.41.67-1.33,1.01-1.03.39-2.41.6-3.9.6-3.14,0-5.23-.97-5.23-1.61,0-.25.41-.67,1.33-1.01,1.03-.39,2.41-.6,3.9-.6,3.14,0,5.23.97,5.23,1.61Z'
      />
      <path
        className='cls-1'
        d='M28.57,15.38c0-1.54-1.64-2.74-3.73-2.74-4.94.09-4.94,5.39,0,5.48,2.09,0,3.73-1.2,3.73-2.74ZM24.84,16.99c-1.41,0-2.6-.74-2.6-1.61s1.19-1.61,2.6-1.61,2.6.74,2.6,1.61-1.19,1.61-2.6,1.61Z'
      />
      <path
        className='cls-1'
        d='M13.85,12.64c-2.09,0-3.73,1.2-3.73,2.74.11,3.63,7.35,3.62,7.45,0,0-1.54-1.64-2.74-3.73-2.74h0ZM13.85,16.99c-1.41,0-2.6-.74-2.6-1.61s1.19-1.61,2.6-1.61,2.6.74,2.6,1.61c0,.87-1.19,1.61-2.6,1.61Z'
      />
      <path
        className='cls-1'
        d='M8.37,24.06c-.54-1.63-.82-3.35-.82-5.12,0-.73-1.13-.73-1.13,0,0,1.89.29,3.73.87,5.47.23.68,1.3.34,1.07-.36Z'
      />
      <path
        className='cls-1'
        d='M9.19,28.26c.43.63,1.33-.04.93-.63-.32-.47-.61-.97-.87-1.48-.33-.65-1.33-.14-1,.51.28.55.6,1.09.94,1.59Z'
      />
      <path
        className='cls-1'
        d='M18.02,19.38c.22-.69-.85-1.03-1.07-.34l-.5,1.57c-.28.89.43,1.82,1.34,1.82h3.12c.91,0,1.62-.93,1.34-1.81l-.49-1.57c-.22-.69-1.29-.36-1.07.34l.49,1.57c.03.21-.06.32-.27.35h-3.12c-.21-.03-.3-.15-.26-.35l.5-1.57Z'
      />
      <path
        className='cls-1'
        d='M42.04,30.86h-.49c.08-1.17-.75-2.14-1.94-2.09-.25-1.5-1.48-2.68-3.01-2.88-.26-1.38-1.34-2.47-2.72-2.76,1.26-5.6-.15-11.98-3.76-16.21-.15-.18-.39-.24-.6-.17l-1.21.39.57-.93c.14-.23.1-.52-.09-.71-3.71-3.49-8.84-4.78-13.57-3.26-.69.22-.35,1.29.34,1.07,4.19-1.35,8.74-.29,12.12,2.7l-1.13,1.83c-.27.44.16.99.65.83l2.3-.74c3.39,4.16,4.55,10.41,3.1,15.74-.38,1.41-1.11,2.14-2.17,2.18-1.73,0-3.18,1.26-3.45,2.91-1.21-.05-2.03.92-1.94,2.09h-.49c-.31,0-.56.25-.56.56v2.74c-4.42,1.88-9.48.92-13.14-2.33l1.13-1.83c.27-.44-.16-.99-.65-.83l-2.3.74c-4.95-5.96-4.91-16.19.1-22.1l2.68.87c.49.16.92-.39.65-.83l-1.29-2.1c.53-.44,1.08-.83,1.65-1.18.62-.38.04-1.34-.58-.96-.76.46-1.49,1-2.17,1.6-.2.18-.25.48-.11.72l.75,1.21-1.59-.51c-.22-.07-.45,0-.6.17-2.78,3.26-4.32,7.56-4.32,12.13s1.5,8.76,4.21,12c.15.18.39.24.6.17l1.21-.39-.57.93c-.14.23-.1.52.09.71,3.88,3.67,9.37,4.9,14.25,3.02v1.72c0,1.63,1.32,2.95,2.95,2.95h12.72c1.63,0,2.95-1.32,2.95-2.95v-5.67c0-.31-.25-.56-.56-.56h0ZM30.44,27c1.62,0,2.73-1.32,3.17-2.76,1.07.2,1.88,1.11,1.92,2.22.01.3.26.54.56.54,1.13-.02,2.1.74,2.37,1.78h-10.34c.26-1.02,1.2-1.78,2.31-1.78h0ZM26.16,30.53c0-.35.28-.63.63-.63,4.33.05,8.68.05,13.01,0,.52,0,.69.54.63.96h-14.27v-.33ZM25.11,37.1v-5.11h3.34c-1.4,2.02-1.4,4.92,0,6.94h-1.52c-1.01,0-1.82-.82-1.82-1.82ZM41.48,37.1c0,1.01-.82,1.82-1.82,1.82h-1.52c.37-.56.66-1.17.83-1.82.18-.72-.87-1.01-1.09-.3-.22.79-.62,1.51-1.18,2.12h-6.83c-1.8-1.88-1.8-5.06,0-6.94h6.83c.56.61.97,1.35,1.18,2.14.22.71,1.26.43,1.09-.29-.18-.66-.46-1.28-.84-1.85h3.34v5.11Z'
      />
      <path
        className='cls-1'
        d='M35.25,34.9h-3.9c-.74.02-.74,1.1,0,1.13h3.9c.31,0,.56-.25.56-.56s-.25-.56-.56-.56Z'
      />
    </svg>
  );
};
