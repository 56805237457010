import { Helmet } from 'react-helmet';

const MetaPixel = () => {
  return (
    <Helmet>
      <Helmet>
        <meta name='facebook-domain-verification' content='l90vq962xiz9vttw3ycseca5wv7jx3' />
        <script>
          {`
           !function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
           n.queue=[];t=b.createElement(e);t.async=!0;
           t.src=v;s=b.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window, document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '1039916637054411');
           fbq('track', 'PageView');
        `}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1039916637054411&ev=PageView&noscript=1"
            />`}</noscript>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-QCMSRBQWZ9'></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', 'G-QCMSRBQWZ9');
        `}
        </script>
      </Helmet>
    </Helmet>
  );
};

export default MetaPixel;
