/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/common/card';
import Button from 'src/components/ui/button';
import cn from 'classnames';
import Loader from 'src/components/ui/loader/loader';
import { getAllPlugins, setActivePlugin } from 'src/store/shop/actions';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import PluginList from 'src/components/plugins/pluginList';
import { SAVE_MODE } from 'src/ts-types';
import { ROUTES } from 'src/utils/routes';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/store';

export default function PluginsPage() {
  const plugins = useAppSelector((state: RootState) => state.shop.shopDetails?.pulgins);
  const { t }: any = useTranslation();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isFetching = useAppSelector((state: RootState) => plugins?.isFetching);

  useEffect(() => {
    dispatch(getAllPlugins());
  }, [dispatch]);

  if (isFetching) return <Loader text={t('text-loading')} />;

  const handleCreateNewPlugin = () => {
    dispatch(
      setActivePlugin(
        {
          plugInId: '',
          plugInTitleWithTranslations: [],
          plugInDescriptionWithTranslations: [],
          icon: '',
          active: false,
        },
        SAVE_MODE.SAVE,
      ),
    );
    history.push(ROUTES.EDIT_CREATE_PLUGIN);
  };
  return (
    <>
      <Card className='flex flex-col mb-8'>
        <div className='w-full flex md:flex-row smx:flex-row items-center'>
          <div className='md:w-1/4 mb-4 md:mb-0'>
            <h1 className='text-lg font-semibold text-heading'>{t('PLUGINS_TITLE')}</h1>
          </div>

          <div className='justify-end w-full md:w-3/4 flex md:flex-row items-center'>
            <Button onClick={() => handleCreateNewPlugin()} className='h-12 ms-4 md:ms-6'>
              <span className='hidden md:block'>+ {t('button-label-add-plugin')}</span>
              <span className='md:hidden'>+ {t('button-label-add')}</span>
            </Button>
          </div>
        </div>
        <div
          className={cn('w-full flex transition', {
            'h-auto visible': visible,
            'h-0 invisible': !visible,
          })}
        ></div>
      </Card>
      <PluginList plugins={plugins} />
      <ToastContainer autoClose={2000} />
    </>
  );
}
