// @ts-nocheck
import * as yup from 'yup';

export const variantValidationSchema = yup.object().shape({
  nameWithTranslation: yup
    .array()
    .min(1, 'required')
    .of(
      yup.object().shape({
        value: yup.string(),
        Language: yup.string().required('required'),
      }),
    ),

  productCode: yup.number().required('error-number-required'),

  price: yup
    .number()
    .typeError('error-price-must-number')
    .positive('error-price-must-positive')
    .required('error-price-required'),

  discountPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .test({
      name: 'discountPrice',
      message: 'מחיר מבצע לא תקין',
      test: function (value) {
        const { price } = this.parent;
        if (value != null && price != null) {
          return price - value > 0;
        }
        return true; // If there's no discountPrice, the test passes
      },
    }),

  endDiscountDate: yup.date().when('discountPrice', {
    is: (discountPrice) => discountPrice != null, // Validate only if discountPrice is present
    then: yup.date().min(new Date(), 'sales-date').required('sales-exp'),
    otherwise: yup.date().nullable(), // If no discountPrice, no validation on date
  }),
  // status: yup.mixed().when('productType', {
  //   is: (productType: { name: string; value: string;[key: string]: unknown }) =>
  //     productType?.value === ProductType.Simple,
  //   then: yup.mixed().when('productType', {
  //     is: (productType: { name: string; value: string;[key: string]: unknown }) =>
  //       productType?.value === ProductType.Simple,
  //     then: yup.bool().required('error-status-required'),
  //   }),
  // }),
});
