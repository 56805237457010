/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const Eyes: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14.989'
      height='18'
      fill='currentColor'
      {...props}
    >
      <g fill='currentColor'>
        <path
          data-name='Path 21753'
          d='M6.071 5.076H4.92V4.002h.165a.332.332 0 000-.663H4.92V1.934a.331.331 0 00-.331-.331H1.814a.331.331 0 00-.331.331v1.405h-.165a.332.332 0 000 .663h.165v1.074H.331A.331.331 0 000 5.407v11.056A1.539 1.539 0 001.537 18h3.328a1.539 1.539 0 001.537-1.537V5.407a.331.331 0 00-.331-.331zm-3.926-2.81h2.112V3.34H2.145zm0 1.736h2.112v1.074H2.145zM5.74 16.463a.876.876 0 01-.875.875H1.537a.876.876 0 01-.875-.875V5.738H5.74z'
        />
        <path
          data-name='Path 21754'
          d='M1.99 8.328a.331.331 0 00-.331.331v3.908a.331.331 0 10.662 0V8.658a.331.331 0 00-.331-.33z'
        />
        <path
          data-name='Path 21755'
          d='M1.99 14.086a.331.331 0 00-.331.331v1.2a.331.331 0 00.662 0v-1.2a.331.331 0 00-.331-.331z'
        />
        <path
          data-name='Path 21756'
          d='M14.657 10.189h-2.31v-4.14c.663-.334 1.007-1.666 1.007-2.984 0-1.523-.46-3.065-1.339-3.065s-1.339 1.542-1.339 3.065c0 1.318.345 2.65 1.007 2.984v4.14h-2.31a.331.331 0 00-.331.331v6.039A1.443 1.443 0 0010.483 18h3.063a1.443 1.443 0 001.441-1.441v-6.042a.331.331 0 00-.33-.328zM11.34 3.065c0-1.56.48-2.4.676-2.4s.676.842.676 2.4-.48 2.4-.676 2.4-.676-.84-.676-2.4zm2.986 13.494a.78.78 0 01-.779.779h-3.062a.78.78 0 01-.779-.779v-3.72h1.553a.332.332 0 100-.663H9.706v-1.325h4.62z'
        />
      </g>
    </svg>
  );
};
