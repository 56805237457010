import * as yup from 'yup';

export const shopValidationSchema = yup.object().shape({
  customerDetails: yup.object().shape({
    customerName: yup.string().required('error-name-required'),
    emailAddress: yup.string().email('error-submit-email').required('required'),
    Phone: yup
      .string()
      .required('required')
      .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'error-phone-required'),
    city: yup.string().required('error-city-required'),
    streetAddress: yup.string().required('error-street-address-required'),
  }),
  companyDetails: yup.object().shape({
    companyName: yup.string().required('error-companyName-required'),
    companyType: yup.string().required('error-companyType-required'),
    companyNumber: yup
      .number()
      .test('len', 'error-companyId', (val: any) => val.toString().length <= 9)
      .required('required'),
    phoneNumber: yup
      .string()
      .required('required')
      .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'error-phone-required'),
    Officeguy: yup.object().shape({
      apiKey: yup.string().required('required').nullable(),
      companyID: yup.string().required('error-companyId').nullable(),
    }),
  }),
  siteTitle: yup.string().required('required'),
  siteSubtitle: yup.string().required('required'),
  shopDescription: yup.string().required('required'),
  logoUrl: yup.string().required('required'),
  siteMainImage: yup.string().required('required'),
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'error-site-domain',
    )
    .required('error-site-domain'),
});
