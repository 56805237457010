import Alert from 'src/components/ui/alert';
import Button from 'src/components/ui/button';
import Input from 'src/components/ui/input';
import PasswordInput from 'src/components/ui/password-input';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ROUTES } from 'src/utils/routes';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { setAuthCredentials } from 'src/utils/authUtils';
import { Permission } from 'src/ts-types/generated';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { registerAction, googleLogIn, getOTP } from 'src/store/session/actions';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { getShopDetails } from 'src/utils/shopUtils';
import { setShopDataForCreate } from 'src/store/utilData/actionTypes';
import { useModalAction } from '../ui/modal/modal.context';

type Props = {
  callbackUrl?: string;
};

type FormValues = {
  name: string;
  email: string;
  password: string;
  permission: Permission;
};
const registrationFormSchema = yup.object().shape({
  name: yup.string().required('error-name-required'),
  email: yup.string().email('error-email-format').required('error-email-required'),
  password: yup
    .string()
    .min(8, 'error-password')
    .max(50, 'error-password')
    .required('error-password-required'),
});

const RegistrationForm = (props: Props) => {
  const { openModal } = useModalAction();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const history = useHistory();
  const { t }: any = useTranslation<any>();

  const dispatch = useAppDispatch();
  const clientId =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : process.env.REACT_APP_GOOGLE_API_KEY_DEV;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const shopForCreateDataResponse = useAppSelector(
    (state) => state.util.UtilData?.shopForCreateData,
  );

  const { shopId } = getShopDetails();
  const { callbackUrl: forwardURL = `${ROUTES.ACTIVE_SHOP}${shopId}` } = props;
  const methods = useForm<FormValues>({
    resolver: yupResolver(registrationFormSchema),
    mode: 'onBlur',
    defaultValues: {
      email: shopForCreateDataResponse?.customerDetails?.emailAddress,
    },
  });

  const {
    register,
    getValues,
    formState: { errors, isValid },
  } = methods;

  const handleOpenModal = () => {
    openModal('OTP_MODAL', { onSubmitNewUser, name, email, password });
  };

  const handleCallbackResponse = (response: CredentialResponse) => {
    const googleToken = response.credential;
    setIsFetching;
    true;
    dispatch(googleLogIn(googleToken)).then((response: any) => {
      const { accessToken, refreshToken } = response.data;
      if (accessToken) {
        setAuthCredentials(accessToken, refreshToken);
        dispatch(setShopDataForCreate({ ...shopForCreateDataResponse, isNewShop: true }));
        // dispatch(getAllMyShops());
        // history.push(forwardURL);

        // if (shopForCreateDataResponse?.packageId) {
        //   dispatch(
        //     createAnonymousShop({
        //       ...shopForCreateDataResponse,
        //       website: shopForCreateDataResponse.website,
        //     }),
        //   ).then(() => {
        // history.push(forwardURL);
        // setIsFetching(false);
        // });
        // }
      } else {
        history.push(ROUTES.SHOPS);
      }
    });
  };

  const onSubmit = async ({ name, email, password }: FormValues) => {
    if (isValid) {
      setEmail(email);
      setName(name);
      setPassword(password);
      // dispatch(getOTP(email, '')).then((response: any) => {
      //   handleOpenModal();
      // });
      onSubmitNewUser(name, email, password, '1234');
    }
  };

  const onSubmitNewUser = async (
    name: string,
    email: string,
    password: string,
    otpCode: string,
  ) => {
    if (isValid) {
      if (otpCode) {
        setIsFetching(true);
        dispatch(registerAction(name, email, password, otpCode))
          .then((response: any) => {
            const { accessToken, refreshToken } = response.data;
            if (accessToken) {
              setAuthCredentials(accessToken, refreshToken);
              dispatch(setShopDataForCreate({ ...shopForCreateDataResponse, isNewShop: true }));
              // history.push(forwardURL);

              // if (shopForCreateDataResponse?.packageId) {
              //   dispatch(
              //     createAnonymousShop({
              //       ...shopForCreateDataResponse,
              //       website: shopForCreateDataResponse?.website,
              //     }),
              //   ).then(() => {
              //     history.push(forwardURL);
              //     setIsFetching(false);
              //   });
              // } else {
              //   history.push(ROUTES.SHOPS);
              // }
            } else {
              history.push(ROUTES.SHOPS);
            }
          })
          .catch((error: { response: { data: { systemMessage: any } } }) => {
            console.log(error);
            const { systemMessage } = error?.response?.data || {};
            setErrorMessage(t(systemMessage));
            setIsFetching(false);
          });
      } else {
        setErrorMessage('invalidOtp');
      }
    }
  };

  return (
    <div className='flex-1 w-[300px]'>
      {errorMessage ? (
        <Alert
          variant='error'
          closeable={true}
          className='mt-5'
          onClose={() => setErrorMessage('')}
          message={t(errorMessage)}
        />
      ) : null}
      <FormProvider {...methods}>
        <form id='reg-form' name='reg-form'>
          <Input
            label={t('input-label-name')}
            {...register('name')}
            variant='outline'
            error={t(errors?.name?.message)}
          />
          <Input
            label={t('input-label-email')}
            {...register('email')}
            type='email'
            variant='outline'
            error={t(errors?.email?.message)}
          />
          <PasswordInput
            label={t('input-label-password')}
            {...register('password')}
            error={t(errors?.password?.message)}
            variant='outline'
          />
          <div className='text-center mt-6'>
            <Button
              className='mr-2 w-full max-w-[260px]'
              type='button'
              form='reg-form'
              loading={isFetching}
              onClick={() => onSubmit(getValues())}
              theme='rounded'
            >
              {t('text-register')}
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className='mt-6'
          >
            <GoogleOAuthProvider clientId={clientId ?? ''}>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleCallbackResponse(credentialResponse);
                }}
                onError={() => {
                  setErrorMessage('bad creds');
                }}
                useOneTap
              />
            </GoogleOAuthProvider>
          </div>
        </form>
      </FormProvider>
      <div className='flex flex-col items-center justify-center relative text-sm text-heading mt-8 sm:mt-4 mb-6 sm:mb-4'>
        <hr className='w-full' />
        <span className='absolute start-2/4 -top-2.5 px-2 -ms-4 bg-light'>{t('text-or')}</span>
      </div>
      <div className='text-sm sm:text-base text-body text-center'>
        {t('text-already-account')}{' '}
        <Link
          className='ms-1 underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-700 hover:no-underline focus:no-underline'
          to={`${ROUTES.LOGIN}`}
        >
          {t('button-label-login')}
        </Link>
      </div>
    </div>
  );
};

export default RegistrationForm;
