/* eslint-disable @typescript-eslint/no-explicit-any */
export const Vitamins: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg id='Magicons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42.61 40.5' {...props}>
      <defs></defs>
      <path
        className='cls-1'
        d='M31.86,20.49l2-2c3.63-3.63,3.63-9.5,0-13.12-3.63-3.63-9.5-3.63-13.12,0-.77.77-15.93,15.93-16.63,16.63-3.63,3.63-3.63,9.5,0,13.12,3.63,3.63,9.5,3.63,13.12,0l2-2c1.6,2.81,4.62,4.71,8.08,4.71,5.12,0,9.28-4.16,9.28-9.28,0-3.46-1.9-6.48-4.71-8.08ZM18.01,28.57c0,.87.12,1.71.34,2.51l-2.6,2.6c-2.82,2.82-7.39,2.82-10.21,0-2.82-2.82-2.82-7.39,0-10.21l7.53-7.53,6.91,6.91c-1.24,1.58-1.98,3.56-1.98,5.72ZM26.26,35.71c-3.49-.5-6.19-3.51-6.19-7.14s2.69-6.64,6.19-7.14v14.29ZM21.43,21.37l-6.9-6.9,7.65-7.65c2.82-2.82,7.39-2.82,10.21,0,2.82,2.82,2.82,7.39,0,10.21l-2.6,2.6c-.8-.22-1.64-.34-2.51-.34-2.22,0-4.26.78-5.86,2.09ZM28.32,35.71v-14.29c3.49.5,6.19,3.51,6.19,7.14,0,3.63-2.69,6.64-6.19,7.14Z'
      />
    </svg>
  );
};
