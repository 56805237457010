import Uploader from 'src/components/common/uploader';
import CropUploader from '../common/cropUploader';

interface FileInputProps {
  multiple?: boolean;
  withCrop?: boolean;
  onImageUploaded: (val: any) => void;
  title?: string;
  classContainer?: string;
  defaultValue?: string[] | string | any;
  uploadSection: string;
  showDefaultValue?: boolean;
  size?: 'small' | 'large';
  imgMaxHeight?: number;
  imgMaxWidth?: number;
  isCircle?: boolean;
  croppedMaxWidth?: number;
  croppedMaxHeight?: number;
}

const UnControlledFileInput = ({
  multiple = true,
  onImageUploaded,
  uploadSection,
  size = 'large',
  showDefaultValue = false,
  title = '',
  classContainer = '',
  defaultValue = [''],
  croppedMaxWidth,
  croppedMaxHeight,
  isCircle = false,
  withCrop = false,
  ...rest
}: FileInputProps) => {
  return (
    <>
      {withCrop ? (
        <CropUploader
          {...rest}
          classContainer={classContainer}
          multiple={multiple}
          onImageUploaded={onImageUploaded}
          title={title}
          defaultValue={defaultValue}
          croppedMaxWidth={croppedMaxWidth}
          isCircle={isCircle}
          croppedMaxHeight={croppedMaxHeight}
          uploadSection={uploadSection}
          showDefaultValue={showDefaultValue}
          size={size}
        />
      ) : (
        <Uploader
          rest
          classContainer={classContainer}
          multiple={multiple}
          onImageUploaded={onImageUploaded}
          title={title}
          defaultValue={defaultValue}
          uploadSection={uploadSection}
          showDefaultValue={showDefaultValue}
          size={size}
        />
      )}
    </>
  );
};

export default UnControlledFileInput;
