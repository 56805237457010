import ErrorMessage from 'src/components/ui/error-message';
import { useAppSelector } from 'src/utils/reduxHooks';
import { RootState } from 'src/store';
import CreateOrUpdateAddOnCategoriesForm from 'src/components/categoriesAddons/addon-category-form';

export default function UpdateAddonCategoriesPage() {
  const activeAddOnCategory = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeAddonsCategory,
  );
  const error = useAppSelector((state: RootState) => state.shop.shopDetails?.error);

  if (error) return <ErrorMessage message={error} />;

  return <CreateOrUpdateAddOnCategoriesForm initialValues={activeAddOnCategory} />;
}
