/* eslint-disable react/prop-types */
import React from 'react';
export const CartIconBig = ({ width = '48px', height = '48px' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 48 48'>
      <g data-name='Group 2994' transform='translate(-601 -757)'>
        <rect
          data-name='Rectangle 541'
          width='48'
          height='48'
          rx='20'
          transform='translate(601 757)'
          fill='#facaca'
        />
        <g transform='translate(610.988 768.246)'>
          <g data-name='Group 2985' transform='translate(3.013 0.754)'>
            <path
              data-name='Path 3641'
              d='M6.071,15.424H23.177a.726.726,0,0,0,.724-.674l.778-10.885a.726.726,0,0,0-.724-.778h-8.6V1.48a.726.726,0,1,0-1.452,0V3.087H5.832c-.051-.618-2.058-.416-.7-.077-.113-.517-.571-.277-.71,0L5.29,14.073a3.058,3.058,0,0,0,.78,6.016h.121a3.058,3.058,0,1,0,5.2,0h6.849a3.059,3.059,0,1,0,5.175-.042.726.726,0,0,0-.241-1.41H6.071a1.607,1.607,0,0,1,0-3.213ZM13.9,4.539V9.058L12.8,7.964a.726.726,0,0,0-1.027,1.027l2.333,2.333a.726.726,0,0,0,1.027,0L17.47,8.991a.726.726,0,0,0-1.027-1.027L15.35,9.058V4.539h7.825L22.5,13.972H6.739L5.953,4.539Zm6.946,15.55A1.607,1.607,0,1,1,19.237,21.7,1.608,1.608,0,0,1,20.844,20.089Zm-12.051,0A1.607,1.607,0,1,1,7.186,21.7,1.608,1.608,0,0,1,8.792,20.089Z'
              transform='translate(-3.013 -0.754)'
              fill='#ff6e6e'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
