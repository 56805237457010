// @ts-nocheck
import { ProductType } from 'src/ts-types/generated';
import * as yup from 'yup';

export const productValidationSchema = yup.object().shape({
  name: yup
    .array()
    .min(1, 'required')
    .of(
      yup.object().shape({
        value: yup.string(),
        Language: yup.string().required('required'),
      }),
    ),
  categoryId: yup.string().required('required'),

  productType: yup.string().required('required'),

  unit: yup.mixed().when('productType', {
    is: (productType: { name: string; value: string; [key: string]: unknown }) =>
      productType?.value === ProductType.Simple,
    then: yup
      .number()
      .typeError('error-quantity-must-number')
      .positive('error-quantity-must-positive')
      .integer('error-quantity-must-integer')
      .required('error-quantity-required'),
  }),

  productCode: yup.number().required('error-number-required'),

  price: yup.number().when('productType', {
    is: 'simple', // Apply validation only when productType is 'simple'
    then: yup
      .number()
      .typeError('error-price-must-number')
      .positive('error-price-must-positive')
      .required('error-price-required'),
    otherwise: yup.number().nullable(), // Otherwise no validation
  }),

  discountPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .test({
      name: 'discountPrice',
      message: 'מחיר מבצע לא תקין',
      test: function (value) {
        const { price } = this.parent;
        if (value != null && price != null) {
          return price - value > 0;
        }
        return true; // If there's no discountPrice, the test passes
      },
    }),

  endDiscountDate: yup.date().when(['discountPrice', 'productType'], {
    is: (discountPrice, productType) => discountPrice != null && productType === 'Simple', // Check if discountPrice exists and productType is 'Simple'
    then: yup
      .date()
      .min(new Date(), 'sales-date') // Date must be today or later
      .required('sales-exp'), // Required if discountPrice and productType are valid
    otherwise: yup.date().nullable(), // No validation if conditions aren't met
  }),

  status: yup.mixed().when('productType', {
    is: (productType: { name: string; value: string; [key: string]: unknown }) =>
      productType?.value === ProductType.Simple,
    then: yup.mixed().when('productType', {
      is: (productType: { name: string; value: string; [key: string]: unknown }) =>
        productType?.value === ProductType.Simple,
      then: yup.bool().required('error-status-required'),
    }),
  }),

  // variation_options: yup.array().of(
  //   yup.object().shape({
  //     quantity: yup
  //       .number()
  //       .typeError('error-quantity-must-number')
  //       .positive('error-quantity-must-positive')
  //       .integer('error-quantity-must-integer')
  //       .required('error-quantity-required'),
  //     sku: yup.string().required('error-sku-required'),
  //   }),
  // ),
});
