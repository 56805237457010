import cn from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getLocale, getTranslatedProductItem } from 'src/common';
import { siteSettings } from 'src/settings/site.settings';
import { Product } from 'src/ts-types/custom.types';
import { formatPrice } from 'src/utils/use-priceNew';

type KryXenonProps = {
  product: Product;
  className?: string;
};

const KryXenon = ({ product, className }: KryXenonProps) => {
  const { t }: any = useTranslation<any>();
  const { productInfoWithTranslations, mainImage, discountPrice, price } = product ?? {};
  const productName = getTranslatedProductItem(productInfoWithTranslations ?? []).name;
  const formattedPrice = formatPrice({
    // @ts-ignore
    price: price,
    currencyCode: 'ILS',
    locale: getLocale(),
  });
  const formattedDiscountPrice = formatPrice({
    // @ts-ignore
    price: discountPrice,
    currencyCode: 'ILS',
    locale: getLocale(),
  });
  // const productTitle = getTranslatedProductItem(productInfoWithTranslations ?? []).shortDescription;
  // const productDescription = getTranslatedProductItem(
  //   productInfoWithTranslations ?? [],
  // ).description;
  return (
    <div>
      <article
        className={cn(
          'product-card cart-type-xenon rounded h-full bg-light overflow-hidden border border-border-200 border-opacity-70 transform transition-all duration-200 hover:shadow hover:border-transparent hover:-translate-y-0.5',
          className,
        )}
      >
        <div className='relative flex items-center justify-center cursor-pointer w-auto h-48 sm:h-64'>
          <span className='sr-only'>{t('text-product-image')}</span>
          <img
            className='product-image'
            src={_.isEmpty(mainImage) ? siteSettings.product.placeholder : mainImage}
            width={'160px'}
            height={'180px'}
          />

          <div className='absolute top-3 start-3 md:top-4 md:start-4 rounded text-xs leading-6 font-semibold px-1.5  md:px-2 lg:px-2.5 bg-skin-primary text-light'>
            {discountPrice}
          </div>
        </div>
        {/* End of product image */}

        <header className='p-3 md:p-6'>
          <h3 className='text-xs md:text-sm text-body truncate cursor-pointer'>
            {_.isEmpty(productName) ? t('input-label-name') : productName}
          </h3>
          {/* End of product name */}

          {/* End of price */}
          <div className='flex items-center justify-between mt-2'>
            <>
              <div className='flex md:items-center flex-col md:flex-row'>
                <span className='text-sm md:text-base text-heading font-semibold'>
                  {formattedDiscountPrice}
                </span>
                <del className='text-xs text-muted mt-1 md:mt-0 md:ms-2'>{formattedPrice}</del>
              </div>

              {/* {variantIsInSock(mainVariant) && (
              <AddToCart
                variant={ProductType.XENON}
                data={mainVariant}
                counterClass="absolute sm:static bottom-3 end-3 sm:bottom-0 sm:end-0"
              />
            )} */}
            </>

            <div className='bg-red-500 rounded text-xs text-light px-1 py-1 truncate'>
              {t('text-out-stock')}
            </div>

            {/* End of cart */}
          </div>
        </header>
      </article>
    </div>
  );
};

export default KryXenon;
