/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const MyShopIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.828 14.8' {...props}>
    <g
      data-name='my shop'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='.8'
    >
      <path data-name='Line 1' d='M15.237 14.4V4.883' />
      <path data-name='Line 2' d='M1.59 14.4V4.883' />
      <path
        data-name='Path 22619'
        d='M15.316 1.677a.626.626 0 00.612-.639h0a.626.626 0 00-.612-.639h-13.8a.639.639 0 000 1.277'
      />
      <path
        data-name='Path 22620'
        d='M13.875 6.616v4.986a.7.7 0 01-.683.712H9.023a.7.7 0 01-.683-.712V6.617a.7.7 0 01.683-.712h4.169a.7.7 0 01.683.711z'
      />
      <path
        data-name='Path 22621'
        d='M2.954 14.4V8.495a.7.7 0 01.684-.714h2.539a.7.7 0 01.684.714V14.4'
      />
      <path data-name='Line 3' d='M3.706 11.007v.38' />
      <path
        data-name='Path 22622'
        d='M3.791 4.897h0a1.13 1.13 0 01-.98-1.618l.729-1.6h1.95l-.656 2.4a1.092 1.092 0 01-1.043.818z'
      />
      <path
        data-name='Path 22623'
        d='M8.414 4.897h0A1.185 1.185 0 017.266 3.58l.174-1.9h1.95l.174 1.9a1.185 1.185 0 01-1.15 1.317z'
      />
      <path
        data-name='Path 22624'
        d='M13.039 4.897h0a1.094 1.094 0 01-1.044-.822l-.656-2.4h1.95l.729 1.6a1.129 1.129 0 01-.979 1.622z'
      />
      <path
        data-name='Path 22625'
        d='M1.431 4.897h0a1.062 1.062 0 01-.845-1.646l1-1.574h1.95L2.344 4.293a1.013 1.013 0 01-.913.604z'
      />
      <path
        data-name='Path 22626'
        d='M5.955 4.897h0a1.051 1.051 0 01-.976-1.35l.51-1.87h1.95l-.18 1.983a1.333 1.333 0 01-1.304 1.237z'
      />
      <path
        data-name='Path 22627'
        d='M10.715 4.897h0a1.155 1.155 0 01-1.131-1.072l-.2-2.148h1.95l.468 1.71a1.175 1.175 0 01-1.087 1.51z'
      />
      <path
        data-name='Path 22628'
        d='M15.398 4.897a1.009 1.009 0 01-.916-.6L13.29 1.676h1.948l1 1.575a1.061 1.061 0 01-.84 1.646z'
      />
      <path data-name='Line 4' d='M1.135 14.4h14.559' />
    </g>
  </svg>
);
