// @ts-nocheck
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Loader from 'src/components/ui/loader/loader';
import { withPermission } from 'src/HOC/withPermission';
import { getAllAlerts } from '../../store/shop/shopsAPIActions';
import Card from '../../components/common/card';
import { Table } from '../../components/ui/table';
import { AlertDetails } from '../../ts-types/custom.types';
import { ROUTES } from '../../utils/routes';
import { setShopId } from 'src/utils/shopUtils';
import Pagination from '../../components/ui/pagination';
import { getProductsByProductId, markAlertsAsSeen } from '../../store/shop/actions';
import { RootState } from 'src/store';

const AlertsPage = () => {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const allMyAlertsResponse = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.allMyAlerts,
  );
  const { totalElements, pageSize, pageNumber } = allMyAlertsResponse.data! ?? {};
  const products = useAppSelector((state: RootState) => state.shop.shopDetails?.products);

  const allShops = useAppSelector((state: RootState) => state.shop.shopDetails?.myShopsResponse);
  const [page, setPage] = useState(pageNumber ? pageNumber : 1);
  const onPageClicked = (value: number) => {
    setPage(value);
    console.log(value);
    dispatch(getAllAlerts(value));
  };

  if (allMyAlertsResponse?.isFetching) return <Loader text={t('text-loading')} />;

  const getTitle = (alertType: string) => {
    if (alertType == 'YOU_HAVE_A_NEW_ORDER') {
      return 'you-have-a-new-order';
    } else if (alertType == 'SHOP_EXPIRED') {
      return 'shop-is-expired-title';
    } else if (alertType == 'PRODUCT_COUNT_IN_STOCK_EXCEDED') {
      return 'product-count-exceeded';
    } else if (alertType == 'SHOP_WILL_BE_EXPIRED_SOON') {
      return 'shop-will-be-expired-title';
    } else if (alertType == 'EXPIRED_SHOP_REMOVED') {
      return 'expired-shop-removed-title';
    }
  };

  const getAlertDescription = (alertType: string) => {
    if (alertType == 'YOU_HAVE_A_NEW_ORDER') {
      return 'order-handle-message';
    } else if (alertType == 'SHOP_EXPIRED') {
      return 'expired-shop-to-message';
    } else if (alertType == 'PRODUCT_COUNT_IN_STOCK_EXCEDED') {
      return 'product-count-exceeded-handle-message';
    } else if (alertType == 'SHOP_WILL_BE_EXPIRED_SOON') {
      return 'expired-shop-removed-message';
    } else if (alertType == 'EXPIRED_SHOP_REMOVED') {
      return 'product-count-exceeded-handle-message';
    }
  };

  const getAlertIcon = (alertType: string) => {
    if (alertType == 'YOU_HAVE_A_NEW_ORDER') {
      return '/image/new-order.svg';
    } else if (alertType == 'SHOP_EXPIRED') {
      return '/image/expired.svg';
    } else if (alertType == 'PRODUCT_COUNT_IN_STOCK_EXCEDED') {
      return '/image/product-count.svg';
    } else if (alertType == 'SHOP_WILL_BE_EXPIRED_SOON') {
      return '/image/will-be-expired-shop.svg';
    } else if (alertType == 'EXPIRED_SHOP_REMOVED') {
      return '/image/removed.svg';
    }
  };
  const getShopData = (shopId: string) => {
    const shopData = allShops?.data.find((element) => {
      return element.shopId === shopId;
    });
    return shopData ?? { logoUrl: '', website: '' };
  };

  function getDateWithTimeZone(dateTime: string) {
    return dateTime.substring(0, 10);
  }

  const columns = [
    {
      title: t('alerts'),
      dataIndex: 'alertId',
      key: 'alertId',
      align: 'center',
      width: 10,
      render: (item: any, record: AlertDetails) => {
        const { alertDetails } = record;
        return (
          <>
            {alertDetails && (
              <div>
                <div className='flex items-center'>
                  <img
                    src={getAlertIcon(record.alertDetails.alertType)}
                    className='w-12 h-12 rounded-full'
                  />

                  <div className='flex items-start justify-between w-full'>
                    <div className='pl-3 w-full'>
                      <p className='text-xl font-medium leading-5 text-gray-800'>
                        {t(getTitle(record.alertDetails.alertType))}
                      </p>
                      <p className='text-sm leading-normal pt-2 text-gray-500'>
                        {t('text-from-shop') +
                          ': ' +
                          getShopData(alertDetails?.paramsDetails?.shopId).website}
                      </p>
                    </div>
                    <div className='items-center' style={{ width: '25%' }}>
                      <svg
                        style={{ margin: 'auto' }}
                        width={28}
                        height={28}
                        viewBox='0 0 28 28'
                        fill={record?.isAlreadySeen ? 'none' : '#ef2c2c'}
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.5001 4.66667H17.5001C18.1189 4.66667 18.7124 4.9125 19.15 5.35009C19.5876 5.78767 19.8334 6.38117 19.8334 7V23.3333L14.0001 19.8333L8.16675 23.3333V7C8.16675 6.38117 8.41258 5.78767 8.85017 5.35009C9.28775 4.9125 9.88124 4.66667 10.5001 4.66667Z'
                          strokeWidth='1.25'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <p className='text-xs text-gray-500'>
                        {getDateWithTimeZone(record.alertDetails?.paramsDetails?.dateTime)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='px-2'>
                  <p className='text-sm leading-5 py-4 text-gray-600'>
                    {t(getAlertDescription(record.alertDetails.alertType))}
                  </p>
                </div>
              </div>
            )}
          </>
        );

        // <span className='whitespace-nowrap truncate'>{item}</span>
      },
    },
    // },
    // {
    //   title: t('table-item-actions'),
    //   dataIndex: 'couponName',
    //   key: 'couponName',
    //   align: 'center',
    //   width: 80,
    //   render: (couponName: string, record: CouponDetails) => (
    //     <ActionButtons
    //       id={couponName || ''}
    //       editAction={() => dispatch(setActiveCoupon(record, record?.couponName, SAVE_MODE.EDIT))}
    //       editUrl={ROUTES.EDIT_CREATE_COUPON}
    //       deleteModalView='DELETE_COUPON'
    //     />
    //   ),
    // },
  ];

  const onRowClick = (record: AlertDetails) => {
    const alertType = record?.alertDetails?.alertType;
    const alerts: Array<string | undefined> = [];
    alerts.push(record.alertId);
    if (record.isAlreadySeen == false) {
      dispatch(markAlertsAsSeen(alerts));
    }

    if (alertType == 'SHOP_EXPIRED') {
      history.push(`${ROUTES.SHOPS}`);
    } else if (alertType == 'PRODUCT_COUNT_IN_STOCK_EXCEDED') {
      setShopId(record?.alertDetails?.paramsDetails.shopId);
      dispatch(
        getProductsByProductId({
          pageNumber: 1,
          pageSize: 1,
          searchText: '',
          categoryId: '',
          productId: `${record?.alertDetails?.paramsDetails.productId}`,
        }),
      ).then(() => {
        history.push(`${ROUTES.EDIT_PRODUCT}`);
      });
    } else if (alertType == 'SHOP_WILL_BE_EXPIRED_SOON') {
      history.push(`${ROUTES.SHOPS}`);
    } else if (alertType == 'EXPIRED_SHOP_REMOVED') {
      history.push(`${ROUTES.SHOPS}`);
    } else if (alertType == 'YOU_HAVE_A_NEW_ORDER') {
      setShopId(record?.alertDetails?.paramsDetails?.shopId);
      history.push(`${ROUTES.ORDERS}${record?.alertDetails?.paramsDetails.OrderId}`);
    }
  };

  return (
    <>
      <Card className=''>
        <div className=''>
          <Table
            /* @ts-ignore */
            columns={columns}
            emptyText={t('empty-table-data')}
            data={allMyAlertsResponse?.data.data}
            rowKey='alertId'
            onRow={(record: AlertDetails, index: AlertDetails) => ({
              onClick: onRowClick.bind(null, record),
              style: {
                cursor: 'pointer',
              },
            })}
            className='cursor_table'
          />
        </div>
        {!!totalElements && (
          <div className='flex justify-center items-center'>
            <Pagination
              total={totalElements}
              current={pageNumber}
              pageSize={pageSize}
              onChange={onPageClicked}
              showLessItems
            />
          </div>
        )}
      </Card>
    </>
  );
};
export default withPermission(AlertsPage);
