/* eslint-disable @typescript-eslint/no-explicit-any */
export const NichePerfumes: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg id='Magicons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42.61 40.5'>
      <g id='_46_Parfume' data-name='46 Parfume'>
        <g>
          <path
            className='cls-1'
            d='M30.09,30.13c.33,0,.59-.27.59-.59v-13.99c0-1.04-.85-1.89-1.89-1.89h-4.48v-1.38c0-.55-.45-1-1-1h-1.86v-1.82c.54-.01.97-.45.97-.99v-1.38h1.9c.69,0,1.26-.57,1.26-1.26V1.26c0-.69-.57-1.26-1.26-1.26h-6.48c-.33,0-.59.27-.59.59s.27.59.59.59h6.48s.07.03.07.07v4.56s-.03.07-.07.07h-11.45s-.07-.03-.07-.07V1.26s.03-.07.07-.07h2.19c.33,0,.59-.27.59-.59s-.27-.59-.59-.59h-2.19c-.69,0-1.26.57-1.26,1.26v4.56c0,.69.57,1.26,1.26,1.26h1.9v1.38c0,.54.43.98.97.99v1.82h-1.86c-.55,0-1,.45-1,1v1.38h-4.48c-1.04,0-1.89.85-1.89,1.89v23.15c0,1.04.85,1.89,1.89,1.89h20.36c1.04,0,1.89-.85,1.89-1.89v-6.38c0-.33-.27-.59-.59-.59s-.59.27-.59.59v6.38c0,.39-.32.7-.7.7H8.44c-.39,0-.7-.32-.7-.7V15.55c0-.39.32-.7.7-.7h20.36c.39,0,.7.32.7.7v13.99c0,.33.27.59.59.59h0ZM15.98,7.08h5.27v1.19h-5.27v-1.19ZM20.28,9.46v1.82h-3.32v-1.82h3.32ZM23.13,13.66h-9.03v-1.19h9.03v1.19h0Z'
          />
          <path
            className='cls-1'
            d='M14.15,21.42c-.69,0-1.24.56-1.24,1.24v8.92c0,.69.56,1.24,1.24,1.24h8.92c.69,0,1.24-.56,1.24-1.24v-8.92c0-.69-.56-1.24-1.24-1.24h-8.92ZM23.13,22.66v8.92s-.02.05-.05.05h-8.92s-.05-.02-.05-.05v-8.92s.02-.05.05-.05h8.92s.05.02.05.05Z'
          />
        </g>
      </g>
    </svg>
  );
};
