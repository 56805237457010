import { useTranslation } from 'react-i18next';
import Card from '../common/card';
import RadioCard from '../ui/radio-card/radio-card';

interface Props {
  register: any;
  errors: any;
  show: boolean;
}

const productCards = [
  {
    label: 'Helium',
    value: 'helium',
    img: '/image/card-helium.png',
  },
  {
    label: 'Neon',
    value: 'neon',
    img: '/image/card-neon.png',
  },
  {
    label: 'Argon',
    value: 'argon',
    img: '/image/card-argon.png',
  },
  {
    label: 'Krypton',
    value: 'krypton',
    img: '/image/card-krypton.png',
  },
  {
    label: 'Xenon',
    value: 'xenon',
    img: '/image/card-xenon.png',
  },
  {
    label: 'Radon',
    value: 'radon',
    img: '/image/card-radon.png',
  },
];

const ShopProductLayoutInfo = ({ register, errors, show }: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <div className='mb-100' hidden={!show}>
      <Card className='md:w-full mt-5' title={t('input-label-product-card-type')}>
        <div>
          <div className='grid grid-cols-3 gap-5'>
            {productCards?.map((productCard, index) => {
              return (
                <RadioCard
                  key={`product-card-${index}`}
                  {...register('productCardType')}
                  label={t(productCard.label)}
                  value={productCard.value}
                  src={productCard.img}
                  id={`product-card-${index}`}
                />
              );
            })}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ShopProductLayoutInfo;
