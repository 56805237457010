/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const Cooking: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28.9'
      height='16.9'
      viewBox='0 0 28.9 16.9'
      fill='currentColor'
      {...props}
    >
      <g id='Cooking' transform='translate(-61.55 -195.55)'>
        <g id='Group_12320' data-name='Group 12320' transform='translate(62 196)'>
          <g id='Group_12319' data-name='Group 12319' transform='translate(11.006 10.518)'>
            <line
              id='Line_10'
              data-name='Line 10'
              x2='0.963'
              fill='none'
              stroke='currentColor'
              strokeLinejoin='round'
              strokeWidth='0.9'
            />
            <line
              id='Line_11'
              data-name='Line 11'
              x2='0.963'
              transform='translate(2.345)'
              fill='none'
              stroke='currentColor'
              strokeLinejoin='round'
              strokeWidth='0.9'
            />
            <line
              id='Line_12'
              data-name='Line 12'
              x2='0.963'
              transform='translate(4.691)'
              fill='none'
              stroke='currentColor'
              strokeLinejoin='round'
              strokeWidth='0.9'
            />
          </g>
          <path
            id='Path_17428'
            data-name='Path 17428'
            d='M578.074,414.485l3.192-.59v-1.15h-3.192Z'
            transform='translate(-553.266 -406.978)'
            fill='none'
            stroke='currentColor'
            strokeLinejoin='round'
            strokeWidth='0.9'
          />
          <path
            id='Path_17429'
            data-name='Path 17429'
            d='M506.668,414.485l-3.192-.59v-1.15h3.192Z'
            transform='translate(-503.476 -406.978)'
            fill='none'
            stroke='currentColor'
            strokeLinejoin='round'
            strokeWidth='0.9'
          />
          <path
            id='Path_17430'
            data-name='Path 17430'
            d='M523.823,401.744a17.212,17.212,0,0,0-10.749,3.829h21.615A17.751,17.751,0,0,0,523.823,401.744Z'
            transform='translate(-509.882 -399.805)'
            fill='none'
            stroke='currentColor'
            strokeLinejoin='round'
            strokeWidth='0.9'
          />
          <line
            id='Line_13'
            data-name='Line 13'
            y1='1.939'
            transform='translate(13.941)'
            fill='none'
            stroke='currentColor'
            strokeMiterlimit='10'
            strokeWidth='0.9'
          />
          <line
            id='Line_14'
            data-name='Line 14'
            x2='3.606'
            transform='translate(12.197)'
            fill='none'
            stroke='currentColor'
            strokeMiterlimit='10'
            strokeWidth='0.9'
          />
          <path
            id='Path_17431'
            data-name='Path 17431'
            d='M534.69,412.643s-.333,10.268-5.321,10.268H518.395c-4.988,0-5.321-10.268-5.321-10.268Z'
            transform='translate(-509.882 -406.911)'
            fill='none'
            stroke='currentColor'
            strokeLinejoin='round'
            strokeWidth='0.9'
          />
        </g>
      </g>
    </svg>
  );
};
