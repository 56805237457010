import { BanUser } from 'src/components/icons/ban-user';
import EditIcon from 'src/components/icons/edit';
import Trash from 'src/components/icons/trash';
import { Eye } from 'src/components/icons/eye-icon';
import { WalletPointsIcon } from 'src/components/icons/wallet-point';
import { useTranslation } from 'react-i18next';
import { CheckMarkCircle } from 'src/components/icons/checkmark-circle';
import { useModalAction } from 'src/components/ui/modal/modal.context';
import { CloseFillIcon } from 'src/components/icons/close-fill';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
  className?: string;
  deleteModalView?: string | any;
  editUrl?: string;
  detailsUrl?: string;
  isUserActive?: boolean;
  userStatus?: boolean;
  isShopActive?: boolean;
  approveButton?: boolean;
  showAddWalletPoints?: boolean;
  changeRefundStatus?: boolean;
  editAction?: () => void;
};

const ActionButtons = ({
  id,
  className = '',
  editAction,
  deleteModalView,
  editUrl,
  detailsUrl,
  userStatus = false,
  isUserActive = false,
  isShopActive,
  approveButton = false,
  showAddWalletPoints = false,
  changeRefundStatus = false,
}: Props) => {
  const { t }: any = useTranslation<any>();
  const { openModal } = useModalAction();
  function handleDelete(e: any) {
    e.preventDefault();
    e.stopPropagation();
    openModal(deleteModalView, id);
  }
  function handleUserStatus(type: string) {
    openModal('BAN_CUSTOMER', { id, type });
  }
  function handleAddWalletPoints() {
    openModal('ADD_WALLET_POINTS', id);
  }
  function handleUpdateRefundStatus() {
    openModal('UPDATE_REFUND', id);
  }
  function handleShopStatus(status: boolean) {
    if (status === true) {
      openModal('SHOP_APPROVE_VIEW', id);
    } else {
      openModal('SHOP_DISAPPROVE_VIEW', id);
    }
  }
  function handleOrderClicked(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (editAction != null) {
      editAction();
    }
  }

  return (
    <div className={`space-s-5 inline-flex items-center w-auto ${className}`}>
      {showAddWalletPoints && (
        <button
          onClick={handleAddWalletPoints}
          className='text-accent transition duration-200 hover:text-accent-hover focus:outline-none'
          title={t('text-add-wallet-points')}
        >
          <WalletPointsIcon width={22} />
        </button>
      )}
      {changeRefundStatus && (
        <button
          onClick={handleUpdateRefundStatus}
          className='text-accent transition duration-200 hover:text-accent-hover focus:outline-none'
          title={t('text-change-refund-status')}
        >
          <CheckMarkCircle width={20} />
        </button>
      )}
      {deleteModalView && (
        <button
          onClick={(e) => handleDelete(e)}
          className='text-red-500 transition duration-200 hover:text-red-600 focus:outline-none'
          title={t('text-delete')}
        >
          <Trash width={16} />
        </button>
      )}
      {approveButton &&
        (!isShopActive ? (
          <button
            onClick={() => handleShopStatus(true)}
            className='text-accent transition duration-200 hover:text-accent-hover focus:outline-none'
            title={t('text-approve-shop')}
          >
            <CheckMarkCircle width={20} />
          </button>
        ) : (
          <button
            onClick={() => handleShopStatus(false)}
            className='text-red-500 transition duration-200 hover:text-red-600 focus:outline-none'
            title={t('text-disapprove-shop')}
          >
            <CloseFillIcon width={20} />
          </button>
        ))}
      {userStatus && (
        <>
          {isUserActive ? (
            <button
              onClick={() => handleUserStatus('ban')}
              className='text-red-500 transition duration-200 hover:text-red-600 focus:outline-none'
              title={t('text-ban-user')}
            >
              <BanUser width={20} />
            </button>
          ) : (
            <button
              onClick={() => handleUserStatus('active')}
              className='text-accent transition duration-200 hover:text-accent focus:outline-none'
              title={t('text-activate-user')}
            >
              <CheckMarkCircle width={20} />
            </button>
          )}
        </>
      )}

      {editUrl && (
        <Link
          to={editUrl}
          className='text-base transition duration-200 hover:text-heading'
          title={t('text-edit')}
          onClick={(e) => handleOrderClicked(e)}
        >
          <EditIcon width={16} />
        </Link>
      )}
      {detailsUrl && (
        <Link
          to={detailsUrl}
          className='ml-2 text-base transition duration-200 hover:text-heading'
          title={t('text-view')}
        >
          <Eye width={24} />
        </Link>
      )}
    </div>
  );
};

export default ActionButtons;
