/* eslint-disable @typescript-eslint/no-explicit-any */
export const NavbarIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg width='25.567' height='18' viewBox='0 0 25.567 18' {...props}>
    <g transform='translate(-776 -462)'>
      <rect
        width='12.749'
        height='2.499'
        rx='1.25'
        transform='translate(776 462)'
        fill='currentColor'
      />
      <rect
        width='25.567'
        height='2.499'
        rx='1.25'
        transform='translate(776 469.75)'
        fill='currentColor'
      />
      <rect
        width='17.972'
        height='2.499'
        rx='1.25'
        transform='translate(776 477.501)'
        fill='currentColor'
      />
    </g>
  </svg>
);
