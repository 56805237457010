export const BanUser = ({ ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 459.739 459.739'
      fill='currentColor'
      {...props}
    >
      <path d='M229.869 0C102.919 0 0 102.918 0 229.87s102.919 229.869 229.869 229.869c126.952 0 229.87-102.917 229.87-229.869S356.821 0 229.869 0zM61.299 229.87c0-37.1 12.196-71.325 32.58-99.198L329.062 365.86c-27.868 20.392-62.093 32.581-99.192 32.581-92.951 0-168.571-75.621-168.571-168.571zm307.839 94.813L135.048 90.601c27.044-18.468 59.684-29.303 94.821-29.303 92.952 0 168.571 75.622 168.571 168.572 0 35.139-10.833 67.779-29.302 94.813z' />
    </svg>
  );
};
