/* eslint-disable @typescript-eslint/no-explicit-any */
export const Storage: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48.75'
      height='60'
      viewBox='0 0 48.75 60'
      fill='currentColor'
      {...props}
    >
      <g id='Storage' transform='translate(-318 -448)'>
        <path
          id='Path_17422'
          data-name='Path 17422'
          d='M364.875,448h-45A1.874,1.874,0,0,0,318,449.875v52.5a1.874,1.874,0,0,0,1.875,1.875h3.75V508H325.5v-3.75h33.75V508h1.875v-3.75h3.75a1.874,1.874,0,0,0,1.875-1.875v-52.5A1.874,1.874,0,0,0,364.875,448Zm-22.5,54.375h-22.5V493h22.5Zm0-11.25h-22.5V459.25h10.313v2.311l-8.02,5.347a.938.938,0,0,0,.52,1.717h16.875a.938.938,0,0,0,.52-1.717l-8.02-5.347V459.25h10.313Zm-11.25-27.936,5.341,3.561H325.784Zm11.25-5.814h-22.5v-7.5h22.5Zm22.5,45H344.25V493h20.625Zm0-11.25H344.25v-41.25h20.625Z'
          fill='currentColor'
        />
        <path
          id='Path_17423'
          data-name='Path 17423'
          d='M355,502h4a1,1,0,0,0,0-2h-4a1,1,0,0,0,0,2Z'
          transform='translate(-2.543 -4)'
          fill='currentColor'
        />
        <path
          id='Path_17424'
          data-name='Path 17424'
          d='M329,502h4a1,1,0,0,0,0-2h-4a1,1,0,0,0,0,2Z'
          transform='translate(-0.707 -4)'
          fill='currentColor'
        />
        <path
          id='Path_17425'
          data-name='Path 17425'
          d='M349,478a1,1,0,0,0,1-1v-4a1,1,0,0,0-2,0v4A1,1,0,0,0,349,478Z'
          transform='translate(-1.95 -1.655)'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
