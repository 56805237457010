import { useTranslation } from 'react-i18next';
import { UPLOAD_SECTION } from 'src/ts-types';
import Card from '../common/card';
import Description from '../ui/description';
import Input from '../ui/input';
import UnControlledFileInput from '../ui/unControlled-file-input';

interface Props {
  register: any;
  errors: any;
  show: boolean;
  control: any;
  setValue: (name: string, val: unknown) => void;
  getValues: (val: string) => string;
}

const ShopBasicInfo = ({ register, errors, show, control, setValue, getValues }: Props) => {
  const { t }: any = useTranslation<any>();

  const handleLogoUrlUpload = (val: unknown) => {
    setValue('logoUrl', val);
  };

  const handleSiteMainImageUpload = (val: unknown) => {
    setValue('siteMainImage', val);
  };

  return (
    <div className='mb-100' hidden={!show}>
      <Card className='md:w-full mt-2'>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-website')}
            {...register('website')}
            variant='outline'
            error={t(errors?.website?.message)}
          />
        </div>

        <div className='flex flex-wrap pb-8 border-b border-dashed border-border-base my-5 sm:my-8'>
          <Description
            title={t('input-label-logo')}
            details={t('shop-logo-help-text')}
            className='w-full px-0 sm:pe-4 md:pe-5 pb-5 sm:w-4/12 md:w-1/3 sm:py-8'
          />

          <UnControlledFileInput
            multiple={false}
            withCrop
            onImageUploaded={(val: unknown) => handleLogoUrlUpload(val)}
            defaultValue={getValues('logoUrl')}
            uploadSection={UPLOAD_SECTION.SITE_LOGO}
          />
        </div>

        <div className='flex flex-wrap pb-8 border-b border-dashed border-border-base my-5 sm:my-8'>
          <Description
            title={t('shop-cover-image-title')}
            className='w-full px-0 sm:pe-4 md:pe-5 pb-5 sm:w-4/12 md:w-1/3 sm:py-8'
          />

          <UnControlledFileInput
            withCrop
            multiple={false}
            onImageUploaded={(val) => handleSiteMainImageUpload(val)}
            defaultValue={getValues('siteMainImage')}
            uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
          />
        </div>
      </Card>
    </div>
  );
};

export default ShopBasicInfo;
