import cn from 'classnames';
import { useTranslation } from 'react-i18next';

type BadgeProps = {
  className?: string;
  color?: string;
  textColor?: string;
  text?: string;
  textKey?: string;
};

const Badge = (props: BadgeProps) => {
  const { t }: any = useTranslation<any>();
  const { className, color: colorOverride, textColor: textColorOverride, text, textKey } = props;

  const classes = {
    root: 'px-3 py-1 rounded-full  whitespace-nowrap',
    default: 'bg-accent',
    text: 'text-light',
  };

  return (
    <span
      className={cn(
        classes.root,
        {
          [classes.default]: !colorOverride,
          [classes.text]: !textColorOverride,
        },
        colorOverride,
        textColorOverride,
        className,
      )}
    >
      {textKey ? t(textKey) : text}
    </span>
  );
};

export default Badge;
