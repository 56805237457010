/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-types */
import cn from 'classnames';

const Logo: React.FC<any> = ({ width = '100', className, ...props }) => {
  return (
    <a className={cn('inline-flex', className)} {...props}>
      <div className='overflow-hidden relative'>
        <img src='/image/ngoshops2big.svg' alt={''} width={width} loading='eager' />
      </div>
    </a>
  );
};

export default Logo;
