import * as yup from 'yup';

export const salesFormValidation = yup.object().shape({
  saleDescription: yup
    .array()
    .min(1, 'required')
    .of(
      yup.object().shape({
        value: yup.string().required('required'),
        Language: yup.string().required('required'),
      }),
    )
    .required('required'),
  saleType: yup.string().required('required'),
  totalPriceSaleObject: yup.object().shape({
    minPriceToGetSale: yup.number().required('required'),
  }),
  categoryId: yup.string().when('saleType', {
    is: 'CATEGORY_SALE',
    then: yup.string().required('Category ID is required when sale type is CATEGORY_SALE'),
    otherwise: yup.string().notRequired(),
  }),
  expirationDate: yup.string().required('required'),
  percentageToDiscount: yup.string().required('required'),
  // percentageToDiscount: yup.string().when('saleType', {
  //   is: 'CATEGORY_SALE',
  //   then: yup.string().required('required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // moneyToDiscount: yup.string().when('saleType', {
  //   is: 'TOTAL_PRICE_SALE',
  //   then: yup.string().required('required'),
  //   otherwise: yup.string().notRequired(),
  // }),
});
