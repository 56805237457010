/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const Breakfast: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.408'
      height='18.5'
      viewBox='0 0 25.408 18.5'
      fill='currentColor'
      {...props}
    >
      <g id='Breakfast' transform='translate(0.25 -70.75)'>
        <path
          id='Path_17388'
          data-name='Path 17388'
          d='M241.73,131a.73.73,0,0,0-.73.73v1.946a.73.73,0,0,0,1.459,0V131.73A.73.73,0,0,0,241.73,131Z'
          transform='translate(-229.276 -57.081)'
          fill='currentColor'
          stroke='#fff'
          strokeWidth='0.4'
        />
        <path
          id='Path_17389'
          data-name='Path 17389'
          d='M292.335,131.324a.73.73,0,1,0-1.214.81l.973,1.459a.73.73,0,0,0,1.214-.81Z'
          transform='translate(-276.841 -57.08)'
          fill='currentColor'
          stroke='#fff'
          strokeWidth='0.4'
        />
        <path
          id='Path_17390'
          data-name='Path 17390'
          d='M173.105,131.121a.73.73,0,0,0-1.012.2l-.973,1.459a.73.73,0,1,0,1.214.81l.973-1.459A.73.73,0,0,0,173.105,131.121Z'
          transform='translate(-162.679 -57.08)'
          fill='currentColor'
          stroke='#fff'
          strokeWidth='0.4'
        />
        <path
          id='Path_17391'
          data-name='Path 17391'
          d='M24.908,80.487A2.68,2.68,0,0,0,22.926,77.9a6.867,6.867,0,0,0-3.151-4.777A12.907,12.907,0,0,0,12.454,71a12.907,12.907,0,0,0-7.321,2.125A6.867,6.867,0,0,0,1.982,77.9a2.676,2.676,0,0,0,.159,5.207l.874,3.5A3.158,3.158,0,0,0,6.083,89H18.825a3.158,3.158,0,0,0,3.068-2.4l.874-3.5A2.68,2.68,0,0,0,24.908,80.487ZM4.431,86.251l-.772-3.089H6.945l.547,4.378H6.083a1.7,1.7,0,0,1-1.652-1.29Zm12.061-3.089-.547,4.378H13.184V83.162Zm-4.768,4.378H8.963l-.547-4.378h3.309Zm8.753-1.29a1.7,1.7,0,0,1-1.652,1.29H17.416l.547-4.378h3.286ZM22.232,81.7H2.676a1.216,1.216,0,0,1,0-2.432.73.73,0,0,0,.73-.73,5.255,5.255,0,0,1,2.561-4.217,11.44,11.44,0,0,1,6.488-1.864,11.44,11.44,0,0,1,6.488,1.864A5.255,5.255,0,0,1,21.5,78.541a.73.73,0,0,0,.73.73,1.216,1.216,0,1,1,0,2.432Z'
          transform='translate(0 0)'
          fill='currentColor'
          stroke='#fff'
          strokeWidth='0.5'
        />
      </g>
    </svg>
  );
};
