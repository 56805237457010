import CreateOrUpdateProductForm from 'src/components/product/productForm';
import ErrorMessage from 'src/components/ui/error-message';
import { RootState } from 'src/store';
import { useAppSelector } from 'src/utils/reduxHooks';

export default function UpdateProductPage() {
  const error = useAppSelector((state: RootState) => state.shop.shopDetails?.error);

  if (error) return <ErrorMessage message={error} />;

  return <CreateOrUpdateProductForm />;
}
