import cn from 'classnames';
import { LabelHTMLAttributes } from 'react';

export interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
}

const Label = ({ className, ...rest }: Props) => {
  return (
    <label
      className={cn('block text-body-dark font-semibold text-sm leading-none mb-3 pl-1', className)}
      {...rest}
    />
  );
};

export default Label;
