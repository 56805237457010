import { CustomerDetails } from 'src/ts-types/custom.types';
function removeFalsy(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => Boolean(v)));
}

export function formatContact(address: CustomerDetails) {
  if (!address) return;
  const temp = ['customerName', 'emailAddress', 'phone'].reduce(
    (acc, k) => ({ ...acc, [k]: (address as any)[k] }),
    {},
  );
  const formattedContact = removeFalsy(temp);
  return Object.values(formattedContact).join(', ');
}
