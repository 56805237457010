import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Ensure you have Quill's CSS

function RTLTextEditor({ value, onChange }: any) {
  const modules = {
    toolbar: [
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['clean'],
      ['direction', 'rtl'], // Add direction button
    ],
    // Add RTL to the clipboard module to ensure content is pasted correctly
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'direction', // Ensure direction is included in formats
  ];

  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      onBlur={(e: any) => e?.preventDefault?.()}
      modules={modules}
      formats={formats}
      style={{ direction: 'rtl', textAlign: 'right' }} // Apply custom styles
    />
  );
}

export default RTLTextEditor;
