import { useUI } from 'src/contexts/ui.context';
import DrawerWrapper from 'src/components/ui/drawer-wrapper';
import Drawer from 'src/components/ui/drawer';

// eslint-disable-next-line react/prop-types
const MobileNavigation: React.FC<any> = ({ children }) => {
  const { displaySidebar, closeSidebar } = useUI();

  return (
    <Drawer open={displaySidebar} onClose={closeSidebar} variant='left'>
      <DrawerWrapper onClose={closeSidebar}>
        <div className='flex flex-col space-y-6 p-5'>{children}</div>
      </DrawerWrapper>
    </Drawer>
  );
};
export default MobileNavigation;
