import { useTranslation } from 'react-i18next';
import Card from '../common/card';
import Input from '../ui/input';
import TextArea from '../ui/text-area';

interface Props {
  register: any;
  errors: any;
  show: boolean;
}

const ShopSettingsInfo = ({ register, errors, show }: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <div className='mb-100' hidden={!show}>
      <Card className='md:w-full mt-5' title={t('shop-basic-info')}>
        <div className='flex flex-col md:flex-row'>
          {/* <Input
          label={t("input-label-name")}
          {...register("name")}
          variant="outline"
          className="mb-5"
          error={t(errors.name?.message)}
        /> */}
          <Input
            label={t('input-label-site-title')}
            {...register('siteTitle')}
            variant='outline'
            error={t(errors.siteTitle?.message)}
          />
        </div>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-site-subtitle')}
            {...register('siteSubtitle')}
            variant='outline'
            error={t(errors.siteSubtitle?.message)}
          />
        </div>
        <TextArea
          label={t('input-label-description')}
          {...register('shopDescription')}
          variant='outline'
          error={t(errors.shopDescription?.message)}
        />
      </Card>
    </div>
  );
};

export default ShopSettingsInfo;
