import { CustomerDetails } from 'src/ts-types/custom.types';
import { UserAddress } from 'src/ts-types/generated';
function removeFalsy(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => Boolean(v)));
}

export function formatAddress(address: CustomerDetails) {
  if (!address) return;
  const temp = ['Phone', 'city', 'customerName', 'postalNumber', 'streetAddress', 'zipCode'].reduce(
    (acc, k) => ({ ...acc, [k]: (address as any)[k] }),
    {},
  );
  const formattedAddress = removeFalsy(temp);
  return Object.values(formattedAddress).join(', ');
}
