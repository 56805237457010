/* eslint-disable @typescript-eslint/ban-types */
export const WalletPointsIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg viewBox='0 0 192 192' {...props}>
    <g data-name='06-king'>
      <path
        d='M96 0a96 96 0 1 0 96 96A96.108 96.108 0 0 0 96 0zm0 176a80 80 0 1 1 80-80 80.091 80.091 0 0 1-80 80z'
        fill='currentColor'
      />
      <path
        d='M96 24a72 72 0 1 0 72 72 72.081 72.081 0 0 0-72-72zm0 40a8 8 0 1 1-8 8 8 8 0 0 1 8-8zM48 80a8 8 0 1 1 8 8 8 8 0 0 1-8-8zm80 48H64l-5-37 21 13 16-24 16 24 20-13zm8-40a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'
        fill='currentColor'
      />
    </g>
  </svg>
);
