// @ts-ignore
import { Control, useForm } from 'react-hook-form';
import Button from 'src/components/ui/button';
import { useAppSelector, useAppDispatch } from '../../utils/reduxHooks';
import Label from 'src/components/ui/label';
import Card from 'src/components/common/card';
import { categoryIcons } from './category-icons';
import SelectInput from 'src/components/ui/select-input';
// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';
import { categoryValidationSchema } from './category-validation-schema';
import { useTranslation } from 'react-i18next';
import { createCategory, updateCategoryAction } from 'src/store/shop/actions';
import { Category, CreateCategoryInput, TranslationObject } from 'src/ts-types/custom.types';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import { useEffect, useState } from 'react';
import { get as getObj } from 'lodash';
// @ts-ignore
import { toast } from 'react-toastify';
import InputWithTranslation from '../ui/inputWithTranslation';
import { UPLOAD_SECTION } from 'src/ts-types';
import UnControlledFileInput from '../ui/unControlled-file-input';
import { RootState } from 'src/store';
import Input from '../ui/input';
import SwitchInput from '../ui/switch-input';
import { getTranslatedItem } from 'src/common';
import { TOAST_MESSAGE } from 'src/utils/constants';

export const getSelectedIcon = (icon?: string) => {
  const selectedIcon = categoryIcons.find((item: any) => item.value === icon);
  if (selectedIcon) {
    return selectedIcon;
  }
  return null;
};

function SelectCategories({
  control,
  categories,
  className,
}: {
  control: Control<FormValues>;
  setValue: any;
  categories?: Category[];
  className?: string;
}) {
  const { t }: any = useTranslation();
  categories?.map(
    (item: Category) => (item.name = getObj(item, 'categoryNameWithTeanslations[0].value', '')),
  );

  return (
    <div className={className}>
      <Label>{t('input-label-parent-category')}</Label>
      <SelectInput
        name='parent'
        control={control}
        menuPlacement='top'
        getOptionLabel={(option: any) => getTranslatedItem(option.categoryNameWithTeanslations)}
        getOptionValue={(option: any) => option.categoryId}
        options={categories ?? [{}]}
        isClearable={true}
        isLoading={false}
      />
    </div>
  );
}

type FormValues = {
  categoryNameWithTeanslations: TranslationObject[];
  parent: any;
  categoryIcon: string;
  categoryImage: string;
  icon: any;
  orderNumber?: number;
  active?: boolean;
  categorySEO?: string;
};

type IProps = {
  initialValues?: Category | null;
};

export default function CreateOrUpdateCategoriesForm({ initialValues }: IProps) {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state: RootState) => state.shop.shopDetails?.categories);
  const [isActive, setIsActive] = useState(true);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      categoryNameWithTeanslations: initialValues?.categoryNameWithTeanslations,
      icon: getSelectedIcon(initialValues?.categoryTagIcons),
      categoryImage: initialValues?.categoryImage,
      orderNumber: initialValues?.orderNumber,
      categoryIcon: initialValues?.categoryIcon,
      categorySEO: initialValues?.categorySEO,
      parent: categories?.data?.find?.(
        (item: Category) => item.categoryId === initialValues?.parentId,
      ),
    },
    resolver: yupResolver(categoryValidationSchema),
  });

  useEffect(() => {
    setValue(
      'parent',
      categories?.data?.find?.((item: Category) => item.categoryId === initialValues?.parentId),
    );
    setValue('categoryImage', initialValues?.categoryImage ?? '');
  }, [categories, initialValues, setValue]);

  const getIconsOptions = () => {
    const iconOptions: any = [];
    categoryIcons.forEach((item: { value: string; label: any }) => {
      const ele: any = {};
      ele.label = (
        <div className='flex space-s-5 items-center'>
          {/* <span className='flex w-5 h-5 items-center justify-center'>
            {getIcon({
              iconList: categoriesIcon,
              iconName: item.value,
              className: 'max-h-full max-w-full',
            })}
          </span> */}
          <span>{item.label}</span>
        </div>
      );
      ele.value = item.value;
      iconOptions.push(ele);
    });
    return iconOptions;
  };

  function onSubmit(values: FormValues) {
    const input: CreateCategoryInput = {
      categoryTagIcons: values?.icon?.value ?? '',
      categoryImage: values.categoryImage.length === 0 ? '' : values.categoryImage,
      categoryIcon: values.categoryIcon,
      parentCategoryId: getObj(values, 'parent.categoryId', ''),
      orderNumber: values?.orderNumber,
      categoryNameWithTeanslations: values.categoryNameWithTeanslations,
      active: values.active,
      categorySEO: values.categorySEO,
    };
    if (input.parentCategoryId === '') {
      delete input['parentCategoryId'];
    }
    if (initialValues) {
      input.categoryId = initialValues.categoryId;
      dispatch(updateCategoryAction(input)).then((res: any) => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        history.push(ROUTES.CATEGORIES);
      });
    } else {
      dispatch(createCategory(input)).then((res: any) => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        history.push(ROUTES.CATEGORIES);
      });
    }
  }

  const updateStatus = async (status: boolean) => {
    setIsActive(status);
    setValue('active', status);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card
        className='w-full'
        title={
          <h1 className='text-lg font-semibold text-heading'>{t('form-title-create-category')}</h1>
        }
      >
        <div className='w-full md:w-2/3'>
          <InputWithTranslation
            label={t('input-label-name')}
            error={t(errors.categoryNameWithTeanslations?.message)}
            variant='outline'
            required
            values={getValues('categoryNameWithTeanslations')}
            setValue={(val: any) => setValue('categoryNameWithTeanslations', val)}
            name='categoryNameWithTeanslations'
          />
          <UnControlledFileInput
            withCrop
            croppedMaxWidth={180}
            croppedMaxHeight={120}
            multiple={false}
            title={t('category_image_page')}
            classContainer='mt-2'
            onImageUploaded={(val: any) => setValue('categoryImage', val)}
            defaultValue={getValues('categoryImage')}
            uploadSection={UPLOAD_SECTION.CATEGORY}
          />
          <SwitchInput
            label={t('table-item-status')}
            name='active'
            className='pb-2 pl-6'
            onChange={(val) => updateStatus(val)}
            defaultValue={isActive}
            value={isActive}
          />
          <Input
            tabIndex={1}
            label={t('input-label-order-number')}
            variant='outline'
            {...register('orderNumber')}
            error={t(errors?.orderNumber?.message)}
          />
          <div>
            <Input
              label={`${t('input-label-product-SEO')}`}
              value={getValues('categorySEO')}
              containerClassName='step3prod'
              {...register('categorySEO')}
              name='categorySEO'
              required
              error={t(errors.categorySEO?.message)}
              variant='outline'
              className='mb-5 '
            />
          </div>
          <div className='mb-5 mt-5'>
            <SelectInput
              name='icon'
              setValue={setValue}
              label={t('input-label-select-icon')}
              control={control}
              options={getIconsOptions()}
              isClearable={true}
            />
            <UnControlledFileInput
              withCrop
              croppedMaxWidth={80}
              croppedMaxHeight={80}
              title={t('category_logo')}
              multiple={false}
              classContainer='mt-2'
              onImageUploaded={(val: any) => setValue('categoryIcon', val)}
              defaultValue={getValues('categoryIcon')}
              uploadSection={UPLOAD_SECTION.CATEGORY}
            />
          </div>
          <SelectCategories control={control} setValue={setValue} categories={categories?.data} />
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {initialValues ? t('button-label-update-category') : t('button-label-add-category')}
        </Button>
        <Button variant='outline' onClick={() => history.push(ROUTES.CATEGORIES)}>
          {t('form-return')}
        </Button>
      </Card>
    </form>
  );
}
