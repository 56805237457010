import { CloseIcon } from 'src/components/icons/close-icon';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full'; // Limit the size to these specific values
  title?: string | JSX.Element;
  children: React.ReactNode;
  showCloseButton?: boolean;
}

export default function Modal({
  open,
  onClose,
  size = 'md', // Accepts 'sm', 'md', 'lg', or any other custom size class
  title = '',
  children,
  showCloseButton = true,
}: ModalProps) {
  const cancelButtonRef = useRef(null);

  const sizeClasses: { [key: string]: string } = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    full: 'w-full',
  };

  const selectedSizeClass = sizeClasses[size]; // No error since 'size' is explicitly typed

  if (!open) {
    return null;
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as='div'
        className={'fixed inset-0 z-50 overflow-y-auto'}
        initialFocus={cancelButtonRef}
        static
        open={open}
        onClose={onClose}
      >
        <div className='min-h-full md:p-5 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-900 bg-opacity-50 w-full h-full' />
          </Transition.Child>

          {/* Centering the modal */}
          <span className='inline-block align-middle' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              className={`inline-block text-start align-middle transition-all 
                          md:rounded-xl relative bg-white mt-[40px] ${selectedSizeClass}`}
            >
              {/* Modal title */}
              <div className='inline-block outline-none focus:outline-none absolute start-14 sm:start-12 top-4 z-[60]'>
                {title}
              </div>
              {showCloseButton && (
                <button
                  onClick={onClose}
                  aria-label='Close panel'
                  ref={cancelButtonRef}
                  className='inline-block outline-none focus:outline-none absolute end-4 top-4 z-[60]'
                >
                  <CloseIcon className='w-4 h-4 mr-8' />
                </button>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
