/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/ban-types */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  setActiveShopDetailsResponse,
  setShopAnalyticsResponse,
  setAllSalesResponse,
  setError,
  setMyShopsResponse,
  setCreateShopResponse,
  updateShopPrivateDetailsResponse,
  updateShopPlugInResponse,
  setCategories,
  setAllMyAlertsResponse,
  updateProductResponse,
  setProducts,
  updateContactUsDetailsResponse,
  fetchOrderId,
  updateProductsResponse,
  setAdminContactUsResponse,
  setUserOrders,
  setOrders,
  setShopEmployeesResponse,
  setBrands,
  setShopAnalyticsResponseNew,
  setAddOnsCategories,
} from './actionTypes';
import BaseService from '../../api/base';
import { API_ENDPOINTS_NEW } from '../../api/endpoints';
import {
  AdminContactUsPayload,
  CompanyDetails,
  ContactUsPayload,
  CreateShopInput,
  EmployeeDetails,
  Order,
  Pagination,
  Product,
  ProductsPagination,
  ShopDetailsType,
  UpdateShopPlugInPayload,
} from '../../ts-types/custom.types';
import { isSuperAdmin } from 'src/utils/authUtils';
import { getShopDetails, setShopId, setShopStatus } from 'src/utils/shopUtils';
import { PAGE_SIZE, SHOP_STATUS } from 'src/ts-types';
import { refreshToken } from '../session/actions';
import _ from 'lodash';
import { setFetchOrderById } from './actions';

const service = new BaseService();

// ******************** -- API Calls ***************************************
export const getAllAlerts = (pageNumber: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        const myAlertsApi = API_ENDPOINTS_NEW.ALL_MY_ALERTS + '?page=' + pageNumber;
        await service.all(myAlertsApi).then((response) => {
          dispatch(setAllMyAlertsResponse({ data: response.data, isFetching: false }));
          dispatch(setError(''));
          resolve(response.data);
        });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setAllMyAlertsResponse({ data: [], isFetching: false }));
      }
    });
  };
};

export const getAllMyShops = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(setMyShopsResponse({ data: [], isFetching: true }));
        const shopsApi = isSuperAdmin()
          ? API_ENDPOINTS_NEW.SHOPS
          : API_ENDPOINTS_NEW.GET_ALL_MY_SHOPS;
        await service.all(shopsApi).then((response) => {
          dispatch(setMyShopsResponse({ data: response.data, isFetching: false }));
          dispatch(setError(''));
          resolve(response.data);
        });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setMyShopsResponse({ data: [], isFetching: false }));
      }
    });
  };
};

export const getActiveShopDetails = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setActiveShopDetailsResponse({ data: { shopId: '' }, isFetching: true }));
      const response = await service.find(API_ENDPOINTS_NEW.GET_SHOP_DETAILS);
      dispatch(setActiveShopDetailsResponse({ data: response.data, isFetching: false }));
      const { shopCurrentStatus } = response.data;
      setShopStatus(shopCurrentStatus);
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
      dispatch(
        setActiveShopDetailsResponse({
          data: { shopId: '', companyDetails: {} },
          isFetching: false,
        }),
      );
    }
  };
};

export const updateShopDetails = (
  shop: ShopDetailsType | CompanyDetails,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const { shopStatus } = getShopDetails();
        let url = isSuperAdmin()
          ? API_ENDPOINTS_NEW.UPDATE_SHOP_DETAILS
          : API_ENDPOINTS_NEW.UPDATE_SHOP_SETTINGS;
        if (shopStatus === SHOP_STATUS.RUNNING) {
          url = API_ENDPOINTS_NEW.UPDATE_SHOP_SETTINGS;
        }
        service
          .update(url, shop)
          .then((res) => {
            dispatch(getAllMyShops());
            dispatch(setError(''));
            resolve(res);
          })
          .catch((e) => {
            dispatch(setError('error'));
            reject(e);
          });
      } catch (e) {
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const getActiveShopAnalytics = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setShopAnalyticsResponse({ data: {}, isFetching: false }));
      const response = await service.find(API_ENDPOINTS_NEW.GET_SHOP_ANALYTICS);
      dispatch(setShopAnalyticsResponse({ data: response.data, isFetching: false }));

      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
      dispatch(setShopAnalyticsResponse({ data: {}, isFetching: false }));
    }
  };
};

export const getActiveShopAnalyticsNew = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setShopAnalyticsResponseNew({ data: {}, isFetching: false }));
      const response = await service.find(API_ENDPOINTS_NEW.GET_SHOP_ANALYTICS_NEW);
      dispatch(setShopAnalyticsResponseNew({ data: response.data, isFetching: false }));

      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
      dispatch(setShopAnalyticsResponseNew({ data: {}, isFetching: false }));
    }
  };
};

export const getAllSalesForAmin = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setAllSalesResponse({ data: [], isFetching: true }));
      const response = await service.all(`${API_ENDPOINTS_NEW.GET_ALL_SALES_FOR_ADMIN}`);
      dispatch(setAllSalesResponse({ data: response.data, isFetching: false }));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
      dispatch(setAllSalesResponse({ data: [], isFetching: false }));
    }
  };
};

export const createShop = (
  input: CreateShopInput,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const { packageId = '' } = input;
        const url = _.isEmpty(packageId)
          ? API_ENDPOINTS_NEW.CREATE_ANONYMOUS_SHOP
          : API_ENDPOINTS_NEW.CREATE_SHOP;
        dispatch(setCreateShopResponse({ data: { shopId: '' }, isFetching: true }));
        service
          .create(url, input)
          .then(async (res) => {
            dispatch(setError(''));
            await dispatch(refreshToken());
            const { shopId } = res.data;
            setShopId(shopId);
            await dispatch(getAllMyShops());
            dispatch(
              setCreateShopResponse({
                data: { shopId: shopId },
                isFetching: false,
              }),
            );
            resolve(res);
          })
          .catch((err) => {
            dispatch(setError('error'));
            dispatch(setCreateShopResponse({ data: { shopId: '' }, isFetching: false }));
            reject(err);
          });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setCreateShopResponse({ data: { shopId: '' }, isFetching: false }));
        reject(e);
      }
    });
  };
};
export const updateShopPrivateDetails = (
  shopDetails: ShopDetailsType,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(updateShopPrivateDetailsResponse({ isFetching: true }));
        await service.update(API_ENDPOINTS_NEW.UPDATE_SHOP_PRIVATE_DETAILS, shopDetails);
        dispatch(updateShopPrivateDetailsResponse({ isFetching: false }));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        dispatch(updateShopPrivateDetailsResponse({ isFetching: false }));
        reject(e);
      }
    });
  };
};

// export const createShop = createAsyncThunk<
//   CreateShopResponse,
//   CreateShopInput>(
//     'shop/create',
//     async (shop, thunkAPI) => {
//       thunkAPI.dispatch(setCreateShopResponse({ data: { shopId: '' }, isFetching: true }));
//       const response = await service.create(API_ENDPOINTS_NEW.CREATE_SHOP, shop);
//       const domainData: RegisterDomainPayload = {
//         domainName: process.env.REACT_APP_DOMAIN_PREFIX,
//         domainPrefix: shop.companyDetails?.companyName,
//         branch: process.env.REACT_APP_BRANCH
//       }
//       await service.create(API_ENDPOINTS_NEW.REGISTER_DOMAIN, domainData);
//       thunkAPI.dispatch(setCreateShopResponse({ data: response.data, isFetching: false }));
//       thunkAPI.dispatch(setError(''));
//       return response;
//     }
//   );
// export const createShop = (
//   shop: CreateShopInput,
// ): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
//   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
//     return new Promise((resolve, reject) => {
//       try {
//         dispatch(setCreateShopResponse({ data: { shopId: '' }, isFetching: true }));
//         service
//           .create(API_ENDPOINTS_NEW.CREATE_SHOP, shop)
//           .then(async (res) => {
//             dispatch(setCreateShopResponse({ data: res.data, isFetching: false }));
//             dispatch(setError(''));
//             resolve(res);
//           })
//           .catch((e) => {
//             dispatch(setError('error'));
//             dispatch(setCreateShopResponse({ data: { shopId: '' }, isFetching: false }));
//             reject(e);
//           });
//       } catch (e) {
//         dispatch(setError('error'));
//         dispatch(setCreateShopResponse({ data: { shopId: '' }, isFetching: false }));
//         reject(e);
//       }
//     });
//   };
// };

export const updateShopPlugIn = (
  data: UpdateShopPlugInPayload,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(updateShopPlugInResponse({ isFetching: true }));
        await service.update(API_ENDPOINTS_NEW.UPDATE_SHOP_PLUGIN, data);
        dispatch(getActiveShopDetails());
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        dispatch(updateShopPlugInResponse({ isFetching: true }));
        reject(e);
      }
    });
  };
};

export const getAllCategories = ({
  pageNumber = 1,
  pageSize = 500,
}: Pagination): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const query = `/?page=${pageNumber}&size=${pageSize}`;
      dispatch(setCategories({ data: [], isFetching: true }));
      const response = await service.all(`${API_ENDPOINTS_NEW.GET_CATEGORIES}${query}`);
      dispatch(setCategories({ ...response.data, isFetching: false }));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
      dispatch(setCategories({ data: [], isFetching: false }));
    }
  };
};

export const getAllAddOnsCategories = ({
  pageNumber = 1,
  pageSize = 500,
}: Pagination): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const query = `/?page=${pageNumber}&size=${pageSize}`;
      dispatch(setAddOnsCategories({ data: [], isFetching: true }));
      const response = await service.all(`${API_ENDPOINTS_NEW.GET_ADDONS_CATEGORIES}${query}`);
      dispatch(setAddOnsCategories({ ...response.data, isFetching: false }));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
      dispatch(setAddOnsCategories({ data: [], isFetching: false }));
    }
  };
};

export const getAllBrands = ({
  pageNumber = 1,
  pageSize = 500,
}: Pagination): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const query = `/?page=${pageNumber}&size=${pageSize}`;
      dispatch(setBrands({ data: [], isFetching: true }));
      const response = await service.all(`${API_ENDPOINTS_NEW.GET_BRANDS}${query}`);
      dispatch(setBrands({ ...response.data, isFetching: false }));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setError('error'));
      dispatch(setCategories({ data: [], isFetching: false }));
    }
  };
};

export const updateProduct = (product: Product): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(updateProductResponse({ isFetching: true }));
        if (product.price === null) {
          product.price = product?.variantDetailsList?.[0].price;
        }
        setTimeout(async () => {
          await service.update(API_ENDPOINTS_NEW.UPDATE_PRODUCT, product).then((res) => {
            dispatch(updateProductResponse({ isFetching: false }));
            dispatch(setError(''));
            resolve(res);
          });
          // dispatch(
          //   getAllProducts({}, { categoryId: '', text: product.productCode ?? '' }, false),
          // ).then((res: any) => {
          //   dispatch(setActiveProduct(res.data[0], SAVE_MODE.EDIT));
          //   resolve(res);
          // });
        }, 500);
      } catch (e) {
        dispatch(setError('error'));
        dispatch(updateProductResponse({ isFetching: false }));
        reject(e);
      }
    });
  };
};

export const createProduct = (product: Product): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(updateProductResponse({ isFetching: true }));
        setTimeout(() => {
          service.create(API_ENDPOINTS_NEW.ADD_PRODUCT, product).then((res) => {
            dispatch(updateProductResponse({ isFetching: false }));
            dispatch(setError(''));
            resolve(res);
          });
        }, 500);
      } catch (e) {
        dispatch(setError('error'));
        dispatch(updateProductResponse({ isFetching: false }));
        reject(e);
      }
    });
  };
};

export const getAllProducts = (
  { pageNumber = 1, pageSize = 10 }: ProductsPagination,
  searchTerm: { categoryId: string; text: string },
  showLoading = true,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (showLoading) {
          dispatch(
            setProducts({
              isFetching: true,
              data: [],
            }),
          );
        }
        let searchQuery = '';
        if (searchTerm.categoryId !== '') {
          searchQuery += `&categoryId=${searchTerm.categoryId}`;
        }
        if (searchTerm.text !== '') {
          searchQuery += `&name=${searchTerm.text} `;
        }
        const query = `/?page=${pageNumber}&size=${pageSize}${searchQuery}`;
        const response = await service.all(`${API_ENDPOINTS_NEW.GET_PRODUCTS}${query} `);
        dispatch(setProducts({ ...response.data, isFetching: false }));
        dispatch(setError(''));
        resolve(response.data);
      } catch (e) {
        dispatch(setError('error'));
        dispatch(
          setProducts({
            isFetching: false,
            data: [],
          }),
        );
      }
    });
  };
};

export const UpdateContactUs = (
  payload: ContactUsPayload,
  isFirstCall: boolean,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(updateContactUsDetailsResponse({ isFetching: true, data: {} }));
        setTimeout(() => {
          const url = isFirstCall
            ? API_ENDPOINTS_NEW.CREATE_CONTACT_US
            : API_ENDPOINTS_NEW.UPDATE_CONTACT_US;
          if (isFirstCall) {
            service.create(url, payload).then((res) => {
              dispatch(getContactUs());
              dispatch(setError(''));
              resolve(res);
            });
          } else {
            service.update(url, payload).then((res) => {
              dispatch(getContactUs());
              dispatch(setError(''));
              resolve(res);
            });
          }
        }, 500);
      } catch (e) {
        dispatch(setError('error'));
        dispatch(updateContactUsDetailsResponse({ isFetching: false }));
        reject(e);
      }
    });
  };
};

export const getContactUs = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(updateContactUsDetailsResponse({ isFetching: true, data: {} }));
        setTimeout(() => {
          service.find(API_ENDPOINTS_NEW.GET_CONTACT_US).then((res) => {
            dispatch(updateContactUsDetailsResponse({ isFetching: false, data: { ...res.data } }));
            dispatch(setError(''));
            resolve(res);
          });
        }, 500);
      } catch (e) {
        dispatch(setError('error'));
        dispatch(updateContactUsDetailsResponse({ isFetching: false }));
        reject(e);
      }
    });
  };
};

export const updateOrderByAdmin = (order: Order): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(fetchOrderId({ data: {}, isFetching: true }));
        service
          .update(API_ENDPOINTS_NEW.UPDATE_ORDER_BY_ADMIN, order)
          .then((res) => {
            dispatch(setError(''));
            resolve(res);
          })
          .then(() => {
            dispatch(setFetchOrderById(order.orderId));
          });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(fetchOrderId({ data: {}, isFetching: false }));
        reject(e);
      }
    });
  };
};

export const createProductList = (
  products: Product[],
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(updateProductsResponse({ isFetching: true }));
        setTimeout(() => {
          service
            .create(API_ENDPOINTS_NEW.ADD_NEW_LIST_OF_PRODUCTS, products)
            .then((res) => {
              dispatch(updateProductsResponse({ isFetching: false }));
              dispatch(setError(''));
              resolve(res);
            })
            .catch(() => {
              dispatch(setError('error'));
              reject('error');
              dispatch(updateProductsResponse({ isFetching: false }));
            });
        }, 500);
      } catch (e) {
        dispatch(setError('error'));
        dispatch(updateProductsResponse({ isFetching: false }));
        reject(e);
      }
    });
  };
};

export const deleteCategoryAction = (
  categoryId?: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const url =
          API_ENDPOINTS_NEW.DELETE_CATEGORY.prefix +
          categoryId +
          API_ENDPOINTS_NEW.DELETE_CATEGORY.suffix;
        service.delete(url).then((res) => {
          // TODO Fetching
          dispatch(getAllCategories({}));
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const deleteBrandAction = (
  brandId?: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const url =
          API_ENDPOINTS_NEW.DELETE_BRAND.prefix + brandId + API_ENDPOINTS_NEW.DELETE_BRAND.suffix;
        service.delete(url).then((res) => {
          // TODO Fetching
          dispatch(getAllCategories({}));
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};

export const sendContactUs = (
  payload: AdminContactUsPayload,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setAdminContactUsResponse({ isFetching: true }));
        const url = API_ENDPOINTS_NEW.CONTACT_US;
        service.create(url, payload).then((response: any) => {
          dispatch(setAdminContactUsResponse({ isFetching: false, success: true }));
        });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setAdminContactUsResponse({ isFetching: false }));
        reject(e);
      }
    });
  };
};

export const getAllOrders = (
  pageNumber: number,
  query?: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setOrders({ data: [], isFetching: true }));
      const response = await service.all(
        API_ENDPOINTS_NEW.GET_ORDERS + `?page=${pageNumber}&${query} `,
      );
      dispatch(setOrders({ ...response.data, isFetching: false }));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setOrders({ data: [], isFetching: false }));
      dispatch(setError('error'));
    }
  };
};

export const getUserOrders = (
  pageNumber: number,
  query?: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setUserOrders({ data: [], isFetching: true }));

      const _query = `?page=${pageNumber}&${query} `;
      const response = await service.all(API_ENDPOINTS_NEW.SEARCH_USER_ORDERS + _query);
      dispatch(setUserOrders({ ...response.data, isFetching: false }));
      dispatch(setError(''));
    } catch (e) {
      dispatch(setUserOrders({ data: [], isFetching: false }));
      dispatch(setError('error'));
    }
  };
};

export const addNewEmployee = (
  userDetails: EmployeeDetails,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        await service.update(API_ENDPOINTS_NEW.ADD_NEW_EMPLOYEE, userDetails);
        dispatch(getAllEmployee());
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const getAllEmployee = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(setShopEmployeesResponse({ data: [], isFetching: true }));
        const response = await service.find(API_ENDPOINTS_NEW.GET_ALL_EMPLOYEE);
        dispatch(setShopEmployeesResponse({ data: response.data, isFetching: false }));
        dispatch(setError(''));
        resolve();
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setShopEmployeesResponse({ data: [], isFetching: false }));
        reject(e);
      }
    });
  };
};

export const deleteEmployee = (userId?: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const url =
          API_ENDPOINTS_NEW.DELETE_EMPLOYEE.prefix +
          userId +
          API_ENDPOINTS_NEW.DELETE_EMPLOYEE.suffix;
        service.delete(url).then((res) => {
          dispatch(getAllEmployee());
          dispatch(setError(''));
          resolve(res);
        });
      } catch (e) {
        dispatch(setError('error'));
        // TODO Fetching;
        reject(e);
      }
    });
  };
};
