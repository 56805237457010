import { useEffect } from 'react';
import Loader from 'src/components/ui/loader/loader';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/utils/routes';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { logOut } from 'src/store/session/actions';
import { clearState } from 'src/store/shop/actions';

function SignOut() {
  const { t }: any = useTranslation<any>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logOut());
    dispatch(clearState());
    setTimeout(() => {
      history.replace(ROUTES.LOGIN);
    }, 1000);
  }, [dispatch, history]);

  return <Loader text={t('signing-out-text')} />;
}

export default SignOut;
