import {
  APIResponse,
  PlanDetailsResponse,
  PlansDetailsResponse,
  ValidateDomainResponse,
  CreateShopInput,
} from 'src/ts-types/custom.types';

export interface SetPlansAction {
  type: 'SET_PLANS';
  response: PlansDetailsResponse;
}
export interface SetGetPlanByIdResponseAction {
  type: 'SET_PLAN_DETAILS_BY_ID_RESPONSE';
  response: PlanDetailsResponse;
}
export interface SetUpdatePlansResponseAction {
  type: 'SET_UPDATE_PLANS';
  response: APIResponse;
}
export interface SetFetching {
  type: 'SET_FETCHING';
  isFetching: boolean;
}
export interface SetError {
  type: 'SET_ERROR';
  error: string;
}
export interface SetShopDataForCreateAction {
  type: 'SET_SHOP_DATA_FOR_CREATE';
  shopData: CreateShopInput | undefined;
}

export interface SetValidateDomainAction {
  type: 'SET_VALIDATE_DOMAIN_RESPONSE';
  response: ValidateDomainResponse;
}

export interface ClearAction {
  type: 'CLEAR_STATE';
}

export interface UpgradePackageAction {
  type: 'SET_UPGRADE_PACKAGE';
  upgrade: boolean;
}

export interface IsRecurrenceAction {
  type: 'SET_RECURRENCE';
  recurrence: boolean;
}

export interface DisplayAction {
  type: 'SET_DISPLAY';
  display: string;
}

export interface ProductSearchAction {
  type: 'SET_PRODUCT_SEARCH';
  searchTerm: { categoryId: string; text: string; page: number };
}

export type Action =
  | SetPlansAction
  | SetFetching
  | SetError
  | SetUpdatePlansResponseAction
  | SetGetPlanByIdResponseAction
  | SetShopDataForCreateAction
  | SetValidateDomainAction
  | UpgradePackageAction
  | IsRecurrenceAction
  | ClearAction
  | DisplayAction
  | ProductSearchAction;

export const setPlanDetails = (response: PlansDetailsResponse): SetPlansAction => {
  return { type: 'SET_PLANS', response };
};
export const setUpdatePlanDetails = (response: APIResponse): SetUpdatePlansResponseAction => {
  return { type: 'SET_UPDATE_PLANS', response };
};
export const setPlanDetailsById = (response: PlanDetailsResponse): SetGetPlanByIdResponseAction => {
  return { type: 'SET_PLAN_DETAILS_BY_ID_RESPONSE', response };
};
export const isFetching = (isFetching: boolean): SetFetching => {
  return { type: 'SET_FETCHING', isFetching };
};
export const setError = (error: string): SetError => {
  return { type: 'SET_ERROR', error };
};
export const setShopDataForCreate = (
  shopData: CreateShopInput | undefined,
): SetShopDataForCreateAction => {
  return { type: 'SET_SHOP_DATA_FOR_CREATE', shopData };
};
export const clear = () => {
  return { type: 'CLEAR_STATE' };
};

export const setValidateDomainResponse = (
  response: ValidateDomainResponse,
): SetValidateDomainAction => {
  return { type: 'SET_VALIDATE_DOMAIN_RESPONSE', response };
};
export const setUpgradePackage = (upgrade: boolean): UpgradePackageAction => {
  return { type: 'SET_UPGRADE_PACKAGE', upgrade };
};
export const setIsRecurrence = (recurrence: boolean): IsRecurrenceAction => {
  return { type: 'SET_RECURRENCE', recurrence };
};
export const setActiveDisplay = (display: string): DisplayAction => {
  return { type: 'SET_DISPLAY', display };
};
export const setProductSearch = (searchTerm: {
  categoryId: string;
  text: string;
  page: number;
}): ProductSearchAction => {
  return { type: 'SET_PRODUCT_SEARCH', searchTerm };
};
