/* eslint-disable @typescript-eslint/no-var-requires */
import { useState, useRef } from 'react';
import cn from 'classnames';
// @ts-ignore
import { CropperPreviewRef, Cropper, CropperRef, CropperPreview } from 'react-advanced-cropper';
import { Navigation } from './components/Navigation';
import { Slider } from './components/Slider';
import { AdjustableCropperBackground } from './components/AdjustableCropperBackground';
import { Button } from './components/Button';
import { ResetIcon } from './icons/ResetIcon';
import 'react-advanced-cropper/dist/style.css';
import { AdjustablePreviewBackground } from './components/AdjustablePreviewBackground';

type Props = {
  imageSrc: string;
  croppedMaxWidth: number;
  isCircle?: boolean;
  croppedMaxHeight: number;
  loading?: boolean;
  saveCroppedImage: (va: Blob) => void;
};
export const ImageEditor = ({
  imageSrc,
  saveCroppedImage,
  croppedMaxWidth,
  croppedMaxHeight,
  isCircle,
  loading = false,
}: Props) => {
  const cropperRef = useRef<CropperRef>(null);
  const previewRef = useRef<CropperPreviewRef>(null);

  const [src, setSrc] = useState(imageSrc);

  const [mode, setMode] = useState('crop');

  const [adjustments, setAdjustments] = useState({
    brightness: 0,
    hue: 0,
    saturation: 0,
    contrast: 0,
  });

  const onChangeValue = (value: number) => {
    if (mode in adjustments) {
      setAdjustments((previousValue) => ({
        ...previousValue,
        [mode]: value,
      }));
    }
  };

  const onReset = () => {
    setMode('crop');
    setAdjustments({
      brightness: 0,
      hue: 0,
      saturation: 0,
      contrast: 0,
    });
  };

  const onUpload = (blob: string) => {
    onReset();
    setMode('crop');
    setSrc(blob);
  };

  const onUpdate = () => {
    previewRef.current?.refresh();
  };

  const saveImage = () => {
    if (cropperRef.current) {
      cropperRef?.current?.getCanvas()?.toBlob((blob: any) => {
        saveCroppedImage(blob);
      });
    }
  };

  const changed = Object.values(adjustments).some((el) => Math.floor(el * 100));

  const cropperEnabled = mode === 'crop';

  return (
    <div className='image-editor mt-14'>
      <div className='image-editor__cropper'>
        <Cropper
          src={src}
          ref={cropperRef}
          stencilProps={{
            handlers: true,
            lines: true,
            movable: true,
            resizable: true,
            overlayClassName: cn(
              'image-editor__cropper-overlay',
              !cropperEnabled && 'image-editor__cropper-overlay--faded',
            ),
          }}
          backgroundWrapperProps={{
            scaleImage: true,
            moveImage: true,
          }}
          backgroundComponent={AdjustableCropperBackground}
          backgroundProps={adjustments}
          onUpdate={onUpdate}
        />
        {mode !== 'crop' && (
          <Slider
            className='image-editor__slider'
            // @ts-ignore
            value={adjustments[mode]}
            onChange={onChangeValue}
          />
        )}
        <CropperPreview
          className={`image-editor__preview w-[${croppedMaxWidth}px] h-[${croppedMaxHeight}px] ${
            isCircle ? 'rounded-full' : ''
          }`}
          ref={previewRef}
          cropper={cropperRef}
          contentClassName='w-[180px] h-[80px]'
          backgroundComponent={AdjustablePreviewBackground}
          backgroundProps={adjustments}
        />
        <Button
          className={cn(
            'image-editor__reset-button',
            !changed && 'image-editor__reset-button--hidden',
          )}
          onClick={onReset}
        >
          <ResetIcon />
        </Button>
      </div>
      <Navigation
        mode={mode}
        onChange={setMode}
        onUpload={onUpload}
        onDownload={saveImage}
        loading={loading}
      />
    </div>
  );
};
