/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
export const OralCare: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.65'
      height='17.65'
      viewBox='0 0 17.65 17.65'
      fill='currentColor'
      {...props}
    >
      <g id='Oral_Care' data-name='Oral Care' transform='translate(-449.675 -599.675)'>
        <path
          id='Path_21772'
          data-name='Path 21772'
          d='M621,1114.334h-11.3a1.214,1.214,0,0,0,0,2.427H621'
          transform='translate(-154.004 -499.761)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.65'
        />
        <path
          id='Path_21773'
          data-name='Path 21773'
          d='M637.875,999.7h6.81l.714-3.343h-8.237Z'
          transform='translate(-181.858 -385.123)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.65'
        />
        <g id='Group_12589' data-name='Group 12589' transform='translate(457.632 613.443)'>
          <line
            id='Line_1'
            data-name='Line 1'
            y1='1.13'
            transform='translate(0 0)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.65'
          />
          <line
            id='Line_2'
            data-name='Line 2'
            y1='1.13'
            transform='translate(1.789 0)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.65'
          />
          <line
            id='Line_3'
            data-name='Line 3'
            y1='1.13'
            transform='translate(3.579 0)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.65'
          />
        </g>
        <path
          id='Path_21774'
          data-name='Path 21774'
          d='M534.133,600h-3.191l-.716,2.464h4.623Z'
          transform='translate(-77.952)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.65'
        />
        <path
          id='Path_21775'
          data-name='Path 21775'
          d='M452.959,1114.333H450v2.427h2.958'
          transform='translate(0 -499.761)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.65'
        />
        <path
          id='Path_21776'
          data-name='Path 21776'
          d='M545.026,850.54c-.128-1.36-.969-2.41-1.988-2.41-1.107,0-2.005,1.241-2.005,2.771a3,3,0,0,0,1.12,2.486'
          transform='translate(-88.454 -241.1)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.65'
        />
        <path
          id='Path_21777'
          data-name='Path 21777'
          d='M458.187,693.955l-.9-4.722-1.625-.805v-1.449h-2.147v1.449l-1.626.805L450,699.088h2.958'
          transform='translate(0 -84.515)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.65'
        />
        <circle
          id='Ellipse_2963'
          data-name='Ellipse 2963'
          cx='1.074'
          cy='1.074'
          r='1.074'
          transform='translate(459.785 604.135) rotate(-45)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.65'
        />
        <path
          id='Path_21778'
          data-name='Path 21778'
          d='M951.683,867.021a.179.179,0,1,1-.179-.179A.179.179,0,0,1,951.683,867.021Z'
          transform='translate(-487.121 -259.281)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.65'
        />
        <g id='Group_12590' data-name='Group 12590' transform='translate(464.853 600)'>
          <line
            id='Line_4'
            data-name='Line 4'
            x2='2.147'
            transform='translate(0 1.074)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.65'
          />
          <line
            id='Line_5'
            data-name='Line 5'
            y2='2.147'
            transform='translate(1.074)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.65'
          />
        </g>
      </g>
    </svg>
  );
};
