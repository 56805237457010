import Card from 'src/components/common/card';
import Search, { SearchValue } from 'src/components/common/search';
import ProductList from 'src/components/product/product-list';
import { useEffect, useState } from 'react';
import { ProductType, SortOrder } from 'src/ts-types/generated';
import CategoryTypeFilter from 'src/components/product/category-type-filter';
import cn from 'classnames';
import { useModalAction } from 'src/components/ui/modal/modal.context';
import Button from 'src/components/ui/button';
import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import { setActiveProduct, setProductVariant } from 'src/store/shop/actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/utils/routes';
import { PAGE_SIZE, SAVE_MODE } from 'src/ts-types';
import Loader from 'src/components/ui/loader/loader';
import { Categories, Category } from 'src/ts-types/custom.types';
import { getAllProducts, getAllCategories, getAllBrands } from 'src/store/shop/shopsAPIActions';
import AddCategoryFirstAlert from 'src/components/shop/addCategoryFirstAlert';
import { RootState } from 'src/store';
import { setProductSearch } from 'src/store/utilData/actionTypes';

const ProductsPage = () => {
  const products = useAppSelector((state: RootState) => state.shop.shopDetails?.products);
  const searchTerm = useAppSelector((state: RootState) => state.util.UtilData?.searchTerm);

  const shopData = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse?.data,
  );
  const categories = useAppSelector(
    (state: RootState) => state.shop?.shopDetails?.categories,
  ) as Categories;
  const packageId = useAppSelector(
    (state: RootState) => state.shop?.shopDetails?.activeShopDetailsResponse?.data.packageId,
  );
  const planElement = useAppSelector((state: RootState) =>
    state.util.UtilData?.planDetailsResponse?.data.find((x) => x.packageId == packageId),
  );
  const dispatch = useAppDispatch();
  const shopId = shopData?.shopId;
  const { t }: any = useTranslation();

  const [, setType] = useState('');
  const [category, setCategory] = useState<Category>();
  const [page, setPage] = useState(1);
  const [, setOrder] = useState('created_at');
  const [, setColumn] = useState<SortOrder>(SortOrder.Desc);
  const [visible, setVisible] = useState(false);
  const { openModal } = useModalAction();
  const [productsCount] = useState(0);
  const loading = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.products?.isFetching,
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(getAllCategories({}));
    dispatch(getAllBrands({}));
  }, [dispatch]);

  useEffect(() => {
    const { page: _page = 1 } = searchTerm ?? {};
    dispatch(
      getAllProducts(
        {
          pageNumber: _page,
          pageSize: PAGE_SIZE,
        },
        searchTerm ?? { categoryId: '', text: '' },
      ),
    );
  }, [dispatch, category]);

  const toggleVisible = () => {
    setVisible((v) => !v);
    console.log(visible);
    if (visible) {
      setCategory({});
    }
  };

  function handleImportModal() {
    openModal('EXPORT_IMPORT_PRODUCT', shopId);
  }

  // if (loading || fetchingShop)
  //   return <Loader text={t("text-loading")} />;
  // if (error) return <ErrorMessage message={error?.message!} />;

  const handleSearch = (data: string) => {
    const searchValue = searchTerm ?? { categoryId: '', text: '', page: page };
    searchValue.text = data ?? '';
    dispatch(setProductSearch(searchValue));
    dispatch(getAllProducts({}, searchValue));
  };

  const handlePagination = (current: any) => {
    const { categoryId = '', text = '' } = searchTerm ?? {};
    dispatch(setProductSearch({ categoryId, text, page: current as number }));
    setPage(current);
    dispatch(
      getAllProducts(
        {
          pageNumber: current,
          pageSize: PAGE_SIZE,
        },
        searchTerm ?? { categoryId: '', text: '' },
      ),
    );
  };

  const handleCreateNewProduct = () => {
    dispatch(
      setActiveProduct(
        {
          price: 0,
          mainImage: '',
          moreImages: [],
          brandId: '',
          categoryId: '',
          variantAllOptions: [],
          variantDetailsList: [],
          discountPrice: 0,
          active: true,
          topProduct: false,
          hotProduct: false,
          name: [],
        },
        SAVE_MODE.SAVE,
      ),
    );
    dispatch(setProductVariant([]));
    history.push(ROUTES.EDIT_PRODUCT);
  };

  const handleCategoryChanged = (value: Category) => {
    setPage(1);

    // Provide default values right when destructuring `searchTerm` and ensure all values are defined.
    const { categoryId = '', text = '' } = searchTerm || {};

    const searchValue = {
      categoryId: value?.categoryId || categoryId, // Ensures a string, defaults to an empty string or a previous categoryId
      text: text, // Already defaulted to an empty string if undefined
      page: 1, // Reset to 1 as per the function's logic
    };

    dispatch(setProductSearch(searchValue));
    setCategory(value);
  };

  const clearSearch = () => {
    setCategory({});
    dispatch(setProductSearch({ categoryId: '', text: '', page: 1 }));
    dispatch(getAllProducts({}, { categoryId: '', text: '' }));
  };

  const searchChange = (value: string) => {
    const { categoryId = '', page = 1 } = searchTerm || {};

    const searchValue = {
      categoryId: categoryId,
      text: value,
      page: page,
    };

    dispatch(setProductSearch(searchValue));
  };
  const handleUpdate = () => {
    dispatch(
      getAllProducts(
        {
          pageNumber: page,
          pageSize: PAGE_SIZE,
        },
        searchTerm ?? { categoryId: '', text: '' },
      ),
    );
  };

  if (loading) return <Loader text={t('text-loading')} />;
  const hasCategory = categories && categories.data?.length > 0;
  return (
    <>
      <Card className='flex flex-col mb-4'>
        <div className='w-full flex md:flex-row smx:flex-col md:items-center'>
          <div className='md:w-1/6 mb-4 md:mb-0 flex flex-row justify-between items-center'>
            <h1 className='text-lg font-semibold text-heading'>{t('input-label-products')}</h1>
            {/* <button
              className='text-accent text-base font-semibold flex items-center md:hidden'
              onClick={toggleVisible}
            >
              {t('text-filter')}{' '}
              {visible ? <ArrowUp className='ml-2' /> : <ArrowDown className='ml-2' />}
            </button> */}
          </div>

          <div className='w-full md:w-3/4 flex smx:flex-col md:flex-row items-center'>
            <div className='w-full flex items-center'>
              <Search
                onSearch={() => handleSearch(searchTerm?.text ?? '')}
                value={searchTerm?.text}
                onChange={(e: any) => searchChange(e.target.value)}
              />
            </div>
            <div className='flex smx:flex-row smx:pt-4'>
              <Button
                onClick={() => handleCreateNewProduct()}
                disabled={!hasCategory && productsCount < (planElement?.maxNumberOfProducts ?? 0)}
                className='h-12 ms-4 md:ms-6'
              >
                <span>+ {t('button-label-add-product')}</span>
              </Button>

              <Button className='ms-4 md:ms-6' onClick={() => history.push(ROUTES.CREATE_CATEGORY)}>
                <span>{t('button-label-add-categories')} +</span>
              </Button>
              {/* <button
                className='text-accent text-base font-semibold flex items-center md:ms-5 mt-5 md:mt-0 smx:hidden'
                onClick={toggleVisible}
              >
                {t('text-filter')}{' '}
                {visible ? <ArrowUp className='ms-2' /> : <ArrowDown className='ms-2' />}
              </button> */}

              <button
                // onClick={handleImportModal}
                className='hidden md:flex w-8 h-8 rounded-full bg-gray-50 hover:bg-gray-100 items-center justify-center flex-shrink-0 ms-5 transition duration-300 mt-2'
              >
                {/* <MoreIcon className='w-3.5 text-body' /> */}
              </button>
            </div>
          </div>
        </div>

        <div className={cn('w-full flex transition h-auto visible', {})}>
          <div
            className={`${hasCategory ? 'block' : 'hidden'} flex flex-col md:flex-row md:items-center mt-5 md:mt-8 border-t border-gray-200 pt-5 md:pt-8 w-full`}
          >
            <CategoryTypeFilter
              className='w-full'
              onCategoryFilter={(value: any) => {
                handleCategoryChanged(value);
              }}
              onTypeFilter={({ slug }: { slug: string }) => {
                setType(slug);
              }}
              category={category}
            />
            <div className='mt-5 w-1/3 pr-8 mt-[-1px]'>
              <Button onClick={clearSearch} className='mt-5 w-1/2' variant='outline'>
                {t('text-clear')}
              </Button>
            </div>
          </div>
        </div>
      </Card>
      {!hasCategory ? (
        <AddCategoryFirstAlert />
      ) : (
        <div className=''>
          {/* <div className=' bg-light text-sm text-gray-500 leading-none rounded-full inline-flex p-1'>
            <button
              className='inline-flex items-center border transition-colors duration-300 ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-full p-1'
              id='grid'
            >
              <img
                src='/image/grid.png'
                className='w-8 h-8 rounded-full'
                data-uw-rm-alt-original=''
              />
            </button>
            <button
              className='inline-flex items-center border transition-colors duration-300 ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-full border-accent p-1'
              id='list'
            >
              <img
                src='/image/list.png'
                className='w-8 h-8 rounded-full'
                data-uw-rm-alt-original=''
              />
            </button>
          </div> */}
          <ProductList
            products={products}
            onUpdate={() => handleUpdate()}
            onPagination={handlePagination}
            onOrder={setOrder}
            onSort={setColumn}
            searchText={searchTerm?.text}
          />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
