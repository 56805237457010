import * as yup from 'yup';

export const shippingFormValidation = yup.object().shape({
  adMessage: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        Language: yup.string(),
      }),
    )
    .compact((v) => !v.checked)
    .required('required'),
  shippingPrice: yup
    .number()
    .typeError('error-price-must-number')
    .positive('error-price-must-positive')
    .required('error-price-required'),
  active: yup.bool().required('error-status-required'),
});
