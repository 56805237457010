/* eslint-disable react/display-name */
import cn from 'classnames';
import _ from 'lodash';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { ValidateDomainResponse } from 'src/ts-types/custom.types';
import Card from '../common/card';
import { useTranslation } from 'react-i18next';
import Loader from './loader/loader';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  required?: boolean;
  containerClassName?: string;
  suffix?: string;
  prefix?: string;
  label?: string;
  note?: string;
  icon?: any;
  name: string;
  labelClass?: string;
  error?: string;
  type?: string;
  loading: boolean;
  setNewDomainAction: (val: string) => void;
  shadow?: boolean;
  watch: any;
  domainIsNotValid?: boolean;
  variant?: 'normal' | 'solid' | 'outline';
  size?: any | 'small' | 'medium' | 'large';
  validateDomainResponse?: ValidateDomainResponse;
}
const classes = {
  root: 'px-4 h-12 flex items-center w-full appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0',
  normal: 'bg-gray-100 border-border-base focus:shadow focus:bg-light focus:border-accent',
  solid: 'bg-gray-100 border-border-base focus:bg-light focus:border-accent',
  outline: 'border-border-base  focus:border-accent',
  shadow: 'focus:shadow',
  small: 'md:w-1/3',
  medium: 'md:w-2/3',
  large: 'md:w-3/3',
};

const DomainInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      size = 'large',
      label,
      icon,
      note,
      name,
      error,
      children,
      required,
      variant = 'normal',
      suffix = '',
      prefix = '',
      shadow = false,
      type = 'text',
      labelClass = '',
      loading,
      domainIsNotValid = false,
      watch,
      inputClassName,
      setNewDomainAction,
      validateDomainResponse,
      ...rest
    },
    ref,
  ) => {
    const { t }: any = useTranslation<any>();
    const [showLoading, setShowLoading] = useState(false);
    const [isDivClicked, setIsDivClicked] = useState(false);
    const valueIsNotEmpty = !_.isEmpty(watch('website'));
    const { suggestedDomain, validDomain } = validateDomainResponse ?? {
      suggestedDomain: [],
      validDomain: true,
    };
    const divRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      function handleClickOutside(event: any) {
        if (divRef.current && !divRef.current.contains(event.target)) {
          // Clicked outside the div
          setIsDivClicked(false);
        }
      }

      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      setShowLoading(false);
    }, [validDomain]);

    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === 'normal',
        [classes.solid]: variant === 'solid',
        [classes.outline]: variant === 'outline',
      },
      {
        [classes.shadow]: shadow,
      },
      {
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
      },
      inputClassName,
    );

    const renderEditIcon = () => {
      return (
        <>
          <div className='flex float-right cursor-pointer text-accent font-bold'>
            <span className=''>{t('domain-already-taken-chose-another')}</span>
          </div>
        </>
      );
    };
    const suggestionCardIsOpen = suggestedDomain?.length > 0 && !validDomain;

    const renderHelperText = () => {
      if ((loading || showLoading) && !suggestionCardIsOpen && valueIsNotEmpty) {
        return <p className='text-sm text-start text-accent-500'>{t('domain-helper-text')}</p>;
      } else if (validDomain && valueIsNotEmpty && !(loading || showLoading)) {
        return <p className='text-sm text-start text-accent-500'>{t('domain-is-Valid')}</p>;
      } else if (!validDomain && domainIsNotValid) {
        return <p className='text-sm text-start text-red-500'>{t('domain-is-required')}</p>;
      }
    };

    return (
      <div className={'mb-4 w-full flex-1 svelte-1l8159u '}>
        <div className='flex items-center justify-between mb-3 mt-3'>
          <label
            htmlFor={name}
            className={`${
              required ? 'required-input' : ''
            }  text-body-dark font-semibold text-sm leading-none`}
          >
            {label}
          </label>
        </div>
        <div
          className={
            'w-full lg:pl-[0px] relative h-12 inline-block items-center rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0'
          }
        >
          <div className='absolute top-2 left-8'>
            {(loading || showLoading) && !suggestionCardIsOpen && valueIsNotEmpty && (
              <Loader className='h-[20px]' text={t('text-loading')} simple />
            )}
          </div>
          {/* <div className='flex '>
            <div className='justify-start w-full'>
              {!_.isEmpty(prefix) && <span className='text-slate-400 '>{prefix}</span>}
            </div>
            <div className='justify-start w-full text-accent'>{rest.value}</div>
            <div className='justify-end'>
              {!_.isEmpty(suffix) && <span className='text-slate-400 '>{suffix}</span>}
            </div>
          </div> */}
          {/*
          <div className=' top-4 left-3 pl-18'></div>
          <div className=' top-4 right-3 pl-18'></div> */}
          <div className='flex' ref={divRef} onFocus={() => setIsDivClicked(true)}>
            <div
              className={`flex rounded-r items-end border-r border-t border-b ${
                isDivClicked ? 'border-accent' : 'border-border-base'
              } p-1 pb-3 text-accent`}
            >
              {prefix}
            </div>
            <input
              id={name}
              key={name}
              name={name}
              type={type}
              ref={ref}
              className={`p-1 px-2 appearance-none outline-none w-full text-gray-800
             focus:outline-none focus:ring-0 ${rootClassName} items-end border-b border-t`}
              autoComplete='off'
              autoCorrect='off'
              onInputCapture={() => setShowLoading(true)}
              autoCapitalize='off'
              spellCheck='false'
              aria-invalid={error ? 'true' : 'false'}
              {...rest}
            />
            <div
              className={`flex rounded-l items-end border-l border-t border-b ${
                isDivClicked ? 'border-accent' : 'border-border-base'
              } p-1 pb-3 text-accent`}
            >
              {suffix}
            </div>
          </div>

          {suggestionCardIsOpen && (
            <Card title={renderEditIcon()} className='bg-[#ffffff]'>
              <ul className='overflow-auto max-h-[250px] '>
                {suggestedDomain.map((item: string, index: number) => (
                  <li
                    key={index}
                    onClick={() => {
                      setNewDomainAction(item);
                      setShowLoading(false);
                    }}
                    className='w-ful border-b-2 
                    border-neutral-100 border-opacity-100 py-4 
                    dark:border-opacity-50 cursor-pointer text-right'
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </Card>
          )}
          {renderHelperText()}
          {/* <div className="absolute top-2 right-2">
             <button className="h-10 w-20 text-white rounded-lg bg-red-500 hover:bg-red-600">Search</button> </div> */}
        </div>
      </div>
    );
  },
);

export default DomainInput;
