/* eslint-disable @typescript-eslint/no-explicit-any */
export const Tools: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='currentColor'
      {...props}
    >
      <g id='Tools' transform='translate(-448 -720)'>
        <path
          id='Path_17429'
          data-name='Path 17429'
          d='M477.063,765h-.88l.851-3.4-.967-3.865L474.25,765H455.5l3.281-13.125H474.6L474.135,750H459.25l3.281-13.125h8.322L470.385,735H463l3.75-15h-1.933l-14.531,58.125h-1.348a.938.938,0,0,0,0,1.875h3.75a.938.938,0,0,0,0-1.875h-.469l2.813-11.25h18.75l-2.812,11.25h-1.406a.938.938,0,0,0,0,1.875h3.75a.938.938,0,0,0,0-1.875H472.9l2.813-11.25h1.349a.938.938,0,0,0,0-1.875Z'
          fill='currentColor'
        />
        <path
          id='Path_17430'
          data-name='Path 17430'
          d='M508.434,778.125h-1.349l-2.812-11.25h.412a.938.938,0,0,0,0-1.875h-.88l-3.281-13.125h.412a.938.938,0,0,0,0-1.875h-.88l-3.281-13.125h.412a.938.938,0,0,0,0-1.875h-.88l-3.281-13.125h2.287a.938.938,0,0,0,0-1.875H469.938l.114.455,14.417,57.67h-.411a.938.938,0,0,0,0,1.875h3.75a.938.938,0,0,0,0-1.875H486.4l-2.812-11.25h18.75l2.813,11.25h-.469a.938.938,0,0,0,0,1.875h3.75a.938.938,0,0,0,0-1.875Zm-36.094-56.25h18.75L494.371,735h-18.75Zm3.75,15h18.75L498.121,750h-18.75ZM483.121,765l-3.281-13.125h18.75L501.871,765Z'
          transform='translate(-1.371)'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
