export const BlackList = [
  'auth/v1/loginByGoogle',
  'auth/v1/loginByMobile',
  'auth/v1/loginByEmail',
  'auth/v1/registerByMobile',
  'auth/v1/refreshtoken',
  'auth/v1/loginByFacebook',
  'auth/v1/registerByEmail',
  'auth/v1/otp/sendVerificationCode',
  'auth/v1/otp/validate',
  'shop/v1/package/getAllPackagesToPurchase',
];
