import cn from 'classnames';
import { OrderStatus } from 'src/ts-types/custom.types';
import { CheckMark } from '../icons/checkmark';
import Scrollbar from '../ui/scrollbar';
import '../ui/progress-box/progress-box.module.css';
import styles from './progress-box.module.css';
import { useTranslation } from 'react-i18next';
import { ORDER_STATUS } from 'src/ts-types';

type ProgressProps = {
  data: any[];
  status?: OrderStatus;
};

const ProgressBox: React.FC<ProgressProps> = ({ status, data }: ProgressProps) => {
  const { t }: any = useTranslation<any>();
  if (!status) return null;
  const statusIndex = Object.values(data).indexOf(status.toLowerCase());
  const failedIndex = Object.values(data).indexOf(ORDER_STATUS.FAILED.toLowerCase());

  return (
    <Scrollbar
      className='h-full w-full'
      options={{
        scrollbars: {
          autoHide: 'never',
        },
      }}
    >
      <div className='flex w-full flex-col py-7 md:flex-row md:items-start md:justify-start'>
        {data?.map((item: any, index: number) => (
          <>
            {failedIndex !== index ? (
              <div className={styles.progress_container} key={item.id}>
                <div
                  className={cn(
                    styles.progress_wrapper,
                    statusIndex >= Object.values(data).indexOf(item) ? styles.checked : '',
                  )}
                >
                  <div className={styles.status_wrapper}>
                    {statusIndex >= Object.values(data).indexOf(item) ? (
                      <div className='w-3 h-4'>
                        <CheckMark className='w-full' />
                      </div>
                    ) : (
                      Object.values(data).indexOf(item)
                    )}
                  </div>
                  <div className={styles.bar} />
                </div>

                <div className='flex flex-col items-start ms-5 md:items-center md:ms-0'>
                  {item && (
                    <span className='text-base text-body-dark capitalize font-semibold text-start md:text-center md:px-2'>
                      {t(`order_status_${item}`)}
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ))}
      </div>
    </Scrollbar>
  );
};

export default ProgressBox;
