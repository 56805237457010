import { useTranslation } from 'react-i18next';

export type SiteTemplate = {
  label: string;
  value: string;
  url: string;
  img: string;
};

export type Props = {
  selectedType: string;
  updateSiteTemplateType: (e: any, val: string) => void;
};

const siteTemplates: SiteTemplate[] = [
  {
    label: 'Classic',
    value: 'classic',
    img: '/image/classic.png',
    url: 'https://ntboutique.ngoshops.com',
  },
  {
    label: 'Modern',
    value: 'modern',
    img: '/image/modern.png',
    url: 'https://suzi.ngoshops.com',
  },
  {
    label: 'Standard',
    value: 'standard',
    img: '/image/standard.png',
    url: 'https://bookshoptest1.ngoshops.com',
  },
  {
    label: 'Beauty',
    value: 'beauty',
    img: '/image/beauty.png',
    url: 'https://pharma.ngoshops.com',
  },
  {
    label: 'ElegancePro',
    value: 'elegancePro',
    img: '/image/beauty.png',
    url: 'https://sydlyty.co.il',
  },
  {
    label: 'Contemporary',
    value: 'contemporary',
    img: '/image/beauty.png',
    url: 'https://sydlyty.co.il',
  },
  {
    label: 'Vintage',
    value: 'vintage',
    img: '/image/beauty.png',
    url: 'https://sydlyty.co.il',
  },
  {
    label: 'Minimal',
    value: 'minimal',
    img: '/image/beauty.png',
    url: 'https://sydlyty.co.il',
  },
];

const SiteTemplates = ({ selectedType, updateSiteTemplateType }: Props) => {
  const { t }: any = useTranslation<any>();

  const handleSiteLayoutSelect = (e: any, val: string) => {
    updateSiteTemplateType(e, val);
  };

  const handleLinkClick = (e: any, url: string) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(url, '_blank');
  };

  return (
    <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 content-evenly animate-in slide-in-from-top mt-10'>
      {siteTemplates.map((siteLayout: SiteTemplate, index) => (
        <div key={index}>
          <h5 className='text-gray-900 text-base leading-tight font-small text-center'>
            {t(siteLayout.label)}
          </h5>
          <p
            className='w-full whitespace-nowrap overflow-hidden text-ellipsis 
            pt-2 ml-4 items-center text-blue-600 text-sm max-w-[200px] ltr cursor-pointer'
            onClick={(e) => handleLinkClick(e, siteLayout.url)}
          >
            {siteLayout.url}
          </p>
          <div
            className={`flex p-1 block rounded-lg shadow-lg bg-white place-items-center cursor-pointer 
                        max-w-[300px] sm:h-[200px] sm:w-full md:h-[240px] md:w-[200px] ${
                          selectedType === siteLayout.value
                            ? 'border-accent border-2 '
                            : 'border border-border-100 hover:border-accent'
                        }`}
            onClick={(e) => handleSiteLayoutSelect(e, siteLayout.value)}
          >
            <img
              src={siteLayout.img ?? '/product-placeholder-borderless.svg'}
              alt={siteLayout.label}
              className='object-contain'
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SiteTemplates;
