/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const InstagramIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg data-name='Group 96' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' {...props}>
    <path
      data-name='Path 1'
      d='M8.5 1A2.507 2.507 0 0111 3.5v5A2.507 2.507 0 018.5 11h-5A2.507 2.507 0 011 8.5v-5A2.507 2.507 0 013.5 1h5m0-1h-5A3.51 3.51 0 000 3.5v5A3.51 3.51 0 003.5 12h5A3.51 3.51 0 0012 8.5v-5A3.51 3.51 0 008.5 0z'
      fill='currentColor'
    />
    <path
      data-name='Path 2'
      d='M9.25 3.5a.75.75 0 11.75-.75.748.748 0 01-.75.75zM6 4a2 2 0 11-2 2 2 2 0 012-2m0-1a3 3 0 103 3 3 3 0 00-3-3z'
      fill='currentColor'
    />
  </svg>
);
