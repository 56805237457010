/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export const OrdersIcon: React.FC<React.SVGAttributes<any>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.352 21.073' {...props}>
    <g data-name='Group 5777'>
      <g data-name='Group 5776'>
        <path
          data-name='Path 843'
          d='M18.269 5.816l-.016-.027a1 1 0 00-.074-.129L15.247.592A1.189 1.189 0 0014.221 0H4.131a1.189 1.189 0 00-1.027.593L.125 5.762a.618.618 0 00-.06.145A1.044 1.044 0 000 6.27v13.35a1.454 1.454 0 001.452 1.452h15.447a1.454 1.454 0 001.452-1.452V6.222v-.053a.615.615 0 00-.082-.353zM9.815 1.235h4.378l2.305 3.987H9.815zm-5.654 0H8.58v3.987H1.862zm12.955 18.386a.217.217 0 01-.217.217H1.452a.217.217 0 01-.217-.217V6.458h15.881z'
          fill='currentColor'
        />
      </g>
    </g>
    <g data-name='Group 5779'>
      <g data-name='Group 5778'>
        <path
          data-name='Path 844'
          d='M11.979 11.114a.618.618 0 00-.873 0l-2.758 2.758-1.06-1.06a.618.618 0 10-.873.873l1.5 1.5a.617.617 0 00.873 0l3.194-3.194a.618.618 0 00-.003-.877z'
          fill='currentColor'
        />
      </g>
    </g>
  </svg>
);
