import Table from 'rc-table';
import { useTranslation } from 'react-i18next';
import * as categoriesIcon from 'src/components/icons/category';
import { setActivePlugin } from 'src/store/shop/actions';
import { SAVE_MODE } from 'src/ts-types';
import { PluginResponse, PlugInType, TranslationObject } from 'src/ts-types/custom.types';
import { getIcon } from 'src/utils/get-icon';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { ROUTES } from 'src/utils/routes';
import ActionButtons from '../common/action-buttons';
import { useHistory } from 'react-router-dom';
import { getLocale, getTranslatedValue } from 'src/common';

export type IProps = {
  plugins?: PluginResponse;
};

const PluginList = ({ plugins }: IProps) => {
  const { t }: any = useTranslation<any>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const columns = [
    {
      title: t('table-item-package-title'),
      dataIndex: 'plugInTitleWithTranslations',
      key: 'plugInTitleWithTranslations',
      align: 'center',
      width: 74,
      render: (pluginTitleWithTranslation: TranslationObject[]) => (
        <>{`${getTranslatedValue(pluginTitleWithTranslation, getLocale() ?? 'he')}    `}</>
      ),
    },
    {
      title: t('description'),
      dataIndex: 'plugInDescriptionWithTranslations',
      key: 'plugInDescriptionWithTranslations',
      align: 'center',
      width: 74,
      render: (plugInDescriptionWithTranslations: TranslationObject[]) => (
        <>{`${getTranslatedValue(plugInDescriptionWithTranslations, getLocale() ?? 'he')}    `}</>
      ),
    },
    {
      title: t('table-item-icon'),
      dataIndex: 'icon',
      key: 'icon',
      align: 'center',
      width: 80,
      render: (icon: string) => {
        if (!icon) return null;
        return (
          <span className='flex items-center justify-center'>
            {getIcon({
              iconList: categoriesIcon,
              iconName: icon,
              className: 'w-5 h-5 max-h-full max-w-full',
            })}
          </span>
        );
      },
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 80,
      render: (id: string, record: PlugInType) => (
        <ActionButtons
          id={id || ''}
          editAction={() => {
            dispatch(setActivePlugin(record, SAVE_MODE.EDIT)),
              history.push(ROUTES.EDIT_CREATE_PLUGIN);
          }}
          editUrl={ROUTES.EDIT_CREATE_PLUGIN}
          deleteModalView='DELETE_PLUGIN'
        />
      ),
    },
  ];

  return (
    <>
      <div className='rounded overflow-hidden shadow mb-6'>
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t('empty-table-data')}
          data={plugins?.data}
          rowKey='plugInId'
          scroll={{ x: 900 }}
          rowClassName={() => 'cursor-pointer'}
          onRow={(record, index) => ({
            style: {
              cursor: 'pointer',
            },
          })}
        />
      </div>
    </>
  );
};

export default PluginList;
