import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import get from 'lodash/get';
import { PlanDetails, PlansDetailsResponse } from 'src/ts-types/custom.types';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'src/utils/use-priceNew';
import Notification from 'src/components/ui/notification';
import parse from 'html-react-parser';
import Loader from '../ui/loader/loader';
import { useEffect, useState } from 'react';
import { RootState } from 'src/store';
import { ROUTES } from 'src/utils/routes';
import { setIsRecurrence } from 'src/store/utilData/actionTypes';
import { isSuperAdmin } from 'src/utils/authUtils';
import { SYSTEM_MESSAGE } from 'src/utils/constants';

interface Props {
  errors: any;
  showFreePackage?: boolean;
  selectedPackage?: string;
  setSelectedPackage?: (val: any) => void;
  show: boolean;
  setValue?: (val: any) => void;
  onNextClick?: () => void;
  onClickTryForFree?: () => void;
}
const PlansForm = ({
  setSelectedPackage,
  show,
  setValue,
  onClickTryForFree,
  showFreePackage = false,
}: Props) => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const [showMonthlyPrice, setShowMonthlyPrice] = useState(true);
  const data = useAppSelector((state: RootState) => state.util.UtilData);
  const plansData: PlansDetailsResponse = get(data, 'planDetailsResponse', {
    data: [],
    isFetching: false,
  });
  const [packageId, setPackage] = useState<string | undefined>('');

  useEffect(() => {
    setShowMonthlyPrice(true);
    dispatch(setIsRecurrence(true));
    const defaultPackage =
      plansData && plansData.data && plansData.data.length > 0 ? plansData.data[0].packageId : '';
    handlePackageSelect(defaultPackage);
  }, []);
  if (!show) {
    return null;
  }
  const handlePackageSelect = (value?: string) => {
    setValue?.(value);
    setPackage(value);
    setSelectedPackage?.(value);
  };

  const handleContactUs = () => {
    console.log('handleContactUs');
  };
  if (plansData?.isFetching) return <Loader text={t('text-loading')} />;

  const setRecurrencePayment = (recurrence: boolean) => {
    setShowMonthlyPrice(recurrence);
    dispatch(setIsRecurrence(recurrence));
  };

  return (
    <>
      <div
        className={` ${
          showFreePackage ? ' items-center justify-center relative md:flex smx:flex-col-2' : ''
        }`}
      >
        {showFreePackage && (
          <Notification variant='info' className='md:pl-8'>
            <>
              <span className='text-right'>{t(SYSTEM_MESSAGE.FREE_TRAIL_MESSAGE)}</span>
              <a
                onClick={onClickTryForFree}
                href='#'
                className='text-blue-600 text-lg cursor-pointer underline font-bold py-1.5 mr-1 
                         text-center inline-flex items-center'
              >
                {t('text-create-shop')}
              </a>
            </>
          </Notification>
        )}

        <Notification variant='warning' className='md:pl-8'>
          <>
            <span className='text-right'>{t(SYSTEM_MESSAGE.ENTERPRISE)}</span>
            <a
              onClick={handleContactUs}
              href={ROUTES.ADMIN_CONTACT_US}
              className='text-blue-600 text-lg cursor-pointer underline font-bold py-1.5 mr-1 
                         text-center inline-flex items-center'
            >
              {t('text-contact-us')}
            </a>
          </>
        </Notification>
      </div>
      <>
        <div className='text-center'>
          <div className='hero_btn md:pb-2'>
            <button
              id='monthlyButton'
              className={`main-btn pricing_btn border border-accent text-accent ${
                showMonthlyPrice ? 'bg-[#e7faf6] ' : ''
              }`}
              onClick={() => setRecurrencePayment(true)}
            >
              חודשי
            </button>
            <button
              id='yearlyButton'
              className={`main-btn pricing_btn mr-2 border border-accent text-accent ${
                !showMonthlyPrice ? 'bg-[#e7faf6] ' : ''
              }`}
              onClick={() => setRecurrencePayment(false)}
            >
              שנתי
            </button>
          </div>
        </div>
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-3 gap-4 content-evenly animate-in slide-in-from-top ${
            !showFreePackage ? 'mt-2' : ''
          }`}
        >
          {plansData?.data?.map((item: PlanDetails, index: number) => {
            const { maxNumberOfProducts = 0, active } = item;
            const displayPremium = isSuperAdmin() ? true : maxNumberOfProducts <= 1000;
            return (
              <>
                {active && displayPremium && (
                  <div
                    className={`bg-white border max-w-sm p-8 shadow-lg rounded ${
                      packageId === item.packageId ? 'border-accent border-2 ' : ''
                    }
                                     cursor-pointer hover:border-accent`}
                    key={`${item.packageId}_${index}`}
                    onClick={(e) => handlePackageSelect(item?.packageId)}
                  >
                    <h3 className='text-2xl text-center'>{item.packageTitle}</h3>
                    <p className='text-center'>
                      {formatPrice({
                        // @ts-ignore
                        price: showMonthlyPrice ? item?.price : item.priceForOnePayment,
                        quantity: 1,
                        displaySign: true,
                        signToDisplay: showMonthlyPrice ? t('input-expiration-month') : t('year'),
                      })}
                    </p>
                    <div>{parse(item.packageDescription ?? '')}</div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </>
    </>
  );
};

export default PlansForm;
