// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDnHEfPznNxpEFBKIanq3AwjMV9zZIta88',
  authDomain: 'theshop-334912.firebaseapp.com',
  projectId: 'theshop-334912',
  storageBucket: 'theshop-334912.appspot.com',
  messagingSenderId: '1076952507379',
  appId: '1:1076952507379:web:0aefecf3e2cbd1e973a886',
  measurementId: 'G-FG8TZVW6Z6',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
