import cn from 'classnames';
import { TriangleArrowDown } from 'src/components/icons/triangle-arrow-down';
import { TriangleArrowUp } from 'src/components/icons/triangle-arrow-up';

type Props = {
  title: string | React.ReactNode;
  ascending: boolean;
  isActive: boolean;
  className?: string;
};

const TitleWithSort = ({ title, ascending, isActive = true, className = '' }: Props) => {
  return (
    <span className={`${className} inline-flex items-center`}>
      <span>{title}</span>

      {ascending ? (
        <TriangleArrowUp
          width='9'
          className={cn('mr-2 flex-shrink-0 text-gray-300', {
            '!text-heading': isActive,
          })}
        />
      ) : (
        <TriangleArrowDown
          width='9'
          className={cn('mr-2 flex-shrink-0 text-gray-300', {
            '!text-heading': isActive,
          })}
        />
      )}
    </span>
  );
};

export default TitleWithSort;
